import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import TalentProfileComponent from 'components/TalentProfile'
import DeleteModal from 'components/DeleteModal'
import TalentProfileSkeleton from 'components/TalentProfile/TalentProfile.skeleton'
import useUserSubscriptions from 'hooks/useUserSubscriptions'
import { Chapter, EnumPipelineType } from 'hooks/useApollo'
import EditTalentModal from 'components/EditTalentModal'
import { TeamContextProps, useTeamContext } from 'screens/Teams/Teams.config'
import { getPreviewLink } from 'constants/config'
import ReminderConfirmationModal from 'components/ReminderConfirmationModal'
import EmptyTalentProfile from './EmptyTalentProfile'
import { useTalentProfile } from './useTalentProfile'
import { TalentProfileContainer } from './TalentProfile.styles'
import { useGlobalWebSocket } from 'context/WebSocketContext'

export interface TalentProfileProps {}

const teamSelector = (store: TeamContextProps) => ({
	demo: store.demo,
	challengeId: store.assessment?.challengeId as string,
	categoryId: store.assessment?.categoryId as string
})

export const TalentProfile = () => {
	const [
		{ isOnHiringPlan, trialExpiredWithoutSubscription, isOnBenchmarkingPlan }
	] = useUserSubscriptions()

	const [
		{
			talent,
			group,
			formLoading,
			activeModal,
			chapters,
			loading,
			updatingTalent,
			pinnedUsers,
			benchmarks,
			assessment
		},
		{
			handleBookmark,
			handleEditNote,
			handleShortlist,
			handleCloseModal,
			handleDeleteTalent,
			handleInviteTalent,
			handleTogglePinUser,
			handleOpenDeleteModal,
			handleEditGroups,
			handleOpenEditModal,
			handleOpenReminderModal,
			handleRefetechApplicant
		}
	] = useTalentProfile()
	const { demo, categoryId, challengeId } = useTeamContext(teamSelector)

	const { registerRefetch } = useGlobalWebSocket()

	useEffect(() => {
		const unregister = registerRefetch(handleRefetechApplicant)
		return () => unregister()
	}, [registerRefetch, handleRefetechApplicant])

	return (
		<>
			{loading ? (
				<Grid container item xs={12}>
					<TalentProfileContainer>
						<Grid item alignItems="center" xs={12}>
							<TalentProfileSkeleton />
						</Grid>
					</TalentProfileContainer>
				</Grid>
			) : !talent ? (
				<EmptyTalentProfile />
			) : (
				<Grid container item xs={12}>
					<TalentProfileContainer className="custom-scrollbar">
						<Grid item alignItems="center" xs={12}>
							<TalentProfileComponent
								demo={demo}
								/** ProfileHeader */
								profileHeaderProps={{
									restricted: demo
										? false
										: trialExpiredWithoutSubscription ||
										  (talent.pipelineType === 'Internal'
												? !isOnBenchmarkingPlan
												: !isOnHiringPlan),
									onDelete: handleOpenDeleteModal,
									onEdit: handleOpenEditModal,
									firstName: talent?.user?.firstName || '',
									lastName: talent?.user?.lastName || '',
									// score: talent?.score?.score || 0,
									score:
										talent?.score?.skills && talent.score.skills.length > 0
											? talent.score.skills[0].score
											: talent?.score?.score || 0,
									photoUrl: talent?.user?.photoUrl || undefined,
									bookmarked: talent.isBookmarked,
									username:
										talent?.user?.hasSandbox && talent?.user?.username
											? (talent?.user?.username as string)
											: undefined,
									linkedin: talent.user?.linkedinLink || '',
									email: talent.user?.email || '',
									resume: talent.user?.resumeLink || '',
									masked: !!talent.masking,
									onBookmark: () => handleBookmark(talent.id, !talent.isBookmarked),
									isPinned: !!(pinnedUsers && pinnedUsers[talent.id]),
									onPin: handleTogglePinUser,
									count: talent.submission.length || 0,
									max: group?.chaptersTotal || 0,
									addedAt: talent?.createdAt,
									type: talent.pipelineType,
									shortlisted: talent.isShortlisted,
									onShortlist: () => handleShortlist(talent.id, !talent.isShortlisted)
								}}
								/** ChallengeProgress */
								challengeProgressProps={{
									count: talent.submission.length || 0,
									max: group?.chaptersTotal || 0,
									onSendEmail: handleOpenReminderModal,
									groups:
										talent?.pipelines.map(pipeline => ({
											id: pipeline.id,
											color: pipeline.color,
											name: pipeline.name
										})) || []
								}}
								/** ProfileTab */
								profileTabProps={{
									// TODO: replace badges with dynamic data
									badges: [],
									benchmarks,
									isRoleBased: assessment?.challengePack === 'role-based',
									interests: talent.user?.roleInterests || [],
									role: assessment?.selectedRoleDetails?.name ?? undefined,
									skills: talent.user?.skills || [],
									peopleSkills: talent.score?.skills || [],
									roleFunction: talent.user?.roleFunction || '',
									// TODO: replace roleLevel with dynamic data
									roleLevel: '',
									education: talent.user?.eduLevel || '',
									experienceLevel: talent.user?.profYear || '',
									customFields: talent.customFields,
									user: {
										firstName: talent.user?.firstName || '',
										lastName: talent.user?.lastName || '',
										photoUrl: talent.user?.photoUrl || ''
									}
								}}
								/** ChallengeTab */
								challengeTabProps={{
									name: talent.user?.firstName || '',
									count: talent.submission.length || 0,
									max: group?.chaptersTotal || 0,
									submission: talent.submission || [],
									chapters: chapters || null,
									allChapters:
										(group?.chapters as Pick<
											Chapter,
											'desc' | 'id' | 'name' | 'timer'
										>[]) || [],
									masked: !!talent.masking,
									getPreviewLink: chapterId =>
										getPreviewLink({
											chapterId,
											categoryId,
											challengeId
										})
								}}
								/** NotesTab */
								notesTabProps={{
									id: talent.id,
									note: talent.note || '',
									onEdit: (note: string) => handleEditNote(talent.id, note)
								}}
							/>
						</Grid>
					</TalentProfileContainer>
				</Grid>
			)}
			{activeModal === 'edit' && (
				<EditTalentModal
					groups={
						talent?.pipelines.map(pipeline => ({
							color: pipeline?.color as string,
							id: pipeline?.id as string,
							name: pipeline?.name as string
						})) || []
					}
					loading={updatingTalent}
					name={talent?.user?.firstName || ''}
					onClose={handleCloseModal}
					// masked={talent?.masking}
					options={
						group?.pipelines
							.filter(
								pipeline => (pipeline?.type as EnumPipelineType) === talent?.pipelineType
							)
							.map(pipeline => ({
								color: pipeline?.color as string,
								id: pipeline?.id as string,
								name: pipeline?.name as string
							})) || []
					}
					onEdit={groups => handleEditGroups(talent?.id as string, groups)}
					open={activeModal === 'edit'}
					masked={talent?.masking as boolean}
				/>
			)}
			<DeleteModal
				loading={formLoading}
				id={talent?.id || ''}
				title={talent?.user?.firstName || ''}
				open={activeModal === 'delete'}
				onClose={handleCloseModal}
				masked={!!talent?.masking}
				onDelete={(id: string) => handleDeleteTalent(id)}
			/>
			{activeModal === 'reminder' && (
				<ReminderConfirmationModal
					loading={formLoading}
					onClose={handleCloseModal}
					open={activeModal === 'reminder'}
					masked={!!talent?.masking}
					firstName={talent?.user?.firstName || ''}
					onSend={() => handleInviteTalent(talent?.id as string)}
				/>
			)}
		</>
	)
}

export default TalentProfile
