import styled from 'styled-components'
import { Tab as MuiTab } from '@material-ui/core'

export const Tab = styled(MuiTab)`
	min-height: 0;
	min-width: 0;
	color: ${props => props.theme.text.description};

	& .MuiTab-wrapper {
		justify-content: center;
	}
`
