import React, { useEffect, useState } from 'react'
import { Box, Card, Grid, Typography } from '@material-ui/core'
import axios from 'axios'

import { useGetAssessmentsQuery } from 'hooks/useApollo'
import { useGlobalWebSocket } from 'context/WebSocketContext'
import CustomGauge from './PieChart'

interface TechnicalAssessmentProps {
	assessId: string
	applicantData?: any
	filterOptions?: any[] // Add this line
}

const TechnicalAssessment: React.FC<TechnicalAssessmentProps> = ({
	assessId,
	applicantData
}) => {
	const [attemptDetails, setAttemptDetails] = useState([
		{
			ipAddress: applicantData?.ipAddress || 'N/A',
			browser: applicantData?.browser || 'N/A',
			location: applicantData?.location || 'N/A',
			os: applicantData?.osVersion || 'N/A',
			starttestTime: applicantData?.startTestTime || 'N/A',
			endtestTime: applicantData?.endTestTime || 'N/A',
			totalScore: applicantData?.Score || 'N/A',
			assessmentName: applicantData?.AssessmentName || 'N/A',
			duration: applicantData?.ExamDuration || 'N/A',
			maxTime: applicantData?.maxTime || 'N/A',
			timeSpent: applicantData?.totalTimeSpent || 'N/A'
		}
	])

	const fetchData = async () => {
		if (!assessId) return

		try {
			const response = await axios.post<{ data: any[] }>(
				'http://192.168.29.43:8081/api/v1/sandbox/getApplicantsbyTeam',
				{ assessId },
				{ headers: { 'Content-Type': 'application/json' } }
			)

			const rawData = response.data?.data || []

			if (rawData.length > 0) {
				const selectedApplicantData = rawData.find(
					item => item.email === applicantData?.email
				)

				if (selectedApplicantData) {
					setAttemptDetails([
						{
							ipAddress: selectedApplicantData.ipAddress || 'N/A',
							browser: selectedApplicantData.browser || 'N/A',
							location: selectedApplicantData.location || 'N/A',
							os: selectedApplicantData.osVersion || 'N/A',
							starttestTime: selectedApplicantData.startTestTime || 'N/A',
							endtestTime: selectedApplicantData.endTestTime || 'N/A',
							totalScore: selectedApplicantData.Score || 'N/A',
							assessmentName: selectedApplicantData.AssessmentName || 'N/A',
							duration: selectedApplicantData.ExamDuration || 'N/A',
							maxTime: selectedApplicantData.maxTime || 'N/A',
							timeSpent: selectedApplicantData.totalTimeSpent || 'N/A'
						}
					])
				}
			}
		} catch (error) {
			console.error('Error fetching applicant details:', error)
		}
	}

	const { data, refetch } = useGetAssessmentsQuery({
		variables: { lastActive: localStorage.getItem('lastActive') }
	})

	const { registerRefetch } = useGlobalWebSocket()

	useEffect(() => {
		const unregister = registerRefetch(refetch)
		return () => unregister()
	}, [registerRefetch, refetch])

	useEffect(() => {
		fetchData()
	}, [data, assessId])

	return (
		<Box>
			<Card
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'flex-start',
					borderRadius: '10px',
					p: 3
				}}
			>
				{attemptDetails.map((detail, index) => (
					<Box key={index} sx={{ width: '100%' }}>
						<Typography variant="h6" fontWeight="bold">
							{detail.assessmentName}
						</Typography>
					</Box>
				))}

				{attemptDetails.map((detail, index) => (
					<Grid container spacing={2} sx={{ mt: 3, width: '100%' }} key={index}>
						<Grid item xs={12} sm={6}>
							<Card
								sx={{
									p: 2,
									borderRadius: '10px',
									boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)'
								}}
							>
								<Typography variant="body2" color="gray">
									Started
								</Typography>
								<Typography variant="body1">{detail.starttestTime}</Typography>
							</Card>
						</Grid>

						<Grid item xs={12} sm={6}>
							<Card
								sx={{
									p: 2,
									borderRadius: '10px',
									boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)'
								}}
							>
								<Typography variant="body2" color="gray">
									Completed
								</Typography>
								<Typography variant="body1">{detail.endtestTime}</Typography>
							</Card>
						</Grid>

						<Grid item xs={12} display="flex" flexDirection="column" alignItems="center">
							<Typography variant="h6" align="left" sx={{ width: '100%' }}>
								Score
							</Typography>
							<CustomGauge percentage={detail.totalScore} />
						</Grid>
					</Grid>
				))}

				<Card sx={{ p: 2, mt: 3, borderRadius: '10px', width: '100%' }}>
					<Typography variant="h6" fontWeight="bold">
						Attempt Details
					</Typography>
					{attemptDetails.map((detail, index) => (
						<Grid container spacing={3} sx={{ mt: 2 }} key={index}>
							<Grid item xs={12} sm={4}>
								<Typography variant="body2" color="gray">
									IP Address
								</Typography>
								<Typography variant="body1">{detail.ipAddress}</Typography>

								<Typography variant="body2" color="gray" sx={{ mt: 2 }}>
									Browser
								</Typography>
								<Typography variant="body1">{detail.browser}</Typography>
							</Grid>

							<Grid item xs={12} sm={4}>
								<Typography variant="body2" color="gray">
									Location
								</Typography>
								<Typography variant="body1">{detail.location}</Typography>

								<Typography variant="body2" color="gray" sx={{ mt: 2 }}>
									Operating System
								</Typography>
								<Typography variant="body1">{detail.os}</Typography>
							</Grid>

							<Grid item xs={12} sm={4}>
								<Typography variant="body2" color="gray">
									Duration
								</Typography>
								<Typography variant="body1">{detail.duration}</Typography>

								<Typography variant="body2" color="gray" sx={{ mt: 2 }}>
									Time Taken
								</Typography>
								<Typography variant="body1">{detail.timeSpent}</Typography>
							</Grid>
						</Grid>
					))}
				</Card>
			</Card>
		</Box>
	)
}

export default TechnicalAssessment
