// import React from 'react'
// import {
// 	Grid,
// 	IconButton,
// 	Typography,
// 	useMediaQuery,
// 	Theme,
// 	Skeleton,
// 	Menu,
// 	Divider,
// 	Button,
// 	Hidden
// } from '@material-ui/core'
// import { alpha } from '@material-ui/core/styles'
// import { useTheme } from 'styled-components'
// import TextToolTip from 'components/TextToolTip'
// import PinIcon from 'assets/icons/PinIcon'
// import PinIconOutlined from 'assets/icons/PinIconOutlined'
// import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder'
// import BookmarkIcon from '@material-ui/icons/Bookmark'
// import CircleProgressBar from 'components/CircleProgressBar'
// import LinkedinIcon from 'assets/icons/LinkedinIcon'
// import EmailOutlinedIcon from 'assets/icons/EmailOutlinedIcon'
// import ContactInfoIcon from 'assets/icons/ContactInfoIcon'
// import { getInitials } from 'utils/string'
// import useIntl from 'hooks/useIntl'
// import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
// import PopupMenuItem from 'components/PopupMenuItem'
// import useToggleAnchor from 'hooks/useToggleAnchor'
// import useId from '@material-ui/core/utils/useId'
// import { EnumPipelineType } from 'hooks/useApollo'
// import { differenceInDays, format, formatDistanceToNow } from 'date-fns'
// import { TALENT_URL } from 'constants/config'
// import ButtonLink from 'components/ButtonLink'
// import { Avatar } from './ProfileHeader.styles'

// export interface ProfileHeaderProps {
// 	/**
// 	 * Defines the username of the talent
// 	 */
// 	username?: string
// 	/**
// 	 * Defines the display photo of the talent
// 	 */
// 	photoUrl?: string
// 	/**
// 	 * Defines the first name of the talent
// 	 */
// 	firstName: string
// 	/**
// 	 * Define if the talent is bookmarked
// 	 */
// 	bookmarked?: boolean
// 	/**
// 	 * Defines if the user is restricted to perform any actions in this component
// 	 */
// 	restricted?: boolean
// 	/**
// 	 * Defines the last name of the talent
// 	 */

// 	lastName: string
// 	/**
// 	 * Defines the linkedin link of the talent
// 	 */
// 	linkedin?: string
// 	/**
// 	 * Defines the email address of the talent
// 	 */
// 	email?: string
// 	/**
// 	 * Defines the resume link of the talent
// 	 */
// 	resume?: string
// 	/**
// 	 * Defines the talent score in percentage (max 100)
// 	 */
// 	score?: number
// 	/**
// 	 * Defines if the user is masked
// 	 * @default false
// 	 */
// 	masked?: boolean
// 	/**
// 	 * Callback fired when the bookmark button is clicked
// 	 */
// 	onBookmark: () => void
// 	/**
// 	 * Callback fired when the delete menu is clicked
// 	 */
// 	onDelete: (talentName?: string) => void
// 	/**
// 	 * Callback fired when the edit menu is clicked
// 	 */
// 	onEdit: () => void
// 	/**
// 	 * Defines the total count of challenges done
// 	 */
// 	count: number
// 	/**
// 	 * Defines the total count of challenges need to take
// 	 */
// 	max: number
// 	/**
// 	 * Date as ISO string - YYYY-MM-DDThh:mm:ssZ
// 	 */
// 	addedAt: string
// 	/**
// 	 * Defines the talent type
// 	 */
// 	type?: EnumPipelineType
// 	/**
// 	 * Callback fired when the shortlist button is clicked
// 	 */
// 	onShortlist: () => void
// 	/**
// 	 * Define if the talent is shortlisted
// 	 */
// 	shortlisted?: boolean
// 	/**
// 	 * Callback fired when the pin button is clicked
// 	 */
// 	onPin: () => void
// 	/**
// 	 * Defines if the user is pinned
// 	 * @default false
// 	 */
// 	isPinned: boolean
// }

// export const ProfileHeader: React.FC<ProfileHeaderProps> = ({
// 	photoUrl,
// 	lastName,
// 	firstName,
// 	score,
// 	bookmarked,
// 	onBookmark,
// 	linkedin,
// 	email,
// 	resume,
// 	masked,
// 	onDelete,
// 	onEdit,
// 	count,
// 	max,
// 	addedAt: addedAtProp,
// 	type,
// 	restricted,
// 	username,
// 	shortlisted,
// 	onShortlist,
// 	isPinned,
// 	onPin
// }) => {
// 	const intl = useIntl()
// 	const theme = useTheme()
// 	const progress = count === 0 && max === 0 ? 0 : Math.ceil((count / max) * 100)

// 	const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
// 	const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
// 	const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))
// 	const [{ anchorEl }, { handleOpenAnchor, handleCloseAnchor }] = useToggleAnchor()
// 	const id = useId()

// 	const addedAt = new Date(addedAtProp)
// 	const timeDiff =
// 		differenceInDays(Date.now(), addedAt) > 7
// 			? format(addedAt, 'MMM dd, yyyy')
// 			: formatDistanceToNow(addedAt, {
// 					addSuffix: true,
// 					includeSeconds: true
// 			  })
import React from 'react'
import {
	Grid,
	IconButton,
	Typography,
	useMediaQuery,
	Theme,
	Skeleton,
	Menu,
	Divider,
	Button,
	Hidden
} from '@material-ui/core'
import { alpha } from '@material-ui/core/styles'
import { useTheme } from 'styled-components'
import TextToolTip from 'components/TextToolTip'
import PinIcon from 'assets/icons/PinIcon'
import PinIconOutlined from 'assets/icons/PinIconOutlined'
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder'
import BookmarkIcon from '@material-ui/icons/Bookmark'
import CircleProgressBar from 'components/CircleProgressBar'
import LinkedinIcon from 'assets/icons/LinkedinIcon'
import EmailOutlinedIcon from 'assets/icons/EmailOutlinedIcon'
import ContactInfoIcon from 'assets/icons/ContactInfoIcon'
import { getInitials } from 'utils/string'
import useIntl from 'hooks/useIntl'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import PopupMenuItem from 'components/PopupMenuItem'
import useToggleAnchor from 'hooks/useToggleAnchor'
import useId from '@material-ui/core/utils/useId'
import { EnumPipelineType } from 'hooks/useApollo'
import { differenceInDays, format, formatDistanceToNow } from 'date-fns'
import { TALENT_URL } from 'constants/config'
import ButtonLink from 'components/ButtonLink'
import { Avatar } from './ProfileHeader.styles'

export interface ProfileHeaderProps {
	/**
	 * Defines the username of the talent
	 */
	username?: string
	/**
	 * Defines the display photo of the talent
	 */
	photoUrl?: string
	/**
	 * Defines the first name of the talent
	 */
	firstName: string
	/**
	 * Define if the talent is bookmarked
	 */
	bookmarked?: boolean
	/**
	 * Defines if the user is restricted to perform any actions in this component
	 */
	restricted?: boolean
	/**
	 * Defines the last name of the talent
	 */

	lastName: string
	/**
	 * Defines the linkedin link of the talent
	 */
	linkedin?: string
	/**
	 * Defines the email address of the talent
	 */
	email?: string
	/**
	 * Defines the resume link of the talent
	 */
	resume?: string
	/**
	 * Defines the talent score in percentage (max 100)
	 */
	score?: number
	/**
	 * Defines if the user is masked
	 * @default false
	 */
	masked?: boolean
	/**
	 * Callback fired when the bookmark button is clicked
	 */
	onBookmark: () => void
	/**
	 * Callback fired when the delete menu is clicked
	 */
	onDelete: (talentName?: string) => void
	/**
	 * Callback fired when the edit menu is clicked
	 */
	onEdit: () => void
	/**
	 * Defines the total count of challenges done
	 */
	count: number
	/**
	 * Defines the total count of challenges need to take
	 */
	max: number
	/**
	 * Date as ISO string - YYYY-MM-DDThh:mm:ssZ
	 */
	addedAt: string
	/**
	 * Defines the talent type
	 */
	type?: EnumPipelineType
	/**
	 * Callback fired when the shortlist button is clicked
	 */
	onShortlist: () => void
	/**
	 * Define if the talent is shortlisted
	 */
	shortlisted?: boolean
	/**
	 * Callback fired when the pin button is clicked
	 */
	onPin: () => void
	/**
	 * Defines if the user is pinned
	 * @default false
	 */
	isPinned: boolean
	skillId?: string // Add skillId prop
	skills?: { id: string; score: number }[]
}

export const ProfileHeader: React.FC<ProfileHeaderProps> = ({
	photoUrl,
	lastName,
	firstName,
	score,
	bookmarked,
	onBookmark,
	linkedin,
	email,
	resume,
	masked,
	onDelete,
	onEdit,
	count,
	max,
	addedAt: addedAtProp,
	type,
	restricted,
	username,
	shortlisted,
	onShortlist,
	isPinned,
	onPin,
	skills = [],
	skillId
}) => {
	const intl = useIntl()
	const theme = useTheme()
	const progress = count === 0 && max === 0 ? 0 : Math.ceil((count / max) * 100)

	const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
	const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
	const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))
	const [{ anchorEl }, { handleOpenAnchor, handleCloseAnchor }] = useToggleAnchor()
	const id = useId()

	const addedAt = new Date(addedAtProp)
	const timeDiff =
		differenceInDays(Date.now(), addedAt) > 7
			? format(addedAt, 'MMM dd, yyyy')
			: formatDistanceToNow(addedAt, {
					addSuffix: true,
					includeSeconds: true
			  })

	const skillScore = skills.find(skill => skill.id === skillId)?.score
	const displayScore = skillScore !== undefined ? skillScore : score
	if (displayScore === 0) {
		// Handle zero score case, or log it for debugging
		console.log('Display Score is zero, check if this is correct!')
	}
	return (
		<Grid container>
			<Grid
				item
				container
				alignItems="center"
				justifyContent="flex-end"
				sx={{ height: '56px' }}
			>
				<Grid item>
					<IconButton
						name="talent profile menu"
						aria-label="talent profile menu"
						aria-controls={`talent-profile-menu-${id}`}
						aria-haspopup="true"
						size="small"
						onClick={e => {
							e.stopPropagation()
							handleOpenAnchor(e)
						}}
					>
						<MoreHorizIcon />
					</IconButton>
					<Menu
						id={`talent-group-${id}-menu`}
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleCloseAnchor}
						MenuListProps={{
							disablePadding: true
						}}
						PaperProps={{
							elevation: 9,
							style: {
								maxWidth: 180,
								width: '100%'
							}
						}}
						getContentAnchorEl={null}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center'
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right'
						}}
					>
						<PopupMenuItem
							primary={intl.formatMessage({ id: 'modifyTalent' })}
							restricted={restricted}
							onClick={() => {
								onEdit()
								handleCloseAnchor()
							}}
						/>
						<Divider />
						<PopupMenuItem
							primary={intl.formatMessage({ id: 'deleteTalent' })}
							danger
							onClick={() => {
								onDelete()
								handleCloseAnchor()
							}}
						/>
					</Menu>
				</Grid>
			</Grid>
			<Grid item container>
				<Grid
					item
					xs
					container
					justifyContent="space-between"
					alignItems={smUp ? 'center' : 'center'}
					wrap="nowrap"
				>
					<Grid item mr={smUp ? 3 : 1.5}>
						<Avatar src={photoUrl} lgUp={lgUp}>
							{getInitials(firstName, lastName)}
						</Avatar>
					</Grid>
					<Grid item xs container>
						<Grid item container justifyContent="space-between" xs wrap="nowrap">
							<Grid container spacing={1} item alignItems="center" wrap="nowrap">
								<Grid
									item
									style={{ whiteSpace: 'nowrap', maxWidth: smDown ? '50%' : 'none' }}
								>
									<Typography
										variant="h5"
										sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
										fontWeight={500}
									>
										{masked ? (
											<Skeleton width={120} animation={false} />
										) : (
											`${firstName} ${lastName}`
										)}
									</Typography>
								</Grid>
								<Grid
									container
									item
									xs="auto"
									alignItems="center"
									wrap="nowrap"
									spacing={1}
								>
									{linkedin && (
										<Grid item>
											<TextToolTip
												title={intl.formatMessage({ id: 'linkedin' })}
												arrow
												placement="top"
											>
												<IconButton
													size="small"
													href={linkedin}
													target="_blank"
													rel="noopener noreferrer"
													sx={{ p: 0, mb: '4px', color: theme.text.description }}
												>
													<LinkedinIcon style={{ width: 16, height: 16 }} />
												</IconButton>
											</TextToolTip>
										</Grid>
									)}
									{email && (
										<Grid item>
											<TextToolTip
												title={intl.formatMessage({ id: 'emailAddress' })}
												arrow
												placement="top"
											>
												<IconButton
													size="small"
													href={`mailto:${email}`}
													sx={{ p: 0, color: theme.text.description }}
												>
													<EmailOutlinedIcon style={{ width: 16, height: 16 }} />
												</IconButton>
											</TextToolTip>
										</Grid>
									)}
									{resume && (
										<Grid item>
											<TextToolTip
												title={intl.formatMessage({ id: 'resume' })}
												arrow
												placement="top"
											>
												<IconButton
													size="small"
													href={resume}
													target="_blank"
													rel="noopener noreferrer"
													sx={{ p: 0, color: theme.text.description }}
												>
													<ContactInfoIcon style={{ width: 16, height: 16 }} />
												</IconButton>
											</TextToolTip>
										</Grid>
									)}
									<Grid item>
										<IconButton size="small" onClick={onBookmark}>
											{bookmarked ? (
												<BookmarkIcon color="primary" sx={{ fontSize: '1.2rem' }} />
											) : (
												<BookmarkBorderIcon
													sx={{ fontSize: '1.2rem', color: theme.text.description }}
												/>
											)}
										</IconButton>
									</Grid>
									<Grid item>
										<IconButton size="small" onClick={onPin} sx={{ ml: -1 }}>
											{isPinned ? (
												<PinIcon
													sx={{
														fill: theme.tag.red,
														fontSize: '1.2rem'
													}}
												/>
											) : (
												<PinIconOutlined
													sx={{
														fill: theme.background.light,
														fontSize: '1.2rem'
													}}
												/>
											)}
										</IconButton>
									</Grid>
									{type === 'Internal' ? null : (
										<Hidden smDown>
											<Grid item sx={{ ml: 1 }}>
												<Button
													size="small"
													name="shortlist"
													variant="contained"
													onClick={onShortlist}
													sx={{
														height: '22px',
														backgroundColor: theme.secondary.orange,
														'&:hover': {
															backgroundColor: alpha(theme.secondary.orange, 0.8)
														}
													}}
												>
													<Typography variant="paragraph3">
														{shortlisted
															? intl.formatMessage({ id: 'removeShortlist' })
															: intl.formatMessage({ id: 'shortlist' })}
													</Typography>
												</Button>
											</Grid>
										</Hidden>
									)}
								</Grid>
							</Grid>
						</Grid>
						<Grid container item>
							<Grid container alignItems="flex-end">
								<Grid item display="flex">
									{type && (
										<Typography
											variant="paragraph2"
											sx={{ textTransform: 'capitalize', color: theme.text.description }}
										>
											{intl.formatMessage({
												id: type === 'Internal' ? 'employee' : 'candidate'
											})}
										</Typography>
									)}
									{addedAt && (
										<Typography variant="paragraph2" sx={{ color: theme.text.description }}>
											&nbsp;&nbsp;•&nbsp;&nbsp;
											{timeDiff}
										</Typography>
									)}
								</Grid>
							</Grid>
							{username ? (
								<Grid item xs={12}>
									<TextToolTip
										placement="top"
										arrow
										title={intl.formatMessage(
											{ id: 'teams.talentProfile.profileTooltip' },
											{ name: firstName }
										)}
									>
										{/* @ts-ignore */}
										<a
											href={`${TALENT_URL}/profile/${username}`}
											rel="noreferrer noopener"
											target="_blank"
										>
											<ButtonLink name="view nugget profile">
												{intl.formatMessage({ id: 'teams.talentProfile.profile' })}
											</ButtonLink>
										</a>
									</TextToolTip>
								</Grid>
							) : null}
						</Grid>
					</Grid>
					{/* <Hidden smDown>
						<Grid item>
							{!score || progress !== 100 ? null : (
								<Grid item xs={12} sm="auto">
									<CircleProgressBar
										stroke={theme.tag.blue}
										background={theme.progressBarBg}
										percentage={score || 0}
										diameter={72}
										strokeWidth={6}
									/>
									<Typography align="center" variant="paragraph3">
										{intl.formatMessage({ id: 'nuggetScore' })}
									</Typography>
								</Grid>
							)}
						</Grid>
					</Hidden> */}
					<Hidden smDown>
						<Grid item>
							{displayScore !== undefined ? (
								<CircleProgressBar
									stroke={theme.tag.blue}
									background={theme.progressBarBg}
									percentage={displayScore} // Passing the calculated displayScore
									diameter={72}
									strokeWidth={6}
								/>
							) : null}
							<Typography align="center" variant="paragraph3">
								{intl.formatMessage({ id: 'nuggetScore' })}
							</Typography>
						</Grid>
					</Hidden>
				</Grid>
			</Grid>
			<Hidden smUp>
				<Grid sx={{ mt: 2 }} container justifyContent="space-between" alignItems="center">
					{type === 'Internal' ? null : (
						<Grid item>
							<Button
								size="small"
								name="shortlist"
								variant="contained"
								onClick={onShortlist}
								sx={{
									backgroundColor: theme.secondary.orange,
									'&:hover': {
										backgroundColor: alpha(theme.secondary.orange, 0.8)
									}
								}}
							>
								{shortlisted
									? intl.formatMessage({ id: 'removeShortlist' })
									: intl.formatMessage({ id: 'shortlist' })}
							</Button>
						</Grid>
					)}
					{/* <Grid item>
						{!score || progress !== 100 ? null : (
							<Grid item xs={12} sm="auto">
								<CircleProgressBar
									stroke={theme.tag.blue}
									background={theme.progressBarBg}
									percentage={score || 0}
									diameter={72}
									strokeWidth={6}
								/>
								<Typography align="center" variant="paragraph3">
									{intl.formatMessage({ id: 'nuggetScore' })}
								</Typography>
							</Grid>
						)}
					</Grid> */}
					<Grid item>
						{displayScore !== undefined ? (
							<CircleProgressBar
								stroke={theme.tag.blue}
								background={theme.progressBarBg}
								percentage={displayScore} // Passing the calculated displayScore
								diameter={72}
								strokeWidth={6}
							/>
						) : null}
						<Typography align="center" variant="paragraph3">
							{intl.formatMessage({ id: 'nuggetScore' })}
						</Typography>
					</Grid>
				</Grid>
			</Hidden>
		</Grid>
	)
}

export default ProfileHeader
