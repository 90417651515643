import React from 'react'
import { Grid, Skeleton, Theme, useMediaQuery } from '@material-ui/core'

export const ProfileHeaderSkeleton = () => {
	const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
	const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))
	const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
	return (
		<Grid container spacing={5}>
			<Grid item container>
				<Grid
					item
					xs
					container
					justifyContent="space-between"
					alignItems={smUp ? 'flex-end' : 'center'}
					wrap="nowrap"
				>
					<Grid item mr={smUp ? 3 : 1}>
						<Skeleton
							variant="circular"
							animation="wave"
							width={lgUp ? 64 : 56}
							height={lgUp ? 64 : 56}
						/>
					</Grid>
					<Grid item xs container spacing={1}>
						<Grid item container justifyContent="space-between" xs wrap="nowrap">
							<Grid
								container
								spacing={smUp ? 3 : 1}
								item
								xs
								alignItems="center"
								wrap="nowrap"
							>
								<Grid
									item
									style={{ whiteSpace: 'nowrap', maxWidth: smDown ? '50%' : 'none' }}
								>
									<Skeleton animation="wave" width={60} />
								</Grid>
								<Grid container item alignItems="center" xs wrap="nowrap">
									{Array(3)
										.fill(null)
										.map((_, index) => (
											<Grid item key={index} mr={0.5}>
												<Skeleton
													variant="circular"
													animation="wave"
													width={18}
													height={18}
												/>
											</Grid>
										))}
								</Grid>
							</Grid>
						</Grid>
						{/* Social Buttons */}
						<Grid container item xs={12} alignItems="flex-end">
							<div style={{ display: 'flex', marginRight: 12, marginBottom: 5 }}>
								<Grid item>
									<Skeleton
										animation="wave"
										width={60}
										height={8}
										sx={{ marginRight: 0.5 }}
									/>
								</Grid>
								<Grid item>
									<Skeleton animation="wave" width={60} height={8} />
								</Grid>
							</div>
						</Grid>
					</Grid>
					<Grid item xs={12} sm="auto">
						<div>
							<Skeleton
								variant="rectangular"
								animation="wave"
								width={72}
								height={36}
								style={{ borderTopLeftRadius: 110, borderTopRightRadius: 110 }}
							/>
							<Skeleton
								variant="text"
								animation="wave"
								width={65}
								height={13}
								sx={{ ml: 0.4 }}
							/>
						</div>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default ProfileHeaderSkeleton
