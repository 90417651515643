import styled, { css } from 'styled-components'
import { Button, Paper, MenuItem, Popper, Typography } from '@material-ui/core'
import { alpha } from '@material-ui/core/styles'

interface BgColorProps {
	bgColor: string
}

interface SizeProps {
	size: 'small' | 'medium'
}

interface StyledButtonProps extends BgColorProps, SizeProps {}
interface ColorBlockProps extends BgColorProps {
	size: 'x-small' | SizeProps['size']
}

const baseBtn = ({ size, bgColor }: StyledButtonProps) => css`
	padding: 0;
	font-weight: 500;
	height: ${size === 'small' ? '25px' : '47px'};
	background-color: ${bgColor};

	&:hover {
		background-color: ${alpha(bgColor, 0.8)};
	}
`

export const StatusButtonContainer = styled.div.withConfig<SizeProps>({
	shouldForwardProp: prop => !(['size'] as typeof prop[]).includes(prop)
})`
	/* width: ${props => (props.size === 'small' ? '110px' : '168px')}; */
	width: fit-content;
	display: flex;
	position: relative;
`

export const MainButton = styled(Button).withConfig<StyledButtonProps>({
	shouldForwardProp: prop => !(['size', 'bgColor'] as typeof prop[]).includes(prop)
})`
	min-width: ${props => (props.size === 'small' ? '75px' : '120px')};
	font-size: ${props => (props.size === 'small' ? '13px' : undefined)};
	${props =>
		props.size === 'medium' &&
		props.theme.mode !== 'dark' &&
		css`
			box-shadow: 0 4px 10px ${alpha(props.bgColor, 0.41)};
		`}
	${props => baseBtn({ size: props.size, bgColor: props.bgColor })};
	padding-left: 8px;
	padding-right: 8px;
`

export const MenuItemPopper = styled(Popper)`
	width: 100%;
	z-index: 100;
`

export const NextButton = styled(Button).withConfig<StyledButtonProps>({
	shouldForwardProp: prop => !(['size', 'bgColor'] as typeof prop[]).includes(prop)
})`
	margin-left: 3px;
	${props =>
		props.size === 'medium' &&
		props.theme.mode !== 'dark' &&
		css`
			box-shadow: 0 4px 10px ${alpha(props.bgColor, 0.41)};
		`}
	min-width: ${props => (props.size === 'small' ? '24px' : '38px')};
	font-size: 14px;
	${props => baseBtn({ size: props.size, bgColor: props.bgColor })};
`
export const MenuItemContainer = styled(Paper)`
	margin-top: 5px;
`

export const StatusItem = styled(MenuItem).withConfig<SizeProps>({
	shouldForwardProp: prop => !(['size'] as typeof prop[]).includes(prop)
})`
	${props =>
		props.size === 'small'
			? css`
					padding: 10px !important;
			  `
			: css`
					padding-top: 10px !important;
					padding-bottom: 10px !important;
			  `}
`

export const StatusItemLabel = styled(Typography).withConfig<SizeProps>({
	shouldForwardProp: prop => !(['size'] as typeof prop[]).includes(prop)
})`
	font-size: ${props => (props.size === 'small' ? '13px' : undefined)};
`

export const ColorBlock = styled.div.withConfig<ColorBlockProps>({
	shouldForwardProp: prop => !(['size', 'bgColor'] as typeof prop[]).includes(prop)
})`
	border-radius: 4px;
	margin-right: 5px;
	${props => {
		switch (props.size) {
			case 'x-small':
				return css`
					border-radius: 2px;
					width: 10px;
					height: 10px;
					margin-right: 5px;
				`
			case 'small':
			default:
				return css`
					width: 14px;
					height: 14px;
				`
			case 'medium':
				return css`
					width: 18px;
					height: 18px;
				`
		}
	}}
	background-color: ${props => props.bgColor};
`
