import React from 'react'

import { useTheme } from 'styled-components'
import { Hidden, Grid, Typography } from '@material-ui/core'

import { useIntl } from 'hooks/useIntl'
import gmsLogo from 'assets/illustrations/gmsLogo.svg'
import optimusLogo from 'assets/illustrations/optimusLogo.svg'
import teemaLogo from 'assets/illustrations/teemaLogo.svg'
import planetLogo from 'assets/illustrations/planetLogo.svg'
import * as S from './SignUpLayout.style'
import Slides from './components/Slides'

export const SignUpLayout: React.FC = ({ children }) => {
	const intl = useIntl()
	const theme = useTheme()
	return (
		<S.Container container>
			<S.Content
				item
				container
				sm={12}
				md={6}
				lg={7}
				direction="column"
				alignItems="stretch"
				justifyContent="center"
			>
				<Grid item>
					<Grid container justifyContent="center">
						<Grid item xs={12} sm={8} md={10} lg={9} xl={7}>
							{children}
						</Grid>
					</Grid>
				</Grid>
			</S.Content>
			<Hidden mdDown>
				<S.Testimonial
					item
					container
					md={6}
					lg={5}
					direction="column"
					alignItems="center"
					justifyContent="center"
				>
					{/* slider */}
					<Grid item>
						<Slides />
					</Grid>
					{/* logos */}
					<Grid item container justifyContent="center" sx={{ mt: 6 }}>
						<Typography
							align="center"
							sx={{ color: theme.tag.white, fontSize: 12, textTransform: 'uppercase' }}
							fontWeight={700}
						>
							{intl.formatMessage({ id: 'slogan' })}
						</Typography>
					</Grid>
					<Grid item container justifyContent="center" alignItems="center" sx={{ mt: 4 }}>
						<Grid item xs={3}>
							<img
								src={gmsLogo}
								alt="global mentoring solutions"
								width="80%"
								height="80%"
							/>
						</Grid>
						<Grid item xs={3}>
							<img src={teemaLogo} alt="teema" width="80%" height="80%" />
						</Grid>
						<Grid item xs={3}>
							<img src={planetLogo} alt="planet4it" width="80%" height="80%" />
						</Grid>
						<Grid item xs={3}>
							<img src={optimusLogo} alt="optimus sbr" width="80%" height="80%" />
						</Grid>
					</Grid>
				</S.Testimonial>
			</Hidden>
		</S.Container>
	)
}
