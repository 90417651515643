import styled from 'styled-components'

export const UserAvatarContainer = styled.div`
	position: relative;

	.image-selector {
		font-size: 18px;
		position: absolute;
		padding: 4px;
		line-height: 18px;
		border-radius: 50%;
		background: white;
		box-shadow: 0 4px 16px 0 rgb(179 192 231 / 32%);
		height: 26px;
		bottom: 0;
		left: 80px;
		color: ${props => props.theme.primary.main};
		cursor: pointer;
	}

	input {
		display: none;
	}
`
