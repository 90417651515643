import React, { useCallback, useMemo, useRef, useState } from 'react'
import { MenuList, Grow, Grid, ClickAwayListener, Box } from '@material-ui/core'
import TextToolTip from 'components/TextToolTip'
import useId from '@material-ui/core/utils/useId'
import useIntl from 'hooks/useIntl'
import { getRgbColor } from 'utils/color'
import {
	MainButton,
	NextButton,
	ColorBlock,
	MenuItemContainer,
	StatusItem,
	MenuItemPopper,
	StatusItemLabel,
	StatusButtonContainer
} from './StatusButton.style'

interface Status {
	label: string
	color: string
}

export interface StatusButtonProps {
	/**
	 * @default 'medium'
	 */
	size?: 'small' | 'medium'
	statuses: Status[]
	currentStatus: number
	onChangeStatus: (status: string) => void
	onNextStatus: () => void
}

export const StatusButton = ({
	currentStatus,
	statuses: statusesProp,
	size = 'medium',
	onChangeStatus,
	onNextStatus
}: StatusButtonProps) => {
	const [open, setOpen] = useState(false)
	const anchorElRef = useRef<HTMLButtonElement>(null)
	const intl = useIntl()
	const statuses: Status[] = useMemo(
		() =>
			statusesProp.map(status => ({
				label: status.label,
				color: getRgbColor(status.color)
			})),
		[statusesProp]
	)
	const handleToggle = (e: React.MouseEvent) => {
		e.preventDefault()
		e.stopPropagation()
		setOpen(prevOpen => !prevOpen)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const handleListKeyDown = (e: React.KeyboardEvent) => {
		switch (e.key) {
			case 'Tab':
			case 'Enter':
				handleClose()
		}
	}

	const handleChangeStatus = (status: string) => {
		handleClose()
		onChangeStatus(status)
	}

	const handleNextStatusChange = useCallback(() => {
		onNextStatus()
	}, [onNextStatus])

	const status = statuses[currentStatus]
	const nextStatus =
		currentStatus === statuses.length - 1 ? null : statuses[currentStatus + 1]
	const nextButton = useMemo(
		() => (
			<NextButton
				name="status menu"
				type="button"
				arial-label="status menu"
				onClick={size === 'medium' ? handleNextStatusChange : handleToggle}
				variant="contained"
				size={size}
				bgColor={status.color}
				disabled={size === 'medium' ? !nextStatus : false}
			>
				{size === 'medium' ? (
					<i className="bx bxs-right-arrow" aria-label="open menu" />
				) : (
					<i className="bx bx-caret-down" aria-label="close menu" />
				)}
			</NextButton>
		),
		// eslint-disable-next-line
		[status, size, handleNextStatusChange]
	)
	const id = useId()
	const anchorEl = anchorElRef.current
	return (
		<StatusButtonContainer size={size}>
			<MainButton
				name="status"
				aria-label="status"
				size={size}
				ref={anchorElRef}
				bgColor={status.color}
				variant="contained"
				aria-controls="status-list"
				aria-haspopup="true"
				onClick={handleToggle}
			>
				{status.label}
			</MainButton>
			{nextStatus && size === 'medium' ? (
				<TextToolTip
					aria-label="openModal"
					title={
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center'
							}}
						>
							{intl.formatMessage({ id: 'statusButton.nextStatus' })}
							<Box sx={{ marginLeft: 1, display: 'flex', alignItems: 'center' }}>
								<ColorBlock size="x-small" bgColor={nextStatus.color} />
								{nextStatus.label}
							</Box>
						</Box>
					}
					placement={open ? 'right' : 'top'}
				>
					{nextButton}
				</TextToolTip>
			) : (
				nextButton
			)}

			<MenuItemPopper
				open={open}
				anchorEl={anchorEl}
				role="menu"
				transition
				disablePortal
				placement="bottom-start"
				style={{ width: '100%', zIndex: 100 }}
			>
				{({ TransitionProps }) => (
					<ClickAwayListener
						onClickAway={e => {
							if (open) {
								e.preventDefault()
								e.stopPropagation()
								handleClose()
							}
						}}
					>
						<Grow
							{...TransitionProps}
							style={{
								transformOrigin: 'center top'
							}}
						>
							<MenuItemContainer elevation={8}>
								<MenuList
									autoFocusItem={open}
									id={`statuses-list-${id}`}
									onKeyDown={handleListKeyDown}
								>
									{statuses.map(({ label, color }, index) => (
										<StatusItem
											size={size}
											key={label}
											onClick={(e: React.MouseEvent) => {
												e.preventDefault()
												e.stopPropagation()
												handleChangeStatus(label)
											}}
											selected={currentStatus === index}
										>
											<Grid container alignItems="center">
												<ColorBlock size={size} bgColor={color} />
												<StatusItemLabel size={size}>{label}</StatusItemLabel>
											</Grid>
										</StatusItem>
									))}
								</MenuList>
							</MenuItemContainer>
						</Grow>
					</ClickAwayListener>
				)}
			</MenuItemPopper>
		</StatusButtonContainer>
	)
}

export default StatusButton
