import React from 'react'
import { useTheme } from 'styled-components'
import { Stepper, Step, Collapse, Chip, Box, StepConnector } from '@material-ui/core'
import CustomStepIcon from './components/CustomStepIcon'
import { StepLabel, SubStepConnector } from './CustomStepper.style'

export interface CustomStepperProps {
	steps: { label: string; sub?: string[]; tag?: string }[]
	step: number
	subStep: number
}

export const CustomStepper = ({ steps, step, subStep }: CustomStepperProps) => {
	const theme = useTheme()

	return (
		<Stepper activeStep={step} orientation="vertical" alternativeLabel={false}>
			{steps.map(({ label, sub, tag }, index) => (
				<Step key={index}>
					<StepLabel>
						<Box display="flex" alignItems="center">
							{label}
							{tag && step !== 0 && (
								<Chip
									label={tag}
									variant="squareFilled"
									size="small"
									sx={{
										textTransform: 'capitalize',
										backgroundColor: 'other.main',
										marginLeft: '22px',
										':hover': {
											backgroundColor: 'other.main'
										}
									}}
								/>
							)}
						</Box>
					</StepLabel>
					<Collapse in={sub && sub.length > 0 && step === index}>
						<StepConnector />
						<Stepper
							activeStep={subStep}
							orientation="vertical"
							alternativeLabel={false}
							connector={<SubStepConnector />}
						>
							{sub &&
								sub.length > 0 &&
								sub.map((sub, index) => (
									<Step key={index} sx={{ ml: 0.6 }}>
										<StepLabel StepIconComponent={CustomStepIcon(theme)}>{sub}</StepLabel>
									</Step>
								))}
						</Stepper>
					</Collapse>
				</Step>
			))}
		</Stepper>
	)
}

export default CustomStepper
