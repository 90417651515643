import React, { useEffect, useState } from 'react'
import {
	Grid,
	Typography,
	TextField,
	MenuItem,
	Chip,
	Button,
	FormControl
} from '@material-ui/core'
import WizardFooter from 'components/Wizard/WizardFooter'
import { useOnboardingStore } from 'screens/Onboarding/hooks/useOnboardingStore'
import { ContentWrapper } from 'screens/Onboarding/components/ContentWrapper'
import { DeleteOutline, EditOutlined, KeyboardReturn } from '@material-ui/icons'
import { useEntryFormStore } from '../../hooks/useEntryFormStore'
import axios from 'axios'
interface SecondComponentProps {
	maxQuestions: number
}
interface SubSection {
	subSectionId: string
	subSectionName: string
	count: number
}

interface Section {
	sectionId: string
	sectionName: string
	count: number
	type?: string
	subSections?: SubSection[]
}

const EntryFormChallengePreview: React.FC<SecondComponentProps> = ({ maxQuestions }) => {
	const { currentStep, prevStep, nextStep, toggleHelpGuide } = useOnboardingStore()

	const { formStates, updateEntryFormState } = useEntryFormStore()
	const [questions, setQuestions] = useState<string[]>([])

	const [selectedType, setSelectedType] = useState<string | null>(
		formStates.selectedType || null
	)

	// const [selectedSubTypes, setSelectedSubTypes] = useState<string[]>(
	// 	formStates.selectedSubTypes || ''
	// )
	const [selectedSubTypes, setSelectedSubTypes] = useState<string>(
		formStates.selectedSubTypes || ''
	)
	const [numQuestions, setNumQuestions] = useState<number | ''>(
		formStates.numQuestions || ''
	)
	const [chartData, setChartData] = useState(formStates.chartData || [])
	const [editingIndex, setEditingIndex] = useState<number | null>(null)
	const [error, setError] = useState<string | null>(null)

	const [questionTypes, setQuestionTypes] = useState<Section[]>([])

	// const handleAddOrUpdate = () => {
	// 	if (!numQuestions || !selectedType || Number(numQuestions) <= 0) {
	// 		setError('Enter a valid number of questions.')
	// 		return
	// 	}

	// 	const selectedQuestionType = questionTypes.find(q => q.sectionId === selectedType)
	// 	if (!selectedQuestionType) {
	// 		setError('Invalid question type.')
	// 		return
	// 	}

	// 	const maxAllowed = selectedQuestionType.count
	// 	const topicName = selectedQuestionType.sectionName

	// 	const totalExistingQuestionsForType = chartData.reduce(
	// 		(total, row, index) =>
	// 			index !== editingIndex && row.sectionId === selectedType
	// 				? total + row.questions
	// 				: total,
	// 		0
	// 	)

	// 	const newTotalForType = totalExistingQuestionsForType + Number(numQuestions)

	// 	if (newTotalForType > maxAllowed) {
	// 		setError(
	// 			`You can add only ${
	// 				maxAllowed - totalExistingQuestionsForType
	// 			} more questions for this topic.`
	// 		)
	// 		return
	// 	}

	// 	const totalExistingQuestions = chartData.reduce(
	// 		(total, row, index) => (index !== editingIndex ? total + row.questions : total),
	// 		0
	// 	)

	// 	const newTotal =
	// 		totalExistingQuestions +
	// 		Number(numQuestions) -
	// 		(editingIndex !== null ? chartData[editingIndex].questions : 0)

	// 	if (newTotal > maxQuestions) {
	// 		setError(`Cannot exceed total limit of ${maxQuestions} questions!`)
	// 		return
	// 	}

	// 	const selectedSubTypeNames: string[] = selectedSubTypes
	// 		.map(subTypeId => {
	// 			const subTypeObj = selectedQuestionType.subSections?.find(
	// 				sub => String(sub.subSectionId) === String(subTypeId)
	// 			)
	// 			return subTypeObj?.subSectionName || 'Unknown'
	// 		})
	// 		.filter(name => name !== 'Unknown')

	// 	setError('')
	// 	const newData = {
	// 		type: topicName,
	// 		sectionId: selectedQuestionType.sectionId,
	// 		subSectionId: selectedSubTypes.length > 0 ? selectedSubTypes[0] : '',
	// 		subTypes: selectedSubTypeNames,
	// 		questions: Number(numQuestions),
	// 		count: maxAllowed
	// 	}

	// 	if (editingIndex !== null) {
	// 		setChartData(prev =>
	// 			prev.map((item, index) =>
	// 				index === editingIndex
	// 					? { ...item, ...newData, questions: Number(numQuestions) }
	// 					: item
	// 			)
	// 		)
	// 		setEditingIndex(null)
	// 	} else {
	// 		setChartData(prev => [...prev, newData])
	// 	}

	// 	resetForm()
	// }

	const handleAddOrUpdate = () => {
		if (!numQuestions || !selectedType || Number(numQuestions) <= 0) {
			setError('Enter a valid number of questions.')
			return
		}

		const selectedQuestionType = questionTypes.find(q => q.sectionId === selectedType)
		if (!selectedQuestionType) {
			setError('Invalid question type.')
			return
		}

		const maxAllowed = selectedQuestionType.count
		const topicName = selectedQuestionType.sectionName

		const totalExistingQuestionsForType = chartData.reduce(
			(total, row, index) =>
				index !== editingIndex && row.sectionId === selectedType
					? total + row.questions
					: total,
			0
		)

		const newTotalForType = totalExistingQuestionsForType + Number(numQuestions)

		if (newTotalForType > maxAllowed) {
			setError(
				`You can add only ${
					maxAllowed - totalExistingQuestionsForType
				} more questions for this topic.`
			)
			return
		}

		const totalExistingQuestions = chartData.reduce(
			(total, row, index) => (index !== editingIndex ? total + row.questions : total),
			0
		)

		const newTotal =
			totalExistingQuestions +
			Number(numQuestions) -
			(editingIndex !== null ? chartData[editingIndex].questions : 0)

		if (newTotal > maxQuestions) {
			setError(`Cannot exceed total limit of ${maxQuestions} questions!`)
			return
		}

		const selectedSubTypeNames = selectedSubTypes
			.split(',')
			.map(subTypeId => {
				const subTypeObj = selectedQuestionType.subSections?.find(
					sub => String(sub.subSectionId) === String(subTypeId.trim())
				)
				return subTypeObj?.subSectionName || ''
			})
			.filter(name => name)

		console.log('Selected SubType Names:', selectedSubTypeNames)

		setError('')
		const newData = {
			type: topicName,
			sectionId: selectedQuestionType.sectionId,
			subSectionId: selectedSubTypes.split(',')[0] || '',
			subTypes: selectedSubTypeNames.join(', '),
			questions: Number(numQuestions),
			count: maxAllowed
		}

		if (editingIndex !== null) {
			setChartData(prev =>
				prev.map((item, index) =>
					index === editingIndex
						? { ...item, ...newData, questions: Number(numQuestions) }
						: item
				)
			)
			setEditingIndex(null)
		} else {
			setChartData(prev => [...prev, newData])
		}

		resetForm()
	}

	const totalQuestions = chartData.reduce((total, row) => total + row.questions, 0)

	// const handleEditRow = (index: number) => {
	// 	const { type, subSectionId, questions } = chartData[index]

	// 	const selectedQuestionType = questionTypes.find(q => q.sectionName === type)

	// 	if (selectedQuestionType) {
	// 		setSelectedType(selectedQuestionType.sectionId)
	// 		setSelectedSubTypes(subSectionId ? [subSectionId] : [])
	// 		setNumQuestions(questions)
	// 		setEditingIndex(index)
	// 	}
	// }
	const handleEditRow = (index: number) => {
		const { type, subSectionId, questions } = chartData[index]

		const selectedQuestionType = questionTypes.find(q => q.sectionName === type)

		if (selectedQuestionType) {
			setSelectedType(selectedQuestionType.sectionId)
			setSelectedSubTypes(subSectionId || '')
			setNumQuestions(questions)
			setEditingIndex(index)
		}
	}

	const handleDeleteRow = (index: number) => {
		setChartData(prev => prev.filter((_, i) => i !== index))
		setEditingIndex(null)
	}

	// const resetForm = () => {
	// 	setNumQuestions('')
	// 	setSelectedType(null)
	// 	setSelectedSubTypes([])
	// 	setEditingIndex(null)
	// }
	const resetForm = () => {
		setNumQuestions('')
		setSelectedType(null)
		setSelectedSubTypes('')
		setEditingIndex(null)
	}

	const handleCancel = () => {
		setSelectedType('')
		setSelectedSubTypes('')
		setNumQuestions('')
	}

	const handleBack = () => prevStep()
	const handleContinue = () => {
		updateEntryFormState({
			selectedType,
			selectedSubTypes,
			numQuestions,
			chartData
		})
		nextStep()
	}

	const canContinue = chartData.length > 0

	// useEffect(() => {
	// 	axios
	// 		.get<Section[]>('http://192.168.29.43:8081/api/v1/custom/sectionData')
	// 		.then(response => {
	// 			setQuestionTypes(response.data)
	// 		})
	// 		.catch(error => console.error('Error fetching data:', error))
	// }, [])
	useEffect(() => {
		const userId = localStorage.getItem('userId') // Retrieve userId from localStorage

		if (userId) {
			axios
				.get<Section[]>(
					`${process.env.REACT_APP_API_URL}/api/v1/custom/sectionData/${userId}`
				)
				.then(response => {
					setQuestionTypes(response.data)
				})
				.catch(error => console.error('Error fetching data:', error))
		}
	}, [])

	// const handleTypeSelection = (value: string) => {
	// 	setSelectedType(value)
	// 	setSelectedSubTypes([])
	// }

	// const handleSubTypeSelection = (subType: string) => {
	// 	setSelectedSubTypes([subType])
	// }
	const handleTypeSelection = (value: string) => {
		setSelectedType(value)
		setSelectedSubTypes('') // Reset as an empty string instead of an array
	}

	const handleSubTypeSelection = (subType: string) => {
		setSelectedSubTypes(subType) // Store as a string instead of an array
	}

	const handleNumQuestionsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value ? parseInt(e.target.value, 10) : ''
		const totalExistingQuestions = chartData.reduce(
			(total, row) => total + row.questions,
			0
		)
		const newTotal = totalExistingQuestions + (typeof value === 'number' ? value : 0)

		if (newTotal > maxQuestions) {
			setError(`Cannot exceed total limit of ${maxQuestions} questions!`)
		} else {
			setError(null)
		}

		setNumQuestions(value)
	}

	return (
		<>
			<ContentWrapper currentStep={currentStep}>
				<Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
					Entry - Level
				</Typography>

				<Grid
					container
					spacing={2}
					alignItems="center"
					style={{ marginTop: '16px' }}
					sx={{
						'@media (max-width: 600px)': {
							flexDirection: 'column',
							alignItems: 'stretch'
						}
					}}
				>
					<Grid item xs={6} sm={2}>
						<FormControl variant="outlined" fullWidth>
							<Typography variant="body2" gutterBottom>
								No. of Questions
							</Typography>
							<TextField
								type="number"
								size="small"
								sx={{ height: '40px' }}
								value={numQuestions}
								onChange={handleNumQuestionsChange}
								fullWidth
								error={!!error}
								helperText={error || ''}
								inputProps={{
									min: 1,
									max: questionTypes.find(q => q.type === selectedType)?.count || 0
								}}
							/>
						</FormControl>
					</Grid>

					<Grid item xs={12} sm={4}>
						<FormControl variant="outlined" fullWidth>
							<Typography variant="body2" gutterBottom>
								Select Topic
							</Typography>
							<TextField
								select
								fullWidth
								size="small"
								sx={{ height: '40px' }}
								value={selectedType || ''}
								onChange={e => handleTypeSelection(e.target.value)}
								SelectProps={{
									MenuProps: {
										getContentAnchorEl: null,
										anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
										transformOrigin: { vertical: 'top', horizontal: 'left' },
										PaperProps: { style: { maxHeight: 200, overflowY: 'auto' } }
									}
								}}
							>
								{questionTypes.map(({ sectionId, sectionName, count }) => (
									<MenuItem key={sectionId} value={sectionId}>
										{sectionName} &nbsp;-&nbsp; ({count})
									</MenuItem>
								))}
							</TextField>
						</FormControl>
					</Grid>

					<Grid item xs={12} sm={4}>
						<FormControl variant="outlined" fullWidth>
							<Typography variant="body2" gutterBottom>
								Select Sub Topic
							</Typography>
							<TextField
								select
								fullWidth
								size="small"
								sx={{ height: '40px' }}
								value={selectedSubTypes || ''}
								onChange={e => handleSubTypeSelection(e.target.value)}
								SelectProps={{
									MenuProps: {
										getContentAnchorEl: null,
										anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
										transformOrigin: { vertical: 'top', horizontal: 'left' },
										PaperProps: {
											style: { maxHeight: 200, overflowY: 'auto' }
										}
									}
								}}
							>
								{questionTypes
									.find(q => q.sectionId === selectedType)
									?.subSections?.map(({ subSectionId, subSectionName, count }) => (
										<MenuItem key={subSectionId} value={subSectionId}>
											{subSectionName} &nbsp;-&nbsp; ({count})
										</MenuItem>
									))}
							</TextField>
						</FormControl>
					</Grid>
					<Grid
						item
						xs={12}
						container
						justifyContent="flex-end"
						spacing={2}
						style={{ marginTop: '4px', marginBottom: '20px' }}
						sx={{
							'@media (max-width: 600px)': {
								justifyContent: 'center'
							}
						}}
					>
						<Grid item>
							<Button
								variant="contained"
								color="primary"
								onClick={() => {
									handleAddOrUpdate()
								}}
							>
								{editingIndex !== null ? 'Update' : 'Add'}
							</Button>
						</Grid>
						<Grid item>
							<Button variant="outlined" onClick={handleCancel}>
								Cancel
							</Button>
						</Grid>
					</Grid>
				</Grid>

				{chartData.length > 0 && (
					<>
						{chartData.map((row, index) => (
							<div
								key={index}
								style={{
									display: 'flex',
									alignItems: 'center',

									padding: '12px 16px',
									borderRadius: '6px',
									marginBottom: '10px',
									marginTop: '10px',
									border: '1px solid lightgray'
								}}
							>
								<Typography
									variant="body1"
									style={{ flex: 1, textAlign: 'left', paddingLeft: '8px' }}
								>
									{row.questions}
								</Typography>

								<Typography
									variant="body1"
									style={{ flex: 1.2, textAlign: 'left', paddingLeft: '8px' }}
								>
									{row.type}{' '}
									{row.subTypes && typeof row.subTypes === 'string'
										? ` - ${row.subTypes}`
										: Array.isArray(row.subTypes) && row.subTypes.length > 0
										? ` - ${row.subTypes.join(', ')}`
										: ''}
								</Typography>

								<Typography
									variant="body1"
									style={{ flex: 0.8, textAlign: 'center', paddingLeft: '8px' }}
								>
									{row.count}
								</Typography>

								<div
									style={{
										display: 'flex',
										gap: '20px',
										cursor: 'pointer',
										paddingRight: '8px'
									}}
								>
									<EditOutlined
										onClick={() => handleEditRow(index)}
										style={{ color: '#7D6EF1' }}
									/>
									<DeleteOutline
										onClick={() => handleDeleteRow(index)}
										style={{ color: 'red' }}
									/>
								</div>
							</div>
						))}

						<Typography
							variant="body1"
							sx={{
								fontWeight: 'bold',
								marginTop: 2
							}}
						>
							Total : {totalQuestions}
						</Typography>
					</>
				)}
			</ContentWrapper>

			<WizardFooter
				onOpenHelpGuide={toggleHelpGuide}
				continueDisabled={!canContinue}
				onBack={handleBack}
				onContinue={handleContinue}
			/>
		</>
	)
}

export default EntryFormChallengePreview
