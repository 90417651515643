import React from 'react'
import { Grid } from '@material-ui/core'
import { Navigate, useLocation, useRoutes } from 'react-router-dom'

import { Logo } from 'components/Logo'
import { authRoutes } from 'routes/auth'
import { useStoreon } from 'storeon/react'
import * as S from './AuthLayout.style'

export const AuthLayout: React.FC = () => {
	const routes = useRoutes(authRoutes)
	const { token } = useStoreon('token')
	const { search } = useLocation()

	if (token) {
		const redirectTo = new URLSearchParams(search).get('redirectTo')
		if (redirectTo) {
			return <Navigate to={redirectTo} />
		}
		return <Navigate to="/" />
	}

	return (
		<S.Container container alignItems="center" justifyContent="center">
			<S.Content item xs={12} sm={10} md={8} lg={5} xl={4}>
				<Grid container direction="column" spacing={5}>
					<Grid item alignSelf="center">
						<Logo width={100} />
					</Grid>
					<Grid item>{routes}</Grid>
				</Grid>
			</S.Content>
		</S.Container>
	)
}

export default AuthLayout
