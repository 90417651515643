import create from 'zustand'

import { Cluster, Chapter } from 'hooks/useApollo'

type State = {
	currentStep: number
	clusters: Cluster[]
	chapters: Chapter[]
	chapterMappingById: Record<string, Chapter>
}

type Methods = {
	goBack: () => void
	goNext: () => void
	setClusters: (clusters: Cluster[]) => void
	setChapters: (chapters: Chapter[]) => void
}

type Store = State & Methods

const initialState: State = {
	currentStep: 0,
	clusters: [],
	chapters: [],
	chapterMappingById: {}
}

export const useSkillSelectionStore = create<Store>(set => ({
	...initialState,
	goBack: () => set(state => ({ ...state, currentStep: state.currentStep - 1 })),
	goNext: () => set(state => ({ ...state, currentStep: state.currentStep + 1 })),
	setClusters: (clusters: Cluster[]) => set(state => ({ ...state, clusters })),
	setChapters: (chapters: Chapter[]) =>
		set(state => {
			// @ts-ignore
			const sortedChapters = [...chapters].sort((a, b) => (a.name < b.name ? -1 : 1))
			return {
				...state,
				chapters: sortedChapters,
				chapterMappingById: sortedChapters.reduce<Record<string, Chapter>>(
					(currentMap, chapter) => {
						currentMap[chapter.id] = chapter
						return currentMap
					},
					{}
				)
			}
		})
}))

export const resetSkillSelectionStore = () => {
	useSkillSelectionStore.setState(initialState)
}
