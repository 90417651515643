/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
	Box,
	TextField,
	TextFieldProps,
	Grid,
	InputAdornment,
	Typography,
	Stepper,
	Step
} from '@material-ui/core'
import { useTheme as useMaterialTheme } from '@material-ui/core/styles'
import { Controller, useForm } from 'react-hook-form'
import { ApolloError } from '@apollo/client'
import iziToast from 'izitoast'
import { useIntl } from 'hooks/useIntl'
import ContinueButton from 'components/ContinueButton'
import { emailRegex, createStringRequirements } from 'utils/regexs'
import {
	useSendPasswordResetUserMutation,
	useResetPasswordUserMutation,
	useCheckPinUserLazyQuery
} from 'hooks/useApollo'
import PasswordInput from 'components/PasswordInput'
import Requirement from 'components/Requirement'
import PinInput from 'components/PinInput'
import { ILocale } from 'constants/locales/ILocale'
import InputCheckIcon from 'components/InputCheckIcon'
import { pxToRem } from 'packages/nuggetai.ui-kit/themes/utils'
import { StepLabel } from './ForgotPassword.style'

const passwordRegex = createStringRequirements()

interface ForgotPasswordForm {
	email: string
	newPassword: string
	confirmNewPassword: string
	pin: string
}

const noErrorProps: TextFieldProps['InputProps'] = {
	endAdornment: (
		<InputAdornment position="end">
			<InputCheckIcon />
		</InputAdornment>
	)
}

export const ForgotPassword = () => {
	const intl = useIntl()
	const {
		register,
		errors,
		watch,
		handleSubmit,
		setError,
		control
	} = useForm<ForgotPasswordForm>({
		mode: 'all'
	})
	const [step, setStep] = useState(0)
	const allFields = watch()
	const materialTheme = useMaterialTheme()
	const navigate = useNavigate()
	const [sendPasswordReset, { loading: sendingPin }] = useSendPasswordResetUserMutation({
		onCompleted: () => {
			setStep(step + 1)
		},
		onError: ({ graphQLErrors }) => {
			if (graphQLErrors) {
				graphQLErrors.forEach(({ message, extensions }) => {
					if (extensions?.exception?.details) {
						if (!Array.isArray(extensions?.exception?.details)) {
							const { details } = extensions?.exception
							setError(details?.key, {
								shouldFocus: true,
								message,
								type: 'custom'
							})
						}
					}
				})
			}
		}
	})
	const handleApolloError = ({ graphQLErrors }: ApolloError) => {
		if (graphQLErrors) {
			graphQLErrors.forEach(({ message, extensions }) => {
				if (extensions?.exception?.details) {
					if (!Array.isArray(extensions?.exception?.details)) {
						const { details } = extensions?.exception
						setError(details?.key, {
							shouldFocus: true,
							message,
							type: 'custom'
						})
						if (details?.key === 'email') {
							setStep(0)
						} else if (details?.key === 'pin') {
							setStep(1)
						}
					}
				}
			})
		}
	}
	const [resetPassword, { loading: resettingPassword }] = useResetPasswordUserMutation({
		onCompleted: () => {
			iziToast.success({
				title: intl.formatMessage({ id: 'auth.forgotPassword.success.title' }),
				message: intl.formatMessage({ id: 'auth.forgotPassword.success.msg' })
			})
			navigate('/auth/login')
		},
		onError: handleApolloError
	})
	const [checkPin, { loading: checkingPin }] = useCheckPinUserLazyQuery({
		onCompleted: () => {
			setStep(step + 1)
		},
		onError: handleApolloError
	})
	const isInValid =
		step === 0
			? allFields.email
				? !!errors.email
				: true
			: step === 1
			? allFields.pin.length !== 4
			: step === 2
			? !allFields.newPassword ||
			  !allFields.confirmNewPassword ||
			  allFields.newPassword !== allFields.confirmNewPassword
			: true
	const handleNextStep = () => {
		if (step === 0) {
			if (!isInValid) {
				sendPasswordReset({
					variables: {
						email: allFields.email
					}
				})
				// setStep(step + 1)
			} else {
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				handleSubmit(() => {})()
			}
		} else if (step === 1) {
			if (!isInValid) {
				checkPin({
					variables: {
						email: allFields.email,
						pin: allFields.pin
					}
				})
			}
		} else if (step === 2) {
			const { confirmNewPassword, ...userInput } = allFields
			resetPassword({
				variables: {
					userInput
				}
			})
		}
	}

	return (
		<Grid container direction="column" spacing={3}>
			<Grid item>
				<Stepper activeStep={step}>
					<Step>
						<StepLabel style={{ fontSize: pxToRem(18), fontWeight: 400 }}>
							{intl.formatMessage({ id: 'email' })}
						</StepLabel>
					</Step>
					<Step>
						<StepLabel style={{ fontSize: pxToRem(18), fontWeight: 400 }}>
							{intl.formatMessage({ id: 'pin' })}
						</StepLabel>
					</Step>
					<Step>
						<StepLabel style={{ fontSize: pxToRem(18), fontWeight: 400 }}>
							{intl.formatMessage({ id: 'auth.forgotPassword.resetPassword' })}
						</StepLabel>
					</Step>
				</Stepper>
			</Grid>
			<Grid item>
				<Box marginTop={1}>
					<Typography gutterBottom variant="h3" paragraph>
						{intl.formatMessage({
							id: `auth.forgotPassword.${step}.title` as keyof ILocale
						})}
					</Typography>
				</Box>
				<Typography variant="paragraph1" color="secondary">
					{intl.formatMessage(
						{
							id: `auth.forgotPassword.${step}.desc` as keyof ILocale
						},
						{ email: allFields.email }
					)}
				</Typography>
			</Grid>
			<Grid item>
				<div
					style={{ flex: '1 1 auto' }}
					onKeyDown={e => {
						if (e.keyCode === 13) {
							handleNextStep()
						}
					}}
				>
					<Grid
						item
						container
						spacing={4}
						style={{ display: step !== 0 ? 'none' : 'flex' }}
					>
						<Grid item xs={12}>
							<TextField
								required
								name="email"
								type="email"
								disabled={step > 0}
								error={!!errors.email}
								helperText={
									errors?.email?.type === 'custom' ? errors?.email?.message : undefined
								}
								inputRef={register({ required: true, pattern: emailRegex })}
								label={intl.formatMessage({ id: 'email' })}
								placeholder="ex. john@gmail.com"
								InputProps={allFields.email && !errors.email ? noErrorProps : {}}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={4} style={{ display: step !== 1 ? 'none' : 'flex' }}>
						<Grid item container justifyContent="center">
							<Controller
								name="pin"
								rules={{ required: true, minLength: 4, maxLength: 4, pattern: /[0-9]/ }}
								control={control}
								render={({ onChange }) => (
									<PinInput
										border="all"
										// fullWidth
										onChange={val => onChange(val)}
										// maxWidth={275}
									/>
								)}
								defaultValue=""
							/>
						</Grid>
					</Grid>
					<Grid container spacing={4} style={{ display: step !== 2 ? 'none' : 'flex' }}>
						<Grid item xs={12}>
							<PasswordInput
								required
								error={!!errors.newPassword}
								name="newPassword"
								label={intl.formatMessage({ id: 'changePassword.newPassword' })}
								inputRef={register({
									required: true,
									pattern: passwordRegex
								})}
								isCorrect={allFields.newPassword ? !errors.newPassword : false}
							/>
							<Grid
								item
								xs={12}
								container
								spacing={1}
								alignItems="center"
								style={{
									marginTop: materialTheme.spacing(2)
								}}
							>
								<Grid item xs={6} sm={4}>
									<Requirement pattern={/[a-z]/} value={allFields.newPassword}>
										{intl.formatMessage({ id: 'validation.lowercase' }, { length: 1 })}
									</Requirement>
								</Grid>
								<Grid item xs={6} sm={4}>
									<Requirement pattern={/[0-9]/} value={allFields.newPassword}>
										{intl.formatMessage({ id: 'validation.number' }, { length: 1 })}
									</Requirement>
								</Grid>
								<Grid item xs={6} sm={4}>
									<Requirement pattern={/.{8,}/} value={allFields.newPassword}>
										{intl.formatMessage({ id: 'validation.minLength' }, { length: 8 })}
									</Requirement>
								</Grid>
								<Grid item xs={6} sm={4}>
									<Requirement pattern={/[A-Z]/} value={allFields.newPassword}>
										{intl.formatMessage({ id: 'validation.uppercase' }, { length: 1 })}
									</Requirement>
								</Grid>
								<Grid item xs={6} sm={4}>
									<Requirement pattern={/[#?!@$%^&*-]/} value={allFields.newPassword}>
										{intl.formatMessage({ id: 'validation.special' }, { length: 1 })}
									</Requirement>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<PasswordInput
								required
								error={
									allFields.confirmNewPassword
										? allFields.confirmNewPassword !== allFields.newPassword
										: false
								}
								isCorrect={
									allFields.confirmNewPassword
										? allFields.confirmNewPassword === allFields.newPassword
										: false
								}
								name="confirmNewPassword"
								label={intl.formatMessage({ id: 'changePassword.confirmNewPassword' })}
								inputRef={register({
									required: true,
									pattern: passwordRegex
								})}
							/>
						</Grid>
					</Grid>
				</div>
			</Grid>
			<Grid item>
				<Grid container spacing={4}>
					<Grid item container justifyContent="flex-end" xs={12}>
						<Grid container justifyContent="flex-end" item xs={12} lg={4}>
							<ContinueButton
								disabled={isInValid}
								pending={sendingPin || resettingPassword || checkingPin}
								onClick={handleNextStep}
								continueText={intl.formatMessage({ id: 'continue' })}
								finishText={intl.formatMessage({
									id: 'auth.forgotPassword.resetPassword'
								})}
								style={{ fontSize: pxToRem(16), maxHeight: 48 }}
								isFinish={step === 2}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Box marginTop={1}>
					<Typography variant="h5" color="secondary" align="center">
						{intl.formatMessage({ id: 'auth.haveAccount' })}{' '}
						<Link to="/auth/login">{intl.formatMessage({ id: 'auth.signIn' })}</Link>
					</Typography>
				</Box>
			</Grid>
		</Grid>
	)
}

export default ForgotPassword
