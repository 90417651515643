import React from 'react'
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	TableHead,
	Typography,
	Box
} from '@material-ui/core'

import { TableColumnLabel, TableCellHeader } from './RecordTable.styles'
import NoRecord from './NoRecord'

export interface RecordTableProps<TData = Record<string, any> | any> {
	className?: string
	data: TData[]
	columns: {
		id: string
		title: string
		content: string | ((data: TData) => React.ReactNode)
	}[]
	emptyMessage?: string
}

export const RecordTable: React.FC<RecordTableProps> = ({
	columns,
	data,
	className,
	emptyMessage
}) => (
	<TableContainer component="div" className={className}>
		<Table stickyHeader aria-label="record-table">
			<TableHead>
				<TableRow>
					{columns.map(({ id, title }) => (
						<TableCellHeader key={id} align="left">
							<TableColumnLabel>{title}</TableColumnLabel>
						</TableCellHeader>
					))}
				</TableRow>
			</TableHead>
			<TableBody>
				{data.map((item, idx) => (
					<TableRow key={idx}>
						{columns.map(({ id, content }) => {
							if (typeof content === 'string') {
								return <Typography>{content}</Typography>
							}
							return (
								<TableCell key={id} align="left">
									{content(item)}
								</TableCell>
							)
						})}
					</TableRow>
				))}
			</TableBody>
		</Table>
		{data.length < 1 && (
			<Box sx={{ marginTop: 3 }}>
				<NoRecord label={emptyMessage || ''} />
			</Box>
		)}
	</TableContainer>
)

export default RecordTable
