/* stylelint-disable */
/* eslint-disable indent */

import { createGlobalStyle } from 'styled-components'
import { alpha } from '@material-ui/core/styles'

export const pxToRem = (px: number): string => `${px / 16}rem`

export const GlobalStyles = createGlobalStyle`
	html {
		box-sizing: border-box;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	*, *::before, *::after {
		box-sizing: border-box;
	}
	* {
		margin: 0;
		padding: 0;
	}
	strong, b {
		font-weight: bold;
	}
	html {
		font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	}
	body {
		color: ${props => props.theme.text.main};
		font-size: 0.875rem;
	 	font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
		font-weight: 400;
		line-height: 1.43;
		overflow-x: hidden !important;
		letter-spacing: 0.01071em;
		background-color: ${props => props.theme.background.light};
		input:-webkit-autofill,
		input:-webkit-autofill:hover,
		input:-webkit-autofill:focus,
		input:-webkit-autofill:active  {
			border-radius: 6px;
			-webkit-box-shadow: 0 0 0 30px white inset !important;
		}
		input:-webkit-autofill:disabled {
			-webkit-box-shadow: 0 0 0 30px ${props =>
				alpha(props.theme.grey.fourth, 0.5)} inset !important;
			-webkit-text-fill-color: 0 0 0 30px ${props => props.theme.grey.second} inset !important;
		}
		&.dark {
			input:-webkit-autofill,
			input:-webkit-autofill:hover,
			input:-webkit-autofill:focus,
			input:-webkit-autofill:active {
				-webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0.1) inset !important;
			}
			input:-internal-autofill-selected {
				background-color: transparent !important;
			}
			.custom-scrollbar {
				scrollbar-color: rgba(180, 180, 180, 0.06);
				&::-webkit-scrollbar-thumb {
					background-color: rgba(180, 180, 180, 0.06);
				}
			}
		}
	}
	button {
		font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	}
	@media print {
		body {
			background-color: #fff;
			&.dark {
			background-color: #1B1B1B;
		}
		}
	}
	body::backdrop {
		background-color: #fafafa;
	}
	h1 {
		font-weight: 700;
		font-size: ${() => pxToRem(30)};
	}
	h2 {
		font-size: ${() => pxToRem(20)};
		font-weight: 400;
	}
	h3 {
		font-size: ${() => pxToRem(18)};
		font-weight: 400;
	}
	h4 {
		font-size: ${() => pxToRem(16)};
	}
	h5 {
		font-size: ${() => pxToRem(14)};
	}
	small {
		font-size: ${() => pxToRem(11)};
	}
	p {
		font-size: ${() => pxToRem(15)}
	}
	a {
		font-size: inherit;
		text-decoration: none;
		color: ${props => props.theme.text.link};
	}
	.custom-scrollbar {
		outline: none;
		scrollbar-color: rgba(1, 1, 1, 0.06);
		scrollbar-width: 7px;
		&::-webkit-scrollbar {
			-webkit-appearance: none;
			width: 7px;
			height: 7px;
		}

		&::-webkit-scrollbar-corner {
			background: rgba(0, 0, 0, 0);
		}

		&::-webkit-scrollbar-thumb {
			max-height: 100px;
			background-color: rgba(1, 1, 1, 0.06);
			border-radius: 3px;
		}

		&::-webkit-scrollbar-track {
			background-color: inherit;
		}
	}
	.transparent-bg {
		background-color: transparent !important;
		font-size: 16px !important;
		color: ${props => props.theme.text} !important;
	}
`
