import React from 'react'
import { Backdrop, Fade, Modal } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

import * as S from './VideoModal.style'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: '2px solid #000',
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3)
		}
	})
)

export interface VideoModalProps {
	content: JSX.Element
	open: boolean
	onClose: () => void
}

export const VideoModal: React.FC<VideoModalProps> = ({ content, open, onClose }) => {
	const classes = useStyles()

	const handleClose = () => {
		onClose()
	}

	return (
		<Modal
			className={classes.modal}
			open={open}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
				style: {
					backgroundColor: 'rgba(0,0,0,0.9)'
				}
			}}
		>
			<Fade in={open}>
				<S.Container>
					<S.CloseButton
						onClick={handleClose}
						style={{ position: 'absolute', top: 30, right: 30, color: '#fff' }}
					>
						<CloseIcon />
					</S.CloseButton>
					<S.Content>{content}</S.Content>
				</S.Container>
			</Fade>
		</Modal>
	)
}
