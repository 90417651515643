import React from 'react'
import { Typography } from '@material-ui/core'
import * as S from './CircleProgressBar.style'

export interface CircleProgressBarProps {
	/**
	 * Progress bar color
	 * @default '#3DD598'
	 */
	stroke?: string
	/**
	 * Border width
	 * @default 5
	 */
	strokeWidth?: number
	/**
	 * Background of Circle Progress Bar
	 * @default #E2E2EA
	 */
	background?: string
	/**
	 * Size of Circle Progress Bar
	 * @default 100
	 */
	diameter?: number
	orientation?: 'up' | 'down'
	direction?: 'left' | 'right'
	label?: string
	/**
	 * Current value
	 */
	percentage: number
	/**
	 * @default true
	 */
	showPercentValue?: boolean
}

export const CircleProgressBar = ({
	stroke = '#3DD598',
	strokeWidth = 5,
	background = '#E2E2EA',
	diameter = 100,
	orientation = 'up',
	direction = 'right',
	showPercentValue = true,
	label,
	percentage
}: CircleProgressBarProps) => {
	const coordinateForCircle = diameter / 2
	const radius = (diameter - 2 * strokeWidth) / 2
	const circumference = Math.PI * radius

	let percentageValue
	if (percentage > 100) {
		percentageValue = 100
	} else if (percentage < 0) {
		percentageValue = 0
	} else {
		percentageValue = percentage
	}
	const semiCirclePercentage = circumference * ((100 - percentageValue) / 100 + 1)

	let rotation
	if (orientation === 'down') {
		if (direction === 'left') {
			rotation = 'rotate(180deg) rotateY(180deg)'
		} else {
			rotation = 'rotate(180deg)'
		}
	} else if (direction === 'right') {
		rotation = 'rotateY(180deg)'
	}

	return (
		<S.Container>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={diameter}
				height={diameter / 2 + 4}
				preserveAspectRatio="xMidYMid meet"
				style={{ transform: rotation, overflow: 'hidden' }}
			>
				<circle
					cx={coordinateForCircle}
					cy={coordinateForCircle}
					r={radius}
					fill="none"
					stroke={background}
					strokeWidth={strokeWidth}
					strokeDasharray={circumference}
					strokeLinecap="round"
					style={{
						strokeDashoffset: 0,
						transform: `scaleY(-1) translateY(-${diameter}px)`
					}}
				/>
				<circle
					cx={coordinateForCircle}
					cy={coordinateForCircle}
					r={radius}
					fill="none"
					stroke={stroke}
					strokeWidth={strokeWidth}
					strokeDasharray={circumference * 2}
					strokeLinecap="round"
					style={{
						strokeDashoffset: semiCirclePercentage,
						opacity: isNaN(semiCirclePercentage) ? 0 : 1,
						transform: `scaleY(-1) translateY(-${diameter}px)`,
						transition:
							'stroke-dashoffset .3s ease 0s, stroke-dasharray .3s ease 0s, stroke .3s'
					}}
				/>
			</svg>
			{showPercentValue && <S.Value>{percentageValue}</S.Value>}
			{label && (
				<Typography component="span" variant="paragraph2" mt={1}>
					{label}
				</Typography>
			)}
		</S.Container>
	)
}

export default CircleProgressBar
