/* eslint-disable camelcase */
import React from 'react'
import { Grid } from '@material-ui/core'
import { useFormContext, Controller } from 'react-hook-form'

import { ToggleButton, OnboardingToggleButtonItem } from 'components/ToggleButton'
import { GuidedChallengesForm } from 'screens/Onboarding/containers/GuidedChallenges'

import WizardFooter from 'components/Wizard/WizardFooter'
import { useOnboardingStore } from '../../../../../../hooks/useOnboardingStore'
import { SelectionContainer } from '../../../../../../components/SelectionContainer'
import { useSkillSelectionStore } from '../../hooks/useSkillSelectionStore'

export const SkillSelectionSucceedingSteps: React.FC = () => {
	const { control, watch, getValues, setValue } = useFormContext<GuidedChallengesForm>()
	const { nextStep, toggleHelpGuide } = useOnboardingStore(state => state)
	const { currentStep, chapters, goBack, goNext } = useSkillSelectionStore(state => ({
		currentStep: state.currentStep,
		chapters: state.chapters,
		goBack: state.goBack,
		goNext: state.goNext
	}))

	const handleBack = () => {
		goBack()
	}

	const handleContinue = () => {
		if (currentStep < 3) {
			return goNext()
		}

		const { chapters, mustHaveSkills, niceToHaveSkills } = getValues()

		if (mustHaveSkills.length || niceToHaveSkills.length) {
			const { mustHaveSkillIds, niceToHaveSkillIds } = updateSkillImportances({
				mustHaveSkillIds: mustHaveSkills,
				niceToHaveSkillIds: niceToHaveSkills,
				clickCountMapByChapterId: createClickCountMappingByChapterId(chapters)
			})

			setValue('mustHaveSkills', mustHaveSkillIds)
			setValue('niceToHaveSkills', niceToHaveSkillIds)

			return nextStep()
		}

		const {
			mustHaveSkillIds,
			niceToHaveSkillIds
		} = getInitialSkillImportancesForBenchmarking(chapters)

		setValue('mustHaveSkills', mustHaveSkillIds)
		setValue('niceToHaveSkills', niceToHaveSkillIds)

		nextStep()
	}

	const selectedChapters: string[] = watch(`chapters.question${currentStep}`)

	return (
		<>
			<SelectionContainer>
				<Controller
					control={control}
					name={`chapters.question${currentStep}`}
					render={({ onChange, value }) => (
						<ToggleButton value={value} onChange={value => onChange(value)}>
							{chapters.map(({ id, name, desc, cluster }) => (
								<Grid item key={id} xs={12} md={6} lg={4}>
									<OnboardingToggleButtonItem
										value={id}
										title={name || ''}
										description={desc || ''}
										label={cluster?.name || ''}
									/>
								</Grid>
							))}
						</ToggleButton>
					)}
				/>
			</SelectionContainer>
			<WizardFooter
				onOpenHelpGuide={toggleHelpGuide}
				continueDisabled={selectedChapters.length < 3}
				onBack={handleBack}
				onContinue={handleContinue}
			/>
		</>
	)
}

const updateSkillImportances = (params: {
	mustHaveSkillIds: string[]
	niceToHaveSkillIds: string[]
	clickCountMapByChapterId: Record<string, number>
}) => {
	const { mustHaveSkillIds, niceToHaveSkillIds, clickCountMapByChapterId } = params

	const additionalMustHaveSkillIds: string[] = []
	const additionalNiceToHaveSkillIds: string[] = []

	Object.keys(clickCountMapByChapterId).forEach(chapterId => {
		const count = clickCountMapByChapterId[chapterId]

		if (count >= 2) {
			additionalMustHaveSkillIds.push(chapterId)
		}

		if (count === 1) {
			additionalNiceToHaveSkillIds.push(chapterId)
		}
	})

	let newMustHaveSkillIds = [...mustHaveSkillIds]
	let newNiceToHaveSkillIds = [...niceToHaveSkillIds]

	newMustHaveSkillIds = newMustHaveSkillIds.filter(id => {
		const count = clickCountMapByChapterId[id]
		const keep = count >= 2
		const moveToNiceToHave = count === 1

		if (moveToNiceToHave) {
			newNiceToHaveSkillIds.push(id)
		}

		return keep
	})

	newNiceToHaveSkillIds = newNiceToHaveSkillIds.filter(id => {
		const count = clickCountMapByChapterId[id]
		const keep = count === 1
		const moveToMustHave = count >= 2

		if (moveToMustHave) {
			newMustHaveSkillIds.push(id)
		}

		return keep
	})

	return {
		mustHaveSkillIds: removeDuplicate([
			...newMustHaveSkillIds,
			...additionalMustHaveSkillIds
		]),
		niceToHaveSkillIds: removeDuplicate([
			...newNiceToHaveSkillIds,
			...additionalNiceToHaveSkillIds
		])
	}
}

const removeDuplicate = (arr: string[]) => [...new Set(arr)]

const createClickCountMappingByChapterId = (
	selectedChapters: GuidedChallengesForm['chapters']
) =>
	Object.keys(selectedChapters).reduce((mapping, questionKey) => {
		selectedChapters[questionKey].forEach(chapterId => {
			let count = mapping[chapterId] ?? 0
			count += 1
			mapping[chapterId] = count
		})

		return mapping
	}, {} as Record<string, number>)

const getInitialSkillImportancesForBenchmarking = (
	selectedChapters: GuidedChallengesForm['chapters']
) => {
	const selectionCountMapByChapterId: Record<string, number> = {}

	Object.keys(selectedChapters).forEach(questionKey => {
		const selectedChapterIds = selectedChapters[questionKey]

		selectedChapterIds.forEach(chapterId => {
			let count = selectionCountMapByChapterId[chapterId] ?? 0
			count += 1
			selectionCountMapByChapterId[chapterId] = count
		})
	})

	const { mustHaveSkillIds, niceToHaveSkillIds } = Object.keys(
		selectionCountMapByChapterId
	).reduce(
		(currentMap, chapterId) => {
			const count = selectionCountMapByChapterId[chapterId]

			if (count >= 2) {
				currentMap.mustHaveSkillIds.push(chapterId)
			} else {
				currentMap.niceToHaveSkillIds.push(chapterId)
			}

			return currentMap
		},
		{
			mustHaveSkillIds: [],
			niceToHaveSkillIds: []
		} as {
			mustHaveSkillIds: string[]
			niceToHaveSkillIds: string[]
		}
	)

	return {
		mustHaveSkillIds,
		niceToHaveSkillIds
	}
}
