import { useStoreon } from 'storeon/react'
import useCheckout from 'hooks/useCheckout'
import { SERVER_URL } from 'constants/config'
import { EnumSubscriptionInterval } from 'hooks/useApollo'

export type IuseBilling = ReturnType<typeof useBilling>

export type ActiveSubscriptions = {
	[key: string]: {
		id: string
		title: string
		endsAt: Date
		nextPaymentAt: Date
		isCanceled: boolean
		nextPaymentCost: number
		interval: EnumSubscriptionInterval
		type: 'hiring' | 'benchmarking'
		isCancellationAlertAcknowledged: boolean
		isCancellationMessageAcknowledged: boolean
	}
}

export const useBilling = () => {
	const { user } = useStoreon('user')
	const { token } = useStoreon('token')
	const [
		{ open, plans, loading },
		{ handleClose, handleOpen, handleActivatePlan }
	] = useCheckout()

	const currentSubscriptions =
		user?.subscriptions.reduce<ActiveSubscriptions>((current, subscription) => {
			subscription.plans.forEach(plan => {
				const key = `${plan}_${subscription.interval}`
				current[key] = {
					id: subscription.id,
					title: plans[key].title,
					endsAt: subscription.endsAt,
					nextPaymentAt: subscription.nextPaymentAt,
					isCanceled: subscription.isCanceled,
					nextPaymentCost: subscription.nextPaymentCost || 0,
					interval: subscription.interval,
					type: plan.toLowerCase() === 'hiring' ? 'hiring' : 'benchmarking',
					isCancellationAlertAcknowledged: !!subscription.isCancellationAlertAcknowledged,
					isCancellationMessageAcknowledged: !!subscription.isCancellationMessageAcknowledged
				}
			})
			return current
		}, {}) || {}

	const filteredPlans = () => {
		const allPlans = { ...plans }
		Object.keys(currentSubscriptions).forEach(key => {
			delete allPlans[key]
		})

		return allPlans
	}

	const getBillingPortalUrl = () =>
		`${SERVER_URL}/api/v1/billing/portal?token=${window.encodeURIComponent(
			token as string
		)}&redirectUrl=${window.encodeURIComponent(window.location.href)}`

	const models = {
		open,
		plans,
		loading,
		availablePlans: filteredPlans(),
		currentSubscriptions,
		employeeCount: user?.employeeCount || 0
	}
	const operations = {
		handleOpen,
		handleClose,
		handleActivatePlan,
		getBillingPortalUrl
	}

	return [models, operations] as [typeof models, typeof operations]
}

export default useBilling
