import React from 'react'
import { useTheme } from 'styled-components'
import {
	Radar,
	RadarChart,
	PolarGrid,
	PolarAngleAxis,
	ResponsiveContainer,
	PolarRadiusAxis
} from 'recharts'

export type ScoreMapping = Record<string, number>

export interface ChartData {
	skill: string
	scoreMapping: ScoreMapping
}

export interface ChartSkill {
	id: string
	name: string
	color: string
}

export interface ChartProps {
	data: ChartData[]
	skills: ChartSkill[]
}

export const TeamDynamicChart: React.FC<ChartProps> = ({ data, skills }) => {
	const theme = useTheme()

	return (
		<ResponsiveContainer width="100%" height="100%">
			<RadarChart cx="50%" cy="50%" outerRadius="60%" data={data}>
				<PolarGrid gridType="circle" />
				<PolarAngleAxis dataKey="skill" tick={customTick(theme.text.main)} />
				<PolarRadiusAxis domain={[0, 100]} tick={false} />
				{!skills.length ? (
					<Radar name="empty" dataKey="scoreMapping.emptyPlaceholder" />
				) : (
					skills.map(({ id, name, color }) => (
						<Radar
							key={id}
							name={name}
							dataKey={`scoreMapping.${id}`}
							stroke={color}
							fill={color}
							fillOpacity={0.05}
						/>
					))
				)}
			</RadarChart>
		</ResponsiveContainer>
	)
}

const customTick = (color: string) => ({
	payload,
	x,
	y,
	textAnchor,
	stroke,
	radius,
	index: itemIndex
}) => (
	<g>
		{itemIndex === 0 ? (
			<text
				radius={radius}
				stroke={stroke}
				x={x}
				y={y - 15}
				textAnchor={textAnchor}
				fill={color}
				fontSize={11}
			>
				<tspan x={x}>{payload.value}</tspan>
			</text>
		) : (
			payload.value.split(/-| /).map((label, index) => (
				<text
					key={label}
					radius={radius}
					stroke={stroke}
					x={x}
					y={y + (textAnchor === 'middle' ? 20 * index + 15 : 20 * index)}
					textAnchor={textAnchor}
					fill={color}
					fontSize={11}
				>
					<tspan x={textAnchor === 'start' ? x + 15 : textAnchor === 'end' ? x - 15 : x}>
						{label}
					</tspan>
				</text>
			))
		)}
	</g>
)
