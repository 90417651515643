import React, { useEffect } from 'react'
import { IntlProvider } from 'react-intl'
import { useRoutes } from 'react-router-dom'
import LocalizationProvider from '@material-ui/lab/LocalizationProvider'
import DateFnsUtils from '@material-ui/lab/AdapterDateFns'
import { useStoreon } from 'storeon/react'
import iziToast from 'izitoast'
import { useMediaQuery } from '@material-ui/core'
import * as locales from './constants/locales'
import { mainRoutes } from './routes'
import 'izitoast/dist/css/iziToast.min.css'
import NuggetProvider from 'packages/nuggetai.ui-kit/nugget/provider'
import { ar } from 'constants/locales/ar'
import { ur } from 'constants/locales/ur'
import { WebSocketProvider } from 'context/WebSocketContext'
import { assessmentsSocketUrl } from 'constants/config'

iziToast.settings({
	position: 'bottomLeft',
	maxWidth: 400
})

declare global {
	interface Window {
		analytics?: {
			track: (eventName: string, properties?: Record<string, any>) => void
			identify: (userId: string, properties?: Record<string, any>) => void
		}
	}
}

const App = () => {
	const routes = useRoutes(mainRoutes)
	const { theme } = useStoreon('theme')
	const smDown = useMediaQuery('(max-width: 600px)')

	// tracking starts

	const { user } = useStoreon('user')
	const username = `${user?.firstName ?? ''}${user?.lastName ?? ''}` || 'Anonymous'

	const trackEvent = (eventName: string, properties?: Record<string, any>) => {
		if (window.analytics) {
			window.analytics.track(eventName, { ...properties, username })
			// console.log(`Event tracked: ${eventName}`, { ...properties, username })
		} else {
			console.warn('Segment analytics not initialized.')
		}
	}

	useEffect(() => {
		if (window.analytics) {
			window.analytics.identify(username, {
				email: 'chandu@example.com'
			})
			console.log(`User identified: ${username}`)
		} else {
			console.warn('Segment analytics not initialized.')
		}
		trackEvent('User Identified', {
			username,
			message: 'User identified with the system.'
		})
	}, [])

	useEffect(() => {
		trackEvent('Page Viewed', {
			pageName: 'App Component'
		})
	}, [])

	useEffect(() => {
		const handleVisibilityChange = () => {
			if (document.hidden) {
				trackEvent('Screen Off', { state: 'off', username })
			} else {
				trackEvent('Screen On', { state: 'on', username })
			}
		}

		document.addEventListener('visibilitychange', handleVisibilityChange)

		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange)
		}
	}, [])

	// tracking ends
	useEffect(() => {
		if (smDown) {
			iziToast.settings({
				position: 'topLeft'
			})
		} else {
			iziToast.settings({
				position: 'bottomLeft',
				maxWidth: 400
			})
		}
	}, [smDown])

	useEffect(() => {
		const handleUnload = () => {
			localStorage.setItem('lastActive', new Date().toJSON())
		}

		window.addEventListener('unload', handleUnload)

		return () => {
			window.removeEventListener('unload', handleUnload)
		}
	}, [])

	const { language } = useStoreon('language')

	return (
		<NuggetProvider mode={theme}>
			<IntlProvider
				locale="en"
				defaultLocale="en"
				messages={language === 'ar' ? ar : language === 'ur' ? ur : locales.en}
			>
				<WebSocketProvider socketUrl={assessmentsSocketUrl({ userId: user?.id || '' })}>
					<LocalizationProvider dateAdapter={DateFnsUtils}>{routes}</LocalizationProvider>
				</WebSocketProvider>
			</IntlProvider>
		</NuggetProvider>
	)
}

export default App
