import styled from 'styled-components'

import { TalkBubbleContainer } from 'components/TalkBubble/TalkBubble.style'

export const NyomiContainer = styled.div`
	width: 64px;
	height: 64px;
	background-color: #477ef7;
	padding: 15px;
	border-radius: 50%;
	box-shadow: 0px 4px 8px rgba(36, 52, 67, 0.2);

	svg {
		width: 100%;
		height: 100%;
	}
`

export const TalkBubble = styled(TalkBubbleContainer)`
	left: 40px;
	top: 4px;
	background-color: ${props => props.theme.tag.white};
	color: ${props => props.theme.tag.black};
	filter: drop-shadow(0px 3px 20px rgba(159, 155, 155, 0.25));

	&:before {
		border-top-color: ${props => props.theme.tag.white};
	}
`
