import { useEffect } from 'react'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
import { useStoreon } from 'storeon/react'

import { useAccountStore } from 'hooks/useAccountStore'

export const VerifyTokenRouteHandler = () => {
	const navigate = useNavigate()
	const { token } = useParams()
	const [searchParams] = useSearchParams()
	const { dispatch, user } = useStoreon('user')
	const { setFirstAccess: activateFirstAccess, nextStep } = useAccountStore(state => state)

	useEffect(() => {
		// @ts-ignore
		dispatch('user/set', { token, user: null, loadingUser: true })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (user) {
			if (searchParams.has('new')) {
				activateFirstAccess(true)
				nextStep()
				navigate('/auth/signup/select-plans', {
					replace: true
				})
				return
			}

			navigate('/')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(user)])

	return null
}

export default VerifyTokenRouteHandler
