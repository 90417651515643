/* eslint-disable no-shadow */
import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import {
	DragDropContext,
	DragDropContextProps,
	Draggable,
	Droppable
} from 'react-beautiful-dnd'
import { useFormContext } from 'react-hook-form'

import { Alert } from 'components/Alert'
import { CreationContent } from 'components/CreationContent'
import { useIntl } from 'hooks/useIntl'
import WizardFooter from 'components/Wizard/WizardFooter'
import { ContentWrapper } from '../../../../components/ContentWrapper'
import { useOnboardingStore } from '../../../../hooks/useOnboardingStore'
import { GuidedChallengesForm } from '../../GuidedChallenges.types'
import { useSkillSelectionStore } from '../GuidedSkillsSelection/hooks/useSkillSelectionStore'
import SkillItem from '../../../../components/SkillItem'

export const GuidedSkillImportance: React.FC = () => {
	const intl = useIntl()
	const { getValues, setValue, watch } = useFormContext<GuidedChallengesForm>()
	const chapterMappingById = useSkillSelectionStore(state => state.chapterMappingById)
	const { currentStep, prevStep, nextStep, toggleHelpGuide } = useOnboardingStore(
		state => state
	)

	const mustHaveSkills = watch('mustHaveSkills')
	const niceToHaveSkills = watch('niceToHaveSkills')

	const handleDragEnd: DragDropContextProps['onDragEnd'] = ({ source, destination }) => {
		if (!destination) {
			return
		}

		if (source.droppableId === destination.droppableId) {
			const items = reorder(
				source.droppableId === 'mustHaveSkills' ? mustHaveSkills : niceToHaveSkills,
				source.index,
				destination.index
			)

			setValue(source.droppableId, items)
		} else {
			const result = move<{
				mustHaveSkills: string[]
				niceToHaveSkills: string[]
			}>(
				source.droppableId === 'mustHaveSkills' ? mustHaveSkills : niceToHaveSkills,
				destination.droppableId === 'mustHaveSkills' ? mustHaveSkills : niceToHaveSkills,
				source,
				destination
			)

			setValue('mustHaveSkills', result.mustHaveSkills)
			setValue('niceToHaveSkills', result.niceToHaveSkills)
		}
	}

	const handleItemDelete = (id: string, droppableId: string) => {
		const { chapters } = getValues()

		Array.from({ length: 3 }).forEach((_, index) => {
			const questionKey = `question${index + 1}`

			setValue(
				`chapters.${questionKey}`,
				chapters[questionKey].filter(skillId => skillId !== id)
			)
		})

		setValue(
			droppableId,
			(droppableId === 'mustHaveSkills' ? mustHaveSkills : niceToHaveSkills).filter(
				currentId => currentId !== id
			)
		)
	}

	const handleBack = () => prevStep()

	const handleContinue = () => {
		nextStep()
	}

	const dragAndDropGroups = [
		{
			droppableId: 'mustHaveSkills',
			items: mustHaveSkills
		},
		{
			droppableId: 'niceToHaveSkills',
			items: niceToHaveSkills
		}
	]

	const minutesEstimative = getMinutesEstimative([...mustHaveSkills, ...niceToHaveSkills])

	const totalSelectedSkills = mustHaveSkills.length + niceToHaveSkills.length

	const canContinue = totalSelectedSkills >= 3
	const canDelete = totalSelectedSkills > 3

	return (
		<>
			<ContentWrapper currentStep={currentStep}>
				<CreationContent
					subHeader
					subTitle={intl.formatMessage({ id: 'onboarding.select.guided.challenges.title' })}
					title={intl.formatMessage({
						id: `onboarding.guided.challenges.step.${currentStep}.title`
					})}
					desc={intl.formatMessage({
						id: `onboarding.guided.challenges.step.${currentStep}.desc`
					})}
				>
					<Grid container direction="column" alignItems="stretch" spacing={6}>
						{canContinue ? null : (
							<Grid item>
								<Alert
									open
									color="danger"
									label={intl.formatMessage(
										{
											id: 'teams.onboarding.skillImportance.skillsRequired'
										},
										{ count: 3 }
									)}
								/>
							</Grid>
						)}
						<Grid item>
							<Typography variant="paragraph1" gutterBottom>
								{intl.formatMessage({
									id: 'teams.onboarding.skillImportance.totalChallengeTime.title'
								})}{' '}
								<strong>
									{minutesEstimative.low}-{minutesEstimative.high}{' '}
									{intl.formatMessage({ id: 'mins' })}
								</strong>
							</Typography>
						</Grid>
						<DragDropContext onDragEnd={handleDragEnd}>
							{dragAndDropGroups.map(({ droppableId, items }) => (
								<Grid key={droppableId} item>
									<Box marginBottom={2}>
										<Typography color="textPrimary" variant="h5" gutterBottom>
											{intl.formatMessage({
												id: `teams.onboarding.skillImportance.${droppableId}.title`
											})}
										</Typography>
										<Typography color="secondary" variant="paragraph1">
											{intl.formatMessage({
												id: `teams.onboarding.skillImportance.${droppableId}.description`
											})}
										</Typography>
									</Box>
									<Droppable droppableId={droppableId}>
										{provided => (
											<Grid
												container
												direction="column"
												justifyContent="flex-start"
												alignItems="stretch"
												spacing={2}
												ref={provided.innerRef}
												{...provided.droppableProps}
											>
												{items.map((chapterId, index) => {
													const { id, name, desc, timer, parts } = chapterMappingById[
														chapterId
													]

													return (
														<Draggable key={id} draggableId={id} index={index}>
															{provided => {
																const handleDelete = () => {
																	handleItemDelete(id, droppableId)
																}

																return (
																	<SkillItem
																		id={id}
																		ref={provided.innerRef}
																		parts={parts.length}
																		title={name || ''}
																		description={desc || ''}
																		durationInMinutes={timer || 0}
																		previewUrl="#"
																		dragHandleProps={provided.dragHandleProps}
																		onDelete={canDelete ? handleDelete : undefined}
																		{...provided.draggableProps}
																	/>
																)
															}}
														</Draggable>
													)
												})}
												{provided.placeholder}
											</Grid>
										)}
									</Droppable>
								</Grid>
							))}
						</DragDropContext>
					</Grid>
				</CreationContent>
			</ContentWrapper>
			<WizardFooter
				onOpenHelpGuide={toggleHelpGuide}
				continueDisabled={!canContinue}
				onBack={handleBack}
				onContinue={handleContinue}
			/>
		</>
	)
}

export default GuidedSkillImportance

const getMinutesEstimative = (chapterIds: string[]) => ({
	low: chapterIds.length * 5,
	high: chapterIds.length * 8
})

const reorder = <TItem extends unknown>(list: TItem[], startIndex, endIndex) => {
	const result = Array.from<TItem>(list)
	const [removed] = result.splice(startIndex, 1)
	result.splice(endIndex, 0, removed)

	return result
}

const move = <TResult extends unknown>(
	source,
	destination,
	droppableSource,
	droppableDestination
) => {
	const sourceClone = Array.from(source)
	const destClone = Array.from(destination)
	const [removed] = sourceClone.splice(droppableSource.index, 1)

	destClone.splice(droppableDestination.index, 0, removed)

	const result = {} as TResult
	result[droppableSource.droppableId] = sourceClone
	result[droppableDestination.droppableId] = destClone

	return result
}
