import React, { useCallback, useEffect, useState } from 'react'
import { Box, Grid, Typography, Button, TextField, MenuItem } from '@material-ui/core'
import { FileRejection, useDropzone } from 'react-dropzone'
import { LogoUploadContainer } from 'screens/AccountSettings/containers/Company/Company.style'
import useIntl from 'hooks/useIntl'
import iziToast from 'izitoast'
import axios from 'axios'

const FileUpload = () => {
	const [file, setFile] = useState<File | null>(null)
	const [loading, setLoading] = useState(false)

	const [sectionData, setSectionData] = useState<any[]>([])
	const [subSectionData, setSubSectionData] = useState<any[]>([])
	const [selectedSection, setSelectedSection] = useState<string>('')
	const [selectedSubSection, setSelectedSubSection] = useState<string>('')
	const [sectionName, setSectionName] = useState<string>('')
	const [subsectionName, setSubsectionName] = useState<string>('')

	useEffect(() => {
		const userId = localStorage.getItem('userId')
		if (userId) {
			axios
				.get(`${process.env.REACT_APP_API_URL}/api/v1/custom/sectionData/${userId}`)
				.then(response => {
					setSectionData(response.data)
				})
				.catch(error => console.error('Error fetching section data:', error))
		}
	}, [])

	const handleSectionChange = (value: string) => {
		setSelectedSection(value)
		const section = sectionData.find(sec => sec.sectionId.toString() === value)
		if (section) {
			setSectionName(section.sectionName)
			setSubSectionData(section.subSections || [])
			setSelectedSubSection('')
		}
	}

	const handleSubSectionChange = (value: string) => {
		setSelectedSubSection(value)
		const subsection = subSectionData.find(sub => sub.subSectionId.toString() === value)
		if (subsection) {
			setSubsectionName(subsection.subSectionName)
		}
	}

	const onDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[]) => {
		if (acceptedFiles.length > 0) {
			const file = acceptedFiles[0]
			console.log('File selected:', file)
			setFile(file)
		}

		fileRejections.forEach(({ errors }) => {
			errors.forEach(({ code }) => {
				if (code === 'file-invalid-type') {
					iziToast.error({ message: 'File type must be CSV or XLSX.' })
				} else if (code === 'file-too-large') {
					iziToast.error({ message: 'File size must not be larger than 2MB.' })
				}
			})
		})
	}, [])

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: ['.csv', '.xlsx'],
		maxSize: 2000000,
		multiple: false
	})

	const intl = useIntl()
	const { onClick: onBrowseFileClick, ...logoUploadContainerProps } = getRootProps()
	const handleUpload = async () => {
		if (!file) {
			iziToast.error({ message: 'No file selected. Please upload a file first.' })
			return
		}

		if (!selectedSection || !selectedSubSection) {
			iziToast.error({
				message: 'Please select both section and subsection before uploading.'
			})
			return
		}

		setLoading(true)

		try {
			const userId = localStorage.getItem('userId') || ''
			const formData = new FormData()

			formData.append('file', file)
			formData.append('sectionName', sectionName)
			formData.append('sectionId', selectedSection)
			formData.append('subsectionName', subsectionName)
			formData.append('subsectionId', selectedSubSection)
			formData.append('userId', userId)

			for (const [key, value] of formData.entries()) {
				console.log(key, value)
			}

			const response = await axios.post(
				'${process.env.REACT_APP_API_URL}/api/v1/custom/uploadQuestions',
				formData
			)

			if (response.status === 200 && response.data?.success) {
				iziToast.success({
					message: response.data?.message || 'File uploaded successfully!'
				})
				setFile(null)
			} else {
				iziToast.error({
					message: 'Upload failed'
				})
			}
		} catch (error: unknown) {
			if (axios.isAxiosError(error)) {
				console.error('Upload Error:', error.response?.data || error.message)
				iziToast.error({
					message: error.response?.data?.message || 'Network error, please try again'
				})
			} else {
				console.error('Unexpected Error:', error)
				iziToast.error({ message: 'An unexpected error occurred' })
			}
		} finally {
			setLoading(false)
		}
	}

	return (
		<Box p={3}>
			<Typography variant="h4" gutterBottom>
				Upload Questions
			</Typography>

			<Grid container spacing={3}>
				<Grid item xs={12}>
					<LogoUploadContainer {...logoUploadContainerProps} active={isDragActive}>
						<Grid
							container
							direction="column"
							justifyContent="center"
							alignItems="center"
							spacing={2}
						>
							{isDragActive ? (
								<Typography fontWeight={500}>
									{intl.formatMessage({ id: 'companySettings.dropYourFiles' })}!
								</Typography>
							) : (
								<>
									<Typography fontWeight={500}>
										{intl.formatMessage({ id: 'invite.challenge.uploadEmailFileDropZone' })}
									</Typography>
									<Typography fontWeight={500}>
										{intl.formatMessage({ id: 'or' }).toUpperCase()}
									</Typography>
									<Button
										type="button"
										variant="outlined"
										size="small"
										color="inherit"
										onClick={onBrowseFileClick}
									>
										{intl.formatMessage({ id: 'browseFiles' })}
									</Button>
								</>
							)}
						</Grid>
					</LogoUploadContainer>
					<input {...getInputProps()} />
				</Grid>

				<Grid item xs={12}>
					<Typography sx={{ fontSize: '13px', color: '#f44336' }}>
						Allowed file formats: .csv, .xlsx
					</Typography>
				</Grid>

				{file && (
					<Grid item xs={12}>
						<Typography variant="body2">Selected file: {file.name}</Typography>
					</Grid>
				)}

				<Grid item xs={6}>
					<TextField
						label="Section Name"
						select
						fullWidth
						value={selectedSection}
						onChange={e => handleSectionChange(e.target.value)}
					>
						{sectionData.map(section => (
							<MenuItem key={section.sectionId} value={section.sectionId.toString()}>
								{section.sectionName}
							</MenuItem>
						))}
					</TextField>
				</Grid>

				<Grid item xs={6}>
					<TextField
						label="Subsection Name"
						select
						fullWidth
						value={selectedSubSection}
						onChange={e => handleSubSectionChange(e.target.value)}
					>
						{subSectionData.map(sub => (
							<MenuItem key={sub.subSectionId} value={sub.subSectionId.toString()}>
								{sub.subSectionName}
							</MenuItem>
						))}
					</TextField>
				</Grid>

				<Grid item xs={12}>
					<Button
						variant="contained"
						color="primary"
						disabled={loading || !file}
						onClick={handleUpload}
					>
						{loading ? 'Uploading...' : 'Submit'}
					</Button>
				</Grid>
			</Grid>
		</Box>
	)
}

export default FileUpload
