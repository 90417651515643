import React from 'react'
import { LoadDot } from './dots-loader.style'

export interface DotsLoaderProps {
	/**
	 * defines the number of dots to be rendered
	 */
	dotsCount?: number
	/**
	 * defines the value to be used as width, height and margin between the dots
	 */
	dotSize?: number
}

export const DotsLoader = ({ dotsCount = 3, dotSize = 6, ...props }: DotsLoaderProps) => {
	return (
		<div style={{ display: 'flex' }} {...props}>
			{Array.from({ length: dotsCount }, () => null).map((n, index) => (
				<LoadDot key={index} size={dotSize} i={n || index} />
			))}
		</div>
	)
}

export default DotsLoader
