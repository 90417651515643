import { Grid } from '@material-ui/core'
import styled from 'styled-components'

export const CardsContainer = styled(Grid)`
	height: auto;
	overflow-x: auto;

	@media (min-width: 768px) {
		height: 560px;
	}

	@media (min-width: 1024px) {
		height: 330px;
	}

	@media (min-width: 1440px) {
		height: 470px;
	}

	@media (min-width: 1680px) {
		height: 630px;
	}
`
