import { useState } from 'react'
import { useTheme } from 'styled-components'
import { ColorChangeHandler, RGBColor } from 'react-color'
import { hexToRgbA } from 'utils/color'

export type IuseColorPicker = ReturnType<typeof useColorPicker>

export const useColorPicker = (defaultColor?: string) => {
	const theme = useTheme()
	const [color, setColor] = useState<{ rgba: RGBColor; hex: string }>({
		rgba: defaultColor
			? { ...hexToRgbA(defaultColor) }
			: {
					r: 0,
					g: 132,
					b: 244,
					a: 1
			  },
		hex: defaultColor ?? theme.tag.blue
	})

	const handleColorChange: ColorChangeHandler = ({ rgb, hex }) => {
		setColor({
			rgba: { ...rgb },
			hex
		})
	}

	const models = { rgba: color.rgba, hex: color.hex }
	const operations = { handleColorChange }

	return [models, operations] as [typeof models, typeof operations]
}

export default useColorPicker
