import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useStoreon } from 'storeon/react'
import iziToast from 'izitoast'
import { useVerifyEmailMutation } from 'hooks/useApollo'
import { resetAccountStore, useAccountStore } from 'hooks/useAccountStore'
import { useIntl } from 'react-intl'

export const VerifyEmailRouteHandler = () => {
	const params = useParams()
	const navigate = useNavigate()
	const intl = useIntl()
	const { dispatch, user } = useStoreon('user')
	const setMailVerification = useAccountStore(state => state.setEmailVerification)
	const [verifyEmailMutation] = useVerifyEmailMutation({
		onCompleted: data => {
			if (!data.verifyEmail) {
				return
			}

			if (params.id === user?.id) {
				dispatch('user/setUser', { ...user, isEmailVerified: true })
				setMailVerification(true)
				return navigate('/')
			}

			dispatch('user/signOut')

			iziToast.success({ message: intl.formatMessage({ id: 'dashboard.emailVerified' }) })
			return navigate('/auth/login')
		},
		onError: () => {
			dispatch('user/signOut')
			return navigate('/auth/login')
		}
	})

	useEffect(() => {
		if (!user) {
			resetAccountStore()
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(user)])

	useEffect(() => {
		verifyEmailMutation({
			variables: {
				token: params.token,
				userId: params.id
			}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return <></>
}

export default VerifyEmailRouteHandler
