/* eslint-disable indent */
import React from 'react'
import { format } from 'date-fns'
import { Grid, Typography, Button, Divider } from '@material-ui/core'
import { useTheme } from 'styled-components'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'

import useIntl from 'hooks/useIntl'
import { ActiveSubscriptions } from 'screens/AccountSettings/containers/Billing/useBilling'
import ButtonLink from 'components/ButtonLink'
import { Card, Tag } from './ManagePlansCard.style'

export interface ManagePlansCardProps {
	billingPortal: string
	employeeCount: number
	subscriptions: ActiveSubscriptions
	onShowCalculateModal: () => void
}

export const ManagePlansCard: React.FC<ManagePlansCardProps> = ({
	billingPortal,
	employeeCount,
	subscriptions,
	onShowCalculateModal
}) => {
	const intl = useIntl()
	const theme = useTheme()

	const subKeys = Object.keys(subscriptions)

	return (
		<>
			<Card>
				<Grid container spacing={2}>
					<Grid
						item
						container
						alignItems="center"
						justifyContent="space-between"
						sx={{ mb: 2 }}
					>
						<Grid item xs>
							<Typography variant="h5" fontWeight={500}>
								{intl.formatMessage({ id: 'accountSettings.billing.myPlans' })}
							</Typography>
						</Grid>
						<Grid item>
							<Button href={billingPortal} variant="contained" color="primary">
								{intl.formatMessage({ id: 'manage' })}
							</Button>
						</Grid>
					</Grid>
					{subKeys.map((key, index) => {
						const {
							title,
							nextPaymentCost,
							type,
							nextPaymentAt,
							endsAt,
							interval,
							isCanceled
						} = subscriptions[key]

						return (
							<>
								{index > 0 && (
									<Grid item xs={12} sx={{ my: 2 }}>
										<Divider />
									</Grid>
								)}
								<Grid item container>
									<Grid container justifyContent="space-between">
										<Grid item xs container spacing={2} alignItems="center">
											<Grid item>
												<Typography variant="paragraph1" fontWeight={500}>
													{title}
												</Typography>
											</Grid>
											{isCanceled && (
												<Grid item>
													<Tag>
														{intl.formatMessage({
															id: 'accountSettings.billing.isCancelled'
														})}
													</Tag>
												</Grid>
											)}
										</Grid>
										{type === 'hiring' && isCanceled ? null : (
											<Grid item>
												<Typography
													variant="paragraph1"
													fontWeight={500}
													sx={{ '& span': { fontSize: 12, color: theme.text.description } }}
												>
													${nextPaymentCost.toFixed(2)}{' '}
													{!isCanceled && (
														<span>
															/
															{intl.formatMessage({
																id: `payment.${interval.toLowerCase()}`
															})}
														</span>
													)}
												</Typography>
											</Grid>
										)}
									</Grid>
									{type === 'benchmarking' ? (
										<Grid item container justifyContent="space-between" mt={1}>
											<Grid item>
												<Typography sx={{ fontSize: 12, color: theme.text.description }}>
													*{employeeCount} {intl.formatMessage({ id: 'employees' })}
												</Typography>
											</Grid>
											<Grid item>
												<ButtonLink onClick={onShowCalculateModal}>
													{intl.formatMessage({ id: 'payment.calculate.cost' })}
												</ButtonLink>
											</Grid>
										</Grid>
									) : null}
								</Grid>
								<Grid item xs={12} sx={{ mt: type === 'benchmarking' ? -1 : undefined }}>
									<Typography
										align={type === 'hiring' && isCanceled ? 'left' : 'right'}
										sx={{ fontSize: 12 }}
									>
										{isCanceled && (
											<WarningRoundedIcon
												fontSize="small"
												sx={{ mb: -0.5, mr: 0.5, fill: theme.tag.yellow }}
											/>
										)}
										{isCanceled
											? intl.formatMessage(
													{
														id: `accountSettings.billing.${type}.no.nextPayment`
													},
													{
														date: format(new Date(endsAt), 'MMM dd, yyyy')
													}
											  )
											: intl.formatMessage(
													{ id: 'accountSettings.billing.nextPayment' },
													{
														date: format(new Date(nextPaymentAt), 'MMM dd, yyyy')
													}
											  )}
									</Typography>
								</Grid>
							</>
						)
					})}
				</Grid>
			</Card>
			{subKeys.find(key => subscriptions[key].type === 'benchmarking') && (
				<Typography sx={{ fontSize: 12, mt: 2, color: theme.text.description }}>
					{intl.formatMessage({ id: 'accountSettings.billing.benchmarking.note' })}
				</Typography>
			)}
		</>
	)
}

export default ManagePlansCard
