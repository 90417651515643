import styled from 'styled-components'
import { IconButton } from '@material-ui/core'

export const Container = styled.div`
	outline: none;

	@media only screen and (max-width: 940px) {
		width: 100%;
	}
`

export const Content = styled.div`
	@media only screen and (max-width: 940px) {
		overflow: hidden;
		position: relative;
		width: 100%;

		&::after {
			padding-top: 56.25%;
			display: block;
			content: '';
		}

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
`

export const CloseButton = styled(IconButton)`
	color: #fff;
	position: absolute;
	top: 30px;
	right: 30px;
`
