import React, { useState, useEffect } from 'react'
import axios from 'axios'
import {
	Box,
	Card,
	CardContent,
	Grid,
	Typography,
	ToggleButton,
	ToggleButtonGroup,
	useMediaQuery,
	Dialog,
	DialogTitle,
	DialogContent
} from '@material-ui/core'

import Technicalassessment from './Technicalassessment'
import { useGetAssessmentsQuery } from 'hooks/useApollo'
import { useGlobalWebSocket } from 'context/WebSocketContext'

interface FlexOneProps {
	assessId: string
}

const FlexOne = ({ assessId }: FlexOneProps) => {
	const [applicantsData, setApplicantsData] = useState<any[]>([])
	const [loading, setLoading] = useState(false)
	const [selectedFilter, setSelectedFilter] = useState('today')
	const [openDialog, setOpenDialog] = useState(false)
	const [applicantsCache, setApplicantsCache] = useState<Record<string, any[]>>({})
	const [selectedApplicant, setSelectedApplicant] = useState<any>(null)

	const isMobile = useMediaQuery('(max-width:600px)')

	const getProgressColor = (progress: number) => {
		if (progress < 50) return '#ff6f61'
		if (progress < 80) return '#7D6EF1'
		return 'rgb(0, 196, 140)'
	}

	const { data, refetch } = useGetAssessmentsQuery({
		variables: { lastActive: localStorage.getItem('lastActive') }
	})

	const { registerRefetch } = useGlobalWebSocket()

	const fetchApplicants = async () => {
		if (!assessId) return

		try {
			const response = await axios.post(
				`${process.env.REACT_APP_API_URL}/api/v1/sandbox/getApplicantsbyTeam`,
				{ assessId, filter: selectedFilter },
				{ headers: { 'Content-Type': 'application/json' } }
			)

			setApplicantsData(response.data.data)
			setApplicantsCache(prevCache => ({
				...prevCache,
				[selectedFilter]: response.data.data
			}))
		} catch (error) {
			console.error('Error fetching applicants:', error)
		}
	}

	useEffect(() => {
		const unregister = registerRefetch(() => {
			refetch()
			fetchApplicants()
		})
		return () => unregister()
	}, [registerRefetch, refetch, assessId, selectedFilter])

	useEffect(() => {
		fetchApplicants()
	}, [assessId, selectedFilter])

	const handleCardClick = (applicant: any) => {
		setSelectedApplicant(applicant)
		setOpenDialog(true)
	}

	const handleCloseDialog = () => {
		setOpenDialog(false)
	}

	const handleFilterChange = (_event: React.MouseEvent<HTMLElement>, newFilter: string) => {
		if (newFilter) setSelectedFilter(newFilter)
	}

	return (
		<div>
			<Box
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center'
				}}
			>
				<Typography variant="body1" style={{ flexGrow: 1, fontWeight: 'bold' }}>
					Recent Assessment
				</Typography>
				<Typography
					variant="body1"
					style={{ flexGrow: 1, fontWeight: 'bold', marginRight: '40px' }}
				>
					Time Taken
				</Typography>
				<Typography
					variant="body1"
					style={{ flexGrow: 1, fontWeight: 'bold', marginRight: '30px' }}
				>
					Exam Taken
				</Typography>

				<ToggleButtonGroup value={selectedFilter} exclusive onChange={handleFilterChange}>
					<ToggleButton value="today">Today</ToggleButton>
					<ToggleButton value="week">Last 7 days</ToggleButton>
					<ToggleButton value="monthly">Last 30 days</ToggleButton>
				</ToggleButtonGroup>
			</Box>

			{loading ? (
				<Typography variant="body2" align="center">
					Loading...
				</Typography>
			) : applicantsData.length === 0 ? (
				<Typography variant="body2" align="center" marginTop="40px">
					No applicants found for {selectedFilter}.
				</Typography>
			) : (
				<Box
					sx={{
						maxHeight: '400px',
						overflowY: 'auto',
						scrollbarWidth: 'none',
						'&::-webkit-scrollbar': { display: 'none' }
					}}
				>
					{applicantsData.map((data, index) => (
						<Card
							key={index}
							style={{
								padding: '10px',
								display: 'flex',
								alignItems: 'center',
								cursor: 'pointer'
							}}
							onClick={() => handleCardClick(data)}
						>
							<div
								style={{
									width: '8px',
									height: '8px',
									borderRadius: '50%',
									backgroundColor: getProgressColor(data.progress),
									marginRight: '10px'
								}}
							></div>
							<CardContent style={{ padding: '12px', flex: 1, width: '100%' }}>
								<Grid container alignItems="center" spacing={2}>
									<Grid item xs={3}>
										<Typography variant="body2" fontWeight="bold">
											{`${data.firstName} ${data.lastName}`}
										</Typography>
									</Grid>
									<Grid item xs={3}>
										<Typography variant="body2" fontWeight="bold">
											{data.totalTimeSpent}
										</Typography>
									</Grid>
									<Grid item xs={3}>
										<Typography variant="body2" fontWeight="bold">
											{data.DateTime || 'N/A'}
										</Typography>
									</Grid>
									<Grid
										item
										xs={3}
										style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
									>
										<div
											style={{
												position: 'relative',
												width: '100%',
												maxWidth: '140px',
												height: '6px',
												backgroundColor: '#e0e0e0',
												borderRadius: 10,
												overflow: 'hidden',
												marginLeft: '80px'
											}}
										>
											<div
												style={{
													width: `${data.progress}%`,
													height: '100%',
													backgroundColor: getProgressColor(data.progress),
													transition: 'width 0.3s ease-in-out'
												}}
											></div>
										</div>
										<Typography variant="body2">{data.progress}%</Typography>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					))}
				</Box>
			)}

			<Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="lg" fullWidth>
				<DialogTitle>
					{selectedApplicant
						? `${selectedApplicant.firstName} ${selectedApplicant.lastName}`
						: ''}
				</DialogTitle>
				<DialogContent>
					{selectedApplicant && (
						<Technicalassessment assessId={assessId} applicantData={selectedApplicant} />
					)}
				</DialogContent>
			</Dialog>
		</div>
	)
}

export default FlexOne
