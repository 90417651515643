import React from 'react'
import { FormControl, OutlinedInput, Box } from '@material-ui/core'
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'

export default function SearchBox({ placeholder, inputValue, handleSearch }) {
	return (
		<FormControl sx={{ width: { xs: '100%', md: 400 } }}>
			<OutlinedInput
				value={inputValue}
				onChange={handleSearch}
				size="small"
				id="header-search"
				startAdornment={
					<Box sx={{ display: 'flex', alignItems: 'center', pl: 1 }}>
						<SearchOutlinedIcon />
					</Box>
				}
				aria-describedby="header-search-text"
				inputProps={{ 'aria-label': 'search' }}
				placeholder={placeholder || 'Ctrl + K'}
			/>
		</FormControl>
	)
}
