import styled from 'styled-components'
import { Grid } from '@material-ui/core'

export const Container = styled(Grid)`
	background-color: ${props => props.theme.background.main};
	min-height: 100vh;
	@media only screen and (max-width: 600px) {
		width: 100%;
	}
`

export const Content = styled(Grid)`
	background-color: ${props => props.theme.background.light};
	padding: 15px;
`

export const Testimonial = styled(Grid)`
	padding: 20px 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: linear-gradient(116.65deg, #7d6ef1 -1.46%, #7d6ef1 5.78%, #484cdb 91.16%);

	@media only screen and (max-width: 960px) {
		display: none;
	}
`
