import styled from 'styled-components'

export const Card = styled.div`
	background-color: ${props => props.theme.grey.fifth};
	border-radius: 12px;
	padding: 30px;
`

export const Tag = styled.div`
	border: 1px solid ${props => props.theme.tag.red};
	padding: 3px 7px;
	border-radius: 4px;
	font-size: 12px;
	color: ${props => props.theme.tag.red};
`
