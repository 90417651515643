import React from 'react'
import {
	FirstPage as FirstPageIcon,
	LastPage as LastPageIcon,
	ChevronLeft as ChevronLeftIcon,
	ChevronRight as ChevronRightIcon
} from '@material-ui/icons'
import { Button, FormControl, MenuItem, Select } from '@material-ui/core'

export function DataTablePagination({ table }) {
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				padding: '8px',
				gap: '8px'
			}}
		>
			<div style={{ flex: 1, fontSize: '14px' }}>
				Total: {table.getFilteredRowModel().rows.length} Item(s)
			</div>
			<div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
				<div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
					<p style={{ fontSize: '14px', fontWeight: 'bold' }}>Items per page</p>
					<FormControl variant="outlined" size="small">
						<Select
							value={table.getState().pagination.pageSize}
							onChange={event => table.setPageSize(Number(event.target.value))}
							style={{ height: '32px', width: '70px' }}
						>
							{[10, 20, 30, 40, 50].map(pageSize => (
								<MenuItem key={pageSize} value={pageSize}>
									{pageSize}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
				<div
					style={{
						fontSize: '14px',
						fontWeight: 'bold',
						textAlign: 'center',
						width: '100px'
					}}
				>
					Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
				</div>
				<div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
					<Button
						variant="outlined"
						style={{
							height: '32px',
							width: '32px',
							padding: '0',
							display: 'none'
						}}
						className="lg:flex"
						onClick={() => table.setPageIndex(0)}
						disabled={!table.getCanPreviousPage()}
					>
						<FirstPageIcon fontSize="small" />
					</Button>
					<Button
						variant="outlined"
						style={{ height: '32px', width: '32px', padding: '0' }}
						onClick={() => table.previousPage()}
						disabled={!table.getCanPreviousPage()}
					>
						<ChevronLeftIcon fontSize="small" />
					</Button>
					<Button
						variant="outlined"
						style={{ height: '32px', width: '32px', padding: '0' }}
						onClick={() => table.nextPage()}
						disabled={!table.getCanNextPage()}
					>
						<ChevronRightIcon fontSize="small" />
					</Button>
					<Button
						variant="outlined"
						style={{
							height: '32px',
							width: '32px',
							padding: '0',
							display: 'none'
						}}
						className="lg:flex"
						onClick={() => table.setPageIndex(table.getPageCount() - 1)}
						disabled={!table.getCanNextPage()}
					>
						<LastPageIcon fontSize="small" />
					</Button>
				</div>
			</div>
		</div>
	)
}
