import React, { useState } from 'react'
import { Grid, Typography, Skeleton } from '@material-ui/core'
import useIntl from 'hooks/useIntl'
import useToggle from 'hooks/useToggle'
import ChallengeCard from 'components/ChallengeCard'
import ViewSubmissionModal from 'components/ViewSubmissionModal'
import {
	CoreChapterResultFieldsFragment,
	CoreChapterFieldsFragment,
	Chapter
} from 'hooks/useApollo'

export interface ChallengeTabProps {
	/**
	 * Defines the name of the challenge
	 */
	name: string
	/**
	 * Defines the total count of challenges done
	 */
	count: number
	/**
	 * Defines the total count of challenges need to take
	 */
	max: number
	/**
	 * Defines if the user is masked
	 * @default false
	 */
	masked?: boolean
	/**
	 * Defines the challenge submissions of the talent
	 */
	submission: CoreChapterResultFieldsFragment[]
	/**
	 * Defines the challenge submissions of the talent
	 */
	/**
	 * Function to get the preview link of the chapter
	 */
	getPreviewLink(chapterId: string): string
	chapters: { [key: string]: CoreChapterFieldsFragment } | null
	/**
	 * Defines all the challenges in the assesment
	 */
	allChapters: Pick<Chapter, 'desc' | 'id' | 'name' | 'timer'>[]
}

export const ChallengeTab: React.FC<ChallengeTabProps> = ({
	name,
	count,
	max,
	submission,
	chapters,
	masked,
	allChapters,
	getPreviewLink
}) => {
	const intl = useIntl()
	const [selected, setSelected] = useState(0)
	const [{ open }, { handleClose, handleOpen }] = useToggle()

	const isLastIndex = selected === submission.length - 1
	const isFirstIndex = selected === 0

	const handleNext = () => {
		if (isLastIndex) return
		setSelected(curr => curr + 1)
	}

	const handlePrev = () => {
		if (isFirstIndex) return
		setSelected(curr => curr - 1)
	}

	const chapter = chapters?.[submission?.[selected]?.chapterId]
	const nextText = chapters?.[submission?.[selected + 1]?.chapterId]?.name ?? undefined
	const prevText = chapters?.[submission?.[selected - 1]?.chapterId]?.name ?? undefined

	const incompletedChapters = allChapters?.filter(
		eachChapter => !submission?.some(({ chapterId }) => chapterId === eachChapter?.id)
	)

	return (
		<>
			<Grid sx={{ py: 4 }} container spacing={4}>
				<Grid item xs={12}>
					<Grid item>
						<Typography sx={{ fontWeight: 500, wordBreak: 'break-all' }}>
							{masked ? (
								<Skeleton
									width={40}
									animation={false}
									sx={{ display: 'inline-flex', mr: 0.5 }}
								/>
							) : (
								name
							)}{' '}
							{intl.formatMessage(
								{ id: 'challengeSubmission.title' },
								{
									value: `${count} ${intl.formatMessage({ id: 'outOf' })} ${max}`
								}
							)}
						</Typography>
					</Grid>
				</Grid>
				<Grid item container direction="column" spacing={4} xs={12}>
					{submission.map((res, index) => {
						const chapter = chapters && chapters[res.chapterId]

						return (
							<Grid item key={index}>
								<ChallengeCard
									label={chapter?.name || ''}
									desc={chapter?.desc || ''}
									status="completed"
									duration={res?.timeSpent}
									onClick={() => {
										handleOpen()
										setSelected(index)
									}}
								/>
							</Grid>
						)
					})}
					{incompletedChapters?.map((res, index) => (
						<Grid item key={index}>
							<ChallengeCard
								label={res?.name || ''}
								desc={res?.desc || ''}
								status="incomplete"
								duration={res?.timer as number}
								preview
								linkToPreview={getPreviewLink(res?.id as string)}
							/>
						</Grid>
					))}
				</Grid>
			</Grid>
			<ViewSubmissionModal
				isFirstIndex={isFirstIndex}
				isLastIndex={isLastIndex}
				modalProps={{
					open,
					maxWidth: 'md',
					onClose: handleClose
				}}
				nextText={nextText}
				prevText={prevText}
				title={chapter?.name || ''}
				desc={chapter?.desc || ''}
				data={submission[selected]}
				onNext={handleNext}
				onPrev={handlePrev}
			/>
		</>
	)
}

export default ChallengeTab
