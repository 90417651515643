import React, { useContext } from 'react'

import { ToggleButtonContext } from './ToggleButton'
import { Wrapper } from './ToggleButtonItem.style'

export interface ToggleButtonItemProps {
	value: string
	fullWidth?: boolean
	fullHeight?: boolean
	restricted?: boolean
	onClick?: (params: { value: string; selected: boolean }) => void
}

export const ToggleButtonItem: React.FC<ToggleButtonItemProps> = ({
	children,
	value: itemValue,
	fullWidth,
	fullHeight,
	restricted,
	onClick
}) => {
	const { value: selectedValue, onChange } = useContext(ToggleButtonContext)

	const multipleSelection = Array.isArray(selectedValue)

	const selected = multipleSelection
		? selectedValue.includes(itemValue)
		: selectedValue === itemValue

	const handleClick = () => {
		onClick?.({ value: itemValue, selected })

		if (!multipleSelection) {
			onChange(selected ? '' : itemValue)

			return
		}

		if (selected) {
			onChange((selectedValue as string[]).filter(value => value !== itemValue))

			return
		}

		onChange([...selectedValue, itemValue])
	}

	return (
		<Wrapper
			component="div"
			disableRipple={restricted}
			selected={selected}
			fullWidth={fullWidth}
			fullHeight={fullHeight}
			restricted={restricted}
			onClick={restricted ? undefined : handleClick}
		>
			<span>{children}</span>
		</Wrapper>
	)
}
