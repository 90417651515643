import styled from 'styled-components'

export const Header = styled.div`
	position: relative;
	padding: 30px;
	background: linear-gradient(116.65deg, #7d6ef1 -1.46%, #7d6ef1 5.78%, #484cdb 91.16%);
`

export const Content = styled.div`
	padding: 35px 40px;
	background-color: ${props => props.theme.background.light};
`
