import { Alert } from '@material-ui/core'
import styled from 'styled-components'

export const StyledAlert = styled(Alert)`
	display: flex;
	justify-content: space-between;
	width: 100%;
	border-radius: 4px;
	margin-bottom: 8px;

	.MuiAlert-action {
		margin-left: 0;
	}
	&:before {
		content: '';
	}
`
