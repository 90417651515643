import { createStoreon } from "storeon";

// Define the language type
export type Language = "en" | "ar" | "ur";

// Define the state interface
export interface LanguageState {
  language: Language;
}

// Define the events interface
export interface LanguageEvents {
  "language/change": Language;
}

export const languageModule = (store: any) => {
  // Initialize the store with default state
  store.on("@init", () => ({
    language: "en" as Language,
  }));

  store.on("@changed", (state: LanguageState) => {
    // Set the HTML lang attribute
    document.documentElement.lang = state.language;
    document.documentElement.dir =
      state.language === "ar" || state.language === "ur" ? "rtl" : "ltr";
    document.documentElement.style.fontFamily =
      state.language === "ar" || state.language === "ur"
        ? "Arial, sans-serif"
        : "Tahoma, sans-serif";
  });

  // Handle language change event
  store.on("language/change", (_, language: Language) => ({
    language,
  }));
};

// Create the store with proper type parameters
export const store = createStoreon<LanguageState, LanguageEvents>([languageModule]);
