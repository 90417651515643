import styled, { css } from 'styled-components'
import type { PinInputProps } from './PinInput'

export type PinInputContainerProps = Pick<
	PinInputProps,
	'fullWidth' | 'border' | 'fields' | 'maxWidth' | 'maxWidthInput'
>

export const PinInputContainer = styled.div<PinInputContainerProps>`
	${({ maxWidth }) =>
		maxWidth &&
		css`
			max-width: ${typeof maxWidth === 'number' ? `${maxWidth}px` : maxWidth};
		`}

	${({ fullWidth, fields, border, maxWidthInput }) =>
		fullWidth &&
		css`
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			input {
				width: ${100 / (fields as number)}% !important;
				${border === 'bottom' &&
				maxWidthInput &&
				css`
					max-width: ${typeof maxWidthInput === 'number'
						? `${maxWidthInput}px`
						: maxWidthInput};
				`}
			}
		`}
	input {
		border-right: none !important;
		font-size: 18px;
		color: ${props => props.theme.grey.main};
		text-align: center;
		border-radius: 0;
		background-color: transparent;
		-webkit-appearance: initial;
		outline: none;
		&:disabled {
			background-color: ${props => props.theme.grey.fourth};
			color: ${props => props.theme.background.summary};
			border: none;
			border-right: none !important;
		}
		${({ border }) =>
			border === 'all'
				? css`
						border: 1px solid;
						border-color: ${props => props.theme.background.summary};
						&:focus {
							border-color: ${props => props.theme.primary.main};
							caret-color: ${props => props.theme.primary.main};
							border-right: 1px solid ${props => props.theme.primary.main} !important;
							&:last-child {
								border-right-color: ${props => props.theme.primary.main} !important;
							}
							& + input {
								border-left: none;
							}
						}
						&:first-child {
							border-radius: 6px 0 0 6px;
						}
						&:last-child {
							border-right: 1px solid ${props => props.theme.background.summary} !important;
							border-radius: 0 6px 6px 0;
						}
				  `
				: css`
						border: none;
						border-bottom: 1px solid ${props => props.theme.background.summary};
						margin-right: 10px;
						&:disabled {
							border-radius: 6px;
						}
						&:focus {
							border-bottom: 1px solid ${props => props.theme.primary.main};
						}
				  `}
	}
`
