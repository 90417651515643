import { ColumnDef } from '@tanstack/react-table'
import React from 'react'

interface SubSection {
	subSectionId: number
	subSectionName: string
	count: number
}

export const SubsectionColumns: ColumnDef<SubSection>[] = [
	{
		accessorKey: 'sectionName',
		header: 'Section Name',
		cell: ({ row }) => <div className="w-[120px]">{row.getValue('sectionName')}</div>,
		size: 120
	},
	{
		accessorKey: 'subSectionName',
		header: 'Sub-Section Name',
		cell: ({ row }) => <div className="w-[120px]">{row.getValue('subSectionName')}</div>,
		size: 120
	},
	{
		accessorKey: 'count',
		header: 'No of Questions',
		cell: ({ row }) => <div className="w-[100px]">{row.getValue('count')}</div>,
		size: 100
	}
]
