import styled from 'styled-components'
import { Typography, ButtonBase } from '@material-ui/core'

export const FilterItemContainer = styled(ButtonBase)`
	display: flex;
	align-items: center;
	margin-right: 25px;
	/* margin-bottom: 15px; */
`

export const FilterItemLabel = styled(Typography).attrs({
	variant: 'h4',
	color: 'inherit'
})
