import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import {
	TextField,
	MenuItem,
	FormControl,
	Typography,
	Grid,
	Button,
	IconButton,
	Box,
	RadioGroup,
	Radio,
	FormControlLabel,
	InputAdornment
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
	Add,
	AddCircleOutline,
	FormatAlignLeft,
	FormatAlignRight,
	FormatBold,
	FormatItalic,
	FormatUnderlined,
	StrikethroughS
} from '@material-ui/icons'
import { GET_ME_ANALYTICS_USER } from 'constants/queries/analyticsUser'
import { useQuery } from '@apollo/client'

// Define constants
const API_BASE_URL = `${process.env.REACT_APP_API_URL}/api/v1/custom`
const EDITOR_MIN_HEIGHT = '80px'
const ANSWERS_MIN_HEIGHT = 120

// Define question types
const QUESTION_TYPES = [
	'Multiple Choice (Radiobutton)',
	'Multiple Choice (Dropdown)',
	'Fill in the Blank',
	'True/False',
	'Multiple Correct',
	'Essay (Evaluated by Admin)',
	'Drag and Match',
	'Matching',
	'All Correct',
	'Audio Answer'
]

// Define styles
const useStyles = makeStyles(theme => ({
	inputField: {
		borderRadius: theme.shape.borderRadius,
		'& fieldset': { border: 'none' }
	},
	formControl: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		width: '100%'
	},
	iconButton: {
		backgroundColor:
			theme.palette.mode === 'dark'
				? theme.palette.background.paper
				: theme.palette.grey[200],

		marginLeft: theme.spacing(0.5),
		padding: theme.spacing(0.5, 1)
	},
	editor: {
		width: '100%',
		height: EDITOR_MIN_HEIGHT,
		padding: theme.spacing(1),
		border: `1px solid #eaecee`,
		borderRadius: theme.shape.borderRadius,
		outline: 'none',
		overflowY: 'scroll',
		wordBreak: 'break-word',
		whiteSpace: 'pre-wrap',
		overflowWrap: 'break-word',
		scrollbarWidth: 'none',
		'&::-webkit-scrollbar': {
			display: 'none'
		}
	},
	menuPaper: {
		maxHeight: 200,
		overflowY: 'auto'
	},
	formGrid: {
		'@media (max-width:500px)': {
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			width: '100%'
		}
	},
	formattingToolbar: {
		display: 'flex',
		gap: theme.spacing(1),
		padding: theme.spacing(1),
		border: `1px solid #eaecee`,
		borderRadius: theme.shape.borderRadius,
		marginBottom: theme.spacing(1)
	}
}))

// Define interfaces
interface SubSection {
	subSectionId: number
	subSectionName: string
}

interface Section {
	sectionId: number
	sectionName: string
	subSections: SubSection[]
}

// Text formatting functions
const toUnicodeBold = (text: string) =>
	text.replace(/[A-Za-z]/g, c =>
		String.fromCodePoint(
			c.charCodeAt(0) + (c >= 'A' && c <= 'Z' ? 0x1d5d4 - 65 : 0x1d5ee - 97)
		)
	)

const toUnicodeItalic = (text: string) =>
	text.replace(/[A-Za-z]/g, c =>
		String.fromCodePoint(
			c.charCodeAt(0) + (c >= 'A' && c <= 'Z' ? 0x1d608 - 65 : 0x1d622 - 97)
		)
	)

const toUnicodeUnderline = (text: string) =>
	text
		.split('')
		.map(c => c + '\u0332')
		.join('')

const toUnicodeStrikethrough = (text: string) =>
	text
		.split('')
		.map(char => char + '\u0336')
		.join('')

export default function Selection() {
	const theme = useTheme()
	const classes = useStyles()

	// State variables
	const [questionName, setQuestionName] = useState('')
	const [questionType, setQuestionType] = useState('')
	const [question, setQuestion] = useState('')
	const [answers, setAnswers] = useState('')
	const [correctAnswer, setCorrectAnswer] = useState('')
	const [explanation, setExplanation] = useState('')
	const [marks, setMarks] = useState(1)
	const [selectedSection, setSelectedSection] = useState('')
	const [selectedSubSection, setSelectedSubSection] = useState('')
	const [sectionData, setSectionData] = useState<Section[]>([])
	const [subSectionData, setSubSectionData] = useState<SubSection[]>([])
	const [errors, setErrors] = useState<{ [key: string]: string }>({})
	const [isCustomSection, setIsCustomSection] = useState(false)
	const [customSection, setCustomSection] = useState('')
	const [isCustomSubSection, setIsCustomSubSection] = useState(false)
	const [customSubSection, setCustomSubSection] = useState('')
	const [selectedOptions, setSelectedOptions] = useState<string[]>([])
	const [selectedOption, setSelectedOption] = useState<string>('')
	const [options, setOptions] = useState<string[]>([''])
	const [textAlign, setTextAlign] = useState<'left' | 'right'>('left')

	// Refs
	const editorRef = useRef<HTMLDivElement | null>(null)

	// GraphQL query
	const { data } = useQuery(GET_ME_ANALYTICS_USER)

	// Effects
	useEffect(() => {
		if (data && data.me) {
			localStorage.setItem('userId', data.me.id)
		}
	}, [data])

	useEffect(() => {
		const userId = localStorage.getItem('userId')

		if (userId) {
			axios
				.get<Section[]>(`${API_BASE_URL}/sectionData/${userId}`)
				.then(response => {
					setSectionData(response.data)
				})
				.catch(error => console.error('Error fetching data:', error))
		}
	}, [])

	// Event handlers
	const handleSectionChange = (value: string) => {
		const selected = sectionData.find(section => section.sectionId.toString() === value)

		if (selected) {
			setSelectedSection(selected.sectionId.toString())
			setSubSectionData(selected.subSections || [])
			setSelectedSubSection('')
		}
	}

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault()

		const userId = localStorage.getItem('userId')

		const selectedSectionObj = sectionData.find(
			section => section.sectionId.toString() === selectedSection
		)
		const selectedSubSectionObj = subSectionData.find(
			subSection => subSection.subSectionId.toString() === selectedSubSection
		)

		let formattedAnswers: string[] = []
		let formattedCorrectAnswer: string[] = []

		if (
			questionType === 'Multiple Choice (Radiobutton)' ||
			questionType === 'Multiple Choice (Dropdown)'
		) {
			formattedAnswers = options.filter(option => option.trim() !== '')
			formattedCorrectAnswer = correctAnswer ? [correctAnswer.trim()] : []
		} else if (questionType === 'Fill in the Blank') {
			formattedAnswers = [answers.trim()]
			formattedCorrectAnswer = correctAnswer ? [correctAnswer.trim()] : []
		} else if (questionType === 'True/False') {
			formattedAnswers = ['True', 'False']
			formattedCorrectAnswer = correctAnswer ? [correctAnswer.trim()] : []
		}

		const payload: any = {
			questionName,
			questionType,
			question,
			answers: formattedAnswers,
			correctAnswer: formattedCorrectAnswer,
			explanation,
			marks,
			sectionId: selectedSectionObj?.sectionId || '',
			sectionName: customSection || selectedSectionObj?.sectionName || '',
			subSectionId: selectedSubSectionObj?.subSectionId || '',
			subSectionName: customSubSection || selectedSubSectionObj?.subSectionName || '',
			user_id: userId || ''
		}

		console.log('Submitting Data:', payload)

		try {
			await axios.post(`${API_BASE_URL}/createQuestion`, payload)
			console.log('Question added successfully!')
			resetForm()
		} catch (error) {
			console.error('API Error:', error.response ? error.response.data : error.message)
		}
	}

	const resetForm = () => {
		setQuestionName('')
		setQuestionType('')
		setQuestion('')
		setAnswers('')
		setCorrectAnswer('')
		setExplanation('')
		setMarks(1)
		setSelectedSection('')
		setSelectedSubSection('')
		setCustomSection('')
		setCustomSubSection('')
		setSelectedOptions([])
		setSelectedOption('')
		setOptions([''])

		if (editorRef.current) {
			editorRef.current.innerHTML = ''
		}
	}

	const handleCancel = () => {
		resetForm()
	}

	const handleQuestionTypeChange = e => {
		const selectedType = e.target.value
		setQuestionType(selectedType)
		setSelectedOption('')
		setAnswers('')

		if (selectedType === 'True/False') {
			setOptions(['True', 'False'])
		} else if (selectedType === 'Multiple Choice (Radiobutton)') {
			setOptions(['', '', '', ''])
		} else if (selectedType === 'Multiple Choice (Dropdown)') {
			setOptions(['', '', '', ''])
		} else if (selectedType === 'Fill in the Blank') {
			setAnswers('')
		}
	}

	const handleOptionChange = (index, value) => {
		const newOptions = [...options]
		newOptions[index] = value
		setOptions(newOptions)
	}

	const applyFormat = (
		formatType: 'bold' | 'italic' | 'underline' | 'fx' | 'left' | 'right'
	) => {
		if (editorRef.current) {
			let text = editorRef.current.innerText.trim()

			if (formatType === 'bold') text = toUnicodeBold(text)
			if (formatType === 'italic') text = toUnicodeItalic(text)
			if (formatType === 'underline') text = toUnicodeUnderline(text)
			if (formatType === 'fx') text = toUnicodeStrikethrough(text)

			if (formatType === 'left') {
				setTextAlign('left')
			}

			if (formatType === 'right') {
				setTextAlign('right')
			}

			setQuestion(text)
			editorRef.current.innerText = text
			editorRef.current.focus()
		}
	}

	const addStartLine = () => {
		if (editorRef.current) {
			editorRef.current.innerText = '\n' + editorRef.current.innerText
			setQuestion(editorRef.current.innerText.trim())
			editorRef.current.focus()
		}
	}

	// Menu props for all select elements
	const menuProps = {
		getContentAnchorEl: null,
		anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
		transformOrigin: { vertical: 'top', horizontal: 'left' },
		PaperProps: { className: classes.menuPaper }
	}

	return (
		<Box sx={{ display: 'flex', justifyContent: 'center' }}>
			<Grid
				container
				spacing={2}
				display="flex"
				alignItems="center"
				justifyContent="center"
				className={classes.formGrid}
			>
				<Grid item xs={12} sm={6}>
					<FormControl className={classes.formControl}>
						<Typography gutterBottom>Question Type</Typography>
						<TextField
							select
							fullWidth
							size="small"
							value={questionType}
							onChange={handleQuestionTypeChange}
							InputProps={{
								className: classes.inputField
							}}
						>
							{QUESTION_TYPES.map(type => (
								<MenuItem key={type} value={type}>
									{type}
								</MenuItem>
							))}
						</TextField>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={6}>
					<Typography gutterBottom>Question Name</Typography>
					<TextField
						fullWidth
						margin="normal"
						value={questionName}
						onChange={e => setQuestionName(e.target.value)}
						error={!!errors.questionName}
						helperText={errors.questionName}
						InputProps={{
							className: classes.inputField
						}}
					/>
				</Grid>

				<Grid item xs={12} sm={6}>
					<Typography gutterBottom>Section Name</Typography>
					<Box sx={{ display: 'flex', flexDirection: 'row' }}>
						{isCustomSection ? (
							<TextField
								fullWidth
								placeholder="Enter Section Name"
								value={customSection}
								onChange={e => setCustomSection(e.target.value)}
								InputProps={{
									className: classes.inputField
								}}
							/>
						) : (
							<FormControl fullWidth>
								<TextField
									select
									fullWidth
									value={selectedSection || ''}
									onChange={e => handleSectionChange(e.target.value)}
									InputProps={{
										className: classes.inputField
									}}
								>
									{sectionData?.length > 0 ? (
										sectionData.map(section => (
											<MenuItem
												key={section.sectionId}
												value={section.sectionId.toString()}
											>
												{section.sectionName}
											</MenuItem>
										))
									) : (
										<MenuItem disabled>No Sections Available</MenuItem>
									)}
								</TextField>
							</FormControl>
						)}
						<IconButton
							onClick={() => setIsCustomSection(prev => !prev)}
							className={classes.iconButton}
							style={{ backgroundColor: '#7D6EF1' }}
						>
							<AddCircleOutline style={{ color: 'white' }} />
						</IconButton>
					</Box>
				</Grid>

				<Grid item xs={12} sm={6}>
					<Typography gutterBottom>Subsection Name</Typography>
					<Box sx={{ display: 'flex', flexDirection: 'row' }}>
						{isCustomSubSection ? (
							<TextField
								fullWidth
								placeholder="Enter Sub Section Name"
								value={customSubSection}
								onChange={e => setCustomSubSection(e.target.value)}
								InputProps={{
									className: classes.inputField
								}}
							/>
						) : (
							<FormControl fullWidth>
								<TextField
									select
									fullWidth
									value={selectedSubSection}
									onChange={e => setSelectedSubSection(e.target.value)}
									InputProps={{
										className: classes.inputField
									}}
								>
									{subSectionData.map(sub => (
										<MenuItem key={sub.subSectionId} value={sub.subSectionId.toString()}>
											{sub.subSectionName}
										</MenuItem>
									))}
								</TextField>
							</FormControl>
						)}
						<IconButton
							onClick={() => setIsCustomSubSection(prev => !prev)}
							className={classes.iconButton}
							style={{ backgroundColor: '#7D6EF1' }}
						>
							<AddCircleOutline style={{ color: 'white' }} />
						</IconButton>
					</Box>
				</Grid>

				<Grid item xs={12}>
					<Typography gutterBottom>Question</Typography>

					<Box className={classes.formattingToolbar}>
						<IconButton size="small" onClick={() => applyFormat('bold')}>
							<FormatBold />
						</IconButton>
						<IconButton size="small" onClick={() => applyFormat('italic')}>
							<FormatItalic />
						</IconButton>
						<IconButton size="small" onClick={() => applyFormat('underline')}>
							<FormatUnderlined />
						</IconButton>
						<IconButton size="small" onClick={() => applyFormat('fx')}>
							<StrikethroughS />
						</IconButton>
						<IconButton size="small" onClick={addStartLine}>
							<Add />
						</IconButton>
						<IconButton size="small" onClick={() => applyFormat('left')}>
							<FormatAlignLeft />
						</IconButton>
						<IconButton size="small" onClick={() => applyFormat('right')}>
							<FormatAlignRight />
						</IconButton>
					</Box>

					<Box
						contentEditable
						ref={editorRef}
						suppressContentEditableWarning
						onInput={() => setQuestion(editorRef.current?.innerText.trim() || '')}
						className={classes.editor}
						style={{ textAlign }}
					/>
				</Grid>

				{/* Answers Section */}
				<Grid item xs={12}>
					<Typography gutterBottom>Answers</Typography>
					<Box minHeight={ANSWERS_MIN_HEIGHT} sx={{ width: '100%' }}>
						{questionType === 'Fill in the Blank' ? (
							<TextField
								fullWidth
								multiline
								rows={4}
								value={answers}
								onChange={e => setAnswers(e.target.value)}
								placeholder="Enter correct answers separated by commas"
								error={!!errors.answers}
								helperText={errors.answers}
								InputProps={{
									className: classes.inputField
								}}
							/>
						) : (
							<FormControl component="fieldset" fullWidth>
								<RadioGroup
									value={selectedOption}
									onChange={e => setSelectedOption(e.target.value)}
								>
									<Grid container spacing={1}>
										{(questionType === 'Multiple Choice (Radiobutton)' ||
											questionType === 'Multiple Choice (Dropdown)') &&
											options.map((option, index) => (
												<Grid item xs={6} key={index}>
													<TextField
														fullWidth
														size="small"
														value={option}
														onChange={e => handleOptionChange(index, e.target.value)}
														placeholder={`Option ${index + 1}`}
														className={classes.inputField}
													/>
												</Grid>
											))}
										{questionType === 'True/False' &&
											['True', 'False'].map((label, index) => (
												<Grid item xs={12} key={index}>
													<FormControlLabel
														control={
															<Radio
																value={label}
																checked={selectedOption === label}
																onChange={() => setSelectedOption(label)}
																sx={{
																	color: 'lightgray',
																	'&.Mui-checked': { color: 'primary.main' }
																}}
															/>
														}
														label={label}
													/>
												</Grid>
											))}
									</Grid>
								</RadioGroup>
							</FormControl>
						)}
					</Box>
				</Grid>

				<Grid item xs={12} sm={6}>
					<Typography gutterBottom>Correct Answer</Typography>
					<TextField
						fullWidth
						margin="normal"
						value={correctAnswer}
						onChange={e => setCorrectAnswer(e.target.value)}
						error={!!errors.correctAnswer}
						helperText={errors.correctAnswer}
						InputProps={{
							className: classes.inputField
						}}
					/>
				</Grid>

				<Grid item xs={12} sm={6}>
					<Typography gutterBottom>Marks</Typography>
					<TextField
						fullWidth
						type="number"
						value={marks}
						onChange={e => setMarks(e.target.value ? parseInt(e.target.value, 10) : 1)}
						error={!!errors.marks}
						helperText={errors.marks}
						InputProps={{
							className: classes.inputField
						}}
					/>
				</Grid>

				<Grid item xs={12}>
					<Typography gutterBottom>Explanation</Typography>
					<TextField
						multiline
						rows={4}
						fullWidth
						margin="normal"
						value={explanation}
						onChange={e => setExplanation(e.target.value)}
						error={!!errors.explanation}
						helperText={errors.explanation}
						InputProps={{
							className: classes.inputField
						}}
					/>
				</Grid>

				<Grid item xs={12} sm={6}></Grid>

				<Grid container justifyContent="flex-end" spacing={2} sx={{ mt: 2 }}>
					<Grid item>
						<Button variant="contained" color="primary" onClick={handleSubmit}>
							Add
						</Button>
					</Grid>
					<Grid item>
						<Button variant="outlined" onClick={handleCancel}>
							Cancel
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	)
}
