import React, { useState } from 'react'
import { TextField, TextFieldProps, InputAdornment, IconButton } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import InputCheckIcon from 'components/InputCheckIcon'

export type PasswordInputProps = TextFieldProps & {
	isCorrect?: boolean
}

export const PasswordInput = ({ isCorrect, ...props }: PasswordInputProps) => {
	const [showPassword, setShowPassword] = useState(false)

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword)
	}

	const handleMouseDownPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault()
	}
	return (
		<TextField
			name="password"
			error={!isCorrect}
			type={showPassword ? 'text' : 'password'}
			placeholder="******"
			{...props}
			InputProps={{
				...props?.InputProps,
				endAdornment: (
					<InputAdornment position="end">
						{props?.InputProps?.endAdornment}
						<IconButton
							aria-label="toggle password visibility"
							onClick={handleClickShowPassword}
							color="secondary"
							onMouseDown={handleMouseDownPassword}
							onMouseUp={handleMouseDownPassword}
						>
							{showPassword ? <Visibility /> : <VisibilityOff />}
						</IconButton>
						{isCorrect && <InputCheckIcon />}
					</InputAdornment>
				)
			}}
		/>
	)
}

export default PasswordInput
