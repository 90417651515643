import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useTheme } from 'styled-components'
import { useIntl } from 'react-intl'

export interface BarStepperProps {
	step: number
	length: number
}

export const BarStepper: React.FC<BarStepperProps> = ({ step, length }) => {
	const intl = useIntl()
	const theme = useTheme()
	return (
		<Grid container justifyContent="center" spacing={1}>
			<Grid item>
				<Typography sx={{ color: theme.grey.main, fontSize: 12 }}>
					{intl.formatMessage({ id: 'stepOf' }, { current: step + 1, length })}
				</Typography>
			</Grid>
			<Grid item container justifyContent="center">
				{Array.from({ length }, (_, index) => {
					const highlighted = index <= step
					return (
						<div
							key={index}
							style={{
								height: '2px',
								width: '56px',
								margin: '4px',
								backgroundColor: highlighted ? theme.primary.main : theme.grey.second
							}}
						/>
					)
				})}
			</Grid>
		</Grid>
	)
}

export default BarStepper
