import { Box, Grid } from '@material-ui/core'
import React from 'react'
import PlanCard from './Tabsdata'

import useBilling from 'screens/AccountSettings/containers/Billing/useBilling'
import SubscriptionCard from 'components/SubscriptionCard'

const plans = [
	{
		name: 'For recruiters',
		heading: 'Talent acquisition',
		subName: 'Simple pricing',
		price: 'Rs.10,000',
		title: 'Basic Plan',
		desc: 'Basic recruitment package',
		packageType: '',
		servicests: '',
		additionalParagraphs: [
			'Use industry skills benchmark to measure your candidates skills',
			'Use unlimited skills benchmark to measure your candidates skills',
			'Email support, basic reporting, and talent pipeline management'
		]
	},
	{
		name: 'For recruiters',
		heading: 'Talent acquisition',
		subName: 'Simple pricing',
		price: 'Rs.25,000',
		title: 'Premium Plan',
		desc: 'Advanced recruitment package',
		packageType: '',
		servicests: '',
		additionalParagraphs: [
			'Use industry skills benchmark to measure your candidates skills',
			'Use unlimited skills benchmark to measure your candidates skills',
			'Email support, basic reporting, and talent pipeline management'
		]
	},
	{
		name: 'For recruiters',
		heading: 'Talent acquisition',
		subName: 'Simple pricing',
		price: 'Rs.50,000',
		title: 'Basic Plan',
		desc: 'Basic recruitment package',
		packageType: '',
		servicests: '',
		additionalParagraphs: [
			'Use industry skills benchmark to measure your candidates skills',
			'Use unlimited skills benchmark to measure your candidates skills',
			'Email support, basic reporting, and talent pipeline management'
		]
	},
	{
		name: 'For recruiters',
		heading: 'Talent acquisition',
		subName: 'Simple pricing',
		price: 'Rs.1,00,000',
		title: 'Premium Plan',
		desc: 'Advanced recruitment package',
		packageType: '',
		servicests: '',
		additionalParagraphs: [
			'Use industry skills benchmark to measure your candidates skills',
			'Use unlimited skills benchmark to measure your candidates skills',
			'Email support, basic reporting, and talent pipeline management'
		]
	}
]

const AvailablePlanContent = ({ activeTab }) => {
	const [{ availablePlans, loading }, { handleActivatePlan }] = useBilling()
	const planKeys = Object.keys(availablePlans || {})

	return (
		<Box sx={{ mt: 2 }}>
			{activeTab === 0 ? (
				<Grid container spacing={2}>
					{plans.map((plan, index) => (
						<Grid item xs={12} sm={6} key={index}>
							<PlanCard {...plan} />
						</Grid>
					))}
				</Grid>
			) : (
				<Grid item container spacing={3}>
					{planKeys.map(
						key =>
							availablePlans[key] && (
								<Grid item key={key} xs={12} md={6}>
									<SubscriptionCard
										{...availablePlans[key]}
										loading={loading}
										onActivate={() => handleActivatePlan({ planName: key })}
									/>
								</Grid>
							)
					)}
				</Grid>
			)}
		</Box>
	)
}

export default AvailablePlanContent
