import React from 'react'
import AccountSettingsLayout from 'layouts/accountSettings'
import { PartialRouteObject } from 'react-router'
import Dashboard from 'screens/Dashboard'

import { teamsRoutes } from './teams'
import { onboardingRoutes } from './onboarding'
import Newscreen from 'screens/Newscreen/Newscreen'
import DashboardScreen from 'screens/DashboardScreen/DashboardScreen'
import DashboardScreen_ from 'screens/DashboardScreen_/DashboardScreen'
import AvailablePlanCards from 'screens/AvailablePlanCards/AvailablePlanCards'
import Selection from 'screens/Selection/Selection'
import Monitor from 'screens/Monitor/Monitor'
import Options from 'screens/Selection/Options'
import Sections from 'screens/Sections&subsections/Sections'
import SubSectionsData from 'screens/Sections&subsections/SubSectionsData'

export const dashboardRoutes: PartialRouteObject[] = [
	{
		path: '',
		element: <Dashboard />
	},
	{
		path: 'settings/*',
		element: <AccountSettingsLayout />
	},
	{
		path: '/onboarding/*',
		children: onboardingRoutes
	},
	{
		path: '/teams/*',
		children: teamsRoutes
	},
	{
		path: 'monitor',
		element: <Monitor />
	},
	{
		path: 'newscreen',
		element: <Newscreen />
	},
	// {
	// 	path: 'newdashboard',
	// 	element: <DashboardScreen />
	// },
	{
		path: 'selection',
		element: <Options />
	},
	{
		path: 'newdashboard_1',
		element: <DashboardScreen_ />
	},
	{
		path: 'availableplancard',
		element: <AvailablePlanCards />
	},
	{
		path: 'CustomSectionsData',
		element: <Sections />
	},
	{
		path: 'CustomSubSectionsData',
		element: <SubSectionsData />
	}
]
