import styled from 'styled-components'
import { Grid } from '@material-ui/core'

export const ButtonGrid = styled(Grid)`
	@media only screen and (min-width: 600px) {
		padding-left: 25px;
	}
	@media only screen and (min-width: 1280px) {
		padding-left: 40px;
	}
`
