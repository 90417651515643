import React from 'react'
import { Grid } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { useFormContext, Controller } from 'react-hook-form'

import { ToggleButton, OnboardingToggleButtonItem } from 'components/ToggleButton'
import { useGetChaptersByClustersLazyQuery, Chapter } from 'hooks/useApollo'
import { GuidedChallengesForm } from 'screens/Onboarding/containers/GuidedChallenges'

import WizardFooter from 'components/Wizard/WizardFooter'
import { useOnboardingStore } from 'screens/Onboarding/hooks/useOnboardingStore'
import { SelectionContainer } from '../../../../../../components/SelectionContainer'
import { useSkillSelectionStore } from '../../hooks/useSkillSelectionStore'

export const SkillSelectionInitialStep: React.FC = () => {
	const { control, watch } = useFormContext<GuidedChallengesForm>()
	const { toggleHelpGuide } = useOnboardingStore()
	const navigate = useNavigate()
	const { clusters, goNext, setChapters } = useSkillSelectionStore(state => ({
		clusters: state.clusters,
		goNext: state.goNext,
		setChapters: state.setChapters
	}))
	const [getChaptersByClusterIds, { loading }] = useGetChaptersByClustersLazyQuery({
		onCompleted: data => {
			setChapters(data.chaptersByClusters as Chapter[])
			goNext()
		}
	})

	const selectedClusters = watch('clusters')

	const handleContinue = () => {
		getChaptersByClusterIds({
			variables: {
				clusterIds: selectedClusters
			}
		})
	}

	return (
		<>
			<SelectionContainer>
				<Controller
					control={control}
					name="clusters"
					render={({ onChange, value }) => (
						<ToggleButton value={value} onChange={value => onChange(value)}>
							{clusters.map(({ id, name, desc }) => (
								<Grid item key={id} xs={12} md={6} lg={4}>
									<OnboardingToggleButtonItem
										value={id}
										title={name || ''}
										description={desc || ''}
									/>
								</Grid>
							))}
						</ToggleButton>
					)}
				/>
			</SelectionContainer>
			<WizardFooter
				onOpenHelpGuide={toggleHelpGuide}
				continueDisabled={selectedClusters.length < 3}
				continuePending={loading}
				onContinue={handleContinue}
				onBack={() => navigate('/onboarding/challenges-selection')}
			/>
		</>
	)
}
