import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
	TableContainer,
	Table as MuiTable,
	TableHead,
	TableRow,
	TableBody,
	TableCell
} from '@material-ui/core'

export interface ITableHeader {
	id: string
	label?: string
	minWidth?: number
	align?: 'left' | 'right' | 'center'
	render?: (item: any) => JSX.Element
}

interface Props {
	columns: ITableHeader[]
	data: Array<any>
}

const useStyles = makeStyles({
	container: {
		maxHeight: 600
	},
	head: {
		background: '#eeeded30',
		'& th': {
			color: '#92929d'
		}
	}
})

export const Table: React.FC<Props> = ({ columns, data }) => {
	const classes = useStyles()

	return (
		<TableContainer className={classes.container}>
			<MuiTable aria-label="sticky table">
				<TableHead className={classes.head}>
					<TableRow>
						{columns.map(column => (
							<TableCell
								key={column.id}
								align={column.align || 'left'}
								// style={{ minWidth: column.minWidth }}
							>
								{column.label}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{data.map(item => (
						<TableRow hover tabIndex={-1} key={item.id}>
							{columns.map(column => (
								<TableCell key={column.id} align={column.align}>
									{typeof column.render === 'function'
										? column.render(item)
										: item[column.id]}
								</TableCell>
							))}
						</TableRow>
					))}
				</TableBody>
			</MuiTable>
		</TableContainer>
	)
}

export default Table
