import React, { useEffect, useMemo, useState } from 'react'
import * as yup from 'yup'
import { useTheme } from 'styled-components'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import useIntl from 'hooks/useIntl'
import { Wizard, WizardProps } from 'components/Wizard'
import {
	resetOnboardingStore,
	useOnboardingStore
} from 'screens/Onboarding/hooks/useOnboardingStore'
import HelpGuideDrawer, { HelpGuideDrawerProps } from 'components/HelpGuideDrawer'

import { EntryFormType } from './EntryForm.types'
import { resetEntryFormStore } from './hooks/useEntryFormStore'
import EntryFormChapterSelection from './containers/EntryFormSkillsSelecion'
import EntryFormChallengePreview from './containers/EntryFormChallengePreview'
import EntryFormTeamCreation from './containers/EntryFormTeamCreation'

const formSchema = yup.object().shape({
	team: yup.object().shape({
		name: yup.string().required(),
		maskChallenge: yup.bool()
	}),
	talentGroup: yup.object().shape({
		name: yup.string().required(),
		type: yup.string().required(),
		color: yup.string().required(),
		teammateEmails: yup.array().of(yup.string().email())
	})
})

export const EntryForm = () => {
	const intl = useIntl()
	const theme = useTheme()
	const { currentStep, showHelpGuide, toggleHelpGuide } = useOnboardingStore(state => state)
	const [numOfQuestions, setNumOfQuestions] = useState<number>(0)
	const formMethods = useForm<EntryFormType>({
		mode: 'all',
		shouldUnregister: false,
		resolver: yupResolver(formSchema),
		defaultValues: {
			challengePack: 'Custom',
			chapters: [],
			team: {
				name: '',
				maskChallenge: false
			},
			talentGroup: {
				name: '',
				type: '',
				color: theme.tag.blue,
				teammateEmails: []
			}
		}
	})

	useEffect(
		() => () => {
			resetOnboardingStore()
			resetEntryFormStore()
		},
		[]
	)

	const steps: WizardProps['stepperLabels'] = useMemo(
		() => [
			{
				label: intl.formatMessage({ id: 'onboarding.entry.level.step.0' })
			},
			{
				label: intl.formatMessage({ id: 'onboarding.entry.level.step.1' })
			},
			{
				label: intl.formatMessage({ id: 'onboarding.entry.level.step.2' })
			}
		],
		[intl]
	)

	const guides: HelpGuideDrawerProps['guides'] = useMemo(
		() =>
			Array.from({ length: 3 }, (_, index) => ({
				title: intl.formatMessage({
					id: `onboarding.free.form.help.guide.title.step.${index}`
				}),
				desc: intl.formatMessage({
					id: `onboarding.free.form.help.guide.desc.step.${index}`
				})
			})),
		[intl]
	)

	const renderStep = () => {
		switch (currentStep) {
			case 0:
				return <EntryFormChapterSelection setNumOfQuestions={setNumOfQuestions} />
			case 1:
				return <EntryFormChallengePreview maxQuestions={numOfQuestions} />
			case 2:
				return <EntryFormTeamCreation />
			default:
				return null
		}
	}

	return (
		<>
			<Wizard
				levelContentWithStepper
				currentStep={currentStep}
				previousPath="/"
				previousPageName={intl.formatMessage({ id: 'dashboard' }).toLowerCase()}
				stepperLabels={steps}
				showMobileStepIndicator={false}
			>
				<FormProvider {...formMethods}>{renderStep()}</FormProvider>
			</Wizard>
			<HelpGuideDrawer
				open={showHelpGuide}
				stepCount={currentStep}
				onClose={toggleHelpGuide}
				guides={guides}
			/>
		</>
	)
}

export default EntryForm
