import React from 'react'
import useIntl from 'hooks/useIntl'
import Modal, { IModalProps } from 'components/Modal'
import { Typography } from '@material-ui/core'

export interface ReminderConfirmationModalProps
	extends Pick<IModalProps, 'open' | 'onClose'> {
	/**
	 * Defines the mask of user
	 * @default false
	 */
	masked?: boolean
	/**
	 * Defines the firstname of the talent
	 */
	firstName?: string
	/**
	 * Defines the loading state of the component
	 * @default false
	 */
	loading?: boolean
	/**
	 * Callback fired when the ok button is clicked
	 */
	onSend: () => void
}

export const ReminderConfirmationModal = ({
	onClose,
	open,
	onSend,
	masked = false,
	firstName,
	loading = false
}: ReminderConfirmationModalProps) => {
	const intl = useIntl()

	const handleInvite = () => onSend()

	return (
		<Modal
			open={open}
			buttonStyle="primary"
			okText={intl.formatMessage({ id: 'sendEmailReminder.btnLabel' })}
			onClose={onClose}
			onOk={handleInvite}
			closeOnOk={false}
			okLoading={loading}
			size="big"
			title={intl.formatMessage(
				{ id: 'sendEmailReminderModal.title' },
				{ masked: masked ? 1 : firstName }
			)}
			footer
			customOnOk
			maxWidth="xs"
		>
			<Typography variant="paragraph1">
				{intl.formatMessage(
					{ id: 'sendEmailReminderModal.desc' },
					{ masked: masked ? 1 : firstName }
				)}
			</Typography>
		</Modal>
	)
}

export default ReminderConfirmationModal
