import React from 'react'
import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const LightBulbIcon = (props: SvgIconProps) => (
	<SvgIcon {...props} width="10" height="14" viewBox="0 0 10 14" fill="none">
		<path
			d="M8.18196 1.15893C7.60709 0.677607 6.93392 0.333001 6.21156 0.150258C5.48921 -0.0324858 4.73589 -0.0487566 4.00658 0.102632C3.03548 0.302326 2.14453 0.791184 1.44677 1.50718C0.749018 2.22318 0.275909 3.13405 0.0874715 4.12421C-0.0501368 4.86663 -0.0251312 5.63081 0.160705 6.36226C0.34654 7.09371 0.688628 7.7744 1.16257 8.35579C1.60241 8.86107 1.85453 9.50723 1.87513 10.182V12.091C1.87513 12.5973 2.0727 13.0829 2.42436 13.4409C2.77602 13.7989 3.25298 14 3.7503 14H6.25053C6.74786 14 7.22481 13.7989 7.57648 13.4409C7.92814 13.0829 8.1257 12.5973 8.1257 12.091V10.3029C8.14668 9.55791 8.41555 8.84221 8.88827 8.27306C9.71655 7.22998 10.107 5.89627 9.97473 4.56177C9.84247 3.22727 9.19821 1.99983 8.18196 1.14621V1.15893ZM6.87559 12.091C6.87559 12.2598 6.80973 12.4216 6.69251 12.541C6.57529 12.6603 6.41631 12.7273 6.25053 12.7273H3.7503C3.58453 12.7273 3.42554 12.6603 3.30832 12.541C3.1911 12.4216 3.12525 12.2598 3.12525 12.091V11.4547H6.87559V12.091ZM7.91943 7.48402C7.29094 8.2429 6.92426 9.19067 6.87559 10.182H5.62547V8.27306C5.62547 8.1043 5.55962 7.94245 5.4424 7.82311C5.32518 7.70378 5.16619 7.63674 5.00042 7.63674C4.83464 7.63674 4.67566 7.70378 4.55844 7.82311C4.44122 7.94245 4.37536 8.1043 4.37536 8.27306V10.182H3.12525C3.10876 9.20725 2.75456 8.26973 2.12516 7.53492C1.7099 7.02841 1.43065 6.42098 1.31465 5.77188C1.19865 5.12278 1.24986 4.45416 1.46328 3.83121C1.67669 3.20825 2.04504 2.65223 2.5324 2.21735C3.01975 1.78248 3.60949 1.48359 4.2441 1.34983C4.78949 1.23551 5.35305 1.24627 5.89386 1.38131C6.43466 1.51636 6.93914 1.7723 7.37066 2.13057C7.80219 2.48884 8.14993 2.94044 8.38865 3.45258C8.62737 3.96473 8.75106 4.52456 8.75076 5.09143C8.75539 5.96311 8.46141 6.80917 7.91943 7.48402Z"
			fill="white"
		/>
	</SvgIcon>
)

export default LightBulbIcon
