import React, { useEffect } from 'react'
import { Grid, useMediaQuery, Theme } from '@material-ui/core'
import Tours, { TEAMS_TOUR_DESKTOP, TEAMS_TOUR_MOBILE } from 'components/Tours'
import AlertStack from 'components/AlertStack'
import { useUpdateUserMutation } from 'hooks/useApollo'

import { TeamsDashboard } from './Teams.style'
import TalentGroups from './containers/Talents/TalentGroups'
import TeamDescription from './containers/TeamDescription'
import TalentProvider from './containers/Talents/context'
import { useTeamContext, TeamContextProps } from './Teams.config'
import TalentSection from './containers/Talents/TalentSection'
import { useGlobalWebSocket } from 'context/WebSocketContext'

const teamSelector = (store: TeamContextProps) => ({
	getAssessment: store.getAssessment,
	loading: store.assessmentLoading,
	assessment: store.assessment,
	teamId: store.teamId,
	demo: store.demo,
	refetchAssessment: store.refetchAssessment,
	refetchApplicants: store.refetchApplicants
})

export const Teams = () => {
	const [updateUser] = useUpdateUserMutation()
	const {
		getAssessment,
		loading,
		assessment,
		teamId,
		demo,
		refetchApplicants,
		refetchAssessment
	} = useTeamContext(teamSelector)
	const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))

	const handleTourDone = () => {
		updateUser({ variables: { userInput: { isTeamTourDone: true } } })
	}

	useEffect(() => {
		getAssessment({
			variables: {
				id: teamId
			}
		})
		// eslint-disable-next-line
	}, [teamId, demo])

	const { registerRefetch } = useGlobalWebSocket()

	const refetchAll = () => {
		refetchAssessment()
		refetchApplicants()
	}

	useEffect(() => {
		const unregister = registerRefetch(refetchAll)
		return () => unregister()
	}, [registerRefetch, refetchAll])

	return (
		<>
			<Tours
				steps={mdUp ? TEAMS_TOUR_DESKTOP : TEAMS_TOUR_MOBILE}
				onFinish={handleTourDone}
			/>
			<AlertStack />
			<TeamsDashboard>
				{/* Talent Group Section */}
				<Grid container spacing={3}>
					<TalentProvider>
						<Grid container item justifyContent="space-between" alignItems="center">
							<TeamDescription
								loading={loading}
								pipelines={assessment?.pipelines || []}
								masking={assessment?.masking || false}
								name={assessment?.name || ''}
								chapters={assessment?.chapters || []}
							/>
						</Grid>
						<Grid container item>
							<TalentGroups groups={assessment?.pipelines || []} loading={loading} />
						</Grid>
						{/* Talent Section */}
						<Grid item xs={12}>
							<TalentSection />
						</Grid>
					</TalentProvider>
				</Grid>
			</TeamsDashboard>
		</>
	)
}

export default Teams
