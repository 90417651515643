import * as React from 'react'

type TableProps = React.ComponentPropsWithoutRef<'table'>
const Table = React.forwardRef<HTMLTableElement, TableProps>(
	({ className, ...props }, ref) => (
		<div className="relative w-full overflow-auto">
			<table
				ref={ref}
				className={`w-full caption-bottom text-sm ${className || ''}`}
				{...props}
			/>
		</div>
	)
)
Table.displayName = 'Table'

type TableSectionProps = React.ComponentPropsWithoutRef<'thead'>
const TableHeader = React.forwardRef<HTMLTableSectionElement, TableSectionProps>(
	({ className, ...props }, ref) => (
		<thead ref={ref} className={`border-b ${className || ''}`} {...props} />
	)
)
TableHeader.displayName = 'TableHeader'

const TableBody = React.forwardRef<HTMLTableSectionElement, TableSectionProps>(
	({ className, ...props }, ref) => (
		<tbody
			ref={ref}
			className={`[&_tr:last-child]:border-0 ${className || ''}`}
			{...props}
		/>
	)
)
TableBody.displayName = 'TableBody'

const TableFooter = React.forwardRef<HTMLTableSectionElement, TableSectionProps>(
	({ className, ...props }, ref) => (
		<tfoot
			ref={ref}
			className={`border-t bg-muted/50 font-medium ${className || ''}`}
			{...props}
		/>
	)
)
TableFooter.displayName = 'TableFooter'

type TableRowProps = React.ComponentPropsWithoutRef<'tr'>
const TableRow = React.forwardRef<HTMLTableRowElement, TableRowProps>(
	({ className, ...props }, ref) => (
		<tr
			ref={ref}
			className={`bg-white border-b transition-colors hover:bg-muted/50 ${className || ''}`}
			{...props}
		/>
	)
)
TableRow.displayName = 'TableRow'

type TableCellProps = React.ComponentPropsWithoutRef<'th'>
const TableHead = React.forwardRef<HTMLTableCellElement, TableCellProps>(
	({ className, ...props }, ref) => (
		<th
			ref={ref}
			className={`h-12 px-4 text-left align-middle font-medium ${className || ''}`}
			{...props}
		/>
	)
)
TableHead.displayName = 'TableHead'

const TableCell = React.forwardRef<HTMLTableCellElement, TableCellProps>(
	({ className, ...props }, ref) => (
		<td ref={ref} className={`p-4 align-middle ${className || ''}`} {...props} />
	)
)
TableCell.displayName = 'TableCell'

type TableCaptionProps = React.ComponentPropsWithoutRef<'caption'>
const TableCaption = React.forwardRef<HTMLTableCaptionElement, TableCaptionProps>(
	({ className, ...props }, ref) => (
		<caption
			ref={ref}
			className={`mt-4 text-sm text-muted-foreground ${className || ''}`}
			{...props}
		/>
	)
)
TableCaption.displayName = 'TableCaption'

export {
	Table,
	TableHeader,
	TableBody,
	TableFooter,
	TableHead,
	TableRow,
	TableCell,
	TableCaption
}
