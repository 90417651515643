import create from 'zustand'

import { Chapter } from 'hooks/useApollo'

type State = {
	chapters: Chapter[]
	selectedChapters: Chapter[]
	chapterMappingById: Record<string, Chapter>
}

type Methods = {
	setChapters: (chapters: Chapter[]) => void
	setSelectedChapters: (chapters: Chapter[]) => void
}

type Store = State & Methods

const initialState: State = {
	chapters: [],
	selectedChapters: [],
	chapterMappingById: {}
}

export const useFreeFormStore = create<Store>(set => ({
	...initialState,
	setChapters: (chapters: Chapter[]) =>
		set(state => ({
			...state,
			chapters
		})),
	setSelectedChapters: (selectedChapters: Chapter[]) =>
		set(state => ({
			...state,
			selectedChapters,
			chapterMappingById: selectedChapters.reduce<Record<string, Chapter>>(
				(currentMap, chapter) => {
					currentMap[chapter.id] = chapter
					return currentMap
				},
				{}
			)
		}))
}))

export const resetFreeFormStore = () => {
	useFreeFormStore.setState(initialState)
}
