import { gql } from '@apollo/client'

export const CORE_ANALYTICS_USER_FIELDS = gql`
	fragment CoreAnalyticsUserFields on AnalyticsUser {
		id
		name
		role
		email
		firstName
		lastName
		photoUrl
		isEmailVerified
		isTermsAccepted
		isTeamTourDone
		isFreeTrialAcknowledged
		isFreeTrialDaysAlertAcknowledged
		isFreeTrialAlertAcknowledged
		isFirstAccess
		employeeCount
		hasPassword
		isTourDone
		org {
			name
			companySize
			industry
			photoUrl
		}
		trialDetails {
			endOfTrial
			daysRemaining
			hasExpired
		}
		isSubscribed
		subscriptions {
			id
			plans
			interval
			isCanceled
			nextPaymentCost
			nextPaymentAt
			isPaymentFailed
			endsAt
			isCancellationAlertAcknowledged
			isCancellationMessageAcknowledged
		}
	}
`

export const GET_ME_ANALYTICS_USER = gql`
	${CORE_ANALYTICS_USER_FIELDS}
	query me {
		me {
			...CoreAnalyticsUserFields
		}
	}
`
export const GET_PROFILE_DATA_ANALYTICS_USER = gql`
	${CORE_ANALYTICS_USER_FIELDS}
	query GetProfile {
		me {
			...CoreAnalyticsUserFields
		}
	}
`

export const SIGN_IN_ANALYTICS_USER = gql`
	${CORE_ANALYTICS_USER_FIELDS}
	mutation SignIn($userInput: SignInUserInput!) {
		signIn(userInput: $userInput) {
			token
			user {
				...CoreAnalyticsUserFields
			}
		}
	}
`

export const SIGN_UP_ANALYTICS_USER = gql`
	${CORE_ANALYTICS_USER_FIELDS}
	mutation SignUp($userInput: SignUpUserInput!) {
		signUp(userInput: $userInput) {
			token
			user {
				...CoreAnalyticsUserFields
			}
		}
	}
`

export const UPDATE_ANALYTICS_USER = gql`
	${CORE_ANALYTICS_USER_FIELDS}
	mutation UpdateUser($userInput: UpdateUserInput!) {
		updateUser(userInput: $userInput) {
			...CoreAnalyticsUserFields
		}
	}
`

export const UPDATE_SUBSCRIPTION_CANCELLATION_STATUS = gql`
	mutation UpdateSubscriptionCancellationStatus(
		$id: ID!
		$input: UpdateSubscriptionCancellationStatusInput!
	) {
		updateSubscriptionCancellationStatus(subscriptionId: $id, input: $input) {
			isCancellationAlertAcknowledged
			isCancellationMessageAcknowledged
		}
	}
`

export const UPDATE_USER_TOUR_STATUS = gql`
	mutation UpdateUserTourStatus($status: Boolean!) {
		updateUserTourStatus(status: $status)
	}
`

export const CHANGE_PASSWORD = gql`
	mutation ChangePassword($userInput: ChangePasswordInput!) {
		changePassword(userInput: $userInput)
	}
`

export const ADD_PASSWORD = gql`
	mutation AddPassword($password: String!) {
		addPassword(password: $password)
	}
`

export const SEND_PASSWORD_RESET_ANALYTICS_USER = gql`
	mutation SendPasswordResetUser($email: String!) {
		sendPasswordResetUser(email: $email)
	}
`

export const RESET_PASSWORD_ANALYTICS_USER = gql`
	mutation ResetPasswordUser($userInput: ResetPasswordInput!) {
		resetPasswordUser(userInput: $userInput)
	}
`

export const CHECK_PIN_ANALYTICS_USER = gql`
	query CheckPinUser($email: String!, $pin: String!) {
		checkPinUser(email: $email, pin: $pin)
	}
`

export const VERIFY_EMAIL_ANALYTICS_USER = gql`
	mutation VerifyEmail($userId: String!, $token: String!) {
		verifyEmail(userId: $userId, token: $token)
	}
`

export const RESEND_EMAIL_VERIFICATION_ANALYTICS_USER = gql`
	mutation ResendEmailVerification {
		resendEmailVerification
	}
`
