import React from 'react'
import { Card, CardContent, Typography, Grid, Divider, Box } from '@material-ui/core'

const PreviousAttempts = () => {
	return (
		<div>
			<Card>
				<CardContent>
					<Typography variant="h6" gutterBottom>
						Previous Attempts
					</Typography>
					<Card style={{ marginTop: '16px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
						<CardContent>
							<Grid container alignItems="center" justifyContent="space-between">
								<Grid item>
									<Typography variant="body1" gutterBottom>
										First Attempt
									</Typography>
									<Typography variant="body2" color="textSecondary" fontSize={12}>
										February 12, 2025
									</Typography>
								</Grid>
								<Grid item>
									<Typography
										variant="body2"
										color="textSecondary"
										align="right"
										style={{ color: 'rgb(255, 162, 99)' }}
									>
										70%
									</Typography>
								</Grid>
							</Grid>
						</CardContent>
					</Card>

					<Card style={{ marginTop: '16px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
						<CardContent>
							<Grid container alignItems="center" justifyContent="space-between">
								<Grid item>
									<Typography variant="body1" gutterBottom>
										Second Attempt
									</Typography>
									<Typography variant="body2" color="textSecondary" fontSize={12}>
										February 12, 2025
									</Typography>
								</Grid>
								<Grid item>
									<Typography
										variant="body2"
										color="textSecondary"
										align="right"
										style={{ color: 'rgb(0, 196, 140)' }}
									>
										85%
									</Typography>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</CardContent>
			</Card>
		</div>
	)
}

export default PreviousAttempts
