import produce, { Draft } from 'immer'
import { keyBy } from 'lodash'
import {
	ApplicantsScoreFieldsFragment,
	CoreChapterFieldsFragment,
	Maybe
} from 'hooks/useApollo'

import { TalentActionTypes, TalentActions } from './talentContextActions'

type PipelineType = 'Internal' | 'External'

export type GroupChapters = CoreChapterFieldsFragment[]
export type PinnedUsers = {
	id: string
	firstName: string
	lastName: string
	pipelineId: string
	pipelineType: PipelineType
	pipelinesId: string[]
	masked: boolean
	score: Maybe<ApplicantsScoreFieldsFragment>
}
export interface TalentState {
	selectedTalent: PinnedUsers
	chapters: {
		[key: string]: CoreChapterFieldsFragment
	} | null
	pinnedUsers: {
		[key: string]: PinnedUsers
	} | null
}

const selectedTalentDefault: PinnedUsers = {
	id: '',
	pipelineId: '',
	firstName: '',
	pipelineType: 'Internal',
	pipelinesId: [],
	lastName: '',
	masked: false,
	score: null
}

export const defaultState: TalentState = {
	selectedTalent: selectedTalentDefault,
	chapters: null,
	pinnedUsers: null
}

export const talentReducer = (
	state: TalentState = defaultState,
	action: TalentActions
): TalentState =>
	produce(state, (draft: Draft<TalentState>) => {
		switch (action.type) {
			case TalentActionTypes.SET_SELECTED_TALENT:
				draft.selectedTalent = action.payload.talent
				break

			case TalentActionTypes.SET_GROUP_CHAPTERS:
				draft.chapters = keyBy(action.payload.chapters, 'id')
				break

			case TalentActionTypes.UNSELECT_TALENT:
				draft.selectedTalent = selectedTalentDefault
				break

			case TalentActionTypes.SET_PINNED_USERS:
				{
					const pinnedUsers = { ...draft.pinnedUsers }
					if (pinnedUsers[action.payload.user.id]) {
						delete pinnedUsers[action.payload.user.id]
						draft.pinnedUsers = pinnedUsers
						return
					}

					draft.pinnedUsers = {
						...draft.pinnedUsers,
						[action.payload.user.id]: action.payload.user
					}
				}
				break
			case TalentActionTypes.REMOVE_PINNED_USER:
				if (draft.pinnedUsers) {
					delete draft.pinnedUsers[action.payload.userId]
				}
				break
			case TalentActionTypes.REMOVE_USERS_FROM_PIPELINE:
				if (draft.selectedTalent.pipelineId === action.payload.pipelineId) {
					draft.selectedTalent = selectedTalentDefault
				}
				if (draft.pinnedUsers) {
					Object.values(draft.pinnedUsers).forEach(pinnedUser => {
						if (pinnedUser.pipelineId === action.payload.pipelineId && draft.pinnedUsers) {
							delete draft.pinnedUsers[pinnedUser.id]
						}
					})
				}
				break
		}
	})
