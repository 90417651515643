/* eslint-disable indent */
import styled, { css, DefaultTheme } from 'styled-components'
import { alpha, hexToRgb, hslToRgb } from '@material-ui/core/styles'
import { Theme } from 'packages/nuggetai.ui-kit/themes/base'

import { hexColorRegex, hslColorRegex, rgbColorRegex } from './regex'

export type Colors = keyof Theme['tag'] | 'primary' | 'secondary' | 'disabled'

const notLabelColors: Colors[] = ['primary', 'secondary', 'disabled']

export const pxToRem = (px: number): string => `${px / 16}rem`

export const getColor = (color: Colors, theme: DefaultTheme) => {
	const index = notLabelColors.indexOf(color)
	if (index !== -1) {
		switch (color) {
			case 'primary':
				return theme.primary.main
			case 'secondary':
				return theme.text.description
			case 'disabled':
				return theme.progressBarBg
		}
	}
	return theme.tag[color]
}

export const getProgressColor = (value: number, theme: DefaultTheme) =>
	value < 25
		? theme.tag.red
		: value < 50
		? theme.secondary.orange
		: value < 75
		? theme.tag.yellow
		: theme.tag.green

export const createModernColor = (color: Colors) => css`
	${({ theme }) => {
		const pickedColor = getColor(color, theme)
		return createCustomColor(pickedColor)
	}}
`

/**
 *
 * @param color color in hex, rgb or hsl
 * @param opacity from 0 to 1, default: 0.2
 */
export const createCustomColor = (color: string, opacity = 0.2) => {
	if (!color) return undefined
	const rgbColor = getRgbColor(color)

	return css`
		color: ${rgbColor} !important;
		background-color: ${alpha(rgbColor, opacity)} !important;
	`
}

export interface ThemeColorProps {
	color: Colors
	/**
	 * @default false
	 */
	onlyColor?: boolean
}

export const ThemeColor = styled.div.attrs<ThemeColorProps>(props => ({
	onlyColor: false,
	...props
}))<ThemeColorProps>`
	${({ color, onlyColor, theme }) =>
		onlyColor
			? css`
					color: ${getColor(color, theme)};
			  `
			: createModernColor(color)}
`

// color utils

export type ColorsType = 'hex' | 'rgb' | 'hsl'

export interface RGBA {
	r: number
	g: number
	b: number
	a: number
}

const colorsRegex: [ColorsType, RegExp][] = [
	['rgb', rgbColorRegex],
	['hex', hexColorRegex],
	['hsl', hslColorRegex]
]

export function getColorType(color: string): ColorsType | undefined {
	for (let i = 0; i < colorsRegex.length; i++) {
		const [colorType, colorRegex] = colorsRegex[i]
		if (colorRegex.test(color)) {
			return colorType
		}
	}
}

export function getRgbColor(color: string): string {
	const colorType = getColorType(color) as ColorsType
	switch (colorType) {
		case 'rgb':
			return color
		case 'hex':
			return hexToRgb(color)
		case 'hsl':
			return hslToRgb(color)
	}
}

export const hexToRgbA = (hex: string): RGBA => {
	let c
	if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
		c = hex.substring(1).split('')
		if (c.length === 3) {
			c = [c[0], c[0], c[1], c[1], c[2], c[2]]
		}
		c = `0x${c.join('')}`
		// eslint-disable-next-line no-bitwise
		return { r: (c >> 16) & 255, g: (c >> 8) & 255, b: c & 255, a: 1 }
	}
	throw new Error('Bad Hex')
}

export default ThemeColor
