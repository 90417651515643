import React from 'react'
import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const LinkedinIcon = (props: SvgIconProps) => (
	<SvgIcon {...props} width="12" height="12" viewBox="0 0 12 12" fill="none">
		<path d="M6.66665 12H3.99999V4H6.66665V5.33333C7.23507 4.61017 8.09707 4.17905 9.01665 4.158C10.6704 4.16718 12.0048 5.51292 12 7.16667V12H9.33331V7.5C9.22667 6.75508 8.58782 6.20238 7.83532 6.204C7.50617 6.2144 7.19548 6.35852 6.97495 6.60308C6.75441 6.84764 6.64308 7.17153 6.66665 7.5V12ZM2.66666 12H0V4H2.66666V12ZM1.33333 2.66667C0.596952 2.66667 0 2.06971 0 1.33333C0 0.596954 0.596952 0 1.33333 0C2.06971 0 2.66666 0.596954 2.66666 1.33333C2.66666 1.68696 2.52619 2.02609 2.27614 2.27614C2.02609 2.52619 1.68695 2.66667 1.33333 2.66667Z" />
	</SvgIcon>
)

export default LinkedinIcon
