import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
	{ [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
	{ [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string
	String: string
	Boolean: boolean
	Int: number
	Float: number
	Date: any
	/** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
	JSON: any
	/** The `ID` scalar type represents a unique MongoDB identifier in collection. MongoDB by default use 12-byte ObjectId value (https://docs.mongodb.com/manual/reference/bson-types/#objectid). But MongoDB also may accepts string or integer as correct values for _id field. */
	MongoID: string
	/** The string representation of JavaScript regexp. You may provide it with flags "/^abc.*\/i" or without flags like "^abc.*". More info about RegExp characters and flags: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions */
	RegExpAsString: any
	/** The `Upload` scalar type represents a file upload. */
	Upload: any
}

export type AnalyticsUser = {
	__typename?: 'AnalyticsUser'
	id: Scalars['ID']
	email: Scalars['String']
	googleId?: Maybe<Scalars['String']>
	facebookId?: Maybe<Scalars['String']>
	role?: Maybe<Scalars['String']>
	isTermsAccepted: Scalars['Boolean']
	isTourDone: Scalars['Boolean']
	isTeamTourDone: Scalars['Boolean']
	isFreeTrialAcknowledged: Scalars['Boolean']
	isFreeTrialDaysAlertAcknowledged: Scalars['Boolean']
	isFreeTrialAlertAcknowledged: Scalars['Boolean']
	loggedInAt?: Maybe<Scalars['Date']>
	lastLogin?: Maybe<Scalars['Date']>
	trialEndsAt?: Maybe<Scalars['Date']>
	subscriptions: Array<AnalyticsUserSubscription>
	primaryColour?: Maybe<Scalars['String']>
	updatedAt?: Maybe<Scalars['Date']>
	createdAt?: Maybe<Scalars['Date']>
	firstName: Scalars['String']
	lastName?: Maybe<Scalars['String']>
	photoUrl?: Maybe<Scalars['String']>
	isEmailVerified: Scalars['Boolean']
	isFirstAccess: Scalars['Boolean']
	isSubscribed: Scalars['Boolean']
	hasPassword: Scalars['Boolean']
	employeeCount: Scalars['Int']
	name: Scalars['String']
	org?: Maybe<Org>
	trialDetails: AnalyticsUserTrialDetails
}

export type AnalyticsUserQuery = {
	__typename?: 'AnalyticsUserQuery'
	token: Scalars['String']
	user: AnalyticsUser
}

export type AnalyticsUserSubscription = {
	__typename?: 'AnalyticsUserSubscription'
	id: Scalars['ID']
	plans: Array<EnumPlanType>
	interval: EnumSubscriptionInterval
	nextPaymentAt?: Maybe<Scalars['Date']>
	nextPaymentAttemptAt?: Maybe<Scalars['Date']>
	nextPaymentCost?: Maybe<Scalars['Float']>
	endsAt?: Maybe<Scalars['Date']>
	isCanceled: Scalars['Boolean']
	isPaymentFailed: Scalars['Boolean']
	isCancellationAlertAcknowledged?: Maybe<Scalars['Boolean']>
	isCancellationMessageAcknowledged?: Maybe<Scalars['Boolean']>
}

export type AnalyticsUserTrialDetails = {
	__typename?: 'AnalyticsUserTrialDetails'
	isOnTrial: Scalars['Boolean']
	endOfTrial?: Maybe<Scalars['Date']>
	daysRemaining?: Maybe<Scalars['Int']>
	hasExpired?: Maybe<Scalars['Boolean']>
}

export type Answer = {
	__typename?: 'Answer'
	id: Scalars['ID']
	type: EnumAnswerType
	title: Scalars['String']
	scene: Scalars['String']
	question: Scalars['String']
	instruction: Scalars['String']
	answer?: Maybe<Scalars['String']>
	options?: Maybe<Array<Scalars['String']>>
}

export type Applicant = {
	__typename?: 'Applicant'
	id: Scalars['ID']
	updatedAt?: Maybe<Scalars['Date']>
	createdAt?: Maybe<Scalars['Date']>
	uuid: Scalars['String']
	email?: Maybe<Scalars['String']>
	progress?: Maybe<Scalars['JSON']>
	type?: Maybe<EnumApplicantType>
	note?: Maybe<Scalars['String']>
	results?: Maybe<Array<Maybe<Scalars['MongoID']>>>
	pipelineType: EnumApplicantPipelineType
	isBookmarked: Scalars['Boolean']
	isCustom?: Maybe<Scalars['Boolean']>
	isShortlisted: Scalars['Boolean']
	masking?: Maybe<Scalars['Boolean']>
	customFields?: Maybe<Scalars['JSON']>
	pipelines: Array<Pipeline>
	assessId: Scalars['MongoID']
	user?: Maybe<User>
	submission: Array<ChapterResult>
	categoryId?: Maybe<Scalars['ID']>
	pipelinesId: Array<Scalars['ID']>
	score?: Maybe<ScoreApplicant>
}

export type ApplicantsPagination = {
	__typename?: 'ApplicantsPagination'
	items: Array<Applicant>
	totalTalents: ApplicantsPaginationTotalTalents
	pageInfo: PaginationInfo
	count: Scalars['Int']
}

export type ApplicantsPaginationTotalTalents = {
	__typename?: 'ApplicantsPaginationTotalTalents'
	count: Scalars['Int']
	employees: Scalars['Int']
	candidates: Scalars['Int']
}

export type Assessment = {
	__typename?: 'Assessment'
	id: Scalars['ID']
	updatedAt?: Maybe<Scalars['Date']>
	createdAt?: Maybe<Scalars['Date']>
	name: Scalars['String']
	category?: Maybe<Category>
	challenge?: Maybe<Challenge>
	chapters: Array<Maybe<Chapter>>
	pipelines: Array<Maybe<Pipeline>>
	objective?: Maybe<EnumAssessmentObjective>
	status?: Maybe<EnumAssessmentStatus>
	masking?: Maybe<Scalars['Boolean']>
	attempts?: Scalars['Int']
	fromSection?: Maybe<Scalars['String']>
	duration?: Maybe<Scalars['String']>
	questionPicking?: Maybe<Scalars['String']>
	notifyUsers?: Maybe<Scalars['Boolean']>
	challengePack?: Maybe<Scalars['String']>
	selectedRole?: Maybe<Scalars['ID']>
	createdBy: Scalars['MongoID']
	pipelinesTotal?: Maybe<Scalars['Int']>
	selectedRoleDetails?: Maybe<Category>
	categoryId: Scalars['ID']
	challengeId: Scalars['ID']
	chaptersTotal?: Maybe<Scalars['Int']>
	chaptersId: Array<Scalars['ID']>
	pipelinesId: Array<Scalars['ID']>
	totalTalents: Scalars['Int']
	newTalentsSinceLastLogin: Scalars['Int']
	talents?: Maybe<ApplicantsPagination>
}

export type AssessmentTalentsArgs = {
	page?: Maybe<Scalars['Int']>
	type?: Maybe<EnumPipelineType>
	filter?: Maybe<FilterApplicantGetManyInput>
	sort?: Maybe<EnumSortApplicantGetManyInput>
}

/** This is category description */
export type Category = {
	__typename?: 'Category'
	id: Scalars['ID']
	name?: Maybe<Scalars['String']>
	color?: Maybe<Scalars['String']>
	img?: Maybe<Scalars['String']>
	desc?: Maybe<Scalars['String']>
	companies_count?: Maybe<Scalars['Float']>
	isSJT?: Maybe<Scalars['Boolean']>
	isPrivate?: Maybe<Scalars['Boolean']>
	isAllSkills?: Maybe<Scalars['Boolean']>
	updatedAt?: Maybe<Scalars['Date']>
	createdAt?: Maybe<Scalars['Date']>
	challenges: Array<Maybe<Challenge>>
	challengesIds?: Maybe<Array<Scalars['MongoID']>>
	challengesTotal?: Maybe<Scalars['Int']>
	benchmarks?: Maybe<Array<Maybe<IndustryBenchmark>>>
}

export type CategoryFilterInput = {
	name?: Maybe<Scalars['String']>
}

export type CategoryOrderByInput = {
	name?: Maybe<EnumSort>
}

export type Challenge = {
	__typename?: 'Challenge'
	id: Scalars['ID']
	name?: Maybe<Scalars['String']>
	desc?: Maybe<Scalars['String']>
	status?: Maybe<Scalars['String']>
	updatedAt?: Maybe<Scalars['Date']>
	createdAt?: Maybe<Scalars['Date']>
	chapters: Array<Maybe<Chapter>>
	chaptersTotal?: Maybe<Scalars['Int']>
	chaptersId?: Maybe<Array<Maybe<Scalars['ID']>>>
}

export type ChangePasswordInput = {
	oldPassword: Scalars['String']
	newPassword: Scalars['String']
}

export type Chapter = {
	__typename?: 'Chapter'
	id: Scalars['ID']
	name?: Maybe<Scalars['String']>
	desc?: Maybe<Scalars['String']>
	reward?: Maybe<Scalars['Float']>
	timer?: Maybe<Scalars['Float']>
	skills: Array<Maybe<Skill>>
	questionnaire?: Maybe<Scalars['Boolean']>
	calculator?: Maybe<Scalars['Boolean']>
	isSJT?: Maybe<Scalars['Boolean']>
	isPrivate?: Maybe<Scalars['Boolean']>
	cluster?: Maybe<Cluster>
	updatedAt?: Maybe<Scalars['Date']>
	createdAt?: Maybe<Scalars['Date']>
	skillsId?: Maybe<Array<Scalars['MongoID']>>
	parts: Array<Maybe<Part>>
	partsId?: Maybe<Array<Scalars['MongoID']>>
	clusterId?: Maybe<Scalars['ID']>
}

export type ChapterFilterInput = {
	name?: Maybe<Scalars['String']>
	isSJT?: Maybe<Scalars['Boolean']>
}

export type ChapterOrderByInput = {
	name?: Maybe<EnumSort>
}

export type ChapterResult = {
	__typename?: 'ChapterResult'
	id: Scalars['ID']
	user_id: Scalars['String']
	parts: Array<Answer>
	feedback?: Maybe<Scalars['String']>
	type: EnumChapterResultType
	isSJT?: Maybe<Scalars['Boolean']>
	updatedAt?: Maybe<Scalars['Date']>
	createdAt?: Maybe<Scalars['Date']>
	user?: Maybe<User>
	chapter?: Maybe<Chapter>
	chapterId: Scalars['MongoID']
	pipelineId?: Maybe<Scalars['MongoID']>
	pipeline?: Maybe<Pipeline>
	timeSpent: Scalars['Int']
}

export type ChapterResultUserArgs = {
	skip?: Maybe<Scalars['Int']>
	sort?: Maybe<SortFindOneUserInput>
}

export type Cluster = {
	__typename?: 'Cluster'
	id: Scalars['ID']
	name?: Maybe<Scalars['String']>
	desc?: Maybe<Scalars['String']>
	chaptersId?: Maybe<Array<Maybe<Scalars['ID']>>>
	chapters: Array<Maybe<Chapter>>
}

export type CreateAssessmentInput = {
	name: Scalars['String']
	masking?: Maybe<Scalars['Boolean']>
	challengePack?: Maybe<Scalars['String']>
	selectedRole?: Maybe<Scalars['MongoID']>
	chapters: Array<Scalars['ID']>
	objective: EnumAssessmentObjective
	attempts?: number
	duration?: Maybe<Scalars['String']>
	questionPicking?: Maybe<Scalars['String']>
	fromSection?: Maybe<SectionInput[]>
}
export type SectionInput = {
	sectionId: Maybe<Scalars['String']>
	sectionName: Maybe<Scalars['String']>
	subSectionId: Maybe<Scalars['String']>
	subSectionName: Maybe<Scalars['String']>
	noOfQuestions: Maybe<Scalars['Int']>
}
export type CreatePipelineInput = {
	name: Scalars['String']
	color?: Maybe<Scalars['String']>
	type: EnumPipelineType
}

export type EnumAnswerType = 'answer' | 'options'

export type EnumApplicantPipelineType = 'Internal' | 'External'

export type EnumApplicantType = 'job' | 'pipeline'

export type EnumAssessmentObjective = 'Hiring' | 'Benchmarking'

export type EnumAssessmentStatus = 'active' | 'inactive'

export type EnumChapterResultType = 'sandbox' | 'pipeline'

export type EnumPipelineType = 'Internal' | 'External'

export type EnumPlanType = 'Benchmarking' | 'Hiring'

export type EnumSort = 'asc' | 'desc'

export type EnumSortApplicantGetManyInput =
	| 'NAME_ASC'
	| 'NAME_DESC'
	| 'CREATED_AT_ASC'
	| 'CREATED_AT_DESC'
	| 'UPDATED_AT_ASC'
	| 'UPDATED_AT_DESC'
	| 'PROGRESS_ASC'
	| 'PROGRESS_DESC'
	| 'SCORE_ASC'
	| 'SCORE_DESC'

export type EnumSubscriptionInterval = 'Monthly' | 'Yearly'

export type Feature = {
	__typename?: 'Feature'
	id: Scalars['ID']
	type?: Maybe<Scalars['String']>
	keywords?: Maybe<Array<Maybe<Scalars['String']>>>
	sections?: Maybe<Scalars['String']>
	skills?: Maybe<Array<Maybe<Scalars['JSON']>>>
	averageScore?: Maybe<Array<Maybe<Scalars['Float']>>>
	value?: Maybe<Scalars['Float']>
	coefficient?: Maybe<Scalars['Float']>
	sType?: Maybe<Scalars['String']>
	updatedAt?: Maybe<Scalars['Date']>
	createdAt?: Maybe<Scalars['Date']>
}

export type FilterApplicantGetManyInput = {
	pipelineId?: Maybe<Scalars['String']>
	isBookmarked?: Maybe<Scalars['Boolean']>
	isShortlisted?: Maybe<Scalars['Boolean']>
	name?: Maybe<Scalars['String']>
	AND?: Maybe<Array<FilterApplicantGetManyInput>>
	OR?: Maybe<Array<FilterApplicantGetManyInput>>
}

export type FilterFindOneUserEdu_HistoryInput = {
	edu_name?: Maybe<Scalars['String']>
	edu_institute?: Maybe<Scalars['String']>
	edu_degree?: Maybe<Scalars['String']>
	edu_specialization?: Maybe<Scalars['String']>
	_id?: Maybe<Scalars['MongoID']>
}

export type FilterFindOneUserEmailOperatorsInput = {
	gt?: Maybe<Scalars['String']>
	gte?: Maybe<Scalars['String']>
	lt?: Maybe<Scalars['String']>
	lte?: Maybe<Scalars['String']>
	ne?: Maybe<Scalars['String']>
	in?: Maybe<Array<Maybe<Scalars['String']>>>
	nin?: Maybe<Array<Maybe<Scalars['String']>>>
	regex?: Maybe<Scalars['RegExpAsString']>
	exists?: Maybe<Scalars['Boolean']>
}

export type FilterFindOneUserFirst_NameOperatorsInput = {
	gt?: Maybe<Scalars['String']>
	gte?: Maybe<Scalars['String']>
	lt?: Maybe<Scalars['String']>
	lte?: Maybe<Scalars['String']>
	ne?: Maybe<Scalars['String']>
	in?: Maybe<Array<Maybe<Scalars['String']>>>
	nin?: Maybe<Array<Maybe<Scalars['String']>>>
	regex?: Maybe<Scalars['RegExpAsString']>
	exists?: Maybe<Scalars['Boolean']>
}

export type FilterFindOneUserInput = {
	uuid?: Maybe<Scalars['String']>
	email?: Maybe<Scalars['String']>
	first_name?: Maybe<Scalars['String']>
	last_name?: Maybe<Scalars['String']>
	resume_link?: Maybe<Scalars['String']>
	linkedin_link?: Maybe<Scalars['String']>
	location?: Maybe<Scalars['String']>
	status?: Maybe<Scalars['String']>
	profile_image?: Maybe<Scalars['String']>
	skills?: Maybe<Array<Maybe<Scalars['JSON']>>>
	certifications?: Maybe<Array<Maybe<Scalars['JSON']>>>
	industries?: Maybe<Array<Maybe<Scalars['JSON']>>>
	jobTypes?: Maybe<Array<Maybe<Scalars['JSON']>>>
	oExperiences?: Maybe<Array<Maybe<Scalars['JSON']>>>
	languages?: Maybe<Array<Maybe<Scalars['JSON']>>>
	role_interests?: Maybe<Array<Maybe<Scalars['JSON']>>>
	role_function?: Maybe<Scalars['String']>
	role_level?: Maybe<Scalars['String']>
	edu_history?: Maybe<Array<Maybe<FilterFindOneUserEdu_HistoryInput>>>
	edu_level?: Maybe<Scalars['String']>
	prof_year?: Maybe<Scalars['String']>
	username?: Maybe<Scalars['String']>
	authenticate_status?: Maybe<Scalars['Boolean']>
	edit_status?: Maybe<Scalars['Float']>
	public_popper?: Maybe<Scalars['Boolean']>
	isSalaryPublic?: Maybe<Scalars['Boolean']>
	salaryRange?: Maybe<Array<Maybe<Scalars['JSON']>>>
	_id?: Maybe<Scalars['MongoID']>
	updatedAt?: Maybe<Scalars['Date']>
	createdAt?: Maybe<Scalars['Date']>
	/** List of *indexed* fields that can be filtered via operators. */
	_operators?: Maybe<FilterFindOneUserOperatorsInput>
	OR?: Maybe<Array<FilterFindOneUserInput>>
	AND?: Maybe<Array<FilterFindOneUserInput>>
}

export type FilterFindOneUserLast_NameOperatorsInput = {
	gt?: Maybe<Scalars['String']>
	gte?: Maybe<Scalars['String']>
	lt?: Maybe<Scalars['String']>
	lte?: Maybe<Scalars['String']>
	ne?: Maybe<Scalars['String']>
	in?: Maybe<Array<Maybe<Scalars['String']>>>
	nin?: Maybe<Array<Maybe<Scalars['String']>>>
	regex?: Maybe<Scalars['RegExpAsString']>
	exists?: Maybe<Scalars['Boolean']>
}

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindOneUserOperatorsInput = {
	uuid?: Maybe<FilterFindOneUserUuidOperatorsInput>
	email?: Maybe<FilterFindOneUserEmailOperatorsInput>
	first_name?: Maybe<FilterFindOneUserFirst_NameOperatorsInput>
	last_name?: Maybe<FilterFindOneUserLast_NameOperatorsInput>
	_id?: Maybe<FilterFindOneUser_IdOperatorsInput>
}

export type FilterFindOneUserUuidOperatorsInput = {
	gt?: Maybe<Scalars['String']>
	gte?: Maybe<Scalars['String']>
	lt?: Maybe<Scalars['String']>
	lte?: Maybe<Scalars['String']>
	ne?: Maybe<Scalars['String']>
	in?: Maybe<Array<Maybe<Scalars['String']>>>
	nin?: Maybe<Array<Maybe<Scalars['String']>>>
	regex?: Maybe<Scalars['RegExpAsString']>
	exists?: Maybe<Scalars['Boolean']>
}

export type FilterFindOneUser_IdOperatorsInput = {
	gt?: Maybe<Scalars['MongoID']>
	gte?: Maybe<Scalars['MongoID']>
	lt?: Maybe<Scalars['MongoID']>
	lte?: Maybe<Scalars['MongoID']>
	ne?: Maybe<Scalars['MongoID']>
	in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
	nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
	exists?: Maybe<Scalars['Boolean']>
}

export type IndustryBenchmark = {
	__typename?: 'IndustryBenchmark'
	id?: Maybe<Scalars['String']>
	score?: Maybe<Scalars['Int']>
	top5?: Maybe<Scalars['Boolean']>
}

export type Mutation = {
	__typename?: 'Mutation'
	signIn?: Maybe<AnalyticsUserQuery>
	signUp?: Maybe<AnalyticsUserQuery>
	updateUser?: Maybe<AnalyticsUser>
	updateUserTourStatus: Scalars['Boolean']
	updateOrg?: Maybe<Org>
	updateSubscriptionCancellationStatus: UpdateSubscriptionCancellationStatusResult
	resendEmailVerification: Scalars['Boolean']
	sendEmailReminder: Scalars['Boolean']
	sendEmailReminderById: Scalars['Boolean']
	sendChallengeInvite: Scalars['Boolean']
	verifyEmail: Scalars['Boolean']
	changePassword: Scalars['Boolean']
	addPassword: Scalars['Boolean']
	createAssessment?: Maybe<Assessment>
	updateAssessment?: Maybe<Assessment>
	sendPasswordResetUser: Scalars['Boolean']
	resetPasswordUser: Scalars['Boolean']
	deleteAssessment: Scalars['Boolean']
	createPipeline?: Maybe<Pipeline>
	updatePipeline?: Maybe<Pipeline>
	deletePipeline: Scalars['Boolean']
	deleteApplicant: Scalars['Boolean']
	updateApplicant?: Maybe<Applicant>
	bulkAddTalents: Scalars['Boolean']
	createCheckoutSession: Scalars['String']
}

export type MutationSignInArgs = {
	userInput: SignInUserInput
}

export type MutationSignUpArgs = {
	userInput: SignUpUserInput
}

export type MutationUpdateUserArgs = {
	userInput: UpdateUserInput
}

export type MutationUpdateUserTourStatusArgs = {
	status: Scalars['Boolean']
}

export type MutationUpdateOrgArgs = {
	orgInput: UpdateOrgInput
}

export type MutationUpdateSubscriptionCancellationStatusArgs = {
	subscriptionId: Scalars['ID']
	input: UpdateSubscriptionCancellationStatusInput
}

export type MutationSendEmailReminderArgs = {
	emails?: Maybe<Array<Scalars['String']>>
	sendToAll?: Maybe<Scalars['Boolean']>
	pipelineId: Scalars['ID']
}

export type MutationSendEmailReminderByIdArgs = {
	id: Scalars['ID']
	assessId: Scalars['ID']
}

export type MutationSendChallengeInviteArgs = {
	assessId: Scalars['ID']
	pipelineId: Scalars['ID']
	emails: Array<Scalars['String']>
}

export type MutationVerifyEmailArgs = {
	userId: Scalars['String']
	token: Scalars['String']
}

export type MutationChangePasswordArgs = {
	userInput: ChangePasswordInput
}

export type MutationAddPasswordArgs = {
	password: Scalars['String']
}

export type MutationCreateAssessmentArgs = {
	assessInput: CreateAssessmentInput
	pipelineInput: CreatePipelineInput
	emailsToInvite?: Maybe<Array<Scalars['String']>>
}

export type MutationUpdateAssessmentArgs = {
	assessId: Scalars['ID']
	assessInput: UpdateAssessmentInput
}

export type MutationSendPasswordResetUserArgs = {
	email: Scalars['String']
}

export type MutationResetPasswordUserArgs = {
	userInput: ResetPasswordInput
}

export type MutationDeleteAssessmentArgs = {
	assessId: Scalars['ID']
}

export type MutationCreatePipelineArgs = {
	pipelineInput: CreatePipelineInput
	assessId: Scalars['ID']
	emailsToInvite?: Maybe<Array<Scalars['String']>>
}

export type MutationUpdatePipelineArgs = {
	pipelineInput: UpdatePipelineInput
	id: Scalars['ID']
}

export type MutationDeletePipelineArgs = {
	id: Scalars['ID']
}

export type MutationDeleteApplicantArgs = {
	assessId: Scalars['ID']
	userId: Scalars['ID']
}

export type MutationUpdateApplicantArgs = {
	assessId: Scalars['ID']
	userId: Scalars['ID']
	applicantInput: UpdateApplicantInput
}

export type MutationBulkAddTalentsArgs = {
	pipelineId: Scalars['ID']
	users: Scalars['JSON']
}

export type MutationCreateCheckoutSessionArgs = {
	plans: Array<PlanInput>
	successUrl: Scalars['String']
	cancelUrl: Scalars['String']
}

export type Org = {
	__typename?: 'Org'
	name?: Maybe<Scalars['String']>
	companySize?: Maybe<Scalars['String']>
	industry?: Maybe<Scalars['String']>
	photoUrl?: Maybe<Scalars['String']>
}

export type PaginationInfo = {
	__typename?: 'PaginationInfo'
	currentPage: Scalars['Int']
	perPage: Scalars['Int']
	pageCount?: Maybe<Scalars['Int']>
	itemCount?: Maybe<Scalars['Int']>
	hasNextPage?: Maybe<Scalars['Boolean']>
	hasPreviousPage?: Maybe<Scalars['Boolean']>
}

export type Part = {
	__typename?: 'Part'
	id: Scalars['ID']
	name?: Maybe<Scalars['String']>
	resource?: Maybe<Array<Maybe<Scalars['JSON']>>>
	answer?: Maybe<Array<Maybe<Scalars['JSON']>>>
	mc?: Maybe<Array<Maybe<Scalars['JSON']>>>
	keyPhrases?: Maybe<Array<Maybe<Scalars['JSON']>>>
	updatedAt?: Maybe<Scalars['Date']>
	createdAt?: Maybe<Scalars['Date']>
	features: Array<Maybe<Feature>>
	featuresId?: Maybe<Array<Scalars['MongoID']>>
}

export type Pipeline = {
	__typename?: 'Pipeline'
	id: Scalars['ID']
	type: EnumPipelineType
	color: Scalars['String']
	skills: Array<PipelineScore>
	assessId: Scalars['MongoID']
	updatedAt?: Maybe<Scalars['Date']>
	createdAt?: Maybe<Scalars['Date']>
	name: Scalars['String']
	categoryId?: Maybe<Scalars['ID']>
	usersTotal: Scalars['Int']
	users: Array<Applicant>
}

export type PipelineScore = {
	__typename?: 'PipelineScore'
	skillId: Scalars['ID']
	score: Scalars['Float']
	skill?: Maybe<Skill>
}

export type PlanInput = {
	planType?: Maybe<EnumPlanType>
	interval?: Maybe<EnumSubscriptionInterval>
}

export type Query = {
	__typename?: 'Query'
	me?: Maybe<AnalyticsUser>
	categories: Array<Category>
	categoryById?: Maybe<Category>
	chapters: Array<Chapter>
	profileDetail?: Maybe<User>
	clusters: Array<Maybe<Cluster>>
	chaptersByClusters: Array<Maybe<Chapter>>
	assessment: Assessment
	assessments: Array<Assessment>
	checkPinUser: Scalars['Boolean']
	applicant?: Maybe<Applicant>
	applicants?: Maybe<ApplicantsPagination>
	emailsToCompleteChallenge: Array<Scalars['String']>
}

export type QueryCategoriesArgs = {
	filters?: Maybe<CategoryFilterInput>
	orderBy?: Maybe<CategoryOrderByInput>
}

export type QueryCategoryByIdArgs = {
	_id?: Maybe<Scalars['MongoID']>
}

export type QueryChaptersArgs = {
	filters?: Maybe<ChapterFilterInput>
	orderBy?: Maybe<ChapterOrderByInput>
}

export type QueryProfileDetailArgs = {
	filter?: Maybe<FilterFindOneUserInput>
	skip?: Maybe<Scalars['Int']>
	sort?: Maybe<SortFindOneUserInput>
}

export type QueryChaptersByClustersArgs = {
	clustersId: Array<Scalars['ID']>
}

export type QueryAssessmentArgs = {
	id: Scalars['MongoID']
}

export type QueryAssessmentsArgs = {
	lastActive?: Maybe<Scalars['Date']>
}

export type QueryCheckPinUserArgs = {
	email: Scalars['String']
	pin: Scalars['String']
}

export type QueryApplicantArgs = {
	assessId: Scalars['ID']
	userId: Scalars['ID']
}

export type QueryApplicantsArgs = {
	page?: Maybe<Scalars['Int']>
	assessId: Scalars['ID']
	type?: Maybe<EnumPipelineType>
	filter?: Maybe<FilterApplicantGetManyInput>
	sort?: Maybe<EnumSortApplicantGetManyInput>
}

export type QueryEmailsToCompleteChallengeArgs = {
	pipelineId: Scalars['ID']
}

export type ResetPasswordInput = {
	email: Scalars['String']
	newPassword: Scalars['String']
	pin: Scalars['String']
}

export type Sandbox = {
	__typename?: 'Sandbox'
	googleId?: Maybe<Scalars['String']>
	facebookId?: Maybe<Scalars['String']>
	refCode?: Maybe<Scalars['MongoID']>
	isVerified?: Maybe<Scalars['Boolean']>
	username?: Maybe<Scalars['String']>
	public_profile?: Maybe<Scalars['String']>
	isPublic?: Maybe<Scalars['Boolean']>
	email_permission?: Maybe<Scalars['Boolean']>
	_id: Scalars['MongoID']
	updatedAt?: Maybe<Scalars['Date']>
	createdAt?: Maybe<Scalars['Date']>
}

export type ScoreApplicant = {
	__typename?: 'ScoreApplicant'
	score: Scalars['Float']
	id: Scalars['ID']
	userId: Scalars['ID']
	skills: Array<ScoreSkill>
}

export type ScoreSkill = {
	__typename?: 'ScoreSkill'
	score: Scalars['Float']
	chapterId: Scalars['ID']
	skillId: Scalars['ID']
	skill?: Maybe<Skill>
	chapter?: Maybe<Chapter>
}

export type SignInUserInput = {
	email: Scalars['String']
	password: Scalars['String']
}

export type SignUpUserInput = {
	firstName: Scalars['String']
	lastName: Scalars['String']
	email: Scalars['String']
	password: Scalars['String']
	isTermsAccepted: Scalars['Boolean']
}

export type Skill = {
	__typename?: 'Skill'
	id: Scalars['ID']
	name?: Maybe<Scalars['String']>
	desc?: Maybe<Scalars['String']>
	color?: Maybe<Scalars['String']>
	type?: Maybe<Scalars['String']>
	updatedAt?: Maybe<Scalars['Date']>
	createdAt?: Maybe<Scalars['Date']>
	features: Array<Maybe<Feature>>
	featuresId?: Maybe<Array<Scalars['MongoID']>>
	categoriesTotal?: Maybe<Scalars['Int']>
}

export type SortFindOneSandboxInput =
	| '_ID_ASC'
	| '_ID_DESC'
	| 'UUID_ASC'
	| 'UUID_DESC'
	| 'EMAIL_ASC'
	| 'EMAIL_DESC'

export type SortFindOneUserInput =
	| '_ID_ASC'
	| '_ID_DESC'
	| 'UUID_ASC'
	| 'UUID_DESC'
	| 'EMAIL_ASC'
	| 'EMAIL_DESC'
	| 'FIRST_NAME_ASC'
	| 'FIRST_NAME_DESC'
	| 'LAST_NAME_ASC'
	| 'LAST_NAME_DESC'

export type UpdateApplicantInput = {
	note?: Maybe<Scalars['String']>
	isBookmarked?: Maybe<Scalars['Boolean']>
	isShortlisted?: Maybe<Scalars['Boolean']>
	pipelinesId?: Maybe<Array<Scalars['ID']>>
}

export type UpdateAssessmentInput = {
	name?: Maybe<Scalars['String']>
	masking?: Maybe<Scalars['Boolean']>
	status?: Maybe<EnumAssessmentStatus>
	attempts?: Maybe<Scalars['Int']>
	duration?: Maybe<Scalars['Int']>
	questionPicking?: Maybe<Scalars['Int']>

	fromSection?: Maybe<SectionInput[]>
}

export type UpdateOrgInput = {
	industry?: Maybe<Scalars['String']>
	name?: Maybe<Scalars['String']>
	companySize?: Maybe<Scalars['String']>
	logo?: Maybe<Scalars['Upload']>
}

export type UpdatePipelineInput = {
	name?: Maybe<Scalars['String']>
	color?: Maybe<Scalars['String']>
}

export type UpdateSubscriptionCancellationStatusInput = {
	isCancellationAlertAcknowledged?: Maybe<Scalars['Boolean']>
	isCancellationMessageAcknowledged?: Maybe<Scalars['Boolean']>
}

export type UpdateSubscriptionCancellationStatusResult = {
	__typename?: 'UpdateSubscriptionCancellationStatusResult'
	isCancellationAlertAcknowledged?: Maybe<Scalars['Boolean']>
	isCancellationMessageAcknowledged?: Maybe<Scalars['Boolean']>
}

export type UpdateUserInput = {
	firstName?: Maybe<Scalars['String']>
	lastName?: Maybe<Scalars['String']>
	role?: Maybe<Scalars['String']>
	isTeamTourDone?: Maybe<Scalars['Boolean']>
	isFreeTrialAcknowledged?: Maybe<Scalars['Boolean']>
	isFreeTrialAlertAcknowledged?: Maybe<Scalars['Boolean']>
	isFreeTrialDaysAlertAcknowledged?: Maybe<Scalars['Boolean']>
	photo?: Maybe<Scalars['Upload']>
}

export type User = {
	__typename?: 'User'
	id: Scalars['ID']
	uuid: Scalars['String']
	email?: Maybe<Scalars['String']>
	location?: Maybe<Scalars['String']>
	status?: Maybe<Scalars['String']>
	skills?: Maybe<Array<Maybe<Scalars['JSON']>>>
	certifications?: Maybe<Array<Maybe<Scalars['JSON']>>>
	industries?: Maybe<Array<Maybe<Scalars['JSON']>>>
	jobTypes?: Maybe<Array<Maybe<Scalars['JSON']>>>
	oExperiences?: Maybe<Array<Maybe<Scalars['JSON']>>>
	languages?: Maybe<Array<Maybe<Scalars['JSON']>>>
	username?: Maybe<Scalars['String']>
	isSalaryPublic?: Maybe<Scalars['Boolean']>
	salaryRange?: Maybe<Array<Maybe<Scalars['JSON']>>>
	updatedAt?: Maybe<Scalars['Date']>
	createdAt?: Maybe<Scalars['Date']>
	sandbox?: Maybe<Sandbox>
	hasSandbox: Scalars['Boolean']
	firstName?: Maybe<Scalars['String']>
	lastName?: Maybe<Scalars['String']>
	resumeLink?: Maybe<Scalars['String']>
	linkedinLink?: Maybe<Scalars['String']>
	roleFunction?: Maybe<Scalars['String']>
	eduLevel?: Maybe<Scalars['String']>
	profYear?: Maybe<Scalars['String']>
	roleInterests: Array<Scalars['String']>
	roleLevel: Array<Scalars['String']>
	photoUrl?: Maybe<Scalars['String']>
	eduHistory?: Maybe<Array<Maybe<UserEdu_History>>>
}

export type UserSandboxArgs = {
	skip?: Maybe<Scalars['Int']>
	sort?: Maybe<SortFindOneSandboxInput>
}

export type UserEdu_History = {
	__typename?: 'UserEdu_history'
	id: Scalars['ID']
	name?: Maybe<Scalars['String']>
	institute?: Maybe<Scalars['String']>
	degree?: Maybe<Scalars['String']>
}

export type CoreAnalyticsUserFieldsFragment = { __typename?: 'AnalyticsUser' } & Pick<
	AnalyticsUser,
	| 'id'
	| 'name'
	| 'role'
	| 'email'
	| 'firstName'
	| 'lastName'
	| 'photoUrl'
	| 'isEmailVerified'
	| 'isTermsAccepted'
	| 'isTeamTourDone'
	| 'isFreeTrialAcknowledged'
	| 'isFreeTrialDaysAlertAcknowledged'
	| 'isFreeTrialAlertAcknowledged'
	| 'isFirstAccess'
	| 'employeeCount'
	| 'hasPassword'
	| 'isTourDone'
	| 'isSubscribed'
> & {
		org?: Maybe<
			{ __typename?: 'Org' } & Pick<Org, 'name' | 'companySize' | 'industry' | 'photoUrl'>
		>
		trialDetails: { __typename?: 'AnalyticsUserTrialDetails' } & Pick<
			AnalyticsUserTrialDetails,
			'endOfTrial' | 'daysRemaining' | 'hasExpired'
		>
		subscriptions: Array<
			{ __typename?: 'AnalyticsUserSubscription' } & Pick<
				AnalyticsUserSubscription,
				| 'id'
				| 'plans'
				| 'interval'
				| 'isCanceled'
				| 'nextPaymentCost'
				| 'nextPaymentAt'
				| 'isPaymentFailed'
				| 'endsAt'
				| 'isCancellationAlertAcknowledged'
				| 'isCancellationMessageAcknowledged'
			>
		>
	}

export type MeQueryVariables = Exact<{ [key: string]: never }>

export type MeQuery = { __typename?: 'Query' } & {
	me?: Maybe<{ __typename?: 'AnalyticsUser' } & CoreAnalyticsUserFieldsFragment>
}

export type GetProfileQueryVariables = Exact<{ [key: string]: never }>

export type GetProfileQuery = { __typename?: 'Query' } & {
	me?: Maybe<{ __typename?: 'AnalyticsUser' } & CoreAnalyticsUserFieldsFragment>
}

export type SignInMutationVariables = Exact<{
	userInput: SignInUserInput
}>

export type SignInMutation = { __typename?: 'Mutation' } & {
	signIn?: Maybe<
		{ __typename?: 'AnalyticsUserQuery' } & Pick<AnalyticsUserQuery, 'token'> & {
				user: { __typename?: 'AnalyticsUser' } & CoreAnalyticsUserFieldsFragment
			}
	>
}

export type SignUpMutationVariables = Exact<{
	userInput: SignUpUserInput
}>

export type SignUpMutation = { __typename?: 'Mutation' } & {
	signUp?: Maybe<
		{ __typename?: 'AnalyticsUserQuery' } & Pick<AnalyticsUserQuery, 'token'> & {
				user: { __typename?: 'AnalyticsUser' } & CoreAnalyticsUserFieldsFragment
			}
	>
}

export type UpdateUserMutationVariables = Exact<{
	userInput: UpdateUserInput
}>

export type UpdateUserMutation = { __typename?: 'Mutation' } & {
	updateUser?: Maybe<{ __typename?: 'AnalyticsUser' } & CoreAnalyticsUserFieldsFragment>
}

export type UpdateSubscriptionCancellationStatusMutationVariables = Exact<{
	id: Scalars['ID']
	input: UpdateSubscriptionCancellationStatusInput
}>

export type UpdateSubscriptionCancellationStatusMutation = { __typename?: 'Mutation' } & {
	updateSubscriptionCancellationStatus: {
		__typename?: 'UpdateSubscriptionCancellationStatusResult'
	} & Pick<
		UpdateSubscriptionCancellationStatusResult,
		'isCancellationAlertAcknowledged' | 'isCancellationMessageAcknowledged'
	>
}

export type UpdateUserTourStatusMutationVariables = Exact<{
	status: Scalars['Boolean']
}>

export type UpdateUserTourStatusMutation = { __typename?: 'Mutation' } & Pick<
	Mutation,
	'updateUserTourStatus'
>

export type ChangePasswordMutationVariables = Exact<{
	userInput: ChangePasswordInput
}>

export type ChangePasswordMutation = { __typename?: 'Mutation' } & Pick<
	Mutation,
	'changePassword'
>

export type AddPasswordMutationVariables = Exact<{
	password: Scalars['String']
}>

export type AddPasswordMutation = { __typename?: 'Mutation' } & Pick<
	Mutation,
	'addPassword'
>

export type SendPasswordResetUserMutationVariables = Exact<{
	email: Scalars['String']
}>

export type SendPasswordResetUserMutation = { __typename?: 'Mutation' } & Pick<
	Mutation,
	'sendPasswordResetUser'
>

export type ResetPasswordUserMutationVariables = Exact<{
	userInput: ResetPasswordInput
}>

export type ResetPasswordUserMutation = { __typename?: 'Mutation' } & Pick<
	Mutation,
	'resetPasswordUser'
>

export type CheckPinUserQueryVariables = Exact<{
	email: Scalars['String']
	pin: Scalars['String']
}>

export type CheckPinUserQuery = { __typename?: 'Query' } & Pick<Query, 'checkPinUser'>

export type VerifyEmailMutationVariables = Exact<{
	userId: Scalars['String']
	token: Scalars['String']
}>

export type VerifyEmailMutation = { __typename?: 'Mutation' } & Pick<
	Mutation,
	'verifyEmail'
>

export type ResendEmailVerificationMutationVariables = Exact<{ [key: string]: never }>

export type ResendEmailVerificationMutation = { __typename?: 'Mutation' } & Pick<
	Mutation,
	'resendEmailVerification'
>

export type CoreChapterResultFieldsFragment = { __typename?: 'ChapterResult' } & Pick<
	ChapterResult,
	'id' | 'chapterId' | 'timeSpent'
> & {
		parts: Array<
			{ __typename?: 'Answer' } & Pick<
				Answer,
				| 'id'
				| 'type'
				| 'title'
				| 'scene'
				| 'question'
				| 'instruction'
				| 'answer'
				| 'options'
			>
		>
	}

export type ApplicantsScoreSkillsFieldsFragment = { __typename?: 'ScoreSkill' } & Pick<
	ScoreSkill,
	'score' | 'skillId'
> & {
		skill?: Maybe<{ __typename?: 'Skill' } & Pick<Skill, 'id' | 'name'>>
		chapter?: Maybe<{ __typename?: 'Chapter' } & Pick<Chapter, 'id' | 'name'>>
	}

export type ApplicantsScoreFieldsFragment = { __typename?: 'ScoreApplicant' } & Pick<
	ScoreApplicant,
	'id' | 'score'
> & { skills: Array<{ __typename?: 'ScoreSkill' } & ApplicantsScoreSkillsFieldsFragment> }

export type ApplicantFieldsFragment = { __typename?: 'Applicant' } & Pick<
	Applicant,
	| 'id'
	| 'progress'
	| 'type'
	| 'isBookmarked'
	| 'isShortlisted'
	| 'masking'
	| 'note'
	| 'customFields'
	| 'pipelineType'
	| 'pipelinesId'
	| 'createdAt'
> & {
		submission: Array<{ __typename?: 'ChapterResult' } & CoreChapterResultFieldsFragment>
		score?: Maybe<{ __typename?: 'ScoreApplicant' } & ApplicantsScoreFieldsFragment>
		pipelines: Array<
			{ __typename?: 'Pipeline' } & Pick<Pipeline, 'id' | 'name' | 'color' | 'type'>
		>
		user?: Maybe<
			{ __typename?: 'User' } & Pick<
				User,
				| 'id'
				| 'email'
				| 'location'
				| 'firstName'
				| 'lastName'
				| 'photoUrl'
				| 'skills'
				| 'linkedinLink'
				| 'resumeLink'
				| 'eduLevel'
				| 'roleFunction'
				| 'roleInterests'
				| 'profYear'
				| 'username'
				| 'hasSandbox'
			>
		>
	}

export type GetApplicantQueryVariables = Exact<{
	userId: Scalars['ID']
	assessId: Scalars['ID']
}>

export type GetApplicantQuery = { __typename?: 'Query' } & {
	applicant?: Maybe<{ __typename?: 'Applicant' } & ApplicantFieldsFragment>
}

export type CoreApplicantItemFieldsFragment = { __typename?: 'Applicant' } & Pick<
	Applicant,
	| 'id'
	| 'progress'
	| 'isBookmarked'
	| 'masking'
	| 'pipelinesId'
	| 'pipelineType'
	| 'createdAt'
> & {
		score?: Maybe<{ __typename?: 'ScoreApplicant' } & ApplicantsScoreFieldsFragment>
		pipelines: Array<
			{ __typename?: 'Pipeline' } & Pick<Pipeline, 'id' | 'name' | 'color' | 'type'>
		>
		user?: Maybe<
			{ __typename?: 'User' } & Pick<User, 'id' | 'firstName' | 'lastName' | 'photoUrl'>
		>
	}

export type GetApplicantsQueryVariables = Exact<{
	assessId: Scalars['ID']
	page?: Maybe<Scalars['Int']>
	filter?: Maybe<FilterApplicantGetManyInput>
	sort?: Maybe<EnumSortApplicantGetManyInput>
	type?: Maybe<EnumPipelineType>
}>

export type GetApplicantsQuery = { __typename?: 'Query' } & {
	applicants?: Maybe<
		{ __typename?: 'ApplicantsPagination' } & Pick<ApplicantsPagination, 'count'> & {
				items: Array<{ __typename?: 'Applicant' } & CoreApplicantItemFieldsFragment>
				totalTalents: { __typename?: 'ApplicantsPaginationTotalTalents' } & Pick<
					ApplicantsPaginationTotalTalents,
					'count' | 'employees' | 'candidates'
				>
				pageInfo: { __typename?: 'PaginationInfo' } & Pick<
					PaginationInfo,
					| 'currentPage'
					| 'perPage'
					| 'pageCount'
					| 'itemCount'
					| 'hasNextPage'
					| 'hasPreviousPage'
				>
			}
	>
}

export type UpdateApplicantMutationVariables = Exact<{
	userId: Scalars['ID']
	assessId: Scalars['ID']
	applicantInput: UpdateApplicantInput
}>

export type UpdateApplicantMutation = { __typename?: 'Mutation' } & {
	updateApplicant?: Maybe<
		{ __typename?: 'Applicant' } & Pick<
			Applicant,
			'id' | 'isBookmarked' | 'isShortlisted' | 'note' | 'pipelinesId' | 'pipelineType'
		> & {
				user?: Maybe<
					{ __typename?: 'User' } & Pick<User, 'id' | 'firstName' | 'lastName' | 'photoUrl'>
				>
				pipelines: Array<
					{ __typename?: 'Pipeline' } & Pick<Pipeline, 'id' | 'name' | 'color' | 'type'>
				>
			}
	>
}

export type DeleteApplicantMutationVariables = Exact<{
	userId: Scalars['ID']
	assessId: Scalars['ID']
}>

export type DeleteApplicantMutation = { __typename?: 'Mutation' } & Pick<
	Mutation,
	'deleteApplicant'
>

export type BulkAddTalentsMutationVariables = Exact<{
	pipelineId: Scalars['ID']
	users: Scalars['JSON']
}>

export type BulkAddTalentsMutation = { __typename?: 'Mutation' } & Pick<
	Mutation,
	'bulkAddTalents'
>

export type CoreChapterFieldsFragment = { __typename?: 'Chapter' } & Pick<
	Chapter,
	'id' | 'name' | 'desc' | 'timer'
> & { parts: Array<Maybe<{ __typename?: 'Part' } & Pick<Part, 'id' | 'name'>>> }

export type GetAssessmentsQueryVariables = Exact<{
	lastActive?: Maybe<Scalars['Date']>
}>

export type GetAssessmentsQuery = { __typename?: 'Query' } & {
	assessments: Array<
		{ __typename?: 'Assessment' } & Pick<
			Assessment,
			| 'id'
			| 'name'
			| 'status'
			| 'masking'
			| 'attempts'
			| 'totalTalents'
			| 'newTalentsSinceLastLogin'
			| 'createdAt'
			| 'updatedAt'
		> & { category?: Maybe<{ __typename?: 'Category' } & Pick<Category, 'name'>> }
	>
}

export type GetAssessmentQueryVariables = Exact<{
	id: Scalars['MongoID']
}>

export type GetAssessmentQuery = { __typename?: 'Query' } & {
	assessment: { __typename?: 'Assessment' } & Pick<
		Assessment,
		| 'id'
		| 'name'
		| 'challengePack'
		| 'selectedRole'
		| 'pipelinesTotal'
		| 'totalTalents'
		| 'masking'
		| 'attempts'
		| 'duration'
		| 'questionPicking'
		| 'fromSection'
		| 'status'
		| 'categoryId'
		| 'challengeId'
		| 'chaptersTotal'
	> & {
			selectedRoleDetails?: Maybe<
				{ __typename?: 'Category' } & Pick<Category, 'id' | 'name'> & {
						benchmarks?: Maybe<
							Array<
								Maybe<
									{ __typename?: 'IndustryBenchmark' } & Pick<
										IndustryBenchmark,
										'id' | 'score'
									>
								>
							>
						>
					}
			>
			pipelines: Array<
				Maybe<
					{ __typename?: 'Pipeline' } & Pick<
						Pipeline,
						'id' | 'name' | 'type' | 'color' | 'usersTotal'
					> & {
							users: Array<
								{ __typename?: 'Applicant' } & Pick<Applicant, 'id' | 'isCustom' | 'email'>
							>
							skills: Array<
								{ __typename?: 'PipelineScore' } & Pick<PipelineScore, 'score'> & {
										skill?: Maybe<{ __typename?: 'Skill' } & Pick<Skill, 'id' | 'name'>>
									}
							>
						}
				>
			>
			chapters: Array<Maybe<{ __typename?: 'Chapter' } & CoreChapterFieldsFragment>>
		}
}

export type GetAssessmentChaptersQueryVariables = Exact<{
	id: Scalars['MongoID']
}>

export type GetAssessmentChaptersQuery = { __typename?: 'Query' } & {
	assessment: { __typename?: 'Assessment' } & Pick<Assessment, 'id' | 'chaptersTotal'> & {
			chapters: Array<Maybe<{ __typename?: 'Chapter' } & CoreChapterFieldsFragment>>
		}
}

export type UpdateAssessmentMutationVariables = Exact<{
	assessId: Scalars['ID']
	assessInput: UpdateAssessmentInput
}>

export type UpdateAssessmentMutation = { __typename?: 'Mutation' } & {
	updateAssessment?: Maybe<
		{ __typename?: 'Assessment' } & Pick<
			Assessment,
			| 'id'
			| 'name'
			| 'masking'
			| 'status'
			| 'attempts'
			| 'duration'
			| 'questionPicking'
			| 'fromSection'
		>
	>
}

export type UpdateAssessmentMaskingMutationVariables = Exact<{
	assessmentId: Scalars['ID']
	masking: Scalars['Boolean']
}>

export type UpdateAssessmentMaskingMutation = { __typename?: 'Mutation' } & {
	updateAssessment?: Maybe<
		{ __typename?: 'Assessment' } & Pick<Assessment, 'id' | 'masking'>
	>
}

export type DeleteAssessmentMutationVariables = Exact<{
	assessId: Scalars['ID']
}>

export type DeleteAssessmentMutation = { __typename?: 'Mutation' } & Pick<
	Mutation,
	'deleteAssessment'
>

export type GetCategoriesQueryVariables = Exact<{
	filters?: Maybe<CategoryFilterInput>
	orderBy?: Maybe<CategoryOrderByInput>
}>

export type GetCategoriesQuery = { __typename?: 'Query' } & {
	categories: Array<
		{ __typename?: 'Category' } & Pick<Category, 'id' | 'name' | 'color' | 'img'>
	>
}

export type GetCategoryByIdQueryVariables = Exact<{
	id?: Maybe<Scalars['MongoID']>
}>

export type GetCategoryByIdQuery = { __typename?: 'Query' } & {
	categoryById?: Maybe<
		{ __typename?: 'Category' } & Pick<Category, 'id'> & {
				benchmarks?: Maybe<
					Array<
						Maybe<
							{ __typename?: 'IndustryBenchmark' } & Pick<
								IndustryBenchmark,
								'id' | 'score' | 'top5'
							>
						>
					>
				>
				challenges: Array<
					Maybe<
						{ __typename?: 'Challenge' } & Pick<Challenge, 'id'> & {
								chapters: Array<
									Maybe<
										{ __typename?: 'Chapter' } & Pick<
											Chapter,
											'id' | 'name' | 'desc' | 'timer'
										> & {
												parts: Array<
													Maybe<{ __typename?: 'Part' } & Pick<Part, 'id' | 'name'>>
												>
											}
									>
								>
							}
					>
				>
			}
	>
}

export type GetChaptersQueryVariables = Exact<{
	filters?: Maybe<ChapterFilterInput>
	orderBy?: Maybe<ChapterOrderByInput>
}>

export type GetChaptersQuery = { __typename?: 'Query' } & {
	chapters: Array<
		{ __typename?: 'Chapter' } & Pick<
			Chapter,
			'id' | 'name' | 'desc' | 'timer' | 'isSJT'
		> & { parts: Array<Maybe<{ __typename?: 'Part' } & Pick<Part, 'id' | 'name'>>> }
	>
}

export type GetChaptersByClustersQueryVariables = Exact<{
	clusterIds: Array<Scalars['ID']> | Scalars['ID']
}>

export type GetChaptersByClustersQuery = { __typename?: 'Query' } & {
	chaptersByClusters: Array<
		Maybe<
			{ __typename?: 'Chapter' } & Pick<Chapter, 'id' | 'name' | 'desc' | 'timer'> & {
					cluster?: Maybe<{ __typename?: 'Cluster' } & Pick<Cluster, 'id' | 'name'>>
					parts: Array<Maybe<{ __typename?: 'Part' } & Pick<Part, 'id' | 'name'>>>
				}
		>
	>
}

export type CreateCheckoutSessionMutationVariables = Exact<{
	plans: Array<PlanInput> | PlanInput
	successUrl: Scalars['String']
	cancelUrl: Scalars['String']
}>

export type CreateCheckoutSessionMutation = { __typename?: 'Mutation' } & Pick<
	Mutation,
	'createCheckoutSession'
>

export type GetClustersQueryVariables = Exact<{ [key: string]: never }>

export type GetClustersQuery = { __typename?: 'Query' } & {
	clusters: Array<Maybe<{ __typename?: 'Cluster' } & Pick<Cluster, 'id' | 'name' | 'desc'>>>
}

export type CreateAssessmentMutationVariables = Exact<{
	assessmentInput: CreateAssessmentInput
	pipelineInput: CreatePipelineInput
	emailsToInvite?: Maybe<Array<Scalars['String']> | Scalars['String']>
}>

export type CreateAssessmentMutation = { __typename?: 'Mutation' } & {
	createAssessment?: Maybe<
		{ __typename?: 'Assessment' } & Pick<
			Assessment,
			| 'id'
			| 'name'
			| 'status'
			| 'challengePack'
			| 'selectedRole'
			| 'masking'
			| 'attempts'
			| 'totalTalents'
			| 'createdAt'
			| 'updatedAt'
		> & { category?: Maybe<{ __typename?: 'Category' } & Pick<Category, 'name'>> }
	>
}

export type GetEmailReminderSuggestionsQueryVariables = Exact<{
	pipelineId: Scalars['ID']
}>

export type GetEmailReminderSuggestionsQuery = { __typename?: 'Query' } & {
	emailSuggestions: Query['emailsToCompleteChallenge']
}

export type UserOrgQueryVariables = Exact<{ [key: string]: never }>

export type UserOrgQuery = { __typename?: 'Query' } & {
	me?: Maybe<
		{ __typename?: 'AnalyticsUser' } & {
			org?: Maybe<
				{ __typename?: 'Org' } & Pick<Org, 'name' | 'companySize' | 'industry' | 'photoUrl'>
			>
		}
	>
}

export type UpdateOrgMutationVariables = Exact<{
	orgInput: UpdateOrgInput
}>

export type UpdateOrgMutation = { __typename?: 'Mutation' } & {
	updateOrg?: Maybe<
		{ __typename?: 'Org' } & Pick<Org, 'name' | 'companySize' | 'industry' | 'photoUrl'>
	>
}

export type CreatePipelineMutationVariables = Exact<{
	assessId: Scalars['ID']
	pipelineInput: CreatePipelineInput
	emailsToInvite?: Maybe<Array<Scalars['String']> | Scalars['String']>
}>

export type CreatePipelineMutation = { __typename?: 'Mutation' } & {
	createPipeline?: Maybe<
		{ __typename?: 'Pipeline' } & Pick<
			Pipeline,
			'id' | 'name' | 'type' | 'color' | 'usersTotal'
		> & {
				users: Array<
					{ __typename?: 'Applicant' } & Pick<Applicant, 'id' | 'isCustom' | 'email'>
				>
			}
	>
}

export type DeletePipelineMutationVariables = Exact<{
	id: Scalars['ID']
}>

export type DeletePipelineMutation = { __typename?: 'Mutation' } & Pick<
	Mutation,
	'deletePipeline'
>

export type UpdatePipelineMutationVariables = Exact<{
	id: Scalars['ID']
	pipelineInput: UpdatePipelineInput
}>

export type UpdatePipelineMutation = { __typename?: 'Mutation' } & {
	updatePipeline?: Maybe<
		{ __typename?: 'Pipeline' } & Pick<Pipeline, 'id' | 'name' | 'color'>
	>
}

export type SendChallengeInviteMutationVariables = Exact<{
	assessId: Scalars['ID']
	pipelineId: Scalars['ID']
	emails: Array<Scalars['String']> | Scalars['String']
}>

export type SendChallengeInviteMutation = { __typename?: 'Mutation' } & Pick<
	Mutation,
	'sendChallengeInvite'
>

export type GetPipelineScoringQueryVariables = Exact<{
	assessmentId: Scalars['MongoID']
}>

export type GetPipelineScoringQuery = { __typename?: 'Query' } & {
	assessment: { __typename?: 'Assessment' } & Pick<Assessment, 'id'> & {
			pipelines: Array<
				Maybe<
					{ __typename?: 'Pipeline' } & Pick<Pipeline, 'id' | 'name' | 'color'> & {
							skills: Array<
								{ __typename?: 'PipelineScore' } & Pick<PipelineScore, 'score'> & {
										skill?: Maybe<{ __typename?: 'Skill' } & Pick<Skill, 'id' | 'name'>>
									}
							>
						}
				>
			>
			talents?: Maybe<
				{ __typename?: 'ApplicantsPagination' } & {
					items: Array<
						{ __typename?: 'Applicant' } & Pick<Applicant, 'id'> & {
								user?: Maybe<{ __typename?: 'User' } & Pick<User, 'id'>>
								score?: Maybe<
									{ __typename?: 'ScoreApplicant' } & Pick<ScoreApplicant, 'id'> & {
											skills: Array<
												{ __typename?: 'ScoreSkill' } & Pick<
													ScoreSkill,
													'skillId' | 'score'
												>
											>
										}
								>
							}
					>
				}
			>
		}
}

export type SendEmailReminderMutationVariables = Exact<{
	emails?: Maybe<Array<Scalars['String']> | Scalars['String']>
	sendToAll?: Maybe<Scalars['Boolean']>
	pipelineId: Scalars['ID']
}>

export type SendEmailReminderMutation = { __typename?: 'Mutation' } & Pick<
	Mutation,
	'sendEmailReminder'
>

export type SendEmailReminderByIdMutationVariables = Exact<{
	id: Scalars['ID']
	assessId: Scalars['ID']
}>

export type SendEmailReminderByIdMutation = { __typename?: 'Mutation' } & Pick<
	Mutation,
	'sendEmailReminderById'
>

export const CoreAnalyticsUserFieldsFragmentDoc = gql`
	fragment CoreAnalyticsUserFields on AnalyticsUser {
		id
		name
		role
		email
		firstName
		lastName
		photoUrl
		isEmailVerified
		isTermsAccepted
		isTeamTourDone
		isFreeTrialAcknowledged
		isFreeTrialDaysAlertAcknowledged
		isFreeTrialAlertAcknowledged
		isFirstAccess
		employeeCount
		hasPassword
		isTourDone
		org {
			name
			companySize
			industry
			photoUrl
		}
		trialDetails {
			endOfTrial
			daysRemaining
			hasExpired
		}
		isSubscribed
		subscriptions {
			id
			plans
			interval
			isCanceled
			nextPaymentCost
			nextPaymentAt
			isPaymentFailed
			endsAt
			isCancellationAlertAcknowledged
			isCancellationMessageAcknowledged
		}
	}
`
export const CoreChapterResultFieldsFragmentDoc = gql`
	fragment CoreChapterResultFields on ChapterResult {
		id
		chapterId
		timeSpent
		parts {
			id
			type
			title
			scene
			question
			instruction
			answer
			options
		}
	}
`
export const ApplicantsScoreSkillsFieldsFragmentDoc = gql`
	fragment ApplicantsScoreSkillsFields on ScoreSkill {
		score
		skillId
		skill {
			id
			name
		}
		chapter {
			id
			name
		}
	}
`
export const ApplicantsScoreFieldsFragmentDoc = gql`
	fragment ApplicantsScoreFields on ScoreApplicant {
		id
		score
		skills {
			...ApplicantsScoreSkillsFields
		}
	}
	${ApplicantsScoreSkillsFieldsFragmentDoc}
`
export const ApplicantFieldsFragmentDoc = gql`
	fragment ApplicantFields on Applicant {
		id
		progress
		type
		isBookmarked
		isShortlisted
		masking
		note
		customFields
		pipelineType
		pipelinesId
		submission {
			...CoreChapterResultFields
		}
		score {
			...ApplicantsScoreFields
		}
		pipelines {
			id
			name
			color
			type
		}
		user {
			id
			email
			location
			firstName
			lastName
			photoUrl
			skills
			linkedinLink
			resumeLink
			eduLevel
			roleFunction
			roleInterests
			profYear
			username
			hasSandbox
		}
		createdAt
	}
	${CoreChapterResultFieldsFragmentDoc}
	${ApplicantsScoreFieldsFragmentDoc}
`
export const CoreApplicantItemFieldsFragmentDoc = gql`
	fragment CoreApplicantItemFields on Applicant {
		id
		progress
		isBookmarked
		masking
		score {
			...ApplicantsScoreFields
		}
		pipelinesId
		pipelineType
		pipelines {
			id
			name
			color
			type
		}
		user {
			id
			firstName
			lastName
			photoUrl
		}
		createdAt
	}
	${ApplicantsScoreFieldsFragmentDoc}
`
export const CoreChapterFieldsFragmentDoc = gql`
	fragment CoreChapterFields on Chapter {
		id
		name
		desc
		timer
		parts {
			id
			name
		}
	}
`
export const MeDocument = gql`
	query me {
		me {
			...CoreAnalyticsUserFields
		}
	}
	${CoreAnalyticsUserFieldsFragmentDoc}
`

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(
	baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export function useMeLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>
export const GetProfileDocument = gql`
	query GetProfile {
		me {
			...CoreAnalyticsUserFields
		}
	}
	${CoreAnalyticsUserFieldsFragmentDoc}
`

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProfileQuery(
	baseOptions?: Apollo.QueryHookOptions<GetProfileQuery, GetProfileQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<GetProfileQuery, GetProfileQueryVariables>(
		GetProfileDocument,
		options
	)
}
export function useGetProfileLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetProfileQuery, GetProfileQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<GetProfileQuery, GetProfileQueryVariables>(
		GetProfileDocument,
		options
	)
}
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>
export type GetProfileLazyQueryHookResult = ReturnType<typeof useGetProfileLazyQuery>
export const SignInDocument = gql`
	mutation SignIn($userInput: SignInUserInput!) {
		signIn(userInput: $userInput) {
			token
			user {
				...CoreAnalyticsUserFields
			}
		}
	}
	${CoreAnalyticsUserFieldsFragmentDoc}
`
export type SignInMutationFn = Apollo.MutationFunction<
	SignInMutation,
	SignInMutationVariables
>

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      userInput: // value for 'userInput'
 *   },
 * });
 */
export function useSignInMutation(
	baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<SignInMutation, SignInMutationVariables>(
		SignInDocument,
		options
	)
}
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>
export type SignInMutationOptions = Apollo.BaseMutationOptions<
	SignInMutation,
	SignInMutationVariables
>
export const SignUpDocument = gql`
	mutation SignUp($userInput: SignUpUserInput!) {
		signUp(userInput: $userInput) {
			token
			user {
				...CoreAnalyticsUserFields
			}
		}
	}
	${CoreAnalyticsUserFieldsFragmentDoc}
`
export type SignUpMutationFn = Apollo.MutationFunction<
	SignUpMutation,
	SignUpMutationVariables
>

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      userInput: // value for 'userInput'
 *   },
 * });
 */
export function useSignUpMutation(
	baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(
		SignUpDocument,
		options
	)
}
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>
export type SignUpMutationOptions = Apollo.BaseMutationOptions<
	SignUpMutation,
	SignUpMutationVariables
>
export const UpdateUserDocument = gql`
	mutation UpdateUser($userInput: UpdateUserInput!) {
		updateUser(userInput: $userInput) {
			...CoreAnalyticsUserFields
		}
	}
	${CoreAnalyticsUserFieldsFragmentDoc}
`
export type UpdateUserMutationFn = Apollo.MutationFunction<
	UpdateUserMutation,
	UpdateUserMutationVariables
>

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      userInput: // value for 'userInput'
 *   },
 * });
 */
export function useUpdateUserMutation(
	baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
		UpdateUserDocument,
		options
	)
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
	UpdateUserMutation,
	UpdateUserMutationVariables
>
export const UpdateSubscriptionCancellationStatusDocument = gql`
	mutation UpdateSubscriptionCancellationStatus(
		$id: ID!
		$input: UpdateSubscriptionCancellationStatusInput!
	) {
		updateSubscriptionCancellationStatus(subscriptionId: $id, input: $input) {
			isCancellationAlertAcknowledged
			isCancellationMessageAcknowledged
		}
	}
`
export type UpdateSubscriptionCancellationStatusMutationFn = Apollo.MutationFunction<
	UpdateSubscriptionCancellationStatusMutation,
	UpdateSubscriptionCancellationStatusMutationVariables
>

/**
 * __useUpdateSubscriptionCancellationStatusMutation__
 *
 * To run a mutation, you first call `useUpdateSubscriptionCancellationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubscriptionCancellationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubscriptionCancellationStatusMutation, { data, loading, error }] = useUpdateSubscriptionCancellationStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubscriptionCancellationStatusMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateSubscriptionCancellationStatusMutation,
		UpdateSubscriptionCancellationStatusMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		UpdateSubscriptionCancellationStatusMutation,
		UpdateSubscriptionCancellationStatusMutationVariables
	>(UpdateSubscriptionCancellationStatusDocument, options)
}
export type UpdateSubscriptionCancellationStatusMutationHookResult = ReturnType<
	typeof useUpdateSubscriptionCancellationStatusMutation
>
export type UpdateSubscriptionCancellationStatusMutationOptions = Apollo.BaseMutationOptions<
	UpdateSubscriptionCancellationStatusMutation,
	UpdateSubscriptionCancellationStatusMutationVariables
>
export const UpdateUserTourStatusDocument = gql`
	mutation UpdateUserTourStatus($status: Boolean!) {
		updateUserTourStatus(status: $status)
	}
`
export type UpdateUserTourStatusMutationFn = Apollo.MutationFunction<
	UpdateUserTourStatusMutation,
	UpdateUserTourStatusMutationVariables
>

/**
 * __useUpdateUserTourStatusMutation__
 *
 * To run a mutation, you first call `useUpdateUserTourStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserTourStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserTourStatusMutation, { data, loading, error }] = useUpdateUserTourStatusMutation({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateUserTourStatusMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateUserTourStatusMutation,
		UpdateUserTourStatusMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		UpdateUserTourStatusMutation,
		UpdateUserTourStatusMutationVariables
	>(UpdateUserTourStatusDocument, options)
}
export type UpdateUserTourStatusMutationHookResult = ReturnType<
	typeof useUpdateUserTourStatusMutation
>
export type UpdateUserTourStatusMutationOptions = Apollo.BaseMutationOptions<
	UpdateUserTourStatusMutation,
	UpdateUserTourStatusMutationVariables
>
export const ChangePasswordDocument = gql`
	mutation ChangePassword($userInput: ChangePasswordInput!) {
		changePassword(userInput: $userInput)
	}
`
export type ChangePasswordMutationFn = Apollo.MutationFunction<
	ChangePasswordMutation,
	ChangePasswordMutationVariables
>

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      userInput: // value for 'userInput'
 *   },
 * });
 */
export function useChangePasswordMutation(
	baseOptions?: Apollo.MutationHookOptions<
		ChangePasswordMutation,
		ChangePasswordMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(
		ChangePasswordDocument,
		options
	)
}
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
	ChangePasswordMutation,
	ChangePasswordMutationVariables
>
export const AddPasswordDocument = gql`
	mutation AddPassword($password: String!) {
		addPassword(password: $password)
	}
`
export type AddPasswordMutationFn = Apollo.MutationFunction<
	AddPasswordMutation,
	AddPasswordMutationVariables
>

/**
 * __useAddPasswordMutation__
 *
 * To run a mutation, you first call `useAddPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPasswordMutation, { data, loading, error }] = useAddPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAddPasswordMutation(
	baseOptions?: Apollo.MutationHookOptions<
		AddPasswordMutation,
		AddPasswordMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<AddPasswordMutation, AddPasswordMutationVariables>(
		AddPasswordDocument,
		options
	)
}
export type AddPasswordMutationHookResult = ReturnType<typeof useAddPasswordMutation>
export type AddPasswordMutationOptions = Apollo.BaseMutationOptions<
	AddPasswordMutation,
	AddPasswordMutationVariables
>
export const SendPasswordResetUserDocument = gql`
	mutation SendPasswordResetUser($email: String!) {
		sendPasswordResetUser(email: $email)
	}
`
export type SendPasswordResetUserMutationFn = Apollo.MutationFunction<
	SendPasswordResetUserMutation,
	SendPasswordResetUserMutationVariables
>

/**
 * __useSendPasswordResetUserMutation__
 *
 * To run a mutation, you first call `useSendPasswordResetUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPasswordResetUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPasswordResetUserMutation, { data, loading, error }] = useSendPasswordResetUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendPasswordResetUserMutation(
	baseOptions?: Apollo.MutationHookOptions<
		SendPasswordResetUserMutation,
		SendPasswordResetUserMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		SendPasswordResetUserMutation,
		SendPasswordResetUserMutationVariables
	>(SendPasswordResetUserDocument, options)
}
export type SendPasswordResetUserMutationHookResult = ReturnType<
	typeof useSendPasswordResetUserMutation
>
export type SendPasswordResetUserMutationOptions = Apollo.BaseMutationOptions<
	SendPasswordResetUserMutation,
	SendPasswordResetUserMutationVariables
>
export const ResetPasswordUserDocument = gql`
	mutation ResetPasswordUser($userInput: ResetPasswordInput!) {
		resetPasswordUser(userInput: $userInput)
	}
`
export type ResetPasswordUserMutationFn = Apollo.MutationFunction<
	ResetPasswordUserMutation,
	ResetPasswordUserMutationVariables
>

/**
 * __useResetPasswordUserMutation__
 *
 * To run a mutation, you first call `useResetPasswordUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordUserMutation, { data, loading, error }] = useResetPasswordUserMutation({
 *   variables: {
 *      userInput: // value for 'userInput'
 *   },
 * });
 */
export function useResetPasswordUserMutation(
	baseOptions?: Apollo.MutationHookOptions<
		ResetPasswordUserMutation,
		ResetPasswordUserMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<ResetPasswordUserMutation, ResetPasswordUserMutationVariables>(
		ResetPasswordUserDocument,
		options
	)
}
export type ResetPasswordUserMutationHookResult = ReturnType<
	typeof useResetPasswordUserMutation
>
export type ResetPasswordUserMutationOptions = Apollo.BaseMutationOptions<
	ResetPasswordUserMutation,
	ResetPasswordUserMutationVariables
>
export const CheckPinUserDocument = gql`
	query CheckPinUser($email: String!, $pin: String!) {
		checkPinUser(email: $email, pin: $pin)
	}
`

/**
 * __useCheckPinUserQuery__
 *
 * To run a query within a React component, call `useCheckPinUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckPinUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckPinUserQuery({
 *   variables: {
 *      email: // value for 'email'
 *      pin: // value for 'pin'
 *   },
 * });
 */
export function useCheckPinUserQuery(
	baseOptions: Apollo.QueryHookOptions<CheckPinUserQuery, CheckPinUserQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<CheckPinUserQuery, CheckPinUserQueryVariables>(
		CheckPinUserDocument,
		options
	)
}
export function useCheckPinUserLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<CheckPinUserQuery, CheckPinUserQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<CheckPinUserQuery, CheckPinUserQueryVariables>(
		CheckPinUserDocument,
		options
	)
}
export type CheckPinUserQueryHookResult = ReturnType<typeof useCheckPinUserQuery>
export type CheckPinUserLazyQueryHookResult = ReturnType<typeof useCheckPinUserLazyQuery>
export const VerifyEmailDocument = gql`
	mutation VerifyEmail($userId: String!, $token: String!) {
		verifyEmail(userId: $userId, token: $token)
	}
`
export type VerifyEmailMutationFn = Apollo.MutationFunction<
	VerifyEmailMutation,
	VerifyEmailMutationVariables
>

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyEmailMutation(
	baseOptions?: Apollo.MutationHookOptions<
		VerifyEmailMutation,
		VerifyEmailMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(
		VerifyEmailDocument,
		options
	)
}
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>
export type VerifyEmailMutationOptions = Apollo.BaseMutationOptions<
	VerifyEmailMutation,
	VerifyEmailMutationVariables
>
export const ResendEmailVerificationDocument = gql`
	mutation ResendEmailVerification {
		resendEmailVerification
	}
`
export type ResendEmailVerificationMutationFn = Apollo.MutationFunction<
	ResendEmailVerificationMutation,
	ResendEmailVerificationMutationVariables
>

/**
 * __useResendEmailVerificationMutation__
 *
 * To run a mutation, you first call `useResendEmailVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendEmailVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendEmailVerificationMutation, { data, loading, error }] = useResendEmailVerificationMutation({
 *   variables: {
 *   },
 * });
 */
export function useResendEmailVerificationMutation(
	baseOptions?: Apollo.MutationHookOptions<
		ResendEmailVerificationMutation,
		ResendEmailVerificationMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		ResendEmailVerificationMutation,
		ResendEmailVerificationMutationVariables
	>(ResendEmailVerificationDocument, options)
}
export type ResendEmailVerificationMutationHookResult = ReturnType<
	typeof useResendEmailVerificationMutation
>
export type ResendEmailVerificationMutationOptions = Apollo.BaseMutationOptions<
	ResendEmailVerificationMutation,
	ResendEmailVerificationMutationVariables
>
export const GetApplicantDocument = gql`
	query GetApplicant($userId: ID!, $assessId: ID!) {
		applicant(userId: $userId, assessId: $assessId) {
			...ApplicantFields
		}
	}
	${ApplicantFieldsFragmentDoc}
`

/**
 * __useGetApplicantQuery__
 *
 * To run a query within a React component, call `useGetApplicantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicantQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      assessId: // value for 'assessId'
 *   },
 * });
 */
export function useGetApplicantQuery(
	baseOptions: Apollo.QueryHookOptions<GetApplicantQuery, GetApplicantQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<GetApplicantQuery, GetApplicantQueryVariables>(
		GetApplicantDocument,
		options
	)
}
export function useGetApplicantLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetApplicantQuery, GetApplicantQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<GetApplicantQuery, GetApplicantQueryVariables>(
		GetApplicantDocument,
		options
	)
}
export type GetApplicantQueryHookResult = ReturnType<typeof useGetApplicantQuery>
export type GetApplicantLazyQueryHookResult = ReturnType<typeof useGetApplicantLazyQuery>
export const GetApplicantsDocument = gql`
	query GetApplicants(
		$assessId: ID!
		$page: Int
		$filter: FilterApplicantGetManyInput
		$sort: EnumSortApplicantGetManyInput
		$type: EnumPipelineType
	) {
		applicants(
			assessId: $assessId
			page: $page
			filter: $filter
			sort: $sort
			type: $type
		) {
			items {
				...CoreApplicantItemFields
			}
			totalTalents {
				count
				employees
				candidates
			}
			pageInfo {
				currentPage
				perPage
				pageCount
				itemCount
				hasNextPage
				hasPreviousPage
			}
			count
		}
	}
	${CoreApplicantItemFieldsFragmentDoc}
`

/**
 * __useGetApplicantsQuery__
 *
 * To run a query within a React component, call `useGetApplicantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicantsQuery({
 *   variables: {
 *      assessId: // value for 'assessId'
 *      page: // value for 'page'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetApplicantsQuery(
	baseOptions: Apollo.QueryHookOptions<GetApplicantsQuery, GetApplicantsQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<GetApplicantsQuery, GetApplicantsQueryVariables>(
		GetApplicantsDocument,
		options
	)
}
export function useGetApplicantsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetApplicantsQuery, GetApplicantsQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<GetApplicantsQuery, GetApplicantsQueryVariables>(
		GetApplicantsDocument,
		options
	)
}
export type GetApplicantsQueryHookResult = ReturnType<typeof useGetApplicantsQuery>
export type GetApplicantsLazyQueryHookResult = ReturnType<typeof useGetApplicantsLazyQuery>
export const UpdateApplicantDocument = gql`
	mutation UpdateApplicant(
		$userId: ID!
		$assessId: ID!
		$applicantInput: UpdateApplicantInput!
	) {
		updateApplicant(assessId: $assessId, userId: $userId, applicantInput: $applicantInput) {
			id
			isBookmarked
			isShortlisted
			note
			user {
				id
				firstName
				lastName
				photoUrl
			}
			pipelinesId
			pipelineType
			pipelines {
				id
				name
				color
				type
			}
		}
	}
`
export type UpdateApplicantMutationFn = Apollo.MutationFunction<
	UpdateApplicantMutation,
	UpdateApplicantMutationVariables
>

/**
 * __useUpdateApplicantMutation__
 *
 * To run a mutation, you first call `useUpdateApplicantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicantMutation, { data, loading, error }] = useUpdateApplicantMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      assessId: // value for 'assessId'
 *      applicantInput: // value for 'applicantInput'
 *   },
 * });
 */
export function useUpdateApplicantMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateApplicantMutation,
		UpdateApplicantMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<UpdateApplicantMutation, UpdateApplicantMutationVariables>(
		UpdateApplicantDocument,
		options
	)
}
export type UpdateApplicantMutationHookResult = ReturnType<
	typeof useUpdateApplicantMutation
>
export type UpdateApplicantMutationOptions = Apollo.BaseMutationOptions<
	UpdateApplicantMutation,
	UpdateApplicantMutationVariables
>
export const DeleteApplicantDocument = gql`
	mutation DeleteApplicant($userId: ID!, $assessId: ID!) {
		deleteApplicant(userId: $userId, assessId: $assessId)
	}
`
export type DeleteApplicantMutationFn = Apollo.MutationFunction<
	DeleteApplicantMutation,
	DeleteApplicantMutationVariables
>

/**
 * __useDeleteApplicantMutation__
 *
 * To run a mutation, you first call `useDeleteApplicantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApplicantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApplicantMutation, { data, loading, error }] = useDeleteApplicantMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      assessId: // value for 'assessId'
 *   },
 * });
 */
export function useDeleteApplicantMutation(
	baseOptions?: Apollo.MutationHookOptions<
		DeleteApplicantMutation,
		DeleteApplicantMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<DeleteApplicantMutation, DeleteApplicantMutationVariables>(
		DeleteApplicantDocument,
		options
	)
}
export type DeleteApplicantMutationHookResult = ReturnType<
	typeof useDeleteApplicantMutation
>
export type DeleteApplicantMutationOptions = Apollo.BaseMutationOptions<
	DeleteApplicantMutation,
	DeleteApplicantMutationVariables
>
export const BulkAddTalentsDocument = gql`
	mutation BulkAddTalents($pipelineId: ID!, $users: JSON!) {
		bulkAddTalents(pipelineId: $pipelineId, users: $users)
	}
`
export type BulkAddTalentsMutationFn = Apollo.MutationFunction<
	BulkAddTalentsMutation,
	BulkAddTalentsMutationVariables
>

/**
 * __useBulkAddTalentsMutation__
 *
 * To run a mutation, you first call `useBulkAddTalentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkAddTalentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkAddTalentsMutation, { data, loading, error }] = useBulkAddTalentsMutation({
 *   variables: {
 *      pipelineId: // value for 'pipelineId'
 *      users: // value for 'users'
 *   },
 * });
 */
export function useBulkAddTalentsMutation(
	baseOptions?: Apollo.MutationHookOptions<
		BulkAddTalentsMutation,
		BulkAddTalentsMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<BulkAddTalentsMutation, BulkAddTalentsMutationVariables>(
		BulkAddTalentsDocument,
		options
	)
}
export type BulkAddTalentsMutationHookResult = ReturnType<typeof useBulkAddTalentsMutation>
export type BulkAddTalentsMutationOptions = Apollo.BaseMutationOptions<
	BulkAddTalentsMutation,
	BulkAddTalentsMutationVariables
>
export const GetAssessmentsDocument = gql`
	query GetAssessments($lastActive: Date) {
		assessments(lastActive: $lastActive) {
			id
			name
			status
			category {
				name
			}
			masking
			totalTalents
			newTalentsSinceLastLogin
			createdAt
			updatedAt
		}
	}
`

/**
 * __useGetAssessmentsQuery__
 *
 * To run a query within a React component, call `useGetAssessmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentsQuery({
 *   variables: {
 *      lastActive: // value for 'lastActive'
 *   },
 * });
 */
export function useGetAssessmentsQuery(
	baseOptions?: Apollo.QueryHookOptions<GetAssessmentsQuery, GetAssessmentsQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<GetAssessmentsQuery, GetAssessmentsQueryVariables>(
		GetAssessmentsDocument,
		options
	)
}
export function useGetAssessmentsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetAssessmentsQuery,
		GetAssessmentsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<GetAssessmentsQuery, GetAssessmentsQueryVariables>(
		GetAssessmentsDocument,
		options
	)
}
export type GetAssessmentsQueryHookResult = ReturnType<typeof useGetAssessmentsQuery>
export type GetAssessmentsLazyQueryHookResult = ReturnType<
	typeof useGetAssessmentsLazyQuery
>
export const GetAssessmentDocument = gql`
	query GetAssessment($id: MongoID!) {
		assessment(id: $id) {
			id
			name
			challengePack
			selectedRole
			selectedRoleDetails {
				id
				name
				benchmarks {
					id
					score
				}
			}
			pipelinesTotal
			totalTalents
			masking
			attempts
			fromSection
			status
			categoryId
			challengeId
			chaptersTotal
			pipelines {
				id
				name
				type
				color
				usersTotal
				users {
					id
					isCustom
					email
				}
				skills {
					score
					skill {
						id
						name
					}
				}
			}
			chapters {
				...CoreChapterFields
			}
		}
	}
	${CoreChapterFieldsFragmentDoc}
`

/**
 * __useGetAssessmentQuery__
 *
 * To run a query within a React component, call `useGetAssessmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssessmentQuery(
	baseOptions: Apollo.QueryHookOptions<GetAssessmentQuery, GetAssessmentQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<GetAssessmentQuery, GetAssessmentQueryVariables>(
		GetAssessmentDocument,
		options
	)
}
export function useGetAssessmentLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetAssessmentQuery, GetAssessmentQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<GetAssessmentQuery, GetAssessmentQueryVariables>(
		GetAssessmentDocument,
		options
	)
}
export type GetAssessmentQueryHookResult = ReturnType<typeof useGetAssessmentQuery>
export type GetAssessmentLazyQueryHookResult = ReturnType<typeof useGetAssessmentLazyQuery>
export const GetAssessmentChaptersDocument = gql`
	query GetAssessmentChapters($id: MongoID!) {
		assessment(id: $id) {
			id
			chaptersTotal
			chapters {
				...CoreChapterFields
			}
		}
	}
	${CoreChapterFieldsFragmentDoc}
`

/**
 * __useGetAssessmentChaptersQuery__
 *
 * To run a query within a React component, call `useGetAssessmentChaptersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssessmentChaptersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssessmentChaptersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssessmentChaptersQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetAssessmentChaptersQuery,
		GetAssessmentChaptersQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<GetAssessmentChaptersQuery, GetAssessmentChaptersQueryVariables>(
		GetAssessmentChaptersDocument,
		options
	)
}
export function useGetAssessmentChaptersLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetAssessmentChaptersQuery,
		GetAssessmentChaptersQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		GetAssessmentChaptersQuery,
		GetAssessmentChaptersQueryVariables
	>(GetAssessmentChaptersDocument, options)
}
export type GetAssessmentChaptersQueryHookResult = ReturnType<
	typeof useGetAssessmentChaptersQuery
>
export type GetAssessmentChaptersLazyQueryHookResult = ReturnType<
	typeof useGetAssessmentChaptersLazyQuery
>
export const UpdateAssessmentDocument = gql`
	mutation UpdateAssessment($assessId: ID!, $assessInput: UpdateAssessmentInput!) {
		updateAssessment(assessId: $assessId, assessInput: $assessInput) {
			id
			name
			masking
			attempts
			duration
			questionPicking
			fromSection
			status
		}
	}
`
export type UpdateAssessmentMutationFn = Apollo.MutationFunction<
	UpdateAssessmentMutation,
	UpdateAssessmentMutationVariables
>

/**
 * __useUpdateAssessmentMutation__
 *
 * To run a mutation, you first call `useUpdateAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssessmentMutation, { data, loading, error }] = useUpdateAssessmentMutation({
 *   variables: {
 *      assessId: // value for 'assessId'
 *      assessInput: // value for 'assessInput'
 *   },
 * });
 */
export function useUpdateAssessmentMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateAssessmentMutation,
		UpdateAssessmentMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<UpdateAssessmentMutation, UpdateAssessmentMutationVariables>(
		UpdateAssessmentDocument,
		options
	)
}
export type UpdateAssessmentMutationHookResult = ReturnType<
	typeof useUpdateAssessmentMutation
>
export type UpdateAssessmentMutationOptions = Apollo.BaseMutationOptions<
	UpdateAssessmentMutation,
	UpdateAssessmentMutationVariables
>
export const UpdateAssessmentMaskingDocument = gql`
	mutation UpdateAssessmentMasking($assessmentId: ID!, $masking: Boolean!) {
		updateAssessment(assessId: $assessmentId, assessInput: { masking: $masking }) {
			id
			masking
		}
	}
`
export type UpdateAssessmentMaskingMutationFn = Apollo.MutationFunction<
	UpdateAssessmentMaskingMutation,
	UpdateAssessmentMaskingMutationVariables
>

/**
 * __useUpdateAssessmentMaskingMutation__
 *
 * To run a mutation, you first call `useUpdateAssessmentMaskingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssessmentMaskingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssessmentMaskingMutation, { data, loading, error }] = useUpdateAssessmentMaskingMutation({
 *   variables: {
 *      assessmentId: // value for 'assessmentId'
 *      masking: // value for 'masking'
 *   },
 * });
 */
export function useUpdateAssessmentMaskingMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateAssessmentMaskingMutation,
		UpdateAssessmentMaskingMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		UpdateAssessmentMaskingMutation,
		UpdateAssessmentMaskingMutationVariables
	>(UpdateAssessmentMaskingDocument, options)
}
export type UpdateAssessmentMaskingMutationHookResult = ReturnType<
	typeof useUpdateAssessmentMaskingMutation
>
export type UpdateAssessmentMaskingMutationOptions = Apollo.BaseMutationOptions<
	UpdateAssessmentMaskingMutation,
	UpdateAssessmentMaskingMutationVariables
>
export const DeleteAssessmentDocument = gql`
	mutation DeleteAssessment($assessId: ID!) {
		deleteAssessment(assessId: $assessId)
	}
`
export type DeleteAssessmentMutationFn = Apollo.MutationFunction<
	DeleteAssessmentMutation,
	DeleteAssessmentMutationVariables
>

/**
 * __useDeleteAssessmentMutation__
 *
 * To run a mutation, you first call `useDeleteAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssessmentMutation, { data, loading, error }] = useDeleteAssessmentMutation({
 *   variables: {
 *      assessId: // value for 'assessId'
 *   },
 * });
 */
export function useDeleteAssessmentMutation(
	baseOptions?: Apollo.MutationHookOptions<
		DeleteAssessmentMutation,
		DeleteAssessmentMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<DeleteAssessmentMutation, DeleteAssessmentMutationVariables>(
		DeleteAssessmentDocument,
		options
	)
}
export type DeleteAssessmentMutationHookResult = ReturnType<
	typeof useDeleteAssessmentMutation
>
export type DeleteAssessmentMutationOptions = Apollo.BaseMutationOptions<
	DeleteAssessmentMutation,
	DeleteAssessmentMutationVariables
>
export const GetCategoriesDocument = gql`
	query GetCategories($filters: CategoryFilterInput, $orderBy: CategoryOrderByInput) {
		categories(filters: $filters, orderBy: $orderBy) {
			id
			name
			color
			img
		}
	}
`

/**
 * __useGetCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetCategoriesQuery(
	baseOptions?: Apollo.QueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(
		GetCategoriesDocument,
		options
	)
}
export function useGetCategoriesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(
		GetCategoriesDocument,
		options
	)
}
export type GetCategoriesQueryHookResult = ReturnType<typeof useGetCategoriesQuery>
export type GetCategoriesLazyQueryHookResult = ReturnType<typeof useGetCategoriesLazyQuery>
export const GetCategoryByIdDocument = gql`
	query GetCategoryById($id: MongoID) {
		categoryById(_id: $id) {
			id
			benchmarks {
				id
				score
				top5
			}
			challenges {
				id
				chapters {
					id
					name
					desc
					timer
					parts {
						id
						name
					}
				}
			}
		}
	}
`

/**
 * __useGetCategoryByIdQuery__
 *
 * To run a query within a React component, call `useGetCategoryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCategoryByIdQuery(
	baseOptions?: Apollo.QueryHookOptions<GetCategoryByIdQuery, GetCategoryByIdQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<GetCategoryByIdQuery, GetCategoryByIdQueryVariables>(
		GetCategoryByIdDocument,
		options
	)
}
export function useGetCategoryByIdLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetCategoryByIdQuery,
		GetCategoryByIdQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<GetCategoryByIdQuery, GetCategoryByIdQueryVariables>(
		GetCategoryByIdDocument,
		options
	)
}
export type GetCategoryByIdQueryHookResult = ReturnType<typeof useGetCategoryByIdQuery>
export type GetCategoryByIdLazyQueryHookResult = ReturnType<
	typeof useGetCategoryByIdLazyQuery
>
export const GetChaptersDocument = gql`
	query GetChapters($filters: ChapterFilterInput, $orderBy: ChapterOrderByInput) {
		chapters(filters: $filters, orderBy: $orderBy) {
			id
			name
			desc
			timer
			isSJT
			parts {
				id
				name
			}
		}
	}
`

/**
 * __useGetChaptersQuery__
 *
 * To run a query within a React component, call `useGetChaptersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChaptersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChaptersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetChaptersQuery(
	baseOptions?: Apollo.QueryHookOptions<GetChaptersQuery, GetChaptersQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<GetChaptersQuery, GetChaptersQueryVariables>(
		GetChaptersDocument,
		options
	)
}
export function useGetChaptersLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetChaptersQuery, GetChaptersQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<GetChaptersQuery, GetChaptersQueryVariables>(
		GetChaptersDocument,
		options
	)
}
export type GetChaptersQueryHookResult = ReturnType<typeof useGetChaptersQuery>
export type GetChaptersLazyQueryHookResult = ReturnType<typeof useGetChaptersLazyQuery>
export const GetChaptersByClustersDocument = gql`
	query GetChaptersByClusters($clusterIds: [ID!]!) {
		chaptersByClusters(clustersId: $clusterIds) {
			id
			name
			desc
			timer
			cluster {
				id
				name
			}
			parts {
				id
				name
			}
		}
	}
`

/**
 * __useGetChaptersByClustersQuery__
 *
 * To run a query within a React component, call `useGetChaptersByClustersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChaptersByClustersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChaptersByClustersQuery({
 *   variables: {
 *      clusterIds: // value for 'clusterIds'
 *   },
 * });
 */
export function useGetChaptersByClustersQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetChaptersByClustersQuery,
		GetChaptersByClustersQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<GetChaptersByClustersQuery, GetChaptersByClustersQueryVariables>(
		GetChaptersByClustersDocument,
		options
	)
}
export function useGetChaptersByClustersLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetChaptersByClustersQuery,
		GetChaptersByClustersQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		GetChaptersByClustersQuery,
		GetChaptersByClustersQueryVariables
	>(GetChaptersByClustersDocument, options)
}
export type GetChaptersByClustersQueryHookResult = ReturnType<
	typeof useGetChaptersByClustersQuery
>
export type GetChaptersByClustersLazyQueryHookResult = ReturnType<
	typeof useGetChaptersByClustersLazyQuery
>
export const CreateCheckoutSessionDocument = gql`
	mutation CreateCheckoutSession(
		$plans: [PlanInput!]!
		$successUrl: String!
		$cancelUrl: String!
	) {
		createCheckoutSession(plans: $plans, successUrl: $successUrl, cancelUrl: $cancelUrl)
	}
`
export type CreateCheckoutSessionMutationFn = Apollo.MutationFunction<
	CreateCheckoutSessionMutation,
	CreateCheckoutSessionMutationVariables
>

/**
 * __useCreateCheckoutSessionMutation__
 *
 * To run a mutation, you first call `useCreateCheckoutSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCheckoutSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCheckoutSessionMutation, { data, loading, error }] = useCreateCheckoutSessionMutation({
 *   variables: {
 *      plans: // value for 'plans'
 *      successUrl: // value for 'successUrl'
 *      cancelUrl: // value for 'cancelUrl'
 *   },
 * });
 */
export function useCreateCheckoutSessionMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreateCheckoutSessionMutation,
		CreateCheckoutSessionMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		CreateCheckoutSessionMutation,
		CreateCheckoutSessionMutationVariables
	>(CreateCheckoutSessionDocument, options)
}
export type CreateCheckoutSessionMutationHookResult = ReturnType<
	typeof useCreateCheckoutSessionMutation
>
export type CreateCheckoutSessionMutationOptions = Apollo.BaseMutationOptions<
	CreateCheckoutSessionMutation,
	CreateCheckoutSessionMutationVariables
>
export const GetClustersDocument = gql`
	query GetClusters {
		clusters {
			id
			name
			desc
		}
	}
`

/**
 * __useGetClustersQuery__
 *
 * To run a query within a React component, call `useGetClustersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClustersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClustersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClustersQuery(
	baseOptions?: Apollo.QueryHookOptions<GetClustersQuery, GetClustersQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<GetClustersQuery, GetClustersQueryVariables>(
		GetClustersDocument,
		options
	)
}
export function useGetClustersLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetClustersQuery, GetClustersQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<GetClustersQuery, GetClustersQueryVariables>(
		GetClustersDocument,
		options
	)
}
export type GetClustersQueryHookResult = ReturnType<typeof useGetClustersQuery>
export type GetClustersLazyQueryHookResult = ReturnType<typeof useGetClustersLazyQuery>
export const CreateAssessmentDocument = gql`
	mutation CreateAssessment(
		$assessmentInput: CreateAssessmentInput!
		$pipelineInput: CreatePipelineInput!
		$emailsToInvite: [String!]
	) {
		createAssessment(
			assessInput: $assessmentInput
			pipelineInput: $pipelineInput
			emailsToInvite: $emailsToInvite
		) {
			id
			name
			status
			category {
				name
			}
			challengePack
			selectedRole
			masking
			attempts
			totalTalents
			createdAt
			updatedAt
		}
	}
`
export type CreateAssessmentMutationFn = Apollo.MutationFunction<
	CreateAssessmentMutation,
	CreateAssessmentMutationVariables
>

/**
 * __useCreateAssessmentMutation__
 *
 * To run a mutation, you first call `useCreateAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssessmentMutation, { data, loading, error }] = useCreateAssessmentMutation({
 *   variables: {
 *      assessmentInput: // value for 'assessmentInput'
 *      pipelineInput: // value for 'pipelineInput'
 *      emailsToInvite: // value for 'emailsToInvite'
 *   },
 * });
 */
export function useCreateAssessmentMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreateAssessmentMutation,
		CreateAssessmentMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<CreateAssessmentMutation, CreateAssessmentMutationVariables>(
		CreateAssessmentDocument,
		options
	)
}
export type CreateAssessmentMutationHookResult = ReturnType<
	typeof useCreateAssessmentMutation
>
export type CreateAssessmentMutationOptions = Apollo.BaseMutationOptions<
	CreateAssessmentMutation,
	CreateAssessmentMutationVariables
>
export const GetEmailReminderSuggestionsDocument = gql`
	query GetEmailReminderSuggestions($pipelineId: ID!) {
		emailSuggestions: emailsToCompleteChallenge(pipelineId: $pipelineId)
	}
`

/**
 * __useGetEmailReminderSuggestionsQuery__
 *
 * To run a query within a React component, call `useGetEmailReminderSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailReminderSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailReminderSuggestionsQuery({
 *   variables: {
 *      pipelineId: // value for 'pipelineId'
 *   },
 * });
 */
export function useGetEmailReminderSuggestionsQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetEmailReminderSuggestionsQuery,
		GetEmailReminderSuggestionsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<
		GetEmailReminderSuggestionsQuery,
		GetEmailReminderSuggestionsQueryVariables
	>(GetEmailReminderSuggestionsDocument, options)
}
export function useGetEmailReminderSuggestionsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetEmailReminderSuggestionsQuery,
		GetEmailReminderSuggestionsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<
		GetEmailReminderSuggestionsQuery,
		GetEmailReminderSuggestionsQueryVariables
	>(GetEmailReminderSuggestionsDocument, options)
}
export type GetEmailReminderSuggestionsQueryHookResult = ReturnType<
	typeof useGetEmailReminderSuggestionsQuery
>
export type GetEmailReminderSuggestionsLazyQueryHookResult = ReturnType<
	typeof useGetEmailReminderSuggestionsLazyQuery
>
export const UserOrgDocument = gql`
	query UserOrg {
		me {
			org {
				name
				companySize
				industry
				photoUrl
			}
		}
	}
`

/**
 * __useUserOrgQuery__
 *
 * To run a query within a React component, call `useUserOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserOrgQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserOrgQuery(
	baseOptions?: Apollo.QueryHookOptions<UserOrgQuery, UserOrgQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<UserOrgQuery, UserOrgQueryVariables>(UserOrgDocument, options)
}
export function useUserOrgLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<UserOrgQuery, UserOrgQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<UserOrgQuery, UserOrgQueryVariables>(UserOrgDocument, options)
}
export type UserOrgQueryHookResult = ReturnType<typeof useUserOrgQuery>
export type UserOrgLazyQueryHookResult = ReturnType<typeof useUserOrgLazyQuery>
export const UpdateOrgDocument = gql`
	mutation UpdateOrg($orgInput: UpdateOrgInput!) {
		updateOrg(orgInput: $orgInput) {
			name
			companySize
			industry
			photoUrl
		}
	}
`
export type UpdateOrgMutationFn = Apollo.MutationFunction<
	UpdateOrgMutation,
	UpdateOrgMutationVariables
>

/**
 * __useUpdateOrgMutation__
 *
 * To run a mutation, you first call `useUpdateOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgMutation, { data, loading, error }] = useUpdateOrgMutation({
 *   variables: {
 *      orgInput: // value for 'orgInput'
 *   },
 * });
 */
export function useUpdateOrgMutation(
	baseOptions?: Apollo.MutationHookOptions<UpdateOrgMutation, UpdateOrgMutationVariables>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<UpdateOrgMutation, UpdateOrgMutationVariables>(
		UpdateOrgDocument,
		options
	)
}
export type UpdateOrgMutationHookResult = ReturnType<typeof useUpdateOrgMutation>
export type UpdateOrgMutationOptions = Apollo.BaseMutationOptions<
	UpdateOrgMutation,
	UpdateOrgMutationVariables
>
export const CreatePipelineDocument = gql`
	mutation CreatePipeline(
		$assessId: ID!
		$pipelineInput: CreatePipelineInput!
		$emailsToInvite: [String!]
	) {
		createPipeline(
			assessId: $assessId
			pipelineInput: $pipelineInput
			emailsToInvite: $emailsToInvite
		) {
			id
			name
			type
			color
			usersTotal
			users {
				id
				isCustom
				email
			}
		}
	}
`
export type CreatePipelineMutationFn = Apollo.MutationFunction<
	CreatePipelineMutation,
	CreatePipelineMutationVariables
>

/**
 * __useCreatePipelineMutation__
 *
 * To run a mutation, you first call `useCreatePipelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePipelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPipelineMutation, { data, loading, error }] = useCreatePipelineMutation({
 *   variables: {
 *      assessId: // value for 'assessId'
 *      pipelineInput: // value for 'pipelineInput'
 *      emailsToInvite: // value for 'emailsToInvite'
 *   },
 * });
 */
export function useCreatePipelineMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreatePipelineMutation,
		CreatePipelineMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<CreatePipelineMutation, CreatePipelineMutationVariables>(
		CreatePipelineDocument,
		options
	)
}
export type CreatePipelineMutationHookResult = ReturnType<typeof useCreatePipelineMutation>
export type CreatePipelineMutationOptions = Apollo.BaseMutationOptions<
	CreatePipelineMutation,
	CreatePipelineMutationVariables
>
export const DeletePipelineDocument = gql`
	mutation DeletePipeline($id: ID!) {
		deletePipeline(id: $id)
	}
`
export type DeletePipelineMutationFn = Apollo.MutationFunction<
	DeletePipelineMutation,
	DeletePipelineMutationVariables
>

/**
 * __useDeletePipelineMutation__
 *
 * To run a mutation, you first call `useDeletePipelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePipelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePipelineMutation, { data, loading, error }] = useDeletePipelineMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePipelineMutation(
	baseOptions?: Apollo.MutationHookOptions<
		DeletePipelineMutation,
		DeletePipelineMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<DeletePipelineMutation, DeletePipelineMutationVariables>(
		DeletePipelineDocument,
		options
	)
}
export type DeletePipelineMutationHookResult = ReturnType<typeof useDeletePipelineMutation>
export type DeletePipelineMutationOptions = Apollo.BaseMutationOptions<
	DeletePipelineMutation,
	DeletePipelineMutationVariables
>
export const UpdatePipelineDocument = gql`
	mutation UpdatePipeline($id: ID!, $pipelineInput: UpdatePipelineInput!) {
		updatePipeline(id: $id, pipelineInput: $pipelineInput) {
			id
			name
			color
		}
	}
`
export type UpdatePipelineMutationFn = Apollo.MutationFunction<
	UpdatePipelineMutation,
	UpdatePipelineMutationVariables
>

/**
 * __useUpdatePipelineMutation__
 *
 * To run a mutation, you first call `useUpdatePipelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePipelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePipelineMutation, { data, loading, error }] = useUpdatePipelineMutation({
 *   variables: {
 *      id: // value for 'id'
 *      pipelineInput: // value for 'pipelineInput'
 *   },
 * });
 */
export function useUpdatePipelineMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdatePipelineMutation,
		UpdatePipelineMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<UpdatePipelineMutation, UpdatePipelineMutationVariables>(
		UpdatePipelineDocument,
		options
	)
}
export type UpdatePipelineMutationHookResult = ReturnType<typeof useUpdatePipelineMutation>
export type UpdatePipelineMutationOptions = Apollo.BaseMutationOptions<
	UpdatePipelineMutation,
	UpdatePipelineMutationVariables
>
export const SendChallengeInviteDocument = gql`
	mutation SendChallengeInvite($assessId: ID!, $pipelineId: ID!, $emails: [String!]!) {
		sendChallengeInvite(assessId: $assessId, pipelineId: $pipelineId, emails: $emails)
	}
`
export type SendChallengeInviteMutationFn = Apollo.MutationFunction<
	SendChallengeInviteMutation,
	SendChallengeInviteMutationVariables
>

/**
 * __useSendChallengeInviteMutation__
 *
 * To run a mutation, you first call `useSendChallengeInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendChallengeInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendChallengeInviteMutation, { data, loading, error }] = useSendChallengeInviteMutation({
 *   variables: {
 *      assessId: // value for 'assessId'
 *      pipelineId: // value for 'pipelineId'
 *      emails: // value for 'emails'
 *   },
 * });
 */
export function useSendChallengeInviteMutation(
	baseOptions?: Apollo.MutationHookOptions<
		SendChallengeInviteMutation,
		SendChallengeInviteMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		SendChallengeInviteMutation,
		SendChallengeInviteMutationVariables
	>(SendChallengeInviteDocument, options)
}
export type SendChallengeInviteMutationHookResult = ReturnType<
	typeof useSendChallengeInviteMutation
>
export type SendChallengeInviteMutationOptions = Apollo.BaseMutationOptions<
	SendChallengeInviteMutation,
	SendChallengeInviteMutationVariables
>
export const GetPipelineScoringDocument = gql`
	query GetPipelineScoring($assessmentId: MongoID!) {
		assessment(id: $assessmentId) {
			id
			pipelines {
				id
				name
				color
				skills {
					score
					skill {
						id
						name
					}
				}
			}
			talents {
				items {
					id
					user {
						id
					}
					score {
						id
						skills {
							skillId
							score
						}
					}
				}
			}
		}
	}
`

/**
 * __useGetPipelineScoringQuery__
 *
 * To run a query within a React component, call `useGetPipelineScoringQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPipelineScoringQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPipelineScoringQuery({
 *   variables: {
 *      assessmentId: // value for 'assessmentId'
 *   },
 * });
 */
export function useGetPipelineScoringQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetPipelineScoringQuery,
		GetPipelineScoringQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useQuery<GetPipelineScoringQuery, GetPipelineScoringQueryVariables>(
		GetPipelineScoringDocument,
		options
	)
}
export function useGetPipelineScoringLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetPipelineScoringQuery,
		GetPipelineScoringQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useLazyQuery<GetPipelineScoringQuery, GetPipelineScoringQueryVariables>(
		GetPipelineScoringDocument,
		options
	)
}
export type GetPipelineScoringQueryHookResult = ReturnType<
	typeof useGetPipelineScoringQuery
>
export type GetPipelineScoringLazyQueryHookResult = ReturnType<
	typeof useGetPipelineScoringLazyQuery
>
export const SendEmailReminderDocument = gql`
	mutation SendEmailReminder($emails: [String!], $sendToAll: Boolean, $pipelineId: ID!) {
		sendEmailReminder(emails: $emails, sendToAll: $sendToAll, pipelineId: $pipelineId)
	}
`
export type SendEmailReminderMutationFn = Apollo.MutationFunction<
	SendEmailReminderMutation,
	SendEmailReminderMutationVariables
>

/**
 * __useSendEmailReminderMutation__
 *
 * To run a mutation, you first call `useSendEmailReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailReminderMutation, { data, loading, error }] = useSendEmailReminderMutation({
 *   variables: {
 *      emails: // value for 'emails'
 *      sendToAll: // value for 'sendToAll'
 *      pipelineId: // value for 'pipelineId'
 *   },
 * });
 */
export function useSendEmailReminderMutation(
	baseOptions?: Apollo.MutationHookOptions<
		SendEmailReminderMutation,
		SendEmailReminderMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<SendEmailReminderMutation, SendEmailReminderMutationVariables>(
		SendEmailReminderDocument,
		options
	)
}
export type SendEmailReminderMutationHookResult = ReturnType<
	typeof useSendEmailReminderMutation
>
export type SendEmailReminderMutationOptions = Apollo.BaseMutationOptions<
	SendEmailReminderMutation,
	SendEmailReminderMutationVariables
>
export const SendEmailReminderByIdDocument = gql`
	mutation SendEmailReminderById($id: ID!, $assessId: ID!) {
		sendEmailReminderById(id: $id, assessId: $assessId)
	}
`
export type SendEmailReminderByIdMutationFn = Apollo.MutationFunction<
	SendEmailReminderByIdMutation,
	SendEmailReminderByIdMutationVariables
>

/**
 * __useSendEmailReminderByIdMutation__
 *
 * To run a mutation, you first call `useSendEmailReminderByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailReminderByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailReminderByIdMutation, { data, loading, error }] = useSendEmailReminderByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      assessId: // value for 'assessId'
 *   },
 * });
 */
export function useSendEmailReminderByIdMutation(
	baseOptions?: Apollo.MutationHookOptions<
		SendEmailReminderByIdMutation,
		SendEmailReminderByIdMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions }
	return Apollo.useMutation<
		SendEmailReminderByIdMutation,
		SendEmailReminderByIdMutationVariables
	>(SendEmailReminderByIdDocument, options)
}
export type SendEmailReminderByIdMutationHookResult = ReturnType<
	typeof useSendEmailReminderByIdMutation
>
export type SendEmailReminderByIdMutationOptions = Apollo.BaseMutationOptions<
	SendEmailReminderByIdMutation,
	SendEmailReminderByIdMutationVariables
>
