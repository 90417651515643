import styled from 'styled-components'
import { Grid } from '@material-ui/core'

export const Container = styled(Grid)`
	background-color: ${props => props.theme.background.main};
	min-height: 100vh;
`

export const Content = styled(Grid)`
	background-color: ${props => props.theme.background.light};
	padding: 40px 60px;
	border-radius: 10px;
	max-width: 500px;
	max-height: 600px;
	border: ${props => `1px solid ${props.theme.border}`};

	@media only screen and (max-width: 600px) {
		width: 100%;
		min-height: 100vh;
		border-radius: 0;
		margin: 0;
		padding: 60px 15px;
	}
`
