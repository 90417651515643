import React from 'react'
import { Grid, Skeleton } from '@material-ui/core'

import { ProjectCardContainer } from './TeamCard.styles'

export const TeamCardSkeleton = () => (
	<ProjectCardContainer>
		<Grid container spacing={2}>
			<Grid container justifyContent="flex-end" item xs={12} spacing={1}>
				<Grid item>
					<Skeleton variant="circular" animation="wave" height={26} width={26} />
				</Grid>
			</Grid>
			<Grid item container alignItems="center">
				<Grid item xs={6}>
					<Skeleton animation="wave" style={{ fontSize: '1rem' }} />
				</Grid>
				<Grid container item xs={6} justifyContent="flex-end">
					<Grid item sx={{ mr: 0.4 }}>
						<Skeleton animation="wave" height={35} width={75} />
					</Grid>
					<Grid item>
						<Skeleton animation="wave" height={35} width={25} />
					</Grid>
				</Grid>
			</Grid>
			<Grid item container xs={12} alignItems="center" justifyContent="space-between">
				<Grid item xs={4}>
					<Skeleton animation="wave" style={{ fontSize: '1rem' }} />
				</Grid>
				<Grid item xs={4}>
					<Skeleton animation="wave" style={{ fontSize: '1rem' }} />
				</Grid>
			</Grid>
		</Grid>
	</ProjectCardContainer>
)

export default TeamCardSkeleton
