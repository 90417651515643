import { useMemo } from 'react'
import { useTheme } from 'styled-components'
import { useMediaQuery, Theme as MuiTheme } from '@material-ui/core'
import logoWhite from 'assets/images/logoWhite.png'
import logoDark from 'assets/images/logoDark.png'
import logoMiniDark from 'assets/images/logoMiniDark.png'
import logoMiniWhite from 'assets/images/logoMiniWhite.png'
import { Theme } from 'packages/nuggetai.ui-kit/themes/base'

type Size = 'large' | 'small'

export const useLogo = (baseSize?: Size, mode?: Theme['mode']): [string, Size] => {
	const theme = useTheme()
	const matches = useMediaQuery((theme: MuiTheme) => theme.breakpoints.down('md'))
	const size: Size = baseSize || (matches ? 'small' : 'large')
	return useMemo(
		() => [
			(mode || theme.mode) === 'light'
				? size === 'large'
					? logoDark
					: logoMiniDark
				: size === 'large'
				? logoWhite
				: logoMiniWhite,
			size
		],
		[mode, size, theme.mode]
	)
}

export default useLogo
