import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { write as copy } from 'clipboardy'
import iziToast from 'izitoast'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTypedController } from '@hookform/strictly-typed'
import useIntl from 'hooks/useIntl'
import { IDataHookResponse } from '@flatfile/adapter/build/main/obj.validation-response'

interface Option {
	label: string
	value: string
}

export interface AddTalentForm {
	talentGroup: Option | null
	teammateEmails?: string[]
}

export type IuseAddTalentForm = ReturnType<typeof useAddTalentForm>

export const useAddTalentForm = (pipeline?: Option) => {
	const intl = useIntl()
	const validationSchema = yup.object({
		talentGroup: yup
			.object()
			.shape({
				label: yup.string(),
				value: yup
					.string()
					.required(intl.formatMessage({ id: 'addTalent.talentGroup.error.required' }))
			})
			.required(intl.formatMessage({ id: 'addTalent.talentGroup.error.required' })),
		teammateEmails: yup.array().of(yup.string().email())
	})

	const {
		watch,
		control,
		errors,
		handleSubmit: onSubmit,
		formState,
		setValue
	} = useForm<AddTalentForm>({
		reValidateMode: 'onChange',
		resolver: yupResolver(validationSchema),
		defaultValues: {
			talentGroup: pipeline ?? null,
			teammateEmails: []
		}
	})

	const Controller = useTypedController<AddTalentForm>({ control })

	const validateRecord = (data, talentGroup, pipelines): IDataHookResponse => {
		const { email } = data
		if (talentGroup) {
			const pipeline = pipelines.find(pipeline => pipeline?.id === talentGroup)
			const exists = pipeline?.users?.some(user => email === user?.email && !user?.isCustom)
			if (exists) {
				return {
					email: {
						info: [
							{
								message: 'There is already an user with this email and cannot be edited',
								level: 'error'
							}
						]
					}
				}
			}
		}
		return {}
	}

	const handleCopy = async (link: string) => {
		await copy(link)
		iziToast.success({
			message: intl.formatMessage({ id: 'copiedLink' })
		})
	}

	const renderErrMessage = () => {
		const invalidEmails: Array<string | undefined> = []

		if (!errors) return []

		// @ts-ignore
		errors?.teammateEmails?.forEach(error => {
			invalidEmails.push(error?.message)
		})

		return intl.formatMessage(
			{ id: 'sendEmailReminder.error.invalid' },
			{ emails: invalidEmails.join(', ') }
		)
	}

	const models = { errors, watch, formState, setValue }
	const operations = { onSubmit, handleCopy, validateRecord, renderErrMessage }
	const components = { Controller }

	return [models, operations, components] as [
		typeof models,
		typeof operations,
		typeof components
	]
}
