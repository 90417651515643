import styled from 'styled-components'
import { Paper } from '@material-ui/core'

export const ProjectCardContainer = styled(Paper)`
	width: 100%;
	min-height: 145px;
	padding: 16px;
	border-radius: 10px;
	cursor: pointer;
	border: ${props => `1px solid ${props.theme.border}`};
`
