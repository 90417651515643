import { IStoreonModule } from 'storeon'
import { getTheme } from 'utils/getTheme'

export interface SettingsState {
	theme: 'dark' | 'light'
	loading: boolean
}

export interface SettingsEvents {
	'theme/switch': 'dark' | 'light' | void
	'loading/set': boolean
}

export const settingsModule: IStoreonModule = store => {
	store.on('@init', () => ({
		theme: getTheme(),
		loading: false
	}))
	store.on('@changed', (_state, data) => {
		if (data.theme) {
			if (data.theme === 'dark') {
				document.body.classList.add('dark')
			} else {
				document.body.classList.remove('dark')
			}
		}
	})
	store.on('loading/set', (_state, isLoading) => ({
		loading: isLoading
	}))
	store.on('theme/switch', (state, payload) => {
		const theme = payload || state.theme === 'dark' ? 'light' : 'dark'
		return {
			theme
		}
	})
}
