import React, { ReactNode } from 'react'
import { enLocales } from 'constants/locales/en'
import { ILocale } from 'constants/locales/ILocale'
import { useIntl as useBaseIntl, IntlShape } from 'react-intl'
import { Skeleton } from '@material-ui/core'

interface MessageDescriptor {
	id: keyof ILocale | string
	description?: string | object
}

export interface IIntlShape extends Omit<IntlShape, 'formatMessage'> {
	formatMessage(
		descriptor: MessageDescriptor,
		values?: Parameters<IntlShape['formatMessage']>[1]
	): string
	formatMessage(
		descriptor: MessageDescriptor,
		values?: Parameters<IntlShape['formatMessage']>[1]
	): ReactNode
}

export const useIntl = (): IIntlShape => {
	const { formatMessage, ...intl } = useBaseIntl()
	return {
		...intl,
		// @ts-ignore
		formatMessage: (
			descriptor: MessageDescriptor,
			values?: Parameters<IIntlShape['formatMessage']>[1]
		): ReturnType<IIntlShape['formatMessage']> =>
			formatMessage(
				{ ...descriptor, defaultMessage: enLocales[descriptor.id] },
				{
					bold: str => <strong>{str}</strong>,
					linebreak: <br />,
					skeleton: (
						<Skeleton animation={false} width={100} sx={{ display: 'inline-block' }} />
					),
					...values
				}
			)
	}
}

export type { ILocale }

export default useIntl
