import React, { useEffect, useRef, useState } from 'react'
import {
	Button,
	Dialog,
	DialogContent,
	Divider,
	Grid,
	Hidden,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
	Typography,
	useMediaQuery
} from '@material-ui/core'
import { Add as PlusIcon } from '@material-ui/icons'
import { useTheme } from 'styled-components'
import useIntl from 'hooks/useIntl'
import InfoTooltip from 'components/InfoTooltip'
import TalentGroupCard, {
	TalentGroupCardProps,
	TalentGroupSkeleton
} from 'components/TalentGroupCard'
import DeleteModal from 'components/DeleteModal'
import EditGroupModal from 'components/EditGroupModal'
import SendEmailReminderModal from 'components/SendEmailReminderModal'
import CreateTalentGroupModal from 'components/CreateTalenGroupModal'
import { EnumPipelineType, GetAssessmentQuery } from 'hooks/useApollo'

import { getAssessmentLink, getAssessmentQueryLink } from 'constants/config'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperClass from 'swiper/types/swiper-class'
import { TeamContextProps, useTeamContext } from 'screens/Teams/Teams.config'
import AddTalentModal from 'components/AddTalentModal'
import { useStoreon } from 'storeon/react'
import useUserSubscriptions from 'hooks/useUserSubscriptions'
import CustomArrow from './components/CustomArrow'
import { useTalentGroups } from './useTalentGroups'
import { slideSettings, slideMobileSettings } from './slideSettings'
import TalentGroupsFilter from './components/Filter'
import CloseIcon from '@material-ui/icons/Close'
export interface TalentGroupsProps {
	/**
	 * Defines the list groups inside a team
	 */
	groups: GetAssessmentQuery['assessment']['pipelines']
	/**
	 * Defines the loading state of the component
	 * @default false
	 */
	loading?: boolean
}

const teamSelector = (store: TeamContextProps) => ({
	assessment: store.assessment,
	teamId: store.teamId,
	demo: store.demo
})

export const TalentGroups: React.FC<TalentGroupsProps> = ({ groups, loading }) => {
	const intl = useIntl()
	const theme = useTheme()
	const { user } = useStoreon('user')
	const { assessment, teamId, demo } = useTeamContext(teamSelector)
	const handleNextSlide = useRef<() => void>(() => {})
	const handlePrevSlide = useRef<() => void>(() => {})
	const containerRef = useRef<HTMLDivElement>(null)
	const [controlledSwiper, setControlledSwiper] = useState<Pick<
		SwiperClass,
		'isBeginning' | 'isEnd'
	> | null>(null)
	const [open, setOpen] = useState(false)
	const [sectionDetails, setSectionDetails] = useState<
		{ sectionName: string; noOfQuestions: number; subSectionName: string | null }[]
	>([])

	const [totalQuestions, setTotalQuestions] = useState(0)

	const handleClose = () => {
		setOpen(false)
	}
	const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

	const [
		{ isOnBenchmarkingPlan, isOnHiringPlan, trialExpiredWithoutSubscription, isOnTrial }
	] = useUserSubscriptions()

	const [
		{ activeGroup, activeModal, formLoading, activeType, filteredGroups },
		{
			handleEdit,
			handleCreate,
			handleDelete,
			handleInvite,
			handleOpenEditModal,
			handleOpenDeleteModal,
			handleOpenCreateModal,
			handleOpenReminderModal,
			handleOpenInviteModal,
			handleCloseModal,
			handleCopy,
			handleActiveGroup,
			handleFilter
		}
	] = useTalentGroups(groups)

	useEffect(() => {
		if (!loading && filteredGroups) {
			handleFilter('All')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading])

	const totalTalents = assessment?.totalTalents ?? 0
	const totalGroups = assessment?.pipelinesTotal
	const attempts = assessment?.attempts ?? 0

	const handleClickOpen = () => {
		if (!assessment?.fromSection || !Array.isArray(assessment.fromSection)) {
			setTotalQuestions(0)
			setSectionDetails([])
			setOpen(true)
			return
		}

		// Create a mapping from API response
		const sectionMap = new Map(
			assessment.fromSection.map((sec: { sectionId: string; sectionName: string }) => [
				sec.sectionId,
				sec.sectionName
			])
		)

		// Map sections with their names and question counts
		const sectionDetails = assessment.fromSection.map(section => ({
			sectionName: sectionMap.get(section.sectionId) || '',
			noOfQuestions: section.noOfQuestions ?? 0,
			subSectionName: section.subSectionName ?? null // Include subSectionName if available
		}))

		// Calculate total question count
		const totalQuestions = sectionDetails.reduce(
			(acc, section) => acc + section.noOfQuestions,
			0
		)

		setTotalQuestions(totalQuestions)
		setSectionDetails(sectionDetails)
		setOpen(true)
	}
	const getDuration = section => {
		if (
			assessment?.chapters &&
			Array.isArray(assessment.chapters) &&
			assessment.chapters.length > 0
		) {
			return (
				assessment.chapters
					.map(chapter =>
						chapter?.timer ? `${(chapter.timer / 60).toFixed(4)} min` : null
					)
					.filter(Boolean)
					.join(', ') || 'N/A'
			)
		}
		return 'N/A'
	}

	const getTotalQuestions = section => totalQuestions

	const swiperSlideStyles: React.CSSProperties = {
		width: 240,
		display: 'flex',
		justifyContent: 'center'
	}

	const swiperMobileStyles: React.CSSProperties = {
		width: 240,
		marginLeft: 45,
		display: 'flex',
		justifyContent: 'center'
	}

	const filters = [
		{
			label: intl.formatMessage({ id: 'teams.talentGroups.filter.all' }),
			value: 'All'
		},
		{
			label: intl.formatMessage({ id: 'teams.talentGroups.filter.internal' }),
			value: 'Internal',
			disabled: !groups?.some(group => group?.type === 'Internal')
		},
		{
			label: intl.formatMessage({ id: 'teams.talentGroups.filter.external' }),
			value: 'External',
			disabled: !groups?.some(group => group?.type === 'External')
		}
	]

	const renderTalentGroups = (
		group: typeof groups[number],
		props?: Partial<TalentGroupCardProps>
	) => {
		// @ts-ignore
		const { id, name, usersTotal, color, type } = group

		const isBlocked = demo
			? false
			: isOnTrial
			? false
			: isOnBenchmarkingPlan && type === 'Internal'
			? false
			: !(isOnHiringPlan && type === 'External')

		return (
			<div id="team-tours-talent-card">
				<TalentGroupCard
					restricted={isBlocked}
					name={name}
					id={id}
					demo={demo}
					isSolo={groups.length === 1}
					color={color || theme.background.light}
					onDelete={handleOpenDeleteModal}
					onEdit={handleOpenEditModal}
					onReminder={handleOpenReminderModal}
					onInvite={handleOpenInviteModal}
					onMenuOpen={() => {
						handleActiveGroup({
							id,
							name,
							color,
							type
						})
					}}
					onCopy={() => {
						handleCopy(
							getAssessmentLink({
								assessId: teamId,
								demo,
								userId: user?.id as string,
								pipelineId: id
							}),
							name
						)
					}}
					challengeLinkDetails={{
						link: getAssessmentQueryLink({
							assessId: teamId,
							demo,
							userId: user?.id as string,
							pipelineId: id
						}),
						name: name
					}}
					count={usersTotal || 0}
					type={type}
					{...props}
				/>
			</div>
		)
	}

	return (
		<Grid container ref={containerRef} item spacing={3}>
			<Grid container item spacing={1}>
				<Grid container item alignItems="center">
					<Grid item>
						<Typography variant="h4" fontWeight={500}>
							{intl.formatMessage({ id: 'teams.talentGroup' })}
						</Typography>
					</Grid>
					<Grid item>
						<InfoTooltip info={intl.formatMessage({ id: 'tooltip.talentGroup' })} isTouch />
					</Grid>
				</Grid>
				<Grid container item spacing={2} alignItems="center">
					<Grid item>
						<Typography variant="paragraph3">
							Challenge type:
							<span style={{ fontWeight: 500 }}>{assessment?.challengePack}</span>
						</Typography>
					</Grid>
					<Grid item height="100%">
						<Divider orientation="vertical" />
					</Grid>
					<Grid item>
						<Typography variant="paragraph3">
							Total attempts:
							<span style={{ fontWeight: 500 }}> {attempts}</span>
						</Typography>
					</Grid>
					<Grid item height="100%">
						<Divider orientation="vertical" />
					</Grid>
					<Grid item>
						<Typography variant="paragraph3">
							{intl.formatMessage({ id: 'teams.totalTalent' }, { total: totalTalents })}
							<span style={{ fontWeight: 500 }}>{totalTalents}</span>
						</Typography>
					</Grid>
					<Grid item height="100%">
						<Divider orientation="vertical" />
					</Grid>

					<Grid item>
						<Typography variant="paragraph3">
							{intl.formatMessage({ id: 'teams.totalGroup' }, { total: totalGroups })}
							<span style={{ fontWeight: 500 }}>{totalGroups}</span>
						</Typography>
					</Grid>

					<Hidden smDown>
						<Grid item height="100%">
							<Divider orientation="vertical" />
						</Grid>
						<Grid item xs={12} sm="auto">
							<TalentGroupsFilter
								handleChangeFilter={event =>
									// @ts-ignore
									handleFilter(event.target.value as EnumPipelineType & 'All')
								}
								field={activeType}
								filters={filters}
							/>
						</Grid>
					</Hidden>
					<Grid item height="100%">
						<Divider orientation="vertical" />
					</Grid>
					<Grid item>
						<Typography variant="paragraph3">
							<Button onClick={handleClickOpen} color="primary">
								More Details
							</Button>
						</Typography>

						<Dialog
							open={open}
							onClose={handleClose}
							fullWidth
							maxWidth="md"
							PaperProps={{
								sx: {
									borderRadius: '8px',
									boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)'
								}
							}}
						>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									padding: '7px 18px',
									justifyContent: 'space-between',
									borderBottom: '1px solid #eaeaea',
									position: 'sticky',
									top: 0,

									zIndex: 1
								}}
							>
								<Typography
									variant="h6"
									sx={{
										fontSize: '17px',
										fontWeight: 'bold',
										color: '#333'
									}}
								>
									More Details
								</Typography>
								<IconButton onClick={handleClose} size="small" aria-label="close">
									<CloseIcon sx={{ fontSize: '20px' }} />
								</IconButton>
							</div>

							{/* Duration & Total Questions Summary */}
							<DialogContent sx={{ padding: '2px' }}>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										marginBottom: '6px',
										backgroundColor: '#f5f7fa',
										padding: '4px 10px',
										borderRadius: '4px'
									}}
								>
									<Typography sx={{ fontWeight: 'bold', fontSize: '15px' }}>
										Duration: {getDuration(sectionDetails[0])}
									</Typography>
									<Typography sx={{ fontWeight: 'bold', fontSize: '15px' }}>
										Total Questions: {getTotalQuestions(sectionDetails[0])}
									</Typography>
								</div>

								{/* Table */}
								<TableContainer sx={{ boxShadow: 'none', overflowX: 'auto' }}>
									<Table sx={{ minWidth: '600px' }}>
										<TableHead>
											<TableRow sx={{ backgroundColor: '#f5f7fa' }}>
												<TableCell style={{ fontWeight: 'bold', padding: '8px' }}>
													Section Name
												</TableCell>
												<TableCell style={{ fontWeight: 'bold', padding: '8px' }}>
													SubSection Name
												</TableCell>
												<TableCell style={{ fontWeight: 'bold', padding: '8px' }}>
													No. of Questions
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{sectionDetails.length > 0 &&
												sectionDetails.map((section, index) => (
													<TableRow
														key={index}
														sx={{
															backgroundColor: index % 2 === 0 ? '#ffffff' : '#f9fafc'
														}}
													>
														<TableCell style={{ wordBreak: 'break-word', padding: '8px' }}>
															{section.sectionName}
														</TableCell>
														<TableCell style={{ wordBreak: 'break-word', padding: '8px' }}>
															{section.subSectionName || '-'}
														</TableCell>
														<TableCell style={{ padding: '8px', textAlign: 'center' }}>
															{section.noOfQuestions || '-'}
														</TableCell>
													</TableRow>
												))}
										</TableBody>
									</Table>
								</TableContainer>
							</DialogContent>
						</Dialog>
					</Grid>
				</Grid>
				<Hidden smUp>
					<Grid item>
						<TalentGroupsFilter
							handleChangeFilter={event =>
								// @ts-ignore
								handleFilter(event.target.value as EnumPipelineType & 'All')
							}
							field={activeType}
							filters={filters}
						/>
					</Grid>
				</Hidden>
			</Grid>
			<Grid
				container
				item
				sx={{
					maxWidth: {
						xs: '92vw',
						md: '93vw',
						lg: '95vw'
					}
				}}
			>
				<Grid
					item
					xs={12}
					md={9}
					lg={10}
					style={{
						width: 'fit-content',
						flexBasis: 'unset'
					}}
				>
					{!loading ? (
						<>
							<Hidden smDown>
								<div
									className="custom-scrollbar"
									style={{ display: 'flex', overflowX: 'auto', paddingBottom: 20 }}
								>
									<Grid container wrap="nowrap" spacing={3}>
										{filteredGroups?.map((group, index) => (
											<Grid key={index} item>
												{renderTalentGroups(group, { style: { width: 300 } })}
											</Grid>
										))}
									</Grid>
								</div>
							</Hidden>
							<Hidden smUp>
								<Swiper
									{...slideMobileSettings}
									style={{
										paddingBottom: 20,
										width: 'calc(100vw + 20px)',
										marginLeft: -35
									}}
									onSlideChange={swiper => {
										setControlledSwiper({
											isBeginning: swiper.isBeginning,
											isEnd: swiper.isEnd
										})
									}}
									onSwiper={swiper => {
										setControlledSwiper({
											isBeginning: swiper.isBeginning,
											isEnd: swiper.isEnd
										})
										handleNextSlide.current = () => swiper.slideNext()
										handlePrevSlide.current = () => swiper.slidePrev()
									}}
								>
									{filteredGroups?.map((group, index) => (
										<SwiperSlide
											key={index}
											style={{ ...swiperSlideStyles, width: undefined }}
										>
											{renderTalentGroups(group)}
										</SwiperSlide>
									))}
									<CustomArrow
										onClick={handleNextSlide.current}
										ended={controlledSwiper?.isEnd}
										className="swiper-button-next"
										name="button next"
										aria-label="button next"
									/>
									<CustomArrow
										onClick={handlePrevSlide.current}
										ended={controlledSwiper?.isBeginning}
										direction="left"
										className="swiper-button-prev"
										name="button next"
										aria-label="button next"
									/>
								</Swiper>
							</Hidden>
						</>
					) : (
						<Swiper {...slideSettings}>
							<Grid container spacing={3}>
								{Array(smDown ? 1 : 5)
									.fill(null)
									.map((_, index) => {
										return (
											<SwiperSlide
												key={index}
												style={smDown ? swiperMobileStyles : swiperSlideStyles}
											>
												<TalentGroupSkeleton />
											</SwiperSlide>
										)
									})}
							</Grid>
						</Swiper>
					)}
				</Grid>
				<Hidden mdDown>
					<Grid
						display="flex"
						item
						alignItems="center"
						justifyContent="center"
						flexDirection="column"
						sx={{
							paddingLeft: {
								xs: '24px'
							}
						}}
					>
						<Button
							name="add talent group"
							aria-label="add talent group"
							id="team-tours-talent-card-add-button"
							color="primary"
							variant="contained"
							disabled={trialExpiredWithoutSubscription}
							sx={{
								borderRadius: '50%',
								width: '36px',
								height: '36px',
								marginBottom: 2,
								minWidth: '36px'
							}}
							onClick={handleOpenCreateModal}
						>
							<PlusIcon fontSize="small" />
						</Button>
						<Typography
							style={{
								color: trialExpiredWithoutSubscription
									? theme.background.summary
									: theme.primary.main
							}}
							align="center"
							variant="paragraph1"
						>
							{intl.formatMessage({ id: 'talentGroup.createNewGroup' })}
						</Typography>
					</Grid>
				</Hidden>
			</Grid>

			{activeModal === 'edit' && (
				<EditGroupModal
					id={activeGroup.id}
					loading={formLoading}
					name={activeGroup.name as string}
					color={activeGroup.color || theme.tag.white}
					type={activeGroup.type || undefined}
					open={activeModal === 'edit'}
					onClose={handleCloseModal}
					onEdit={handleEdit}
				/>
			)}
			<DeleteModal
				id={activeGroup.id}
				title={activeGroup.name as string}
				loading={formLoading}
				open={activeModal === 'delete'}
				onClose={handleCloseModal}
				onDelete={handleDelete}
			/>
			<SendEmailReminderModal
				id={activeGroup.id}
				open={activeModal === 'reminder'}
				onClose={handleCloseModal}
			/>
			<CreateTalentGroupModal
				id={teamId}
				demo={demo}
				loading={formLoading}
				link="https://www.nugget.ai"
				open={activeModal === 'create'}
				onClose={handleCloseModal}
				onCreate={handleCreate}
			/>
			{activeModal === 'invite' && (
				<AddTalentModal
					pipelines={groups}
					teamId={teamId}
					demo={demo}
					open={activeModal === 'invite'}
					onClose={handleCloseModal}
					getLink={pipelineId => {
						return getAssessmentLink({
							assessId: teamId,
							demo,
							userId: user?.id as string,
							pipelineId
						})
					}}
					defaultPipeline={activeGroup.id}
					onInvite={handleInvite}
				/>
			)}
		</Grid>
	)
}

export default React.memo(TalentGroups)
