import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
	flexRender,
	getCoreRowModel,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
	SortingState,
	ColumnFiltersState
} from '@tanstack/react-table'

import { DataTablePagination } from './DataTablePagination'
import SearchBox from './SearchBox'
import { CircularProgress, Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import { TableCellHeader } from 'components/RecordTable/RecordTable.styles'
import { TableHeader } from './Table'

export default function CustomTable({ loading, tableData, tableColumns }) {
	const [rowSelection, setRowSelection] = useState({})
	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
	const [sorting, setSorting] = useState<SortingState>([])
	const [globalFilter, setGlobalFilter] = useState('')

	const table = useReactTable({
		data: tableData || [],
		columns: tableColumns || [],
		state: {
			sorting,
			rowSelection,
			columnFilters,
			globalFilter
		},
		enableRowSelection: true,
		onRowSelectionChange: setRowSelection,
		onSortingChange: setSorting,
		onColumnFiltersChange: setColumnFilters,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		onGlobalFilterChange: setGlobalFilter,
		globalFilterFn: 'includesString'
	})

	return (
		<div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					gap: '16px'
				}}
			>
				<SearchBox
					placeholder="Search all columns..."
					inputValue={globalFilter ?? ''}
					handleSearch={e => setGlobalFilter(e.target.value)}
				/>

				<DataTablePagination table={table} />
			</div>

			<div
				style={{
					borderRadius: '8px',
					border: '1px solid #ddd',
					overflow: 'hidden'
				}}
			>
				<Table>
					<TableHeader>
						{table.getHeaderGroups().map(headerGroup => (
							<TableRow key={headerGroup.id}>
								{headerGroup.headers.map(header => (
									<TableCell colSpan={header.colSpan} style={{ fontWeight: 'bold' }}>
										{header.isPlaceholder
											? null
											: flexRender(header.column.columnDef.header, header.getContext())}
									</TableCell>
								))}
							</TableRow>
						))}
					</TableHeader>
					<TableBody>
						{table.getRowModel().rows?.length ? (
							table.getRowModel().rows.map(row => (
								<TableRow
									key={row.id}
									data-state={row.getIsSelected() && 'selected'}
									style={{
										backgroundColor: row.getIsSelected() ? '#e3f2fd' : 'white',
										transition: 'background-color 0.3s'
									}}
									// style={{
									// 	transition: 'background-color 0.3s' // Removed backgroundColor
									// }}
								>
									{row.getVisibleCells().map(cell => (
										<TableCell
											key={cell.id}
											style={{
												padding: '12px',
												borderBottom: '1px solid #ddd'
											}}
										>
											{flexRender(cell.column.columnDef.cell, cell.getContext())}
										</TableCell>
									))}
								</TableRow>
							))
						) : loading ? (
							<TableRow>
								<TableCell colSpan={tableColumns.length}>
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											padding: '20px'
										}}
									>
										<CircularProgress size={20} />
									</div>
								</TableCell>
							</TableRow>
						) : (
							<TableRow>
								<TableCell colSpan={tableColumns.length}>
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											height: '120px',
											color: '#777'
										}}
									>
										No data available
									</div>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</div>
		</div>
	)
}

CustomTable.propTypes = {
	loading: PropTypes.bool,
	tableData: PropTypes.arrayOf(PropTypes.object),
	tableColumns: PropTypes.arrayOf(PropTypes.object)
}
