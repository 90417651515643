import axios from 'axios'
import React, { useEffect, useState } from 'react'
import CustomTable from 'screens/CustomTable/CustomTable'
import { SectionsDataColumn } from './SectionsDataColumn'
import MiniDrawer from 'screens/DashboardMonitor/DashboardMonitor'

const Sections = () => {
	const [tableData, setTableData] = useState([])
	const [loading, setLoading] = useState(true)
	const userId = localStorage.getItem('userId')
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_URL}/api/v1/custom/sectionData/${userId}`
				)
				setTableData(response.data)
			} catch (error) {
				console.error('Error fetching data:', error)
			} finally {
				setLoading(false)
			}
		}

		fetchData()
	}, [])
	return (
		<div>
			<MiniDrawer />
			<CustomTable
				tableColumns={SectionsDataColumn}
				tableData={tableData}
				loading={loading}
			/>
		</div>
	)
}

export default Sections
