import React from 'react'
import { Card, CardContent, Button, Typography } from '@material-ui/core'
import PreviousAttempts from './PreviousAttempts'

const QuickActions = () => {
	return (
		<div>
			<Card>
				<CardContent>
					<Typography variant="h6" gutterBottom>
						Quick Actions
					</Typography>
					<div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
						<Button
							variant="contained"
							fullWidth
							style={{ backgroundColor: 'rgb(0, 196, 140)' }}
						>
							View Detailed Reports
						</Button>
						<Button variant="contained" fullWidth>
							Download Certificate
						</Button>
						<Button variant="outlined" fullWidth>
							Share Results
						</Button>
					</div>
				</CardContent>
			</Card>
			<div style={{ marginTop: '25px' }}>
				<PreviousAttempts />
			</div>
		</div>
	)
}

export default QuickActions
