import React from 'react'
import { Navigate } from 'react-router-dom'
import { PartialRouteObject } from 'react-router'
import { Login } from 'screens/Login'
import { ForgotPassword } from 'screens/ForgotPassword'

export const authRoutes: PartialRouteObject[] = [
	{
		path: 'login',
		element: <Login />
	},
	{
		path: 'password-reset',
		element: <ForgotPassword />
	},
	{
		path: '*',
		element: <Navigate to="/auth/login" />
	}
]
