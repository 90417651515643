import React from 'react'
import iziToast from 'izitoast'
import { Navigate } from 'react-router-dom'
import { useStoreon } from 'storeon/react'
import useIntl from 'hooks/useIntl'
import Onboarding from 'screens/Onboarding'

export interface OnboardingRouteHandlerProps {}

export const OnboardingRouteHandler = () => {
	const { user } = useStoreon('user')
	const intl = useIntl()

	if (!user?.isEmailVerified) {
		iziToast.error({ message: intl.formatMessage({ id: 'mailVerification.teams' }) })
		return <Navigate to="/" />
	}

	return <Onboarding />
}

export default OnboardingRouteHandler
