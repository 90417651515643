import React from 'react'
import { Grid, Typography, Button, Alert, Box } from '@material-ui/core'
import { useStoreon } from 'storeon/react'
import { useIntl } from 'react-intl'
import useUserSubscriptions from 'hooks/useUserSubscriptions'

interface Props {
	title: JSX.Element | string
	action?: JSX.Element
	actionTitle?: string
	onAction?: () => void
}

export const Header: React.FC<Props> = ({ title, actionTitle, action, onAction }) => {
	const { user } = useStoreon('user')
	const [{ isOnTrial }] = useUserSubscriptions()
	const intl = useIntl()
	return (
		<Grid container spacing={5} alignItems="center" mb={5}>
			<Grid item xs={12}>
				<Typography variant="h4" color="textPrimary" fontWeight={500}>
					{title}
				</Typography>
				{(user?.trialDetails?.daysRemaining || 0) <= 14 && isOnTrial ? (
					<>
						<br />
						{title === 'Billing' ? (
							<Alert variant="outlined" severity="info">
								{user?.trialDetails?.daysRemaining === 0 && !user?.trialDetails?.hasExpired
									? 'Your trial ends today'
									: `Your trail ends in ${user?.trialDetails?.daysRemaining} days.`}
							</Alert>
						) : null}
					</>
				) : null}
			</Grid>
			{actionTitle && (
				<Grid item xs={6} container justifyContent="flex-end">
					<Button name="action" variant="contained" onClick={onAction}>
						{actionTitle}
					</Button>
				</Grid>
			)}
			{action && (
				<Grid item xs={6} container justifyContent="flex-end">
					{action}
				</Grid>
			)}
		</Grid>
	)
}
export default Header
