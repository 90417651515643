import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'

import { useIntl } from 'hooks/useIntl'
import { useOnboardingStore } from 'screens/Onboarding/hooks/useOnboardingStore'
import { ContentWrapper } from 'screens/Onboarding/components/ContentWrapper'
import { CreationContent } from 'components/CreationContent'
import { Cluster, useGetClustersQuery } from 'hooks/useApollo'

import { useSkillSelectionStore } from './hooks/useSkillSelectionStore'
import { SkillSelectionInitialStep } from './containers/SkillSelectionInitialStep'
import { SkillSelectionSucceedingSteps } from './containers/SkillSelectionSucceedingSteps'

export const GuidedSkillsSelection = () => {
	const intl = useIntl()
	const { currentStep: currentRootStep } = useOnboardingStore(state => state)
	const { currentStep, setClusters } = useSkillSelectionStore(state => state)

	useGetClustersQuery({
		fetchPolicy: 'cache-and-network',
		onCompleted: ({ clusters }) => {
			// @ts-ignore
			const sortedCluster = [...clusters].sort((a, b) => (a.name < b.name ? -1 : 1))
			setClusters(sortedCluster as Cluster[])
		}
	})

	const title = intl.formatMessage({
		id: `onboarding.guided.challenges.skillSelection.title.${currentStep}`
	})

	const description = intl.formatMessage({
		id: `onboarding.guided.challenges.skillSelection.description.${currentStep}`
	})

	useEffect(() => {
		window.scroll({ top: 0 })
	}, [currentStep])

	const renderStep = () => {
		if (currentStep === 0) {
			return <SkillSelectionInitialStep />
		}

		return <SkillSelectionSucceedingSteps />
	}

	return (
		<>
			<ContentWrapper currentStep={currentRootStep}>
				<CreationContent
					subHeader
					title={title}
					desc={description}
					subTitle={intl.formatMessage({ id: 'onboarding.select.guided.challenges.title' })}
				>
					<Grid container spacing={7}>
						{renderStep()}
					</Grid>
				</CreationContent>
			</ContentWrapper>
		</>
	)
}

export default GuidedSkillsSelection
