import { ColumnDef } from '@tanstack/react-table'
import React from 'react'

interface Sections {
	sectionId: number
	sectionName: string
	count: number
}

export const SectionsDataColumn: ColumnDef<Sections>[] = [
	{
		accessorKey: 'sectionName',
		header: 'Section Name',
		cell: ({ row }) => <div className="w-[120px]">{row.getValue('sectionName')}</div>,
		size: 120
	},
	{
		accessorKey: 'count',
		header: 'No of Questions',
		cell: ({ row }) => <div className="w-[100px]">{row.getValue('count')}</div>,
		size: 100
	}
	// {
	// 	accessorKey: 'subSectionName',
	// 	header: 'SubSection Name',
	// 	cell: ({ row }) => <div className="w-[100px]">{row.getValue('subSectionName')}</div>,
	// 	size: 100
	// },
	// {
	// 	accessorKey: 'count',
	// 	header: 'No of Questions',
	// 	cell: ({ row }) => <div className="w-[100px]">{row.getValue('count')}</div>,
	// 	size: 100
	// }
]
