/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useEffect, useMemo, useState } from 'react'
import { Button, Grid, Hidden, Typography } from '@material-ui/core'
import { Add as PlusIcon } from '@material-ui/icons'
import { useNavigate, Link } from 'react-router-dom'
import { useTheme } from 'styled-components'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'

import ClearIcon from '@material-ui/icons/Clear'
import { matchSorter } from 'match-sorter'
import { compareDesc, parseISO } from 'date-fns'
import useIntl from 'hooks/useIntl'
import SearchInput from 'components/SearchInput'
import FilterItem from 'components/FilterItem'
import TeamCard from 'components/TeamCard'
import TeamCardSkeleton from 'components/TeamCard/TeamCard.skeleton'
import useUserSubscriptions from 'hooks/useUserSubscriptions'
import EditModal from 'components/EditModal'
import EditAttempts from 'components/EditAttempts/EditAttempts'
import DeleteModal from 'components/DeleteModal'
import useSearchFilter from 'hooks/useFilter/useSearchFilter'
import statuses from 'data/teamStatus.json'
import { useGetAssessmentsQuery } from 'hooks/useApollo'
import useToggle from 'hooks/useToggle'
import FilterModal from 'components/FilterModal'
import { pxToRem } from 'packages/nuggetai.ui-kit/themes/utils'
import WithSpeechBubble from 'components/WithSpeechBubble'
import { useMain } from './useMain'
import { ButtonGrid } from './Main.style'
import { useWebSocket } from 'hooks/useSocket'
import { useStoreon } from 'storeon/react'
import { useGlobalWebSocket } from 'context/WebSocketContext'

export const MainDashboard = () => {
	const intl = useIntl()
	const theme = useTheme()
	const navigate = useNavigate()
	const [search, setSearch] = useState('')
	const [{ open }, { handleOpen, handleClose }] = useToggle()
	const [{ trialExpiredWithoutSubscription }] = useUserSubscriptions()

	const { data, loading, refetch } = useGetAssessmentsQuery({
		variables: {
			lastActive: localStorage.getItem('lastActive')
		}
	})

	const { registerRefetch } = useGlobalWebSocket()

	useEffect(() => {
		const unregister = registerRefetch(refetch)
		return () => unregister()
	}, [registerRefetch, refetch])

	const [
		{ activeAssessment, activeModal, formLoading, activeAttemptsModal },
		{
			handleEdit,
			handleAttemptsEdit,
			handleDelete,
			handleMasking,
			handleCloseModal,
			handleCloseAttemptsModal,
			handleChangeStatus,
			handleOpenEditModal,
			handleOpenEditAttemptsModal,
			handleOpenDeleteModal,
			handleActiveAssessment
		}
	] = useMain()

	const { checkoutFilter, items, onClick, clearFilters } = useSearchFilter({
		status: {
			label: intl.formatMessage({ id: 'status' }),
			options: statuses.map(status => status.label)
		}
	})

	const assessments = useMemo(() => {
		const searchedData = !data?.assessments.length
			? []
			: matchSorter(data.assessments, search, {
					keys: ['name']
			  })
		const statuses = (checkoutFilter.status || []).map(status => status.toLowerCase())

		return (statuses.length > 0
			? searchedData.filter(assessment => statuses.includes(assessment.status as string))
			: searchedData
		).sort((a, b) => compareDesc(parseISO(a.updatedAt), parseISO(b.updatedAt)))
	}, [data, search, checkoutFilter.status])

	return (
		<>
			<Grid container spacing={4}>
				<Grid
					item
					container
					justifyContent="space-between"
					spacing={4}
					direction="row-reverse"
				>
					<Grid container item justifyContent="flex-end" xs={12} md={6}>
						<ButtonGrid item xs={12} sm={5} lg={4} sx={{ maxWidth: { sm: '200px' } }}>
							<WithSpeechBubble
								show={trialExpiredWithoutSubscription}
								text={intl.formatMessage({ id: 'dashboard.welcome.box.trialEnded' })}
								btnLabel={intl.formatMessage({ id: 'activatePlan' })}
								onClick={() => navigate('/settings/billing')}
							>
								<Button
									size="medium"
									name="createTeam"
									disabled={trialExpiredWithoutSubscription}
									variant="contained"
									fullWidth
									onClick={() => navigate('/onboarding/challenges-selection')}
									startIcon={
										trialExpiredWithoutSubscription ? (
											<LockOutlinedIcon sx={{ fill: theme.background.summary }} />
										) : (
											<PlusIcon />
										)
									}
									style={{ fontSize: pxToRem(14) }}
								>
									{intl.formatMessage({ id: 'dashboard.welcome.box.buttonText' })}
								</Button>
							</WithSpeechBubble>
						</ButtonGrid>
					</Grid>
					<Grid container item xs={12} md={6} spacing={2}>
						<Grid item xs={12}>
							<SearchInput
								containerStyle={{ height: 38, width: 326 }}
								value={search}
								placeholder={intl.formatMessage({ id: 'teams.searchGroupPlaceholder' })}
								onChange={event => setSearch(event.target.value)}
							/>
						</Grid>
						<Hidden mdUp>
							<Grid item>
								<Button name="filters" onClick={handleOpen}>
									<Typography>{intl.formatMessage({ id: 'filters' })}</Typography>
								</Button>
							</Grid>
						</Hidden>
						<Grid container item justifyContent="space-between" xs={12}>
							<Hidden mdDown>
								<Grid item xs={12} md={8}>
									{items.map(({ key, label, count }) => (
										<FilterItem
											onClick={onClick(key)}
											key={key}
											label={label}
											count={count}
										/>
									))}
								</Grid>
							</Hidden>
							{checkoutFilter.status?.length > 0 && (
								<Grid item>
									<Hidden mdDown>
										<Button
											name="clearFilters"
											onClick={clearFilters}
											color="secondary"
											size="small"
											startIcon={<ClearIcon />}
										>
											{intl.formatMessage({ id: 'clear' })}
										</Button>
									</Hidden>
								</Grid>
							)}
						</Grid>
					</Grid>
				</Grid>
				<Grid container item spacing={8}>
					{!loading
						? assessments.map(
								(
									{ id, name, totalTalents, masking, status, newTalentsSinceLastLogin },
									index
								) => (
									<Grid key={index} container item xs={12} sm={6} lg={3}>
										<Grid item xs={12}>
											<Link
												to={`teams/${id}`}
												onClick={() => {
													window.scrollTo({
														top: 0
													})
												}}
											>
												<TeamCard
													id={id}
													count={totalTalents}
													status={status || 'inactive'}
													newCounts={newTalentsSinceLastLogin || 0}
													masking={masking ?? false}
													onMask={handleMasking}
													onEdit={handleOpenEditModal}
													onEditAttempts={handleOpenEditAttemptsModal}
													onDelete={handleOpenDeleteModal}
													onMenuOpen={() => handleActiveAssessment({ id, name, masking })}
													name={name || ''}
													onStatusChange={handleChangeStatus}
												/>
											</Link>
										</Grid>
									</Grid>
								)
						  )
						: Array(12)
								.fill(null)
								.map((_, index) => (
									<Grid key={index} container item xs={12} sm={6} lg={3}>
										<Grid item xs={12}>
											<TeamCardSkeleton />
										</Grid>
									</Grid>
								))}
				</Grid>
			</Grid>
			{/* rendering conditionally to avoid stale data */}
			{activeModal === 'edit' && (
				<EditModal
					field="name"
					loading={formLoading}
					id={activeAssessment.id || ''}
					value={activeAssessment.name || ''}
					open={activeModal === 'edit'}
					onClose={handleCloseModal}
					onFinish={handleEdit}
				/>
			)}
			{/* Dashboad Card attempts Code props  */}
			{activeAttemptsModal === 'edit' && (
				<EditAttempts
					field="Attempts"
					loading={formLoading}
					id={activeAssessment.id || ''}
					attempts={Number(activeAssessment.attempts)}
					open={activeAttemptsModal === 'edit'}
					onClose={handleCloseAttemptsModal}
					onFinish={handleAttemptsEdit}
				/>
			)}
			<DeleteModal
				loading={formLoading}
				id={activeAssessment.id || ''}
				title={activeAssessment.name || ''}
				open={activeModal === 'delete'}
				onClose={handleCloseModal}
				onDelete={handleDelete}
			/>
			<FilterModal open={open} onClose={handleClose} />
		</>
	)
}

export default MainDashboard
