import React from 'react'
import { useTheme } from 'styled-components'
import { Grid, Typography, Box, Theme, useMediaQuery } from '@material-ui/core'
import useIntl from 'hooks/useIntl'
import { convertDuration } from 'utils/time'
import ButtonLink from 'components/ButtonLink'
import { Card } from './ChallengeCard.styles'
import ChallengeStatus from './components/ChallengeStatus'

export interface ChallengeCardProps {
	/**
	 * Defines the name of the challenge
	 */
	label: string
	/**
	 * Defines the description of the challenge
	 */
	desc: string
	/**
	 * If true, sets the card to preview mode
	 * @default false
	 */
	preview?: boolean
	/**
	 * Props applied to ChallengeStatus component
	 * If present, the ChallengeStatus will be shown.
	 */
	status?: string
	/**
	 * Defines the duration of the challenge in mins
	 */
	duration?: number
	/**
	 * Callback fired when the view submission button is clicked.
	 */
	onClick?: React.EventHandler<any>
	/**
	 * Link to preview
	 */
	linkToPreview?: string
	parts?: number
}

const mapStatus = (status?: string) => {
	if (!status) return

	if (status.toLowerCase() === 'completed') {
		return 'completed'
	}

	if (status.toLowerCase() === 'incomplete') {
		return 'incomplete'
	}
}

export const ChallengeCard: React.FC<ChallengeCardProps> = ({
	label,
	desc,
	status,
	duration,
	linkToPreview,
	preview,
	onClick
}) => {
	const intl = useIntl()
	const theme = useTheme()
	const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))

	const challengeStatus = mapStatus(status)

	return (
		<Card>
			<Grid container spacing={2}>
				<Grid container item xs={12} alignItems={smUp ? 'center' : 'flex-start'}>
					<Grid
						item
						container
						spacing={1}
						xs={8}
						sm
						alignItems={smUp ? 'center' : 'flex-start'}
					>
						<Grid item>
							<Typography variant="h5" fontWeight={500}>
								{label}
							</Typography>
						</Grid>
						{challengeStatus && (
							<Grid item xs>
								<ChallengeStatus status={challengeStatus} />
							</Grid>
						)}
					</Grid>
					{duration && (
						<Grid
							item
							xs={4}
							sm
							justifySelf="flex-end"
							alignSelf={smUp ? 'flex-end' : 'flex-start'}
						>
							<Typography variant="paragraph1" textAlign="right">
								{!preview && (
									<>
										{convertDuration(duration || 0)}{' '}
										{intl.formatMessage({
											id: 'spent'
										})}
										<br />
									</>
								)}
							</Typography>
						</Grid>
					)}
				</Grid>
				<Grid item xs={12}>
					<Grid
						container
						{...{
							justifyContent: smUp ? 'space-between' : undefined,
							direction: smUp ? undefined : 'column',
							alignItems: smUp ? 'center' : 'flex-start',
							spacing: smUp ? undefined : 2
						}}
					>
						<Grid item xs={12} md={8}>
							<Typography variant="paragraph1" sx={{ color: theme.text.description }}>
								{desc}
							</Typography>
						</Grid>
						<Grid item>
							<Box sx={{ mr: smUp ? '-5px' : undefined, ml: smUp ? undefined : '-5px' }}>
								{preview ? (
									// @ts-ignore
									<a href={linkToPreview} target="_blank" rel="noopener noreferrer">
										<ButtonLink name="preview">
											{intl.formatMessage({ id: 'preview' })}
										</ButtonLink>
									</a>
								) : (
									<ButtonLink onClick={onClick} name="viewSubmission">
										{intl.formatMessage({ id: 'viewSubmission' })}
									</ButtonLink>
								)}
							</Box>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Card>
	)
}

export default ChallengeCard
