import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import iziToast from 'izitoast'
import {
	useUpdateAssessmentMutation,
	useUpdateAssessmentMaskingMutation,
	useDeleteAssessmentMutation,
	Assessment,
	EnumAssessmentStatus
} from 'hooks/useApollo'

export type IuseMain = ReturnType<typeof useMain>

type ActiveAssessment = Pick<Assessment, 'id' | 'masking' | 'name' | 'attempts'>

export const useMain = () => {
	const navigate = useNavigate()
	const [formLoading, setFormLoading] = useState(false)
	const [activeModal, setActiveModal] = useState<'edit' | 'delete' | null>(null)
	const [activeAttemptsModal, setActiveAttemptsModal] = useState<'edit' | 'delete' | null>(
		null
	)
	const [activeAssessment, setActiveAssessment] = useState<ActiveAssessment>({
		id: '',
		name: '',
		masking: false,
		attempts: 0
	})

	const [updateAssessment] = useUpdateAssessmentMutation({
		onCompleted: data => {
			setFormLoading(false)
			setActiveModal(null)
			if (data.updateAssessment?.id) {
				iziToast.success({
					message: `${activeAssessment.name} team updated successfully.`
				})
			}
		},
		onError: () => {
			setFormLoading(false)
		}
	})

	const [updateAssessmentMasking] = useUpdateAssessmentMaskingMutation({
		onCompleted: () => {
			setFormLoading(false)
			setActiveModal(null)
		},
		onError: () => {
			setFormLoading(false)
		}
	})

	const [deleteAssessment] = useDeleteAssessmentMutation({
		onCompleted: data => {
			setFormLoading(false)
			setActiveModal(null)
			if (data.deleteAssessment) {
				navigate('/', { replace: true })

				iziToast.success({
					message: `${activeAssessment.name} team deleted successfully.`
				})
			}
		},
		onError: () => {
			setFormLoading(false)
		},
		update(cache) {
			cache.modify({
				fields: {
					assessments(existingAssessmentRefs = [], { readField }) {
						return existingAssessmentRefs.filter(
							assementRef => activeAssessment.id !== readField('id', assementRef)
						)
					}
				}
			})
		}
	})

	const handleActiveAssessment = (payload: ActiveAssessment) => {
		setActiveAssessment(payload)
	}

	const handleOpenEditModal = () => {
		setActiveModal('edit')
	}
	const handleOpenEditAttemptsModal = () => {
		setActiveAttemptsModal('edit')
	}
	const handleOpenDeleteModal = () => {
		setActiveModal('delete')
	}

	const handleCloseModal = () => {
		setActiveModal(null)
	}
	const handleCloseAttemptsModal = () => {
		setActiveAttemptsModal(null)
	}
	const handleCloseAttemptsAnchor = () => {
		setActiveAttemptsModal(null)
	}
	const handleEdit = (id: string, name: string) => {
		setFormLoading(true)
		updateAssessment({
			variables: { assessId: id, assessInput: { name } },
			optimisticResponse: {
				updateAssessment: { id, name }
			}
		})
	}

	const handleAttemptsEdit = (id: string, name: string, attempts: number) => {
		setFormLoading(true)
		updateAssessment({
			variables: { assessId: id, assessInput: { attempts } },
			optimisticResponse: {
				updateAssessment: { id, name, attempts }
			}
		})
	}

	const handleChangeStatus = (id: string, status: EnumAssessmentStatus) => {
		setFormLoading(true)
		updateAssessment({
			variables: { assessId: id, assessInput: { status } }
		})
	}

	const handleMasking = (id: string, masking: boolean) => {
		setFormLoading(true)
		updateAssessmentMasking({
			variables: { assessmentId: id, masking },
			optimisticResponse: {
				updateAssessment: { id, masking }
			}
		})
	}

	const handleDelete = (id: string) => {
		setFormLoading(true)
		deleteAssessment({ variables: { assessId: id } })
	}

	const models = { activeModal, activeAssessment, formLoading, activeAttemptsModal }
	const operations = {
		handleEdit,
		handleDelete,
		handleAttemptsEdit,
		handleMasking,
		handleCloseModal,
		handleChangeStatus,
		handleCloseAttemptsAnchor,
		handleOpenEditAttemptsModal,
		handleCloseAttemptsModal,
		handleOpenEditModal,
		handleOpenDeleteModal,
		handleActiveAssessment
	}

	return [models, operations] as [typeof models, typeof operations]
}
