import React, { RefObject, useEffect, useState } from 'react'
import useDynamicRefs from 'hooks/useDynamicRefs'
import { PinInputContainer } from './PinInput.style'

const KEY_CODE = {
	backspace: 8,
	left: 37,
	up: 38,
	right: 39,
	down: 40
}

export interface PinInputProps
	extends Omit<React.ComponentPropsWithoutRef<'input'>, 'placeholder' | 'onChange'> {
	type?: 'text' | 'number'
	onChange?: (val: string) => void
	onComplete?: (val: string) => void
	fields?: number
	fieldWidth?: number
	fieldHeight?: number
	fullWidth?: boolean
	border?: 'all' | 'bottom'
	containerProps?: React.ComponentPropsWithoutRef<'div'>
	/**
	 * The max width of the container
	 */
	maxWidth?: number | string
	/**
	 * The max width of the input that should just be used with the fullWidth and border bottom
	 */
	maxWidthInput?: number | string
	values?: string[]
	placeholder?: string[]
}

export const PinInput = ({
	type = 'number',
	fields = 4,
	fieldWidth = 58,
	fieldHeight = 52.56,
	border = 'all',
	placeholder = [],
	values: baseValues,
	fullWidth = false,
	containerProps,
	maxWidth,
	maxWidthInput,
	onChange,
	onComplete,
	...props
}: PinInputProps) => {
	const [values, setValues] = useState<string[]>(
		baseValues ? baseValues.slice(0, fields) : Array(fields).fill('')
	)
	const [getRef, setRef] = useDynamicRefs<HTMLInputElement>()

	const triggerChange = (values: string[]) => {
		const val = values.join('')
		if (onChange) onChange(val)
		if (onComplete && val.length >= fields) {
			onComplete(val)
		}
	}

	const handleChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
		if (type === 'number') {
			e.target.value = e.target.value.replace(/[^\d]/gi, '')
		}
		if (e.target.value === '' || (type === 'number' && !e.target.validity.valid)) {
			return
		}
		let next: React.RefObject<HTMLInputElement>
		const { value } = e.target
		let newValues = [...values]
		newValues = Object.assign([], newValues)
		if (value.length > 1) {
			let nextIndex = value.length + index - 1
			if (nextIndex >= fields) {
				nextIndex = fields - 1
			}
			next = getRef(nextIndex.toString()) as RefObject<HTMLInputElement>
			const split = value.split('')
			split.forEach((item, i) => {
				const cursor = index + i
				if (cursor < fields) {
					newValues[cursor] = item
				}
			})
			setValues(newValues)
		} else {
			next = getRef((index + 1).toString()) as RefObject<HTMLInputElement>
			newValues[index] = value
			setValues(newValues)
		}

		if (next?.current) {
			next.current.focus()
			next.current.select()
		} else {
			e.target.blur()
		}
	}

	const onKeyDown = (index: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
		const prevIndex = (index - 1).toString()
		const nextIndex = (index + 1).toString()
		const prev = getRef(prevIndex)
		const next = getRef(nextIndex)
		switch (e.keyCode) {
			case KEY_CODE.backspace:
				e.preventDefault()
				const vals = [...values]
				if (values[index]) {
					vals[index] = ''
					setValues(vals)
				} else if (prev) {
					vals[prevIndex] = ''
					prev?.current?.focus()
					setValues(vals)
				}
				break
			case KEY_CODE.left:
				e.preventDefault()
				if (prev) {
					prev?.current?.focus()
				}
				break
			case KEY_CODE.right:
				e.preventDefault()
				if (next) {
					next?.current?.focus()
				}
				break
			case KEY_CODE.up:
			case KEY_CODE.down:
				e.preventDefault()
				break
			default:
				break
		}
	}
	const onFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.target.select()
	}
	useEffect(() => {
		triggerChange(values)
		// eslint-disable-next-line
	}, [values])
	useEffect(() => {
		setValues(prevValues => {
			const newValues = [...prevValues].slice(0, fields)
			if (newValues.length < fields) {
				newValues.push(...(baseValues?.slice(fields) || []))
				if (newValues.length < fields) {
					newValues.push(...Array(fields - newValues.length).fill(''))
				}
			}
			return newValues
		})
		// eslint-disable-next-line
	}, [fields])
	const INPUT_STYLE = {
		width: fieldWidth,
		height: fieldHeight
	}
	return (
		<PinInputContainer
			border={border}
			fullWidth={fullWidth}
			fields={fields}
			maxWidth={maxWidth}
			maxWidthInput={maxWidthInput}
			{...containerProps}
		>
			{values.map((value, index) => (
				<input
					type={type === 'number' ? 'tel' : type}
					pattern={type === 'number' ? '[0-9]*' : undefined}
					style={INPUT_STYLE}
					value={value}
					key={index}
					ref={setRef(index.toString())}
					onChange={handleChange(index)}
					onKeyDown={onKeyDown(index)}
					onFocus={onFocus}
					placeholder={placeholder[index]}
					{...props}
				/>
			))}
		</PinInputContainer>
	)
}

export default PinInput
