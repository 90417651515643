import styled from 'styled-components'

export const Bubble = styled.div`
	position: relative;
	background: #262358;
	color: #ffffff;
	border-radius: 10px;
	padding: 20px;
	max-width: 250px;

	&:after {
		content: '';
		position: absolute;
		display: block;
		width: 0;
		z-index: 1;
		border-style: solid;
		border-color: #262358 transparent;
		border-width: 10px 10px 0;
		bottom: -10px;
		left: 50%;
		margin-left: -10px;
	}
`
