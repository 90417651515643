/* eslint-disable max-len */
import React from 'react'
import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const NyomiFaceIcon = (props: SvgIconProps) => (
	<SvgIcon {...props} width="44" height="38" viewBox="0 0 44 38" fill="none">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M22.3385 0C42.0882 0 44.0002 12.8837 44 24.7C43.9998 35.2496 35.3808 38 22.3385 38C9.65924 38 0 35.2496 0 24.7C0 12.8837 2.5887 0 22.3385 0Z"
			fill="white"
			fillOpacity="0.8"
		/>
		<mask
			id="mask0"
			mask-type="alpha"
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="44"
			height="38"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M22.3385 0C42.0882 0 44.0002 12.8837 44 24.7C43.9998 35.2496 35.3808 38 22.3385 38C9.65924 38 0 35.2496 0 24.7C0 12.8837 2.5887 0 22.3385 0Z"
				fill="white"
			/>
		</mask>
		<g mask="url(#mask0)">
			<rect x="-2" y="-1" width="47.7446" height="39.7617" fill="white" />
		</g>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M22.34 8C34.1539 8 39.0002 13.4607 39 17.6875C38.9998 21.9143 32.3397 23 22.34 23C12.464 23 5 21.9143 5 17.6875C5 13.4607 10.1388 8 22.34 8Z"
			fill="black"
			fillOpacity="0.8"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.6688 20.8725C16.939 20.3736 18.5765 18.536 18.3263 16.7679C18.0761 14.9998 16.033 13.9709 13.7628 14.4697C11.4927 14.9685 9.85513 16.8062 10.1053 18.5743C10.3555 20.3423 12.3986 21.3713 14.6688 20.8725Z"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M29.0675 20.8725C31.3377 21.3713 33.3808 20.3423 33.631 18.5743C33.8812 16.8062 32.2437 14.9685 29.9735 14.4697C27.7033 13.9709 25.6602 14.9998 25.41 16.7679C25.1598 18.536 26.7973 20.3736 29.0675 20.8725Z"
			fill="white"
		/>
	</SvgIcon>
)

export default NyomiFaceIcon
