import React from 'react'
import { PartialRouteObject } from 'react-router'
import OnboardingRouteHandler from 'containers/OnboardingRouteHandler'
import RoleBasedChallenges from 'screens/Onboarding/containers/RoleBasedChallenges'
import GuidedChallenges from 'screens/Onboarding/containers/GuidedChallenges'
import FreeForm from 'screens/Onboarding/containers/FreeForm'
import EntryForm from 'screens/Onboarding/containers/EntryForm'

export const onboardingRoutes: PartialRouteObject[] = [
	{
		path: '/challenges-selection',
		element: <OnboardingRouteHandler />
	},
	{
		path: '/role-based-challenges',
		element: <RoleBasedChallenges />
	},
	{
		path: '/guided-challenges',
		element: <GuidedChallenges />
	},
	{
		path: '/free-form',
		element: <FreeForm />
	},
	{
		path: '/entry-form',
		element: <EntryForm />
	}
]
