import React from 'react'
import iziToast from 'izitoast'
import { Navigate } from 'react-router-dom'
import { useStoreon } from 'storeon/react'
import useIntl from 'hooks/useIntl'

export interface TeamCreationRouteHandlerProps {}

export const TeamCreationRouteHandler = () => {
	const { user } = useStoreon('user')
	const intl = useIntl()

	if (!user?.isEmailVerified) {
		iziToast.error({ message: intl.formatMessage({ id: 'mailVerification.teams' }) })
		return <Navigate to="/" />
	}
}

export default TeamCreationRouteHandler
