import styled, { css } from 'styled-components'
import { ButtonBase, ButtonBaseProps } from '@material-ui/core'
import { alpha } from '@material-ui/core/styles'

interface WrapperProps extends ButtonBaseProps {
	selected?: boolean
	restricted?: boolean
	component?: string
	fullWidth?: boolean
	fullHeight?: boolean
}

const selectedStyle = css`
	background-color: ${props => alpha(props.theme.tag.darkerBlue, 0.03)};
	border-color: ${props => props.theme.primary.main};
`

const restrictedStyle = css`
	background-color: ${props => props.theme.grey.fifth};
`

export const Wrapper = styled<React.ComponentType<WrapperProps>>(ButtonBase)`
	display: block;
	width: ${props => (props.fullWidth ? '100%' : undefined)};
	height: ${props => (props.fullHeight ? '100%' : undefined)};
	padding: 31px 17px;
	/* box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04); */
	${props =>
		!props.restricted &&
		css`
			border: 1px solid ${props.theme.grey.third};
		`}
	border-radius: 10px;
	&:hover {
		border-color: ${props => props.theme.primary.main};
	}
	${props =>
		props.selected ? selectedStyle : props.restricted ? restrictedStyle : undefined}
	transition: border-color 0.25s linear;
`
