import React, { useEffect } from 'react'
import { useMediaQuery, Theme } from '@material-ui/core'
import { useTheme } from 'styled-components'
import useIntl from 'hooks/useIntl'
import JoyRide, { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from 'react-joyride'
import { resetTeamToursStore, useTours } from './useTours'

export interface ToursProps {
	steps: Step[]
	onFinish?: () => void
}

export const Tours: React.FC<ToursProps> = ({ steps, onFinish }) => {
	const intl = useIntl()
	const { stop, next, prev, run, continuous, stepIndex } = useTours()
	const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))

	const theme = useTheme()

	const handleStop = () => {
		stop()
		onFinish?.()
	}

	// eslint-disable-next-line arrow-body-style
	useEffect(() => {
		return () => {
			resetTeamToursStore()
		}
	}, [])

	const callback: (data: CallBackProps) => void = data => {
		const { action, type, status } = data

		if (
			action === ACTIONS.CLOSE ||
			(status === STATUS.SKIPPED && run) ||
			status === STATUS.FINISHED
		) {
			handleStop()
		} else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
			if (action === ACTIONS.PREV) prev()
			if (action === ACTIONS.NEXT) next()
		}
	}

	return (
		<JoyRide
			run={run}
			continuous={continuous}
			stepIndex={stepIndex}
			steps={steps}
			callback={callback}
			showSkipButton
			showProgress
			scrollToFirstStep
			scrollOffset={200}
			styles={{
				tooltipContainer: {
					textAlign: 'left'
				},
				buttonBack: {
					color: theme.text.main,
					backgroundColor: 'transparent',
					border: `1px solid ${theme.tag.black}`,
					borderRadius: 4,
					width: smUp ? 120 : 80,
					fontSize: smUp ? 14 : 12,
					marginRight: 20
				},
				buttonNext: {
					backgroundColor: theme.tag.black,
					fontSize: smUp ? 14 : 12,
					width: smUp ? 120 : 100
				},
				options: {
					backgroundColor: theme.background.light,
					textColor: theme.text.main,
					arrowColor: theme.background.light,
					zIndex: 100000
				},
				tooltipTitle: {
					marginTop: 5,
					marginLeft: 10
				}
			}}
			locale={{
				last: intl.formatMessage({ id: 'tours.team.finish' })
			}}
		/>
	)
}

export default Tours
