import React from 'react'
import { DefaultTheme } from 'styled-components'
import { StepIconProps, Box } from '@material-ui/core'
import AdjustIcon from '@material-ui/icons/Adjust'
import CheckIcon from '@material-ui/icons/Check'

export const CustomStepIcon = (theme: DefaultTheme) => (props: StepIconProps) => {
	const { active, completed } = props

	return (
		<Box
			display="flex"
			alignItems="center"
			justifyContent="center"
			sx={{
				width: 32,
				height: 32,
				backgroundColor: active || completed ? theme.primary.main : 'transparent',
				color: active || completed ? theme.tag.white : theme.grey.fourth,
				border: active || completed ? null : `1px solid ${theme.grey.fourth}`,
				borderRadius: '50%'
			}}
		>
			{completed ? (
				<CheckIcon color="inherit" fontSize="small" />
			) : active ? (
				<AdjustIcon color="inherit" fontSize="small" />
			) : (
				<AdjustIcon color="inherit" fontSize="small" />
			)}
		</Box>
	)
}

export default CustomStepIcon
