/* eslint-disable no-shadow */
import React from 'react'
import { Grid, GridProps } from '@material-ui/core'
import { ASSESSMENT_URL } from 'constants/config'
import { DragHandle as DragIcon } from '@material-ui/icons'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { SkillCard } from 'screens/Onboarding/components/SkillCard'
import { RemoveIconButton } from 'components/RemoveIconButton'

interface ItemProps extends GridProps {
	id: string
	title: string
	description: string
	durationInMinutes: number
	benchmarked?: boolean
	parts: number
	previewUrl: string
	dragHandleProps?: DraggableProvidedDragHandleProps
	onDelete?: () => void
}

export const SkillItem = React.forwardRef<HTMLDivElement, ItemProps>(function SkillItem(
	{
		id,
		title,
		description,
		previewUrl,
		dragHandleProps,
		onDelete,
		benchmarked,
		...props
	}: ItemProps,
	ref
) {
	return (
		<Grid {...props} ref={ref} item>
			<Grid container alignItems="center" justifyContent="space-between" spacing={3}>
				<Grid item xs>
					<SkillCard
						title={title}
						benchmarked={benchmarked}
						description={description}
						previewUrl={`${ASSESSMENT_URL}/preview/5fea8dae45b7820017baf8e6/5fea8ddf22a70b00177afc49/${id}`}
					/>
				</Grid>
				<Grid item container justifyContent="space-between" sx={{ mt: 1, width: 90 }}>
					<Grid item>
						<RemoveIconButton
							style={{ padding: 0 }}
							disabled={!onDelete}
							onClick={onDelete}
						/>
					</Grid>
					<Grid item>
						<div {...dragHandleProps} style={{ height: 35 }}>
							<DragIcon color="action" />
						</div>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
})

export default SkillItem
