import React, { useEffect, useState } from 'react'
import {
	Box,
	Card,
	Typography,
	CardContent,
	Grid,
	IconButton,
	Select,
	MenuItem,
	FormControl
} from '@material-ui/core'
import {
	TocOutlined,
	AccessTimeOutlined,
	CheckOutlined,
	CloseOutlined
} from '@material-ui/icons'
import RecentAssessment from './RecentAssessment'
import { useMediaQuery, Theme } from '@material-ui/core'
import axios from 'axios'
import MiniDrawer from 'screens/DashboardMonitor/DashboardMonitor'

interface Team {
	id: string
	name: string
	assessId: string
	assessmentName: string
	userCount: number
}

interface Group {
	id: string
	name: string
	pipelineId: string
	pipelineName: string
}

const DashboardCard = ({ title, icon: Icon, iconColor, bgColor, count }) => (
	<Card>
		<CardContent style={{ display: 'flex', alignItems: 'center' }}>
			<IconButton
				style={{
					borderRadius: '9px',
					backgroundColor: bgColor,

					marginRight: 16
				}}
			>
				<Icon style={{ color: iconColor }} />
			</IconButton>
			<div>
				<Typography variant="body2">{title}</Typography>
				<Typography variant="h6">{count}</Typography>
			</div>
		</CardContent>
	</Card>
)

const DashboardScreen_ = () => {
	const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
	const [dropdown2, setDropdown2] = useState('')
	const [dropdown1, setDropdown1] = useState('')
	const [totalTeams, setTotalTeams] = useState<Team[]>([])
	const [totalGroups, setTotalGroups] = useState<Group[]>([])
	const [applicantsData, setApplicantsData] = useState<any>(null)
	const [loading, setLoading] = useState(false)

	const [finishedCount, setFinishedCount] = useState(0)
	const [livecount, setLiveCount] = useState(0)
	const [terminatedCount, setTerminatedCount] = useState(0)
	const [allAttemptsCount, setAllAttemptsCount] = useState(0)
	const userId = localStorage.getItem('userId')
	useEffect(() => {
		const fetchTeams = async () => {
			try {
				const user_id = userId

				const response = await axios.post(
					`${process.env.REACT_APP_API_URL}/api/v1/sandbox/getTotalTeams`,
					{ user_id },
					{
						headers: {
							'Content-Type': 'application/json'
						}
					}
				)

				setTotalTeams(response.data.totalTeams)
			} catch (error) {
				console.error('Error fetching teams:', error)
			}
		}

		fetchTeams()
	}, [])

	useEffect(() => {
		const fetchGroups = async (assessId: string) => {
			if (!assessId) return

			try {
				const response = await axios.post(
					`${process.env.REACT_APP_API_URL}/api/v1/sandbox/getAllgroupsbyTeam`,
					{ assessId },
					{
						headers: {
							'Content-Type': 'application/json'
						}
					}
				)

				const groups = response.data.data
				setTotalGroups(Array.isArray(groups) ? groups : [])
				setDropdown2('')
			} catch (error) {
				console.error('Error fetching groups:', error)
			}
		}

		if (dropdown1) {
			fetchGroups(dropdown1)
		}
	}, [dropdown1])

	useEffect(() => {
		const fetchApplicants = async (pipelineId: string) => {
			if (!pipelineId) return

			setLoading(true)

			try {
				const response = await axios.post(
					`${process.env.REACT_APP_API_URL}/api/v1/sandbox/getApplicantsbyGroup`,
					{ pipelineId },
					{
						headers: {
							'Content-Type': 'application/json'
						}
					}
				)

				setApplicantsData(response.data.data)
				setLoading(false)
			} catch (error) {
				console.error('Error fetching applicants:', error)
				setLoading(false)
			}
		}

		if (dropdown2) {
			fetchApplicants(dropdown2)
		}
	}, [dropdown2])

	useEffect(() => {
		const fetchExamLogs = async (assessId: string) => {
			if (!assessId) return

			try {
				const response = await axios.post(
					`${process.env.REACT_APP_API_URL}/api/v1/sandbox/getExamLogs`,
					{ assessId },
					{
						headers: {
							'Content-Type': 'application/json'
						}
					}
				)
				setLiveCount(response.data.live)

				setFinishedCount(response.data.totalFinished)
				setTerminatedCount(response.data.totalTerminated)
			} catch (error) {
				console.error('Error fetching exam logs:', error)
			}
		}

		if (dropdown1) {
			fetchExamLogs(dropdown1)
		}
	}, [dropdown1])

	useEffect(() => {
		const selectedTeam = totalTeams.find(team => team.assessId === dropdown1)
		if (selectedTeam) {
			setAllAttemptsCount(selectedTeam.userCount)
		} else {
			setAllAttemptsCount(totalTeams.reduce((acc, team) => acc + team.userCount, 0))
		}
	}, [dropdown1, totalTeams])

	return (
		<div>
			<MiniDrawer />
			<Box
				display="flex"
				flexDirection={isSmallScreen ? 'column' : 'row'}
				alignItems="center"
				gap={2}
			>
				<Box>
					<Typography variant={isSmallScreen ? 'h5' : 'h4'} style={{ fontWeight: 'bold' }}>
						Welcome back, John!
					</Typography>
					<Typography variant="body2">Here's Assessment Overview</Typography>
				</Box>

				<Box display="flex" flexDirection={isSmallScreen ? 'column' : 'row'} gap={2}>
					<FormControl
						sx={{
							minWidth: isSmallScreen ? 200 : 380,
							'& .MuiInputBase-root': { height: 40 }
						}}
					>
						<Select
							value={dropdown1}
							onChange={e => setDropdown1(e.target.value)}
							displayEmpty
							MenuProps={{
								PaperProps: {
									style: {
										maxHeight: 200,
										overflowY: 'auto',
										overflowX: 'hidden'
									}
								},
								anchorOrigin: {
									vertical: 'bottom',
									horizontal: 'left'
								},
								transformOrigin: {
									vertical: 'top',
									horizontal: 'left'
								},
								getContentAnchorEl: null
							}}
						>
							<MenuItem value="" disabled>
								Team
							</MenuItem>
							{totalTeams.length ? (
								totalTeams.map(team => (
									<MenuItem key={team.assessId} value={team.assessId}>
										{team.assessmentName}
									</MenuItem>
								))
							) : (
								<MenuItem disabled>No teams available</MenuItem>
							)}
						</Select>
					</FormControl>

					<FormControl
						sx={{
							minWidth: isSmallScreen ? 200 : 380,
							'& .MuiInputBase-root': { height: 40 }
						}}
					>
						<Select
							value={dropdown2}
							onChange={e => setDropdown2(e.target.value)}
							displayEmpty
							MenuProps={{
								PaperProps: {
									style: {
										maxHeight: 200,
										overflowY: 'auto',
										overflowX: 'hidden'
									}
								},
								anchorOrigin: {
									vertical: 'bottom',
									horizontal: 'left'
								},
								transformOrigin: {
									vertical: 'top',
									horizontal: 'left'
								},
								getContentAnchorEl: null
							}}
						>
							<MenuItem value="" disabled>
								Group
							</MenuItem>
							{totalGroups.length ? (
								totalGroups.map(group => (
									<MenuItem key={group.pipelineId} value={group.pipelineId}>
										{group.pipelineName}
									</MenuItem>
								))
							) : (
								<MenuItem disabled>No groups available</MenuItem>
							)}
						</Select>
					</FormControl>
				</Box>
			</Box>

			<Grid container spacing={3} style={{ marginTop: 16 }}>
				<Grid item xs={12} sm={6} md={3}>
					<DashboardCard
						title="All Attempts"
						icon={TocOutlined}
						iconColor="lightgreen"
						bgColor="rgb(218, 247, 221)"
						count={allAttemptsCount}
					/>
				</Grid>

				<Grid item xs={12} sm={6} md={3}>
					<DashboardCard
						title="Live"
						icon={AccessTimeOutlined}
						iconColor="rgb(255, 162, 99)"
						bgColor="rgb(250, 238, 176)"
						count={livecount}
					/>
				</Grid>

				<Grid item xs={12} sm={6} md={3}>
					<DashboardCard
						title="Finished"
						icon={CheckOutlined}
						iconColor="rgb(0, 196, 140)"
						bgColor="rgb(176, 215, 170)"
						count={finishedCount}
					/>
				</Grid>

				<Grid item xs={12} sm={6} md={3}>
					<DashboardCard
						title="Terminated"
						icon={CloseOutlined}
						iconColor="red"
						bgColor="rgb(248, 195, 195)"
						count={terminatedCount}
					/>
				</Grid>
			</Grid>

			<Card style={{ marginTop: '20px' }}>
				<RecentAssessment assessId={dropdown1} />
			</Card>
		</div>
	)
}

export default DashboardScreen_
