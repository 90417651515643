import styled from 'styled-components'
import { Grid } from '@material-ui/core'

export const Container = styled(Grid)`
	padding: 30px 43px;
	max-width: 1500px;

	@media (max-width: 600px) {
		padding-left: 15px;
		padding-right: 15px;
	}
`
