import React from 'react'
import { Grid, Theme, useMediaQuery } from '@material-ui/core'

// import { NyomiTalkBubble } from 'components/NyomiTalkBubble'
import { MobileStepIndicator } from 'components/Wizard'
// import { NyomiTalkBubbleContainer } from '../../TeamCreation.style'

export interface ContentWrapperProps {
	currentStep: number
}

export const ContentWrapper: React.FC<ContentWrapperProps> = ({
	children,
	currentStep
}) => {
	const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))

	return (
		<Grid container direction="column" alignItems="stretch" spacing={6}>
			{!mdUp && (
				<Grid item>
					<MobileStepIndicator currentStep={currentStep + 1} totalStep={4} />
				</Grid>
			)}
			<Grid item>{children}</Grid>
		</Grid>
	)
}
