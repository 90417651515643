import React from 'react'
import useIntl from 'hooks/useIntl'
import { useTheme } from 'styled-components'
import { Grid, Typography, useMediaQuery, Theme } from '@material-ui/core'
import BenchmarkProgress from 'components/BenchmarkProgress'
import { ApplicantsScoreSkillsFieldsFragment, IndustryBenchmark } from 'hooks/useApollo'

import { Mark } from './SkillsBenchmark.style'

export interface SkillsBenchmarkProps {
	skills: ApplicantsScoreSkillsFieldsFragment[]
	benchmarks: Record<string, IndustryBenchmark>
	isRoleBased?: boolean
	role?: string
	user: { firstName: string; lastName: string; photoUrl: string }
}

export const SkillsBenchmark = ({
	skills,
	benchmarks,
	isRoleBased,
	user
}: SkillsBenchmarkProps) => {
	const intl = useIntl()
	const upSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
	const showAsterisk = skills.some(
		({ chapter }) => !(isRoleBased && benchmarks[chapter?.id || '']?.score)
	)
	return (
		<Grid container spacing={4}>
			{isRoleBased && (
				<Grid
					item
					sx={{ mt: -1 }}
					container
					spacing={upSm ? 3 : 2}
					direction={upSm ? 'row' : 'column'}
					alignItems={upSm ? 'center' : 'flex-start'}
				>
					<Grid item>
						<Grid item container alignItems="center">
							<Mark />
							<Typography sx={{ fontSize: 12, ml: 1 }}>
								{intl.formatMessage({ id: 'industryBench' })}
							</Typography>
						</Grid>
					</Grid>
					{showAsterisk && (
						<Grid item>
							<Grid item container alignItems="center">
								<Asterisk />
								<Typography sx={{ fontSize: 12, ml: 1 }}>
									{intl.formatMessage({ id: 'industryComingSoon' })}
								</Typography>
							</Grid>
						</Grid>
					)}
				</Grid>
			)}
			<Grid item container alignItems="center" spacing={7}>
				{skills.map(({ score, skill, chapter }, index) => (
					<Grid
						key={index}
						item
						xs={12}
						sx={{
							mb: index === skills.length - 1 ? 6 : undefined,
							mt: index === 0 && !isRoleBased ? 2 : undefined
						}}
					>
						<BenchmarkProgress
							firstName={user.firstName}
							lastName={user.lastName}
							photoUrl={user.photoUrl}
							score={score}
							benchmark={(isRoleBased && benchmarks[chapter?.id || '']?.score) || 0}
							label={
								<Typography variant="paragraph3">
									{skill?.name || ''}{' '}
									{isRoleBased && !benchmarks[chapter?.id || '']?.score && <Asterisk />}
								</Typography>
							}
						/>
					</Grid>
				))}
			</Grid>
		</Grid>
	)
}

const Asterisk = () => {
	const theme = useTheme()
	return <span style={{ fontSize: 12, color: theme.tag.purple }}>*</span>
}

export default SkillsBenchmark
