import styled, { css } from 'styled-components'
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import { AccordionProps } from './Accordion'

type Props = Pick<AccordionProps, 'bordered'>

export const AccordionContainer = styled(Accordion).withConfig<Props>({
	shouldForwardProp: prop => !['bordered'].includes(prop)
})`
	${props =>
		props.bordered
			? css`
					padding-top: 20px;
					&:before {
						content: unset;
					}
					&.Mui-expanded {
						margin: unset;
					}
			  `
			: css`
					background-color: transparent;
					&:not(:first-child) {
						margin-top: 18px;
					}
					.MuiAccordionSummary-root {
						padding: 30px 0 12px;
						.MuiAccordionSummary-content {
							margin: 0;
						}
					}
					&:not(.Mui-expanded) {
						&:first-child {
							&:before {
								opacity: 0;
							}
						}
					}
					&.Mui-expanded {
						&:not(:first-child) {
							.MuiAccordionSummary-content {
								margin-bottom: 8px;
							}
							&:before {
								opacity: 1;
							}
						}
					}
					&.Mui-expanded + & {
						&:before {
							display: block;
						}
					}
			  `}
`

export const AccordionHeader = styled(AccordionSummary).withConfig<Props>({
	shouldForwardProp: prop => !['bordered'].includes(prop)
})`
	${props =>
		props.bordered
			? css`
					min-height: 53px;
					border: 1px solid;
					border-color: ${props => props.theme.background.summary};
					border-radius: 6px;
			  `
			: css`
					padding: 0;
					&.Mui-expanded {
						min-height: 48px;
					}
			  `}
`

export const AccordionContent = styled(AccordionDetails)`
	padding: 0;
`
