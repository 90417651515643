import React from 'react'
// import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@material-ui/core'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'

import { GlobalStyles } from './global-styles'
import { materialTheme, materialDarkTheme } from './mui-theme'
import { theme, darkTheme } from './sc-theme'
import { StyledEngineProvider } from '@material-ui/styled-engine-sc'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'

export interface ThemeProviderProps {
	/**
	 * a prop to choose between light and dark mode
	 */
	mode?: 'light' | 'dark'
	/**
	 * react children prop
	 */
	children?: React.ReactNode
}

export const ThemeProvider = ({ children, mode = 'light' }: ThemeProviderProps) => {
	return (
		<StyledEngineProvider injectFirst>
			<MuiThemeProvider theme={mode === 'light' ? materialTheme : materialDarkTheme}>
				<StyledThemeProvider theme={mode === 'light' ? theme : darkTheme}>
					<GlobalStyles />
					{children}
				</StyledThemeProvider>
			</MuiThemeProvider>
		</StyledEngineProvider>
	)
}
