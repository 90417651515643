import React from 'react'
import { Grid, TextField, MenuItem, useMediaQuery, Theme } from '@material-ui/core'
import Modal, { IModalProps } from 'components/Modal'
import useIntl from 'hooks/useIntl'
import ColorPickerField from 'components/ColorPickerField'
import types from 'data/groupTypes.json'
import { EnumPipelineType, UpdatePipelineInput } from 'hooks/useApollo'
import useEditTalentGroupForm, { EditTalentGroupForm } from './useEditGroupForm'

export interface EditGroupModalProps extends Pick<IModalProps, 'open' | 'onClose'> {
	/**
	 * Defines the unique id of the item to be edited
	 */
	id: string
	/**
	 * Defines the loading state of the component
	 * @default false
	 */
	loading?: boolean
	/**
	 * Defines the current group name
	 */
	name: string
	/**
	 * Defines the talent group type
	 */
	type?: EnumPipelineType
	/**
	 * Defines the current group color
	 */
	color: string
	/**
	 * Callback fired when the finish button is clicked
	 */
	onEdit: (id: string, input: UpdatePipelineInput) => void
}

export const EditGroupModal: React.FC<EditGroupModalProps> = ({
	name,
	color,
	type,
	onClose,
	open,
	id,
	onEdit,
	loading
}) => {
	const intl = useIntl()
	const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

	const [
		{ errors, hex, rgba, anchorEl, watch },
		{
			onSubmit,
			handleOpenAnchor,
			handleCloseAnchor,
			handleColorChange,
			handleColorChangeComplete
		},
		{ Controller }
	] = useEditTalentGroupForm({ color, type: type || '', name })

	const handleSubmit = ({ name, color }: EditTalentGroupForm): void => {
		onEdit(id, { name, color })
	}

	const newName = watch('name')

	return (
		<Modal
			open={open}
			buttonStyle="primary"
			okText={intl.formatMessage({ id: 'finish' })}
			onClose={onClose}
			size={mdDown ? 'normal' : 'big'}
			maxWidth="xs"
			onOk={onSubmit(handleSubmit)}
			closeOnOk={false}
			okLoading={loading}
			onOkDisabled={newName === name && hex === color}
			title={intl.formatMessage({ id: 'talentGroup.edit' })}
		>
			<form onSubmit={onSubmit(handleSubmit)}>
				<Grid container spacing={4}>
					<Grid item container>
						<Controller
							name="name"
							render={props => (
								<TextField
									fullWidth
									label={intl.formatMessage({ id: 'talentGroupName' })}
									error={!!errors?.name?.message}
									helperText={errors?.name?.message}
									required
									{...props}
								/>
							)}
						/>
					</Grid>
					<Grid item container spacing={4}>
						<Grid item xs={12} md={8}>
							<Controller
								name="type"
								render={props => (
									<TextField
										label={intl.formatMessage({ id: 'talentType' })}
										select
										error={!!errors?.type?.message}
										helperText={errors?.type?.message}
										required
										disabled
										{...props}
									>
										{types.map(type => (
											<MenuItem value={type.value} key={type.value}>
												{type.label}
											</MenuItem>
										))}
									</TextField>
								)}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<Controller
								name="color"
								render={props => (
									<ColorPickerField
										label={intl.formatMessage({ id: 'groupColor' })}
										anchorEl={anchorEl}
										onClick={handleOpenAnchor}
										onClose={handleCloseAnchor}
										hex={hex}
										rgba={rgba}
										onColorChange={handleColorChange}
										onColorChangeComplete={handleColorChangeComplete}
										TextFieldProps={{ ...props }}
									/>
								)}
							/>
						</Grid>
					</Grid>
				</Grid>
			</form>
		</Modal>
	)
}

export default EditGroupModal
