/* eslint-disable indent */
import { gql } from '@apollo/client'
import { useTours } from 'components/Tours'

import { useNavigate } from 'react-router-dom'

import iziToast from 'izitoast'
import { useCreateAssessmentMutation } from 'hooks/useApollo'

import { useStoreon } from 'storeon/react'

export const useCreateAssessment = (callback: () => void) => {
	const navigate = useNavigate()
	const { start: runTours } = useTours()
	const { dispatch, user } = useStoreon('user')
	const [createAssessment, { loading }] = useCreateAssessmentMutation({
		onCompleted: data => {
			if (data.createAssessment?.name) {
				iziToast.success({
					message: `${data.createAssessment.name} team created successfully.`
				})
				if (!user?.isTeamTourDone) {
					runTours()
					// @ts-ignore
					dispatch('user/setUser', { ...user, isTeamTourDone: true, isFirstAccess: false })
				} else {
					dispatch('user/setUser', {
						isFirstAccess: false
					})
				}
			}
			window.scrollTo({
				top: 0
			})
			navigate(`/teams/${data.createAssessment?.id}`)
			callback()
		},
		onError: () => {},
		update: (cache, { data }) => {
			cache.modify({
				fields: {
					assessments: existingAssessments => {
						const newAssessmentRef = cache.writeFragment({
							data: data?.createAssessment,
							fragment: gql`
								fragment NewAssessment on AnalyticsUserAssessment {
									id
									name
									status
									category {
										name
									}
									masking
									totalTalents
									createdAt
									updatedAt
								}
							`
						})

						return [...existingAssessments, newAssessmentRef]
					}
				}
			})
		}
	})

	const models = { loading }
	const operations = { createAssessment }

	return [models, operations] as [typeof models, typeof operations]
}
