import React, { useState } from 'react'
import Selection from './Selection'
import { Box, Paper, ToggleButton, ToggleButtonGroup } from '@material-ui/core'
import MiniDrawer from 'screens/DashboardMonitor/DashboardMonitor'
import FileUpload from './FileUpload'

const Options = () => {
	const [selectedOption, setSelectedOption] = useState('single')

	const handleToggleChange = (event, newOption) => {
		if (newOption !== null) {
			setSelectedOption(newOption)
		}
	}

	return (
		<Box>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					width: '100%',
					padding: '8px 16px'
				}}
			>
				<MiniDrawer />

				<ToggleButtonGroup
					value={selectedOption}
					exclusive
					onChange={handleToggleChange}
					aria-label="question type selection"
				>
					<ToggleButton value="single" aria-label="single question">
						Single Question
					</ToggleButton>
					<ToggleButton value="multiple" aria-label="bulk questions">
						Bulk Upload
					</ToggleButton>
				</ToggleButtonGroup>
			</Box>

			{/* Content area */}
			<Box sx={{ width: '100%' }}>
				{selectedOption === 'single' && <Selection />}
				{selectedOption === 'multiple' && <FileUpload />}
			</Box>
		</Box>
	)
}

export default Options
