import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ColorChangeHandler } from 'react-color'
import { useTypedController } from '@hookform/strictly-typed'
import { write as copy } from 'clipboardy'
import iziToast from 'izitoast'
import useIntl from 'hooks/useIntl'
import useColorPicker from 'hooks/useColorPicker'
import useToggleAnchor from 'hooks/useToggleAnchor'

export interface CreateTalentGroupForm {
	name: string
	type: string
	color: string
	teammateEmails?: string[]
}

export type IuseCreateTalentGroupForm = ReturnType<typeof useCreateTalentGroupForm>

export const useCreateTalentGroupForm = () => {
	const intl = useIntl()
	const [{ anchorEl }, { handleOpenAnchor, handleCloseAnchor }] = useToggleAnchor()

	const [{ hex, rgba }, { handleColorChange }] = useColorPicker()

	const validationSchema = yup.object({
		name: yup
			.string()
			.required(intl.formatMessage({ id: 'talentGroup.teamName.error.required' })),
		type: yup
			.string()
			.oneOf(['Internal', 'External'])
			.required(intl.formatMessage({ id: 'talentGroup.type.error.required' })),
		color: yup
			.string()
			.required(intl.formatMessage({ id: 'talentGroup.color.error.required' })),
		teammateEmails: yup.array().of(yup.string().email())
	})

	const {
		watch,
		control,
		errors,
		handleSubmit: onSubmit,
		formState,
		setValue
	} = useForm<CreateTalentGroupForm>({
		reValidateMode: 'onChange',
		mode: 'onChange',
		resolver: yupResolver(validationSchema),
		defaultValues: {
			name: '',
			type: '',
			color: hex,
			teammateEmails: []
		}
	})

	const Controller = useTypedController<CreateTalentGroupForm>({ control })

	const handleColorChangeComplete: ColorChangeHandler = ({ hex }) => {
		setValue('color', hex)
	}

	const handleCopy = async (link: string) => {
		await copy(link)
		iziToast.success({
			message: intl.formatMessage({ id: 'copiedLink' })
		})
	}

	const models = { errors, watch, anchorEl, hex, rgba, formState, setValue }
	const operations = {
		onSubmit,
		handleOpenAnchor,
		handleCopy,
		handleCloseAnchor,
		handleColorChange,
		handleColorChangeComplete
	}
	const components = { Controller }

	return [models, operations, components] as [
		typeof models,
		typeof operations,
		typeof components
	]
}

export default useCreateTalentGroupForm
