import React, { useRef } from 'react'
import { Popper, Typography } from '@material-ui/core'

import NaomiFaceIcon from 'assets/icons/NyomiIcons/NyomiFaceIcon'

import { NyomiContainer, TalkBubble } from './NyomiTalkBubble.style'

export interface NyomiTalkBubbleProps {
	message: string | React.ReactNode
	inline?: boolean
}

export const NyomiTalkBubble: React.FC<NyomiTalkBubbleProps> = ({
	message,
	inline,
	...props
}) => {
	const naomiRef = useRef<HTMLDivElement>(null)

	const naomi = (
		<NyomiContainer {...props}>
			<NaomiFaceIcon width="100%" height="100%" />
		</NyomiContainer>
	)

	const talkBubble = (
		<TalkBubble arrow arrowX="left" arrowY="bottom">
			{React.isValidElement(message) ? (
				message
			) : (
				<Typography variant="paragraph1">{message}</Typography>
			)}
		</TalkBubble>
	)

	if (inline) {
		return (
			<>
				{naomiRef.current && (
					<Popper open anchorEl={naomiRef.current} placement="top-start">
						{talkBubble}
					</Popper>
				)}
				<div ref={naomiRef}>{naomi}</div>
			</>
		)
	}

	return (
		<>
			{talkBubble}
			{naomi}
		</>
	)
}
