import React from 'react'
import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const EmailOutlinedIcon = (props: SvgIconProps) => (
	<SvgIcon {...props} width="16" height="12" viewBox="0 0 16 12" fill="none">
		<path d="M14.4 12H1.6C0.716344 12 0 11.3284 0 10.5V1.43475C0.0372867 0.631911 0.742826 -0.000759282 1.6 6.83933e-07H14.4C15.2837 6.83933e-07 16 0.671574 16 1.5V10.5C16 11.3284 15.2837 12 14.4 12ZM1.6 2.901V10.5H14.4V2.901L8 6.9L1.6 2.901ZM2.24 1.5L8 5.1L13.76 1.5H2.24Z" />
	</SvgIcon>
)

export default EmailOutlinedIcon
