import styled from 'styled-components'
import { SpeedDial } from '@material-ui/core'

export const FixedDialButton = styled(SpeedDial)`
	position: fixed;
	bottom: 48px;
	left: 40px;

	@media only screen and (max-width: 600px) {
		bottom: 20px;
		left: 15px;
	}

	.MuiSpeedDialAction-staticTooltipLabel {
		padding: 0;
	}
`
