import { intervalToDuration } from 'date-fns'

export const convertDuration = (seconds: number) => {
	const duration = intervalToDuration({ start: 0, end: seconds * 1000 })
	return `${duration.minutes}m ${duration.seconds ? `${duration.seconds}s` : ''}`
}

interface TimeDiff {
	type: 'min' | 'hour' | 'day' | 'date'
	date: number | string
}
/**
 * @param date Date as ISO string - YYYY-MM-DDThh:mm:ssZ or any other date string
 */
export const getDateDifference = (date: string): TimeDiff => {
	const createdAt = Date.parse(date as string)
	const dateNow = Date.now()

	let timeDiff: TimeDiff = {
		type: 'min',
		date: Math.floor((dateNow - createdAt) / (1000 * 60))
	}

	if (timeDiff.date >= 60) {
		timeDiff = {
			type: 'hour',
			date: Math.floor((timeDiff.date as number) / 60)
		}
	}

	if (timeDiff.date > 24) {
		timeDiff = {
			type: 'day',
			date: Math.floor((timeDiff.date as number) / 24)
		}
	}

	if ((timeDiff.date as number) / 24 > 7) {
		timeDiff = {
			type: 'date',
			date: new Date(createdAt).toLocaleDateString('en-CA', {
				month: 'long',
				day: '2-digit',
				year: 'numeric'
			})
		}
	}

	return timeDiff
}
