import React from 'react'
import { useTheme } from 'styled-components'
import { Divider, Grid, Typography, Avatar } from '@material-ui/core'
import TextToolTip from 'components/TextToolTip'

import {
	ProgressContainer,
	AvatarMarkContainer,
	MarkContainer,
	IndicatorContainer
} from './BenchmarkProgress.style'

export interface BenchmarkProgressProps {
	benchmark: number
	score: number
	firstName: string
	lastName: string
	photoUrl?: string
	label: string | React.ReactNode
}

export const BenchmarkProgress = ({
	benchmark,
	firstName,
	lastName,
	photoUrl,
	score,
	label
}: BenchmarkProgressProps) => {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				{typeof label === 'string' ? (
					<Typography variant="paragraph3">{label}</Typography>
				) : (
					label
				)}
			</Grid>
			<Grid item xs={12}>
				<ProgressContainer container alignItems="center">
					<Indicator position="start" />
					<Mark benchmark={benchmark} />
					<AvatarMark
						score={score}
						firstName={firstName}
						lastName={lastName}
						photoUrl={photoUrl}
					/>
					<Grid item xs={12}>
						<Divider orientation="horizontal" />
					</Grid>
					<Indicator position="end" />
				</ProgressContainer>
			</Grid>
		</Grid>
	)
}

interface IndicatorProps {
	position: 'start' | 'end'
}

const Indicator = ({ position }: IndicatorProps) => {
	const theme = useTheme()
	return (
		<IndicatorContainer position={position}>
			<Typography
				variant="paragraph3"
				align="center"
				sx={{
					position: 'absolute',
					mt: 1.5,
					left: position === 'start' ? -3 : -10,
					color: theme.text.description
				}}
			>
				{position === 'start' ? 0 : 100}
			</Typography>
		</IndicatorContainer>
	)
}

interface MarkProps {
	benchmark: number
}

const Mark = ({ benchmark }: MarkProps) => {
	const theme = useTheme()
	return benchmark > 0 ? (
		<MarkContainer value={benchmark}>
			<Typography
				variant="paragraph3"
				align="center"
				sx={{
					position: 'absolute',
					mt: 1.5,
					left: -5,
					color: theme.text.description
				}}
			>
				{benchmark}
			</Typography>
		</MarkContainer>
	) : null
}

interface AvatarMarkProps {
	score: number
	firstName: string
	lastName: string
	photoUrl?: string
}

const AvatarMark = ({ score, firstName, lastName, photoUrl }: AvatarMarkProps) => {
	const theme = useTheme()
	return (
		<TextToolTip
			title={
				<Typography variant="paragraph3" sx={{ color: theme.grey.main }}>
					Score <span style={{ color: theme.tag.white }}>{score}</span>
				</Typography>
			}
			placement="top"
			arrow
		>
			<AvatarMarkContainer score={score}>
				<Avatar src={photoUrl} sx={{ height: '32px', width: '32px' }}>
					{photoUrl
						? null
						: `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`}
				</Avatar>
			</AvatarMarkContainer>
		</TextToolTip>
	)
}

export default BenchmarkProgress
