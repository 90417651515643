import React from 'react'
import { useTheme } from 'styled-components'
import automarker from 'automarker'
import { Container } from './highlighted.style'

export interface HighlightedProps extends React.HTMLAttributes<HTMLLIElement> {
	/**
	 * defines the text to be rendered in the component
	 */
	text: string | { label: string }
	/**
	 * defines the search string
	 */
	search: string
	/**
	 * an optional prop to overflow text
	 */
	overflowText?: boolean
}

export const Highlighted = ({
	text,
	search,
	overflowText = false,
	...props
}: HighlightedProps) => {
	const theme = useTheme()
	const parts = automarker(typeof text === 'object' ? text.label : text, search)

	return (
		<li {...props}>
			<Container overflowText={overflowText}>
				{parts.map((part, i) => (
					<span
						key={i}
						style={{
							fontWeight: part.highlight ? 700 : 400,
							color: part.highlight ? theme.primary.main : 'inherit'
						}}
					>
						{part.text}
					</span>
				))}
			</Container>
		</li>
	)
}

export default Highlighted
