import React, { useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { Button, Grid, Typography, useMediaQuery, Theme } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { useTheme } from 'styled-components'
import { useStoreon } from 'storeon/react'

import emails from 'data/emails.json'
import { useIntl } from 'hooks/useIntl'
import SubscriptionCard from 'components/SubscriptionCard'
import CalculateCostModal from 'components/CalculateCostModal'
import { useCheckout } from 'hooks/useCheckout'
import ButtonLink from 'components/ButtonLink'

export interface SelectPlansProps {}

export const SelectPlans = () => {
	const intl = useIntl()
	const theme = useTheme()
	const navigate = useNavigate()
	const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))

	const { dispatch, token } = useStoreon('token')

	const [{ open, plans, loading }, { handleClose, handleActivatePlan }] = useCheckout()

	const planKeys = Object.keys(plans)

	useEffect(() => {
		window.history.pushState(null, '', window.location.pathname)
		window.addEventListener('popstate', onBackButtonEvent)
		return () => {
			window.removeEventListener('popstate', onBackButtonEvent)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// override default browser back button functionality for this screen only
	const onBackButtonEvent = e => {
		e.preventDefault()
		dispatch('user/signOut')
		navigate('/auth/signup')
	}

	if (!token) {
		return <Navigate to="/auth/login" />
	}

	return (
		<>
			<Grid container spacing={4}>
				<Grid item container justifyContent="center">
					<Typography variant="h3" fontWeight={700}>
						{intl.formatMessage({ id: 'payment.plan' })}
					</Typography>
					<Typography
						variant="paragraph1"
						align="center"
						sx={{ mt: 2, color: theme.text.description, lineHeight: '22px' }}
					>
						{intl.formatMessage({ id: 'payment.plan.desc' })}
					</Typography>
				</Grid>
				<Grid item container spacing={3}>
					{planKeys.map(key => (
						<Grid item key={key} xs={12} md={6}>
							<SubscriptionCard
								{...plans[key]}
								loading={loading}
								onActivate={() => handleActivatePlan({ planName: key, fromSignup: true })}
							/>
						</Grid>
					))}
				</Grid>
				<Grid item container alignItems="center" justifyContent="space-between">
					<Grid item xs={12} md sx={{ order: smUp ? 1 : 2 }}>
						<a href={`mailto:${emails.sales}`}>
							<ButtonLink>{intl.formatMessage({ id: 'payment.plan.contact' })}</ButtonLink>
						</a>
					</Grid>
					<Grid item sx={{ order: smUp ? 2 : 1 }}>
						<Button
							sx={{ ml: smUp ? undefined : -0.5, mb: smUp ? undefined : 1 }}
							size="small"
							variant="text"
							color="secondary"
							onClick={() => navigate('/')}
						>
							{intl.formatMessage({ id: 'plans.freeTrial' })}{' '}
							<ArrowForwardIcon sx={{ color: 'inherit', ml: 1, height: 14, width: 14 }} />
						</Button>
					</Grid>
				</Grid>
			</Grid>
			<CalculateCostModal
				title={`${plans.Benchmarking_Monthly.title} plan`}
				open={open}
				onClose={handleClose}
			/>
		</>
	)
}

export default SelectPlans
