import React from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { Computer, ControlPointOutlined, TableChart } from '@material-ui/icons'
import { ClickAwayListener } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex'
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap'
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		overflowX: 'hidden',
		width: theme.spacing(7) + 1,
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9) + 1
		}
	}
}))

export default function MiniDrawer() {
	const classes = useStyles()
	const theme = useTheme()
	const navigate = useNavigate()
	const [open, setOpen] = React.useState(false)

	const handleDrawerOpen = () => {
		setOpen(true)
	}

	const handleDrawerClose = () => {
		setOpen(false)
	}

	const menuItems = [
		{ text: 'Monitor', icon: <Computer />, path: '/newdashboard_1' },
		{ text: 'Add Questions', icon: <ControlPointOutlined />, path: '/selection' },
		{ text: 'Sections', icon: <TableChart />, path: '/CustomSectionsData' },
		{
			text: 'SubSections',
			icon: <TableChart />,
			path: '/CustomSubSectionsData'
		}
	]

	return (
		<ClickAwayListener onClickAway={handleDrawerClose}>
			<div>
				<div style={{ display: 'flex', marginBottom: '20px' }}>
					<IconButton
						color="primary"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						edge="start"
					>
						<MenuIcon />
					</IconButton>
				</div>
				<Drawer
					open={open}
					onClose={handleDrawerClose}
					variant="persistent"
					className={clsx(classes.drawer, {
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open
					})}
					classes={{
						paper: clsx({ [classes.drawerOpen]: open, [classes.drawerClose]: !open })
					}}
				>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							padding: '8px 16px'
						}}
					>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<ListItemIcon>
								<MenuIcon />
							</ListItemIcon>
							<Typography>Monitor Dashboard</Typography>
						</div>
						<IconButton onClick={handleDrawerClose}>
							{theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
						</IconButton>
					</div>
					<Divider />
					<List>
						{menuItems.map(({ text, icon, path }) => (
							<ListItem button key={text} onClick={() => path && navigate(path)}>
								<ListItemIcon>{icon}</ListItemIcon>
								<ListItemText primary={text} />
							</ListItem>
						))}
					</List>
				</Drawer>
			</div>
		</ClickAwayListener>
	)
}
