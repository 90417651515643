import styled from 'styled-components'
import { createCustomColor } from 'packages/nuggetai.ui-kit/themes/utils'

export const Card = styled.div<{ color: string }>`
	width: 100%;
	border-radius: 6px;
	padding: 20px;
	border: ${props => `1px solid ${props.color}`};
	background-color: ${props => createCustomColor(props.color)};
`
