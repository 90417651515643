/* eslint-disable indent */
import React from 'react'
import { Button, Grid, Hidden, IconButton, Typography, Collapse } from '@material-ui/core'
import { alpha } from '@material-ui/core/styles'
import { Close as CloseIcon } from '@material-ui/icons'
import { useTheme, DefaultTheme } from 'styled-components'
import { StyledAlert } from './Alert.style'

export interface AlertProps {
	/**
	 * Defines the condition for the alert
	 * @default false
	 */
	condition?: boolean
	/**
	 * Defines the color of the component
	 */
	color: 'primary' | 'success' | 'danger' | 'reminder'
	/**
	 * Callback fired when the action button is clicked.
	 */
	onClick?: () => void
	/**
	 * Defines if the alert will be shown or not
	 */
	open: boolean
	/**
	 * Defines the icon to be displayed (mui-icons only at the meantime)
	 */
	icon?: typeof CloseIcon
	/**
	 * Callback fired when the close button is clicked.
	 */
	onClose?: () => void
	/**
	 * Defines the label of the alert
	 */
	label: string
	/**
	 * Defines the label of the action button
	 */
	actionLabel?: string
	/**
	 * Defines the component is closeable
	 */
	closeable?: boolean
}

const getColor = (color: AlertProps['color'], theme: DefaultTheme) => {
	switch (color) {
		case 'primary':
			return { bg: alpha(theme.primary.main, 0.2), color: theme.primary.main }
		case 'success':
			return { bg: alpha(theme.tag.green, 0.2), color: theme.tag.green }
		case 'danger':
			return { bg: alpha(theme.tag.red, 0.2), color: theme.tag.red }
		case 'reminder':
			return { bg: alpha(theme.secondary.orange, 0.2), color: theme.secondary.orange }
		default:
			return { bg: alpha(theme.primary.main, 0.2), color: theme.primary.main }
	}
}

export const Alert = ({
	condition = false,
	open,
	onClose,
	onClick,
	color,
	icon: Icon,
	label,
	actionLabel,
	closeable
}: AlertProps) => {
	const theme = useTheme()
	const activeColor = getColor(color, theme)

	if (!open) {
		return null
	}

	const closeButton = (
		<IconButton aria-label="close" color="inherit" size="small" onClick={onClose}>
			<CloseIcon style={{ fill: theme.text.main }} />
		</IconButton>
	)

	return (
		<Collapse in={open}>
			<StyledAlert
				sx={{
					backgroundColor: condition ? alpha(theme.tag.green, 0.2) : activeColor.bg
				}}
				onClose={onClose}
				icon={false}
				{...(condition
					? { action: closeButton }
					: closeable
					? { action: closeButton }
					: { action: '' })}
			>
				<Grid container alignItems="center" justifyContent="center">
					{Icon && (
						<Hidden smDown>
							<Grid item>
								<Icon sx={{ fill: activeColor, display: condition ? 'none' : 'flex' }} />
							</Grid>
						</Hidden>
					)}
					<Grid item>
						<Typography
							variant="paragraph1"
							fontWeight={400}
							sx={{ color: theme.text.main }}
						>
							{label}
						</Typography>
					</Grid>
					{!condition && actionLabel && onClick && (
						<Grid item>
							<Button
								variant="contained"
								size="small"
								sx={{
									backgroundColor: activeColor.color,
									color: theme.tag.white,
									ml: 2,
									padding: '0px 8px',
									'&:hover': {
										backgroundColor: alpha(activeColor.color, 0.8)
									}
								}}
								onClick={onClick}
							>
								{actionLabel}
							</Button>
						</Grid>
					)}
				</Grid>
			</StyledAlert>
		</Collapse>
	)
}

export default Alert
