import React from 'react'
import Leadership from './Leadership'
import { Card } from '@material-ui/core'
import QuickActions from './QuickActions'
import styled from 'styled-components'

const Container = styled.div`
	display: flex;
	width: 100%;
	gap: 20px;

	@media (max-width: 768px) {
		flex-direction: column;
		gap: 20px;
	}
`

const StyledCard = styled(Card)`
	flex: 7;
	@media (max-width: 768px) {
		flex: none;
		width: 100%;
	}
`

const QuickActionsWrapper = styled.div`
	flex: 3;
	@media (max-width: 768px) {
		flex: none;
		width: 100%;
	}
`

const Newscreen = () => {
	return (
		<Container>
			<StyledCard>{/* <Leadership /> */}</StyledCard>
			<QuickActionsWrapper>
				<QuickActions />
			</QuickActionsWrapper>
		</Container>
	)
}

export default Newscreen
