import React, { useMemo } from 'react'
import { Tabs, Typography, useMediaQuery, Theme } from '@material-ui/core'
import { useNavigate, useParams } from 'react-router-dom'
import useIntl from 'hooks/useIntl'
import { pxToRem } from 'packages/nuggetai.ui-kit/themes/utils'
import { SidebarContent, TabStyled } from './Sidebar.style'

interface Props {
	onClose?: () => void
}

export const Sidebar = ({ onClose }: Props) => {
	const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
	const navigate = useNavigate()

	const intl = useIntl()
	const params = useParams()
	const tab = useMemo(() => params['*'].split('/')[0], [params])
	const goToMainPageTab = (value: string) => () => {
		if (value === tab) {
			navigate(value)
		}
	}
	const handleChangeTab = (_e, newTab) => {
		navigate(newTab)
		if (onClose) {
			onClose()
		}
	}
	return (
		<SidebarContent>
			<Typography variant="h3" style={{ fontWeight: 500 }}>
				{intl.formatMessage({ id: 'accountSettings.title' })}
			</Typography>
			<Tabs
				value={tab}
				TabIndicatorProps={{
					style: {
						height: !mdDown ? 36 : 2,
						marginTop: 14,
						fontSize: !mdDown ? pxToRem(16) : 'auto'
					}
				}}
				style={{ marginTop: mdDown ? 'auto' : 32 }}
				onChange={handleChangeTab}
				orientation={mdDown ? 'horizontal' : 'vertical'}
				centered
				variant="fullWidth"
			>
				<TabStyled
					value="account"
					label={intl.formatMessage({ id: 'nav.personalInformation' })}
				/>
				<TabStyled value="company" label={intl.formatMessage({ id: 'nav.company' })} />
				<TabStyled
					value="security"
					onClick={goToMainPageTab('security')}
					label={intl.formatMessage({ id: 'nav.security' })}
				/>
				<TabStyled value="billing" label={intl.formatMessage({ id: 'nav.billing' })} />
			</Tabs>
		</SidebarContent>
	)
}

export default Sidebar
