import React, { useEffect } from 'react'
import { IconButton, Grid, TextField, Button } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'
import { useForm } from 'react-hook-form'
import { useTypedController } from '@hookform/strictly-typed'

import useIntl from 'hooks/useIntl'
import useToggle from 'hooks/useToggle'

export interface NotesTabProps {
	/**
	 * Defines the unique id of the talent
	 */
	id: string
	/**
	 * Defines the note to be displayed
	 */
	note: string
	/**
	 * Callback fired when form is submitted
	 */
	onEdit: (note: string) => void
}

export interface AddNoteForm {
	note: string
}

export const NotesTab: React.FC<NotesTabProps> = ({ id, note, onEdit }) => {
	const intl = useIntl()
	const [{ open }, { handleToggle, handleClose }] = useToggle()

	const { control, handleSubmit: onSubmit, formState, setValue } = useForm<AddNoteForm>({
		reValidateMode: 'onChange',
		defaultValues: {
			note
		}
	})

	const handleToggleEdit = () => {
		if (open) {
			setValue('note', note)
		}
		handleToggle()
	}

	useEffect(() => {
		setValue('note', note)
		// eslint-disable-next-line
	}, [id])

	const Controller = useTypedController<AddNoteForm>({ control })

	const handleSubmit = ({ note }: AddNoteForm): void => {
		onEdit(note)
		handleClose()
	}

	return (
		<Grid sx={{ py: 4 }} container spacing={3}>
			<Grid item xs={12}>
				<form onSubmit={onSubmit(handleSubmit)}>
					<Grid container spacing={4} sx={{ position: 'relative' }}>
						<Grid item sx={{ position: 'absolute', zIndex: 100, right: 10, top: 10 }}>
							<IconButton size="small" onClick={handleToggleEdit}>
								{open ? (
									<CloseIcon fontSize="small" color="primary" />
								) : (
									<EditIcon fontSize="small" color="primary" />
								)}
							</IconButton>
						</Grid>
						<Grid item xs={12}>
							<Controller
								name="note"
								render={props => (
									<TextField disabled={!open} fullWidth multiline rows={5} {...props} />
								)}
							/>
						</Grid>
						{open && (
							<Grid item container justifyContent="flex-end">
								<Button
									name="finishNote"
									disabled={!formState.isDirty}
									type="submit"
									sx={{ width: 170 }}
									variant="contained"
								>
									{intl.formatMessage({ id: 'finish' })}
								</Button>
							</Grid>
						)}
					</Grid>
				</form>
			</Grid>
		</Grid>
	)
}

export default NotesTab
