import React from 'react'
import { Grid, Typography, Divider } from '@material-ui/core'
import { alpha } from '@material-ui/core/styles'
import { LoadingButton } from '@material-ui/lab'
import { useTheme } from 'styled-components'

import useIntl from 'hooks/useIntl'
import CheckIcon from 'assets/icons/CheckIcon'
import ButtonLink from 'components/ButtonLink'
import { CardContainer } from './SubscriptionCard.styles'

export interface SubscriptionCardProps {
	/**
	 * Defines the title of the subscription
	 */
	title: string
	/**
	 * Defines the description of the subscription
	 */
	desc: string
	/**
	 * Defines the description of the subscription
	 */
	pricingDesc?: string
	/**
	 * Defines the loading status of the activate button
	 */
	loading?: boolean
	/**
	 * Defines if the card is selected
	 */
	selected?: boolean
	/**
	 * Defines the price of the subscription
	 */
	price: number
	/**
	 * Defines the paymentType of the subscription
	 */
	packageType: 'monthly' | 'yearly'
	/**
	 * Defines the services provided for the subscription
	 */
	services: string[]
	/**
	 * Callback fired when the activate plan is clicked
	 */
	onActivate: () => void
	/**
	 * Callback fired when the calculate button is clicked
	 * If set, calculate button will render
	 */
	onShowCalculateModal?: () => void
}

export const SubscriptionCard: React.FC<SubscriptionCardProps> = ({
	title,
	desc,
	pricingDesc,
	price,
	selected,
	packageType,
	services,
	loading,
	onShowCalculateModal,
	onActivate
}) => {
	const intl = useIntl()
	const theme = useTheme()
	return (
		<CardContainer container flexDirection={onShowCalculateModal ? 'row' : 'column'}>
			<Grid container spacing={2} flex={!onShowCalculateModal ? '1 1 auto' : undefined}>
				<Grid item container spacing={1}>
					<Grid item xs={12}>
						<Typography sx={{ color: theme.grey.main, fontSize: '12px' }}>
							{desc}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="paragraph1" fontWeight={500}>
							{title}
						</Typography>
					</Grid>
				</Grid>
				<Grid item container spacing={1}>
					{pricingDesc && (
						<Grid item xs={12}>
							<Typography sx={{ color: theme.grey.main, fontSize: '12px' }}>
								{pricingDesc}
							</Typography>
						</Grid>
					)}
					<Grid item xs={12}>
						<Typography
							variant="h5"
							fontWeight={500}
							sx={{ '& span': { fontSize: 12, color: theme.grey.main, fontWeight: 400 } }}
						>
							${price} <span>/ {intl.formatMessage({ id: `payment.${packageType}` })}</span>
						</Typography>
					</Grid>
					<Grid item xs={12} sx={{ display: onShowCalculateModal ? 'static' : 'none' }}>
						<ButtonLink
							onClick={e => {
								e.stopPropagation()
								onShowCalculateModal?.()
							}}
						>
							{intl.formatMessage({ id: 'payment.calculate.cost' })}
						</ButtonLink>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Divider />
				</Grid>
				<Grid item container spacing={2} alignItems="center">
					{services.map(service => (
						<Grid item xs={12} key={service}>
							<Grid container alignItems="center">
								<Grid item>
									<CheckIcon
										sx={{
											fill: theme.tag.green,
											marginRight: '5px'
										}}
									/>
								</Grid>
								<Grid item xs>
									<Typography align="left" sx={{ fontSize: 12 }}>
										{service}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					))}
				</Grid>
				<Grid item xs={12} style={{ marginTop: 'auto' }}>
					<LoadingButton
						onClick={onActivate}
						variant="contained"
						sx={{
							fontSize: 12,
							backgroundColor: theme.tag.blue,
							'&:hover': { backgroundColor: alpha(theme.tag.blue, 0.8) }
						}}
						fullWidth
						pending={selected && loading}
					>
						{intl.formatMessage({ id: 'activate' })}
					</LoadingButton>
				</Grid>
			</Grid>
		</CardContainer>
	)
}

export default SubscriptionCard
