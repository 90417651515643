import React from 'react'
import useLogo from 'hooks/useLogo'

export interface LogoProps extends React.ComponentPropsWithoutRef<'img'> {
	size?: Parameters<typeof useLogo>[0]
	mode?: Parameters<typeof useLogo>[1]
	width?: number
	resize?: boolean
}

export const Logo = ({ size, mode, width = 143, resize = false, ...rest }: LogoProps) => {
	const [logo, imgSize] = useLogo(size, mode)
	return (
		<img
			src={logo}
			width={size ? width : imgSize === 'large' ? width : 35}
			alt="Nugget Logo"
			{...rest}
		/>
	)
}

export default Logo
