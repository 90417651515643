import styled from 'styled-components'
import { pxToRem } from 'packages/nuggetai.ui-kit/themes/utils'

export const Value = styled.span`
	font-size: ${pxToRem(13)};
	width: 100%;
	left: 0;
	text-align: center;
	top: 50%;
	position: absolute;
`

export const Container = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: fit-content;
`
