import React from 'react'
import { Card } from '@material-ui/core'
import styled from 'styled-components'
import FlexOne from './FlexOne'
import FlexTwo from './FlexTwo'
import { AccessTimeOutlined, CheckOutlined, TocOutlined } from '@material-ui/icons'

const Container = styled.div`
	display: flex;
	width: 100%;
	gap: 20px;
	@media (max-width: 768px) {
		flex-direction: column;
		gap: 10px;
	}
`

const StyledCard = styled(Card)`
	flex: 7;
	padding: 20px;
	@media (max-width: 768px) {
		flex: none;
		width: 100%;
	}
`

const FlexContainer = styled(Card)`
	flex: 3;
	padding: 20px;

	@media (max-width: 768px) {
		flex: none;
		width: 100%;
	}
`

const attemptsData = [
	{
		name: 'Completed Assessment',
		time: '3 minutes ago',
		icon: <TocOutlined />,
		color: '#f9e79f',
		backgroundColor: 'lightyellow'
	},
	{
		name: 'Started Assessment',
		time: '16 minutes ago',
		icon: <AccessTimeOutlined />,
		color: 'orange',
		backgroundColor: 'lightyellow'
	},
	{
		name: 'Completed Assessment',
		time: '1 hour ago',
		icon: <CheckOutlined />,
		color: 'green',
		backgroundColor: '#dff8c0'
	}
]

interface RecentAssessmentProps {
	assessId: string // Accept the assessId as a prop
}

const RecentAssessment = ({ assessId }: RecentAssessmentProps) => {
	return (
		<Container>
			<StyledCard>
				<FlexOne assessId={assessId} />
			</StyledCard>
			{/* <FlexContainer>
				<FlexTwo attempts={attemptsData} />
			</FlexContainer> */}
		</Container>
	)
}

export default RecentAssessment
