import styled from 'styled-components'
import { SvgIcon } from '@material-ui/core'

export const StyledSvgIcon = styled(SvgIcon)`
	.cls-1,
	.cls-10,
	.cls-12,
	.cls-8,
	.cls-9 {
		fill: none;
		stroke-miterlimit: 10;
	}
	.cls-1 {
		stroke: #1a76d1;
	}
	.cls-1,
	.cls-12,
	.cls-9 {
		stroke-width: 7px;
	}
	.cls-2 {
		fill: #fcd930;
	}
	.cls-3 {
		fill: #f40157;
	}
	.cls-4 {
		fill: #fe8e02;
	}
	.cls-5 {
		fill: #1a76d1;
	}
	.cls-6 {
		fill: #fff;
	}
	.cls-7 {
		fill: #333;
	}
	.cls-8 {
		stroke: #fff;
		stroke-linecap: round;
		stroke-width: 5px;
	}
	.cls-10,
	.cls-9 {
		stroke: #fcd930;
	}
	.cls-10 {
		stroke-width: 6px;
	}
	.cls-11 {
		fill: #fbc02e;
	}
	.cls-12 {
		stroke: #f40157;
	}
`
