import styled from 'styled-components'
import { Paper } from '@material-ui/core'

export const Card = styled(Paper)`
	padding: 56px 32px;
	background-color: ${props => props.theme.background.calm};
	border: 2px solid transparent;
	height: 100%;
	width: 100%;
	border-radius: 10px;

	&:hover {
		border-color: ${props => props.theme.primary.main};
	}
	transition: all 0.3s ease-in-out;
`
