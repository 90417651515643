import styled from 'styled-components'
import { Grid } from '@material-ui/core'

export const CardContainer = styled(Grid)`
	height: 100%;
	border-radius: 10px;
	padding: 20px 30px;
	border: 1px solid ${props => props.theme.background.summary};
	width: 100%;
`
