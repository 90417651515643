import { useState, useMemo, useEffect, useCallback } from 'react'
import { useStoreon } from 'storeon/react'
import useToggle from 'hooks/useToggle'
import { SubscriptionCardProps } from 'components/SubscriptionCard'
import { useSearchParams } from 'react-router-dom'
import { loadStripe } from '@stripe/stripe-js'
import iziToast from 'izitoast'

import useIntl from 'hooks/useIntl'
import { useCreateCheckoutSessionMutation } from './useApollo'

export type IuseCheckout = ReturnType<typeof useCheckout>

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string)

const benchmarking = 'Benchmarking_Monthly'
const hiring = 'Hiring_Monthly'

export const useCheckout = () => {
	const [selected, setSelected] = useState('')
	const { token } = useStoreon('token')
	const [searchParams] = useSearchParams()
	const intl = useIntl()
	const [{ open }, { handleOpen, handleClose }] = useToggle()

	const checkoutResult = searchParams.get('checkoutResult')

	useEffect(() => {
		if (!checkoutResult) {
			return
		}

		if (checkoutResult === 'successful') {
			iziToast.success({
				message: 'You are now subscribed to x.'
			})

			return
		}

		if (checkoutResult === 'canceled') {
			iziToast.error({
				message: 'Checkout cancelled.'
			})
		}
	}, [checkoutResult])

	const [createCheckoutSession, { loading }] = useCreateCheckoutSessionMutation({
		onCompleted: async data => {
			const sessionId = data.createCheckoutSession
			const stripe = await stripePromise

			if (!stripe) {
				return
			}

			await stripe.redirectToCheckout({
				sessionId
			})
		}
	})

	const handleActivatePlan = useCallback(
		(params: { planName: string; fromSignup?: boolean }) => {
			const cancelUrl =
				!params?.fromSignup || !token
					? window.location.href
					: `${window.location.origin}/auth/verify-token/${encodeURI(token)}?new=true`

			setSelected(params.planName)
			const [planType, interval] = params.planName.split('_')
			createCheckoutSession({
				variables: {
					plans: {
						planType: planType as any,
						interval: interval as any
					},
					successUrl: window.location.origin,
					cancelUrl
				}
			})
		},
		[createCheckoutSession, token]
	)

	const plans: { [key: string]: Omit<SubscriptionCardProps, 'onActivate'> } = useMemo(
		() => ({
			[benchmarking]: {
				title: 'Talent benchmarking',
				desc: 'For teams and managers',
				pricingDesc: intl.formatMessage({ id: 'payment.startingAt' }),
				packageType: 'monthly',
				selected: selected === benchmarking,
				price: 600,
				services: [
					'Minimum 20 users per benchmark',
					'Includes team performance data',
					'Individual and team level reporting'
				],
				onShowCalculateModal: handleOpen
			},
			[hiring]: {
				title: 'Talent acquisition',
				desc: 'For recruiters',
				pricingDesc: intl.formatMessage({ id: 'payment.simple.pricing' }),
				packageType: 'monthly',
				selected: selected === hiring,
				price: 500,
				services: [
					'Use industry skills benchmark to measure your candidates skills',
					'Use unlimited skills benchmark to measure your candidates skills ',
					'Email support, basic reporting and talent pipeline managment'
				]
			}
		}),
		[handleOpen, intl, selected]
	)

	const models = {
		open,
		plans,
		loading
	}
	const operations = {
		handleOpen,
		handleClose,
		handleActivatePlan
	}

	return [models, operations] as [typeof models, typeof operations]
}

export default useCheckout
