import { useState } from 'react'

export type IuseToggleAnchor = ReturnType<typeof useToggleAnchor>

export const useToggleAnchor = () => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

	const handleOpenAnchor = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleCloseAnchor = () => {
		setAnchorEl(null)
	}

	const handleOpenAttemptsAnchor = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleCloseAttemptsAnchor = () => {
		setAnchorEl(null)
	}

	const handleToggleAnchor = (event: React.MouseEvent<HTMLElement>) => {
		if (!anchorEl) {
			setAnchorEl(event.currentTarget)
			return
		}

		setAnchorEl(null)
	}

	const models = { anchorEl }
	const operations = {
		handleOpenAnchor,
		handleCloseAnchor,
		handleToggleAnchor,
		handleOpenAttemptsAnchor,
		handleCloseAttemptsAnchor
	}

	return [models, operations] as [typeof models, typeof operations]
}

export default useToggleAnchor
