import React from 'react'
import { LoadingButton, LoadingButtonProps } from '@material-ui/lab'

export interface ContinueButtonProps extends Omit<LoadingButtonProps, 'children'> {
	isFinish?: boolean
	continueText?: string
	finishText?: string
}

export const ContinueButton = ({
	isFinish,
	continueText = 'Continue',
	finishText = 'Finish',
	...rest
}: ContinueButtonProps) => (
	<LoadingButton variant="contained" {...rest}>
		{!isFinish ? continueText : finishText}
	</LoadingButton>
)

export default ContinueButton
