import styled from 'styled-components'
import { pxToRem } from 'packages/nuggetai.ui-kit/themes/utils'
import { RequirementProps } from './Requirement'

export const RequirementContainer = styled.div<Pick<RequirementProps, 'disabled'>>`
	font-size: ${pxToRem(13)};
	display: flex;
	align-items: center;
	color: ${props => (props.disabled ? props.theme.border : props.theme.text)};
`

export const Dot = styled.div<Pick<RequirementProps, 'disabled'>>`
	display: block;
	border-radius: 50%;
	width: 8px;
	height: 8px;
	background-color: ${props =>
		props.disabled ? props.theme.border : props.theme.primary.main};
`
