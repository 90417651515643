import React from 'react'
import { Grid, Hidden, Typography } from '@material-ui/core'
import { pxToRem } from 'packages/nuggetai.ui-kit/themes/utils'
import useIntl from 'hooks/useIntl'
import { useTheme } from 'styled-components'
import ProfilesBubbleIcon from 'assets/icons/ProfilesBubbleIcon'
import { TalentProfileContainer } from './TalentProfile.styles'

export const EmptyTalentProfile = () => {
	const intl = useIntl()
	const theme = useTheme()

	return (
		<TalentProfileContainer>
			<Grid container item sx={{ height: '100%' }} alignItems="center">
				<Grid container justifyContent="center" alignItems="center" spacing={3}>
					<Grid item container justifyContent="center" alignItems="center">
						<ProfilesBubbleIcon />
					</Grid>
					<Grid item container justifyContent="center" alignItems="center">
						<Typography variant="h4">
							{intl.formatMessage({ id: 'teams.profile.noTalentSelect' })}
						</Typography>
					</Grid>
					<Grid
						container
						item
						alignItems="center"
						justifyContent="center"
						sx={{ color: theme.grey.main }}
					>
						<Hidden mdDown>
							<Grid item sx={{ display: 'flex' }}>
								<i className="bx bx-left-arrow-alt" style={{ fontSize: pxToRem(16) }} />
							</Grid>
							<Grid item>
								<Typography variant="paragraph1">
									{intl.formatMessage(
										{ id: 'teams.profile.clickOnProfile' },
										{ direction: 'left' }
									)}
								</Typography>
							</Grid>
						</Hidden>
						<Hidden mdUp>
							<Grid item sx={{ display: 'flex' }}>
								<i className="bx bx-up-arrow-alt" style={{ fontSize: pxToRem(16) }} />
							</Grid>
							<Grid item>
								<Typography variant="paragraph1">
									{intl.formatMessage(
										{ id: 'teams.profile.clickOnProfile' },
										{ direction: 'up section' }
									)}
								</Typography>
							</Grid>
						</Hidden>
					</Grid>
				</Grid>
			</Grid>
		</TalentProfileContainer>
	)
}

export default EmptyTalentProfile
