import React from 'react'
import { useRoutes } from 'react-router-dom'
import { accountSettingsRoutes } from 'routes/accountSettings'
import { Grid } from '@material-ui/core'
import AlertStack from 'components/AlertStack'

import Sidebar from './components/Sidebar'
import { AccountSettingsMain } from './AccountSettingsLayout.style'

export const AccountSettingsLayout: React.FC = () => {
	const routes = useRoutes(accountSettingsRoutes)

	return (
		<Grid container>
			<AccountSettingsMain>
				<Grid container justifyContent="center">
					<Grid item xs={12}>
						<AlertStack />
					</Grid>
					<Grid
						item
						container
						sx={{
							maxWidth: {
								md: '900px',
								sm: '500px'
							}
						}}
					>
						<Grid item xs={12} md={4} lg={3}>
							<Sidebar />
						</Grid>
						<Grid item xs={12} md={8} lg={9}>
							{routes}
						</Grid>
					</Grid>
				</Grid>
			</AccountSettingsMain>
		</Grid>
	)
}

export default AccountSettingsLayout
