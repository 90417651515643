import React, { useEffect } from 'react'
import {
	TextField,
	Autocomplete,
	Grid,
	Divider,
	FormControlLabel,
	Checkbox,
	useMediaQuery,
	Theme
} from '@material-ui/core'
import iziToast from 'izitoast'
import Modal, { IModalProps } from 'components/Modal'
import {
	useGetEmailReminderSuggestionsLazyQuery,
	useSendEmailReminderMutation
} from 'hooks/useApollo'
import useIntl from 'hooks/useIntl'
import { useSendEmailReminder, SendEmailReminderForm } from './useSendEmailReminderForm'

export interface SendEmailReminderModalProps extends Pick<IModalProps, 'open' | 'onClose'> {
	/**
	 * Defines the unique id of the talent group
	 */
	id: string
	/**
	 * Defines the default email in the options
	 */
	defaultEmail?: string
	/**
	 * Defines the list of emails of your talent group
	 */
	options?: string[]
}

export const SendEmailReminderModal: React.FC<SendEmailReminderModalProps> = ({
	id,
	open,
	onClose,
	defaultEmail
}) => {
	const intl = useIntl()
	const [getEmailReminderSuggestions, { data }] = useGetEmailReminderSuggestionsLazyQuery()
	const [
		{ errors, watch },
		{ handleChange, handleCheck, renderErrMessage, onSubmit },
		{ Controller }
	] = useSendEmailReminder(defaultEmail)

	const emails = watch('emails')
	const checked = watch('checked')

	const emailSuggestions = data?.emailSuggestions || []

	const [sendEmailReminder, { loading }] = useSendEmailReminderMutation({
		onCompleted: () => {
			const recipientCount = checked ? emailSuggestions.length : emails.length

			iziToast.success({
				message: intl.formatMessage(
					{
						id: 'sendEmailReminder.success'
					},
					{ recipientCount }
				)
			})
			onClose()
		}
	})
	const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

	useEffect(() => {
		if (id) {
			getEmailReminderSuggestions({
				variables: {
					pipelineId: id
				}
			})
		}
		// eslint-disable-next-line
	}, [id])

	const handleSubmit = (payload: SendEmailReminderForm) => {
		sendEmailReminder({
			variables: {
				emails: checked ? undefined : payload.emails,
				sendToAll: payload.checked,
				pipelineId: id
			}
		})
	}

	// @ts-ignore
	const error = errors && errors?.emails?.length > 0

	return (
		<Modal
			open={open}
			buttonStyle="primary"
			okText={intl.formatMessage({ id: 'sendEmailReminder.btnLabel' })}
			onClose={onClose}
			size={mdDown ? 'normal' : 'big'}
			onOk={onSubmit(handleSubmit)}
			okLoading={loading}
			closeOnOk={false}
			onOkDisabled={emails.length === 0 && !checked}
			title={intl.formatMessage({ id: 'sendEmailReminder.title' })}
		>
			<Grid container spacing={4}>
				<Grid item container>
					<Controller
						name="emails"
						render={({ value }) => (
							<Autocomplete
								multiple
								fullWidth
								id={id}
								options={emailSuggestions}
								filterSelectedOptions
								onChange={handleChange}
								value={value}
								disabled={checked}
								renderInput={params => (
									<TextField
										{...params}
										disabled={checked}
										label={intl.formatMessage({ id: 'sendEmailReminder.label' })}
										placeholder={intl.formatMessage({
											id: 'sendEmailReminder.placeholder'
										})}
										error={error}
										helperText={
											error
												? renderErrMessage()
												: intl.formatMessage({
														id: 'sendEmailReminder.helperText'
												  })
										}
									/>
								)}
							/>
						)}
					/>
				</Grid>
				<Grid sx={{ my: -2 }} item xs={12}>
					<Divider orientation="horizontal" variant="fullWidth">
						{intl.formatMessage({ id: 'or' })}
					</Divider>
				</Grid>
				<Grid item container>
					<Controller
						name="checked"
						render={({ value, onBlur }) => (
							<FormControlLabel
								control={
									// eslint-disable-next-line react/jsx-wrap-multilines
									<Checkbox
										color="primary"
										checked={value}
										onChange={handleCheck}
										onBlur={onBlur}
									/>
								}
								label={intl.formatMessage({ id: 'talentGroup.sendNotification' })}
								labelPlacement="end"
							/>
						)}
					/>
				</Grid>
			</Grid>
		</Modal>
	)
}

export default SendEmailReminderModal
