import React, { useState } from 'react'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import {
	Typography,
	LinearProgress,
	useMediaQuery,
	Theme,
	Divider
} from '@material-ui/core'
import {
	WrapperDiv,
	ArrowPointer,
	CustomTimeline,
	CustomTimelineDot,
	CustomTimelineConnector
} from './ExamListTimeLine.style'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined'
import CropLandscapeOutlinedIcon from '@material-ui/icons/CropLandscapeOutlined'
import DonutSmallOutlinedIcon from '@material-ui/icons/DonutSmallOutlined'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'

const colors = ['#FFABAB', '#90EE90']

const ExamListTimeLine: React.FC = () => {
	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
	const [expandedIndex, setExpandedIndex] = useState<number | null>(null)

	const data = [
		{
			date: 'Jun 04,2024',
			time: '12:00 PM',
			title: 'Chandu Nallakula',
			description: 'TechLeaders-Assessment',
			progress: 50,
			finish: '4 Minutes ago',
			ipaddress: '123.123.123.123',
			location: 'Mumbai, Maharashtra, India',
			platform: 'Windows',
			browser: 'Chrome (126.0.0)',
			examduration: '02:00 (hh:mm)'
		},
		{
			date: 'Jul 10,2024',
			time: '2:30 PM',
			title: 'React Bootcamp',
			description: 'Frontend Developer Workshop',
			progress: 70,
			finish: '10 Minutes ago',
			ipaddress: '192.168.1.1',
			location: 'Bangalore, Karnataka, India',
			platform: 'macOS',
			browser: 'Safari (15.4)',
			examduration: '03:00 (hh:mm)'
		},
		{
			date: 'Aug 15,2024',
			time: '9:00 AM',
			title: 'Project Planning',
			description: 'Team Strategy Meeting',
			progress: 80,
			finish: '1 Hour ago',
			ipaddress: '172.16.0.1',
			location: 'Hyderabad, Telangana, India',
			platform: 'Linux',
			browser: 'Firefox (92.0)',
			examduration: '01:30 (hh:mm)'
		},
		{
			date: 'Sep 05,2024',
			time: '11:00 AM',
			title: 'UI/UX Design',
			description: 'Design Thinking Workshop',
			progress: 90,
			finish: '30 Minutes ago',
			ipaddress: '10.10.10.10',
			location: 'Chennai, Tamil Nadu, India',
			platform: 'Windows',
			browser: 'Edge (93.0)',
			examduration: '02:30 (hh:mm)'
		},
		{
			date: 'Oct 12,2024',
			time: '3:45 PM',
			title: 'API Development',
			description: 'Backend Systems Workshop',
			progress: 65,
			finish: '5 Minutes ago',
			ipaddress: '203.0.113.1',
			location: 'Kolkata, West Bengal, India',
			platform: 'Linux',
			browser: 'Opera (80.0)',
			examduration: '02:00 (hh:mm)'
		},
		{
			date: 'Nov 22,2024',
			time: '1:15 PM',
			title: 'AI & ML Basics',
			description: 'Artificial Intelligence Introduction',
			progress: 75,
			finish: '20 Minutes ago',
			ipaddress: '192.168.100.1',
			location: 'Delhi, India',
			platform: 'macOS',
			browser: 'Safari (14.0)',
			examduration: '01:45 (hh:mm)'
		},
		{
			date: 'Dec 03,2024',
			time: '10:00 AM',
			title: 'Blockchain Essentials',
			description: 'Blockchain Technology Overview',
			progress: 60,
			finish: '1 Hour ago',
			ipaddress: '198.51.100.2',
			location: 'Pune, Maharashtra, India',
			platform: 'Windows',
			browser: 'Chrome (94.0)',
			examduration: '02:00 (hh:mm)'
		},
		{
			date: 'Jan 15,2025',
			time: '4:30 PM',
			title: 'Cloud Computing Basics',
			description: 'Cloud Architecture Workshop',
			progress: 85,
			finish: '15 Minutes ago',
			ipaddress: '203.0.113.5',
			location: 'Kochi, Kerala, India',
			platform: 'macOS',
			browser: 'Firefox (94.0)',
			examduration: '03:00 (hh:mm)'
		},
		{
			date: 'Feb 18,2025',
			time: '8:30 AM',
			title: 'DevOps Workshop',
			description: 'CI/CD and Automation',
			progress: 30,
			finish: '30 Minutes ago',
			ipaddress: '192.168.200.3',
			location: 'Noida, Uttar Pradesh, India',
			platform: 'Linux',
			browser: 'Edge (95.0)',
			examduration: '02:30 (hh:mm)'
		},
		{
			date: 'Mar 22,2025',
			time: '5:00 PM',
			title: 'Cybersecurity Basics',
			description: 'Ethical Hacking and Security',
			progress: 55,
			finish: '1 Hour ago',
			ipaddress: '172.31.0.4',
			location: 'Jaipur, Rajasthan, India',
			platform: 'Windows',
			browser: 'Safari (16.0)',
			examduration: '01:45 (hh:mm)'
		}
	]

	const toggleExpand = (index: number) => {
		setExpandedIndex(expandedIndex === index ? null : index)
	}

	return (
		<CustomTimeline>
			{data.map((event, index) => (
				<TimelineItem key={index} style={{ marginTop: '20px' }}>
					<TimelineOppositeContent
						sx={{
							display: 'flex',
							justifyContent: 'flex-end',
							alignItems: 'center',
							marginTop: '-34px',
							flex: '0 !important',
							whiteSpace: 'nowrap',
							'@media (max-width: 350px)': {
								display: 'none'
							}
						}}
						color="textSecondary"
					>
						<div>
							<Typography
								variant="body2"
								sx={{ fontSize: isMobile ? '0.75rem' : '0.875rem', color: 'gray' }}
							>
								{event.date}
							</Typography>
							<Typography
								variant="body2"
								sx={{ fontSize: isMobile ? '0.75rem' : '0.875rem', color: 'gray' }}
							>
								{event.time}
							</Typography>
						</div>
					</TimelineOppositeContent>
					<TimelineSeparator>
						<CustomTimelineDot color={colors[index % colors.length]} />
						<CustomTimelineConnector
							style={index === data.length - 1 ? { display: 'none' } : {}}
						/>
					</TimelineSeparator>
					<TimelineContent>
						<WrapperDiv
							color={colors[index % colors.length]}
							onClick={() => toggleExpand(index)}
							style={{
								position: 'relative'
							}}
						>
							<ArrowPointer color={colors[index % colors.length]} />
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									padding: '10px 15px'
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between'
									}}
								>
									<div>
										<Typography variant="body2" sx={{ fontWeight: '500' }}>
											{event.title}
										</Typography>
										<Typography variant="body2" sx={{ color: 'gray' }}>
											{event.description}
										</Typography>
									</div>
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											gap: '5px',
											justifyContent: 'center'
										}}
									>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												justifyContent: 'space-between'
											}}
										>
											<Typography style={{ fontSize: '14px' }}>Progress</Typography>
											<Typography style={{ fontSize: '16px' }}>
												{event.progress}%
											</Typography>
										</div>
										<div>
											<LinearProgress
												variant="determinate"
												value={event.progress}
												style={{ height: '6px', width: '150px', borderRadius: '9px' }}
											/>
										</div>
									</div>
								</div>
								{expandedIndex === index && (
									<>
										<Divider
											sx={{
												borderWidth: '1px',
												color: 'black',
												marginTop: '5px',
												marginBottom: '5px'
											}}
										/>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												justifyContent: 'space-between'
											}}
										>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													gap: '5px',
													justifyContent: 'center'
												}}
											>
												<AccessTimeIcon style={{ fontSize: '20px' }} />
												<Typography style={{ fontSize: '14px' }}>Finished :</Typography>
											</div>
											<Typography style={{ fontSize: '14px' }}>{event.finish}</Typography>
										</div>
										<Divider
											sx={{
												borderWidth: '1px',
												color: 'black',
												marginTop: '5px',
												marginBottom: '5px'
											}}
										/>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												justifyContent: 'space-between'
											}}
										>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													gap: '5px',
													justifyContent: 'center',
													alignItems: 'center'
												}}
											>
												<LocationOnOutlinedIcon style={{ fontSize: '20px' }} />
												<Typography style={{ fontSize: '14px' }}>Ip Address :</Typography>
											</div>
											<div
												style={{
													display: 'flex',
													flexDirection: 'column',
													justifyContent: 'flex-end'
												}}
											>
												<Typography
													style={{
														fontSize: '14px',
														display: 'flex',
														justifyContent: 'flex-end'
													}}
												>
													{event.ipaddress}
												</Typography>
												<Typography
													variant="body2"
													sx={{ color: 'gray', fontSize: '14px' }}
												>
													{event.location}
												</Typography>
											</div>
										</div>
										<Divider
											sx={{
												borderWidth: '1px',
												color: 'black',
												marginTop: '5px',
												marginBottom: '5px'
											}}
										/>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												justifyContent: 'space-between'
											}}
										>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													gap: '5px',
													justifyContent: 'center',
													alignItems: 'center'
												}}
											>
												<CropLandscapeOutlinedIcon style={{ fontSize: '20px' }} />
												<Typography style={{ fontSize: '14px' }}>Platform :</Typography>
											</div>
											<div
												style={{
													display: 'flex',
													flexDirection: 'column',
													justifyContent: 'flex-end'
												}}
											>
												<Typography
													style={{
														fontSize: '14px',
														display: 'flex',
														justifyContent: 'flex-end'
													}}
												>
													{event.platform}
												</Typography>
												<Typography
													variant="body2"
													sx={{ color: 'gray', fontSize: '14px' }}
												>
													{event.browser}
												</Typography>
											</div>
										</div>
										<Divider
											sx={{
												borderWidth: '1px',
												color: 'black',
												marginTop: '5px',
												marginBottom: '5px'
											}}
										/>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												justifyContent: 'space-between'
											}}
										>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													gap: '5px',
													justifyContent: 'center',
													alignItems: 'center'
												}}
											>
												<DonutSmallOutlinedIcon style={{ fontSize: '20px' }} />
												<Typography style={{ fontSize: '14px' }}>Result :</Typography>
											</div>
											<div
												style={{
													display: 'flex',
													flexDirection: 'column',
													justifyContent: 'flex-end',
													gap: '5px'
												}}
											>
												<Typography
													style={{
														fontSize: '14px',
														display: 'flex',
														justifyContent: 'flex-end'
													}}
												>
													{event.progress >= 35
														? `pass (${event.progress}%)`
														: `fail (${event.progress}%)`}
												</Typography>
												<LinearProgress
													variant="determinate"
													value={event.progress}
													style={{ height: '6px', width: '150px', borderRadius: '9px' }}
												/>
											</div>
										</div>
										<Divider
											sx={{
												borderWidth: '1px',
												color: 'black',
												marginTop: '5px',
												marginBottom: '5px'
											}}
										/>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												justifyContent: 'space-between',
												marginBottom: '20px'
											}}
										>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													gap: '5px',
													justifyContent: 'center',
													alignItems: 'center'
												}}
											>
												<AccessTimeIcon style={{ fontSize: '20px' }} />
												<Typography style={{ fontSize: '14px' }}>ExamDuration :</Typography>
											</div>
											<div
												style={{
													display: 'flex',
													flexDirection: 'column',
													justifyContent: 'flex-end',
													gap: '5px'
												}}
											>
												<Typography
													style={{
														fontSize: '14px',
														display: 'flex',
														justifyContent: 'flex-end'
													}}
												>
													{event.examduration}
												</Typography>
											</div>
										</div>
									</>
								)}
							</div>
							{expandedIndex === index && (
								<div
									style={{
										position: 'absolute',
										bottom: 0,
										width: '100%',
										height: '20px',
										backgroundColor: colors[index % colors.length],
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										color: 'white',
										fontSize: '12px'
									}}
								>
									<OpenInNewIcon style={{ fontSize: '16px' }} />
									Reopen Exam
								</div>
							)}
						</WrapperDiv>
					</TimelineContent>
				</TimelineItem>
			))}
		</CustomTimeline>
	)
}

export default ExamListTimeLine
