import React from 'react'
import { Card, CardContent, Typography, Box, Divider, Grid } from '@material-ui/core'

import { LoadingButton } from '@material-ui/lab'
import { theme } from 'packages/nuggetai.ui-kit/themes/base'
import CheckIcon from 'assets/icons/CheckIcon'

const PlanCard = ({ name, heading, subName, price, additionalParagraphs }) => {
	return (
		<Box sx={{ mt: 3 }}>
			<Grid container spacing={3}>
				<Card
					sx={{ border: '1px solid lightgray', width: '360px', ml: 3.5, borderRadius: 2 }}
				>
					<CardContent sx={{ ml: 1, p: 2.5 }}>
						<Box>
							<Typography sx={{ color: theme.grey.main, fontSize: '12px', mb: 1 }}>
								{name}
							</Typography>
							<Typography variant="paragraph1" fontWeight={500}>
								{heading}
							</Typography>
							<Typography sx={{ color: theme.grey.main, fontSize: '12px', mt: 2 }}>
								{subName}
							</Typography>
							<Typography variant="h5" fontWeight={500} sx={{ mt: 1 }}>
								₹{price}
								<span
									style={{
										fontSize: 12,
										color: theme.grey.main,
										fontWeight: 400,
										marginLeft: 4
									}}
								>
									/month
								</span>
							</Typography>
							<Divider sx={{ mt: 2 }} />

							<Box sx={{ mt: 2 }}>
								{additionalParagraphs.map((text, index) => (
									<Box
										key={index}
										sx={{
											display: 'flex',
											alignItems: 'center',
											mt: 1,
											mb: index !== additionalParagraphs.length - 1 ? 2 : 0
										}}
									>
										<CheckIcon
											sx={{
												fill: theme.tag.green,
												marginRight: '5px'
											}}
										/>
										<Typography align="left" sx={{ fontSize: 12 }}>
											{text}
										</Typography>
									</Box>
								))}
							</Box>

							<Grid item xs={12} style={{ marginTop: 30 }}>
								<LoadingButton
									variant="contained"
									sx={{
										fontSize: 12,
										backgroundColor: theme.tag.blue,
										'&:hover': { backgroundColor: '#1E90FF' }
									}}
									fullWidth
								>
									Activate
								</LoadingButton>
							</Grid>
						</Box>
					</CardContent>
				</Card>
			</Grid>
		</Box>
	)
}

export default PlanCard
