import styled, { keyframes } from 'styled-components'

interface LoadDotProps {
	/**
	 * The index of the dot
	 */
	i: number
	/**
	 * The value to be used as width, height and margin between the dots
	 */
	size?: number
}

const move = keyframes`
	0% {
		transform: translateY(0%);
	}
	25% {
		transform: translateY(-40%)

	}
	50% {
		transform: translateY(-80%)
	}
	75% {
		transform: translateY(10%);
	}
	100% {
		transform: translateY(0%);
	}
`

export const LoadDot = styled.div<LoadDotProps>`
	transform: none;
	animation: ${move} 1s linear infinite forwards;
	animation-delay: ${({ i }) => `calc(${i} * 0.15s)`};
	width: ${({ size = 3 }) => `${size}px`};
	height: ${({ size = 3 }) => `${size}px`};
	margin-right: ${({ size = 3 }) => `${size}px`};
	background: currentColor;
	border-radius: 50%;
	z-index: 1;
`
