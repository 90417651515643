// WebSocketContext.tsx
import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	ReactNode,
	useRef
} from 'react'
import useWebSocket, { ReadyState } from 'react-use-websocket'

// Define types
type RefetchFunction = () => Promise<any> | void

interface WebSocketContextValue {
	sendMessage: (message: string | ArrayBufferLike | Blob | ArrayBufferView) => void
	lastMessage: MessageEvent<any> | null
	readyState: ReadyState
	messageHistory: MessageEvent<any>[]
	registerRefetch: (refetchFn: RefetchFunction) => () => void
}

interface WebSocketProviderProps {
	children: ReactNode
	socketUrl: string
}

// Create context with default values
const WebSocketContext = createContext<WebSocketContextValue | undefined>(undefined)

export function WebSocketProvider({
	children,
	socketUrl
}: WebSocketProviderProps): JSX.Element {
	const refetchFunctionsRef = useRef<RefetchFunction[]>([])
	const [messageHistory, setMessageHistory] = useState<MessageEvent<any>[]>([])

	const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl)

	// Handle incoming messages
	useEffect(() => {
		console.log('readyState:', readyState)

		if (lastMessage) {
			console.log('Message received:', lastMessage)
			setMessageHistory(prev => prev.concat(lastMessage))

			// Trigger all registered refetch functions from the ref
			refetchFunctionsRef.current.forEach(refetch => refetch())
		}
	}, [lastMessage, readyState]) // Remove refetchFunctions from dependencies

	// Register a refetch function using the ref
	const registerRefetch = (refetchFn: RefetchFunction): (() => void) => {
		refetchFunctionsRef.current.push(refetchFn)

		return () => {
			refetchFunctionsRef.current = refetchFunctionsRef.current.filter(
				fn => fn !== refetchFn
			)
		}
	}

	const contextValue: WebSocketContextValue = {
		sendMessage,
		lastMessage,
		readyState,
		messageHistory,
		registerRefetch
	}

	return (
		<WebSocketContext.Provider value={contextValue}>{children}</WebSocketContext.Provider>
	)
}

export function useGlobalWebSocket(): WebSocketContextValue {
	const context = useContext(WebSocketContext)
	if (context === undefined) {
		throw new Error('useGlobalWebSocket must be used within a WebSocketProvider')
	}
	return context
}
