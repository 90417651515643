import styled from 'styled-components'

export const DashboardContainer = styled.div`
	display: flex;
	min-height: 100vh;
	background-color: ${props => props.theme.background.main};

	.MuiDrawer-root {
		width: 295px;

		.MuiDrawer-paper {
			width: 295px;
		}
	}
`

export const DashboardMain = styled.main`
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	padding: 30px 43px;
	@media (max-width: 600px) {
		padding-left: 15px;
		padding-right: 15px;
	}
`
