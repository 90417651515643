import React, { useEffect } from 'react'
import { useTheme } from 'styled-components'
import { Grid, Typography, Divider } from '@material-ui/core'
import useIntl from 'hooks/useIntl'
import ProfileCard, { ProfileCardSkeleton } from 'components/ProfileCard'
import { CoreApplicantItemFieldsFragment } from 'hooks/useApollo'
import SearchBubbleIcon from 'assets/icons/SearchBubbleIcon'
import { useTalentContext, setSelectedTalent, setPinnedUsers } from '../context'
import useTalentProfile from '../TalentProfile/useTalentProfile'

export interface TalentSearchListProps {
	talents: CoreApplicantItemFieldsFragment[]
	loading?: boolean
	pickFirst: boolean
	smDown: boolean
	onPickFirst(): void
}

export const TalentSearchList: React.FC<TalentSearchListProps> = ({
	talents,
	loading,
	pickFirst,
	smDown,
	onPickFirst
}) => {
	const intl = useIntl()
	const theme = useTheme()
	const [{ selectedTalent, pinnedUsers }, dispatch] = useTalentContext()
	const [, { handleBookmark }] = useTalentProfile()
	const height = 1000 - 60 - 52 // subtract tabs header (60px) and pagination components (52px) height

	useEffect(() => {
		const [talent] = talents
		if (!smDown && !loading && talent && pickFirst) {
			dispatch(
				setSelectedTalent({
					...(talent?.user as { firstName: string; lastName: string }),
					id: talent?.id,
					pipelineId: talent?.pipelinesId[0] as string,
					pipelineType: talent?.pipelineType,
					pipelinesId: talent?.pipelinesId,
					masked: !!talent?.masking,
					score: talent?.score || null
				})
			)
			onPickFirst()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading, pickFirst, smDown])

	const loaderCount = 3

	return loading ? (
		<Grid
			container
			alignItems="flex-start"
			sx={{
				height: '100%',
				maxHeight: {
					md: height
				},
				minHeight: height,
				overflowX: 'hidden'
			}}
		>
			<Grid container style={{ direction: 'ltr' }} justifyContent="center">
				{Array(loaderCount)
					.fill(null)
					.map((_, index) => (
						<React.Fragment key={index}>
							<Grid item>
								<ProfileCardSkeleton />
							</Grid>
							{loaderCount - 1 === index ? null : (
								<Grid item xs={12}>
									<Divider />
								</Grid>
							)}
						</React.Fragment>
					))}
			</Grid>
		</Grid>
	) : talents.length === 0 ? (
		<Grid
			container
			direction="column"
			justifyContent="center"
			alignItems="center"
			sx={{
				height: '100%',
				minHeight: height,
				maxHeight: {
					md: height,
					xs: 'none'
				},
				pb: '27px'
			}}
		>
			<Grid item>
				<SearchBubbleIcon style={{ fontSize: 96 }} />
			</Grid>
			<Grid item sx={{ my: 3 }}>
				<Typography variant="h4">
					{intl.formatMessage({ id: 'teams.profile.noTalentFound' })}
				</Typography>
			</Grid>
			<Grid item>
				<Typography variant="paragraph1" sx={{ color: theme.text.description }}>
					{intl.formatMessage({ id: 'teams.profile.noTalentAdded' }, { direction: 'left' })}
				</Typography>
			</Grid>
		</Grid>
	) : (
		<Grid
			container
			alignItems="flex-start"
			sx={{
				height: {
					md: height,
					xs: '100%'
				},
				maxHeight: {
					md: height
				},
				overflowX: 'hidden',
				direction: 'rtl'
			}}
			className="custom-scrollbar"
		>
			<Grid container style={{ direction: 'ltr' }} justifyContent="center">
				{talents.map((talent, index) => {
					const {
						id,
						progress,
						pipelines,
						pipelinesId,
						score,
						user,
						isBookmarked,
						pipelineType,
						masking,
						createdAt
					} = talent
					const pipelineId = pipelinesId[0] || ''
					return (
						<>
							<Grid item key={id} style={{ minWidth: '100%' }}>
								<ProfileCard
									groups={pipelines.map(pipeline => ({
										id: pipeline.id,
										name: pipeline?.name || '',
										color: pipeline?.color || theme.background.light
									}))}
									onClick={() =>
										dispatch(
											setSelectedTalent({
												id,
												pipelineId,
												firstName: user?.firstName || '',
												lastName: user?.lastName || '',
												pipelineType,
												pipelinesId,
												masked: !!masking,
												score: talent?.score || null
											})
										)
									}
									onPin={() =>
										dispatch(
											setPinnedUsers({
												id,
												firstName: user?.firstName || '',
												lastName: user?.lastName || '',
												pipelineId,
												pipelinesId,
												pipelineType,
												masked: !!talent.masking,
												score: talent?.score || null
											})
										)
									}
									isPinned={!!(pinnedUsers && pinnedUsers[id])}
									masked={!!masking}
									onBookmark={() => handleBookmark(id, !isBookmarked)}
									isSelected={selectedTalent.id === id}
									isBookmarked={isBookmarked}
									photoUrl={user?.photoUrl || ''}
									firstName={user?.firstName || ''}
									lastName={user?.lastName || ''}
									addedAt={createdAt}
									// score={score?.score || 0}
									score={
										talent?.score?.skills && talent.score.skills.length > 0
											? talent.score.skills[0].score
											: talent?.score?.score || 0
									}
									type={pipelineType}
									progress={progress || 0}
								/>
							</Grid>
							{talents.length > 0 && talents.length - 1 !== index && (
								<Grid item xs={12}>
									<Divider />
								</Grid>
							)}
						</>
					)
				})}
			</Grid>
		</Grid>
	)
}

export default TalentSearchList
