import React from 'react'
import { IconButton } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import TextToolTip from 'components/TextToolTip'

export interface InfoTooltipProps {
	info: string
	isTouch?: boolean
}

export const InfoTooltip = ({ isTouch, info }: InfoTooltipProps) => (
	<TextToolTip
		arrow
		title={info}
		placement={isTouch ? 'top' : 'right'}
		enterTouchDelay={100}
	>
		<IconButton style={{ marginLeft: 5 }} size="small">
			<InfoOutlinedIcon fontSize="inherit" />
		</IconButton>
	</TextToolTip>
)

export default InfoTooltip
