import React from 'react'
import { Backdrop, SpeedDialAction, SpeedDialIcon } from '@material-ui/core'
import useToggle from 'hooks/useToggle'
import { useTheme } from 'styled-components'
import { FixedDialButton } from './AddTalentDialButton.style'
import { pxToRem } from 'packages/nuggetai.ui-kit/themes/utils'

export interface AddTalentDialButtonProps {
	actions: {
		buttonText: string
		disabled?: boolean
		onClick: () => void
	}[]
}

export const AddTalentDialButton = ({ actions }: AddTalentDialButtonProps) => {
	const [{ open }, { handleClose, handleToggle }] = useToggle()
	const theme = useTheme()

	return (
		<>
			<Backdrop
				open={open}
				style={{
					zIndex: 1202
				}}
			/>
			<FixedDialButton
				ariaLabel="Add talent dial button"
				aria-label="Add talent and create talent group dial button"
				icon={<SpeedDialIcon id="team-tours-speed-dial-button" />}
				onClose={handleClose}
				onClick={handleToggle}
				open={open}
				style={{ zIndex: 1203, alignItems: 'flex-start' }}
			>
				{actions.map(({ buttonText, onClick, disabled }, index) => (
					<SpeedDialAction
						key={index}
						icon={buttonText}
						tooltipOpen
						tooltipPlacement="top"
						FabProps={{
							disabled,
							style: {
								borderRadius: 12,
								width: '100%',
								padding: 18,
								textTransform: 'capitalize',
								fontSize: pxToRem(16),
								backgroundColor: disabled ? undefined : theme.primary.main,
								boxShadow: theme.shadow.first,
								color: disabled ? undefined : theme.tag.white
							}
						}}
						onClick={disabled ? undefined : onClick}
					/>
				))}
			</FixedDialButton>
		</>
	)
}

export default AddTalentDialButton
