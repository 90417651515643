import React from 'react'
import { Grid, GridProps } from '@material-ui/core'

export interface SelectionContainerProps {
	containerProps?: GridProps
}

export const SelectionContainer: React.FC<SelectionContainerProps> = ({
	children,
	containerProps
}) => (
	<Grid container item xs={12}>
		<Grid container spacing={4} {...containerProps}>
			{children}
		</Grid>
	</Grid>
)
