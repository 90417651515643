import React from 'react'
import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const PinIconOutlined = (props: SvgIconProps) => (
	<SvgIcon {...props} width="21" height="20" viewBox="0 0 21 20" fill="none">
		<path
			d="M8.03119 8.59361L11.4852 5.49789C11.6922 5.31237 11.8088 5.05015 11.8581 4.7766C12.0073 3.94971 12.445 3.27035 12.8729 2.81151C13.2112 2.44866 13.7622 2.53041 14.0934 2.89988C15.4237 4.38424 16.824 5.9465 18.0099 7.2697C18.3369 7.6345 18.3737 8.19292 17.9933 8.50153C17.3386 9.03259 16.5439 9.27264 15.9573 9.33312C15.6758 9.36215 15.3969 9.4499 15.1861 9.63882L11.7579 12.7114C11.5001 12.9425 11.3811 13.2867 11.3949 13.6327C11.4789 15.7287 10.3629 17.4082 9.4058 18.363C9.03188 18.736 8.43723 18.6674 8.08473 18.2741L2.06867 11.5618C1.68503 11.1338 1.71877 10.4692 2.19611 10.149C3.99241 8.94391 5.9571 8.76582 7.11799 8.87041C7.4479 8.90014 7.78451 8.8147 8.03119 8.59361Z"
			stroke="#5E6C84"
		/>
		<path
			d="M5.99503 13.3465C6.1941 13.1681 6.53095 13.2192 6.74741 13.4607V13.4607C6.96387 13.7023 6.97797 14.0427 6.77891 14.2211L2.14411 18.3751C1.94505 18.5535 1.6082 18.5024 1.39174 18.2608V18.2608C1.17528 18.0193 1.16117 17.6789 1.36024 17.5005L5.99503 13.3465Z"
			fill="#5E6C84"
		/>
	</SvgIcon>
)

export default PinIconOutlined
