import styled from 'styled-components'
import CheckIcon from '../icons/CheckIcon'

export const InputCheckIcon = styled(CheckIcon)`
	font-size: 28px;
	margin-right: 12px;
	fill: ${props => props.theme.primary.main};
`

export default InputCheckIcon
