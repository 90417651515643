import React from 'react'
import { PartialRouteObject } from 'react-router'
import CreateProfile from 'screens/SignUp/containers/CreateProfile'
import SelectPlans from 'screens/SignUp/containers/SelectPlans'

export const signUpRoutes: PartialRouteObject[] = [
	{
		path: 'create-account',
		element: <CreateProfile />
	},
	{
		path: 'select-plans',
		element: <SelectPlans />
	}
]
