import styled from 'styled-components'
import { Grid } from '@material-ui/core'

export const ProgressContainer = styled(Grid)`
	position: relative;
	width: 98.5%;
`

export const IndicatorContainer = styled.div<{ position: 'start' | 'end' }>`
	position: absolute;
	width: 2px;
	height: 11px;
	background-color: ${props => props.theme.secondary.orange};
	left: ${props => (props.position === 'start' ? 0 : '100%')};
`

export const MarkContainer = styled.div<{ value: number }>`
	position: absolute;
	width: 3px;
	height: 11px;
	background-color: ${props => props.theme.tag.blue};
	left: ${props => `${props.value}%`};
`

export const AvatarMarkContainer = styled.div<{ score: number }>`
	position: absolute;
	left: ${props => `${props.score}%`};
`
