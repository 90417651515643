/* eslint-disable indent */
import styled from 'styled-components'
import { TalkBubbleProps } from './TalkBubble'

export const TalkBubbleContainer = styled.div<Omit<TalkBubbleProps, 'message'>>`
	padding: 15px 40px;
	display: inline-block;
	position: relative;
	height: auto;
	background-color: ${props => props.theme.background.main};
	margin-right: 40px;
	border-radius: 30px;
	&:before {
		content: '';
		${props =>
			props.arrow &&
			props.arrowX === 'left' && {
				width: 0,
				height: 0,
				borderLeft: 0,
				position: 'absolute',
				borderRight: '20px solid transparent',
				borderTop: `30px solid ${props.theme.background.main}`,
				borderBottom: '5px solid transparent',
				left: 19,
				bottom: -19
			}}
	}
`
