import styled from 'styled-components'
import { getProgressColor } from 'packages/nuggetai.ui-kit/themes/utils'

export const ProgressBarContainer = styled.div<{ noBg?: boolean }>`
	position: relative;
	width: 100%;
	height: 8px;
	border-radius: 4px;
	background-color: ${props => (props.noBg ? 'transparent' : props.theme.progressBarBg)};
`

export const ProgressBarContent = styled.div<{ value: number; color?: string }>`
	width: ${props => (props.value ? `${props.value}%` : 0)};
	height: 8px;
	border-radius: 4px;
	transition: all 1s linear;
	background-color: ${({ value, theme, color }) => color ?? getProgressColor(value, theme)};
`

export const Mark = styled.div<{ value: number }>`
	position: absolute;
	width: 3px;
	height: 13px;
	margin-top: -13px;
	margin-left: -1px;
	border-radius: 5px;
	left: ${props => `${props.value}%`};
	transition: all 1s linear;
	background-color: ${props => props.theme.tag.blue};
`
