import React from 'react'
import { CheckIcon } from 'components/icons/CheckIcon'
import { Colors } from 'packages/nuggetai.ui-kit/themes/utils'
import { CheckboxCheckedIcon } from './Checkbox.style'

export interface CheckboxIconProps {
	fontSize?: 'small' | 'medium'
	/**
	 * @default 'primary'
	 */
	color?: Colors
	/**
	 * @default 'squared'
	 */
	shape?: 'squared' | 'circle'
	/**
	 * @default true
	 */
	checked?: boolean
}

export const CheckboxChecked = ({
	fontSize,
	shape = 'squared',
	color = 'primary'
}: CheckboxIconProps) => (
	<CheckboxCheckedIcon color={color} fontSize={fontSize} shape={shape}>
		<CheckIcon style={{ fontSize: fontSize === 'small' ? 12 : 14 }} fill="inherit" />
	</CheckboxCheckedIcon>
)

export const CheckboxIconDisplay = ({
	fontSize,
	shape = 'squared',
	color = 'primary',
	checked = true
}: CheckboxIconProps) => (
	<CheckboxCheckedIcon checked={checked} color={color} fontSize={fontSize} shape={shape}>
		{checked && (
			<CheckIcon style={{ fontSize: fontSize === 'small' ? 16 : 22 }} fill="inherit" />
		)}
	</CheckboxCheckedIcon>
)
