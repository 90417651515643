import styled, { css } from 'styled-components'
import { DialogContent, Paper } from '@material-ui/core'
import { IModalProps } from './Modal'

export const Wrapper = styled(Paper)`
	@media only screen and (max-width: 600px) {
		.MuiDialogTitle-root,
		.MuiDialogContent-root,
		.MuiDialogActions-root {
			padding-left: 25px;
			padding-right: 25px;
		}

		.MuiDialogContent-root,
		.MuiDialogActions-root {
			padding-bottom: 25px;
		}
	}
`

type ModalContentProps = Pick<IModalProps, 'onlyContent' | 'size' | 'showCloseButton'>

export const ModalContent = styled(DialogContent).withConfig<ModalContentProps>({
	shouldForwardProp: prop => !['onlyContent', 'size', 'showCloseButton'].includes(prop)
})`
	${props =>
		props.showCloseButton &&
		css`
			padding-bottom: 30px;
		`}
	${props =>
		props.onlyContent
			? css`
					@media only screen and (max-width: 960px) {
						padding: 25px;
					}
			  `
			: props.size === 'big' &&
			  css`
					padding-top: 20px;
			  `}
`
