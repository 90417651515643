import React, { useCallback, useState } from 'react'
import {
	Grid,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Typography,
	TextField
} from '@material-ui/core'
import debounce from 'lodash.debounce'
import { useNavigate } from 'react-router-dom'

import useIntl from 'hooks/useIntl'
import { useOnboardingStore } from 'screens/Onboarding/hooks/useOnboardingStore'
import { ContentWrapper } from 'screens/Onboarding/components/ContentWrapper'
import { CreationContent } from 'components/CreationContent'
import WizardFooter from 'components/Wizard/WizardFooter'
import { useEntryFormStore } from '../../hooks/useEntryFormStore'

export const EntryFormChapterSelection = ({ setNumOfQuestions }) => {
	const intl = useIntl()
	const navigate = useNavigate()

	const [debounceSearch, setDebounceSearch] = useState('')
	const debouncedSearch = useCallback(
		debounce(nextValue => setDebounceSearch(nextValue), 300),
		[]
	)

	const { nextStep, currentStep, toggleHelpGuide } = useOnboardingStore()
	const { formData, setFormData } = useEntryFormStore()

	const handleContinue = () => {
		nextStep()
	}

	const convertDurationToMinutes = (time: string): number => {
		if (!time) return 0

		const [hours, minutes] = time.split(':').map(Number)
		return hours * 60 + minutes
	}

	// const handleContinue = () => {
	// 	if (formData.timer) {
	// 		const totalMinutes = convertDurationToMinutes(formData.timer.split(' ')[0])

	// 		const payload = {
	// 			...formData,
	// 			timer: totalMinutes // Ensure this is a number (minutes)
	// 		}

	// 		console.log('Payload Sent to Backend:', payload)
	// 		nextStep()
	// 	}
	// }

	const handleChange = event => {
		const { name, value } = event.target
		setFormData({ ...formData, [name]: value })
	}

	const durationOptions = Array.from({ length: 60 }, (_, i) => {
		const minutes = (i + 1) * 5
		const hours = Math.floor(minutes / 60)
		const remainingMinutes = minutes % 60
		const formattedTime = `${String(hours).padStart(2, '0')}:${String(
			remainingMinutes
		).padStart(2, '0')} ${hours > 0 ? 'hrs' : 'min'}`

		return { value: formattedTime, label: formattedTime }
	})

	const questionPickingOptions = [
		{ value: 'true', label: 'Auto Pick' },
		{ value: 'false', label: 'Manual' }
	]

	const isContinueEnabled =
		formData.questions !== '' && formData.timer !== '' && formData.picking !== ''
	// console.log('Questions:', formData.questions)
	// console.log('Timer:', formData.timer)
	// console.log('Picking:', formData.picking)
	// console.log('Continue Enabled:', isContinueEnabled)

	return (
		<>
			<ContentWrapper currentStep={currentStep}>
				<Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
					Entry - Level
				</Typography>
				<CreationContent
					subHeader
					title={intl.formatMessage({
						id: `onboarding.free.form.step.${currentStep}.title`
					})}
				>
					<Grid container spacing={2} sx={{ mt: 4 }}>
						{/* Number of Questions */}
						<Grid item xs={6} sm={2}>
							<FormControl variant="outlined" fullWidth>
								<Typography variant="body2" gutterBottom>
									No. of Questions
								</Typography>
								<TextField
									type="number"
									size="small"
									sx={{ height: '40px' }}
									value={formData.questions || ''}
									name="questions"
									onChange={e => {
										const numQuestions = parseInt(e.target.value, 10) || 0
										setNumOfQuestions(numQuestions)
										handleChange(e)
									}}
									fullWidth
								/>
							</FormControl>
						</Grid>

						<Grid item xs={12} sm={4}>
							<FormControl fullWidth>
								<InputLabel>
									Duration{' '}
									<span style={{ color: 'gray', fontSize: '12px' }}>(HH : MM)</span>{' '}
								</InputLabel>
								<Select
									name="timer"
									value={formData.timer}
									onChange={handleChange}
									MenuProps={{
										getContentAnchorEl: null,
										anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
										transformOrigin: { vertical: 'top', horizontal: 'left' },
										PaperProps: { style: { maxHeight: 200, overflowY: 'auto' } }
									}}
								>
									{durationOptions.map(option => (
										<MenuItem key={option.value} value={option.value}>
											{option.label}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>

						<Grid item xs={12} sm={4}>
							<FormControl fullWidth>
								<InputLabel>Question Picking</InputLabel>
								<Select
									name="picking"
									value={formData.picking}
									onChange={handleChange}
									MenuProps={{
										getContentAnchorEl: null,
										anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
										transformOrigin: { vertical: 'top', horizontal: 'left' },
										PaperProps: { style: { maxHeight: 200, overflowY: 'auto' } }
									}}
								>
									{questionPickingOptions.map(option => (
										<MenuItem
											key={option.value}
											value={option.value}
											disabled={option.value === 'false'}
										>
											{option.label}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					</Grid>
				</CreationContent>
			</ContentWrapper>

			{/* Footer */}
			<WizardFooter
				onOpenHelpGuide={toggleHelpGuide}
				continueDisabled={!isContinueEnabled}
				onContinue={handleContinue}
				onBack={() => navigate('/onboarding/challenges-selection')}
			/>
		</>
	)
}

export default EntryFormChapterSelection
