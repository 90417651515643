import React from 'react'
import { useTheme } from 'styled-components'
import { ButtonBase, Collapse, Grid, Typography } from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import useToggle from 'hooks/useToggle'
import useIntl from 'hooks/useIntl'
import { DrawerHeader, DrawerContainer, DrawerContent } from './TalentDynamicDrawer.styles'

export interface TalentDynamicDrawerProps {}

export const TalentDynamicDrawer: React.FC<TalentDynamicDrawerProps> = ({ children }) => {
	const intl = useIntl()
	const theme = useTheme()
	const [{ open }, { handleToggle }] = useToggle()

	return (
		<DrawerContainer container open={open}>
			<Grid item id="team-tours-talent-dynamic-chart">
				<ButtonBase onClick={handleToggle}>
					<DrawerHeader item container justifyContent="center" open={open}>
						<Grid item style={{ padding: '12px 0', maxWidth: 60 }}>
							{open ? (
								<ChevronRightIcon sx={{ fill: theme.tag.white }} />
							) : (
								<ChevronLeftIcon
									id="team-tours-talent-dynamic-button"
									sx={{ fill: theme.tag.white }}
								/>
							)}
							<div style={{ transform: 'rotate(90deg)', marginTop: 40 }}>
								<Typography variant="paragraph1" noWrap>
									{intl.formatMessage({
										id: open ? 'teams.talentProfile' : 'teams.talentDynamic'
									})}
								</Typography>
							</div>
						</Grid>
					</DrawerHeader>
				</ButtonBase>
			</Grid>
			<Grid item container sx={{ height: '1000px', width: 'calc(100%  - 60px)' }}>
				<Collapse
					orientation="horizontal"
					timeout={500}
					style={{ height: '100%' }}
					in={open}
				>
					<DrawerContent open container>
						{children}
					</DrawerContent>
				</Collapse>
			</Grid>
		</DrawerContainer>
	)
}

export default TalentDynamicDrawer
