import React from 'react'
import ContrastChip from 'components/ContrastChip'
import { useTheme } from 'styled-components'
import useIntl from 'hooks/useIntl'

export interface ChallengeStatusProps {
	/**
	 * Defines the status of the challenge
	 */
	status: 'incomplete' | 'completed'
}

export const ChallengeStatus: React.FC<ChallengeStatusProps> = ({ status }) => {
	const theme = useTheme()
	const intl = useIntl()
	let color = theme.grey.third

	if (status === 'completed') color = theme.tag.green

	return <ContrastChip color={color} label={intl.formatMessage({ id: status })} />
}

export default ChallengeStatus
