import styled from 'styled-components'
import ListItem from '@material-ui/core/ListItem'
import Tab from '@material-ui/core/Tab'
import MenuItem from '@material-ui/core/MenuItem'
import { pxToRem } from 'packages/nuggetai.ui-kit/themes/utils'

export const SidebarContent = styled.div`
	overflow: auto;

	@media only screen and (min-width: 960px) {
		.MuiTab-root {
			padding: 10px 16px;
		}
	}

	@media only screen and (max-width: 959px) {
		padding-left: 0;
		padding-right: 0;
		margin-bottom: 32px;
		margin-top: 0;

		.MuiTab-root {
			margin-top: 32px;
		}
	}

	@media only screen and (max-width: 600px) {
		padding-left: 0;
		padding-right: 0;
	}
`

export const ListItemStyled = styled(ListItem)`
	&::before {
		position: absolute;
		opacity: ${props => (props.selected ? 1 : 0)};
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		content: '';
		width: 3px;
		height: 100%;
		border-radius: 0 100px 100px 0;
	}
`

export const MenuItemButton = styled(MenuItem)`
	color: ${props => props.theme.tag.blue};
	text-transform: uppercase;
	font-weight: 500;
	font-size: 12px;
	letter-spacing: 0.8px;
`

export const TabStyled = styled(Tab)`
	font-size: ${pxToRem(14)};
	min-width: 0;

	@media only screen and (max-width: 320px) {
		padding: 0 10px;
		min-width: 0;
	}

	@media only screen and (max-width: 968px) {
		& .MuiTab-wrapper {
			flex-direction: column;
		}
	}
`
