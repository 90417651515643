import React from 'react'
import { SwiperOptions } from 'swiper'

export const slideSettings: SwiperOptions & { style: React.CSSProperties } = {
	spaceBetween: 24,
	slidesPerView: 'auto',
	centeredSlides: false,
	style: {
		paddingBottom: 20
	}
}

export const slideMobileSettings: SwiperOptions = {
	centeredSlides: true,
	slidesPerView: 1
}

export default slideSettings
