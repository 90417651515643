import React from 'react'
import { Skeleton, Grid } from '@material-ui/core'
import { ChallengeProgressSkeleton } from './components/ChallengeProgress'
import { ProfileHeaderSkeleton } from './components/ProfileHeader'

export const TalentProfileSkeleton = () => (
	<Grid container>
		<Grid
			container
			justifyContent="flex-end"
			alignItems="center"
			xs={12}
			sx={{ mt: 2.2, mb: 1 }}
		>
			<Skeleton animation="wave" variant="circular" height={24} width={24} />
		</Grid>
		<Grid container spacing={6}>
			<Grid item xs={12}>
				<ProfileHeaderSkeleton />
			</Grid>
			<Grid
				container
				item
				xs={12}
				justifyContent="space-between"
				spacing={2}
				alignItems="flex-end"
			>
				<Grid item xs>
					<ChallengeProgressSkeleton />
				</Grid>
			</Grid>
			<Grid container item xs={12} spacing={1}>
				<Grid item xs>
					<Skeleton animation="wave" height={60} />
				</Grid>
				<Grid item xs>
					<Skeleton animation="wave" height={60} />
				</Grid>
				<Grid item xs>
					<Skeleton animation="wave" height={60} />
				</Grid>
			</Grid>
		</Grid>
	</Grid>
)

export default TalentProfileSkeleton
