import React from 'react'
import { useTheme } from 'styled-components'
import { InputAdornment, MenuItem, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import useIntl from 'hooks/useIntl'
import { FilterTextField, InputBox } from './Filter.style'

export interface FilterProps {
	/**
	 * Defines the list of filters
	 */
	filters: { label: string; value: string; disabled?: boolean }[]
	/**
	 * Actual field
	 */
	field: string
	handleChangeFilter: (event: React.ChangeEvent) => void
}

export const TalentGroupsFilter = ({ field, filters, handleChangeFilter }: FilterProps) => {
	const intl = useIntl()
	return (
		<FilterTextField
			variant="filled"
			hiddenLabel
			onChange={handleChangeFilter}
			value={field
				.split('_')
				.slice(0, field.split('_').length > 2 ? 2 : 1)
				.join('_')}
			SelectProps={{
				sx: {
					backgroundColor: 'transparent !important',
					'.MuiSelect-select:focus': {
						backgroundColor: 'transparent'
					}
				},
				IconComponent: CustomDropDownIcon,
				renderValue: value => (
					<InputBox>
						<InputAdornment position="start">
							<Typography variant="paragraph3">
								{intl.formatMessage({ id: 'teams.talentGroups.filter' })}
							</Typography>
						</InputAdornment>
						<Typography variant="paragraph3" component="span" fontWeight={500}>
							{intl.formatMessage({
								id: `teams.talentGroups.filter.${(value as string)
									.toLowerCase()
									.replace('_', '')}`
							})}
						</Typography>
					</InputBox>
				)
			}}
			select
			InputProps={{
				sx: {
					backgroundColor: 'transparent !important',
					':focus': {
						backgroundColor: 'transparent !important'
					},
					minHeight: 0
				}
			}}
		>
			{filters.map(({ label, value, disabled }) => (
				<MenuItem key={value} value={value} disabled={disabled}>
					{label}
				</MenuItem>
			))}
		</FilterTextField>
	)
}

const CustomDropDownIcon = () => {
	const theme = useTheme()
	return (
		<ExpandMoreIcon
			sx={{ ml: -2.5, mt: 0.2, fontSize: 16, fill: theme.text.description }}
		/>
	)
}

export default TalentGroupsFilter
