// import create from 'zustand'

// interface EntryFormData {
// 	questions: string
// 	timer: string
// 	picking: string
// }

// interface EntryFormState {
// 	selectedType: string | null
// 	selectedSubTypes: string[]
// 	numQuestions: number | ''
// 	chartData: {
// 		sectionId: string
// 		subSectionId: string
// 		type: string
// 		subTypes: string[]
// 		questions: number
// 		count: number
// 	}[]
// 	editingIndex: number | null
// 	error: string | null
// }

// interface EntryFormStore {
// 	formData: EntryFormData
// 	formStates: EntryFormState
// 	setFormData: (data: Partial<EntryFormData>) => void
// 	updateEntryFormState: (updates: Partial<EntryFormState>) => void
// 	resetEntryFormStore: () => void
// 	[key: string]: unknown
// }

// const initialFormData: EntryFormData = {
// 	questions: '',
// 	timer: '',
// 	picking: 'true'
// }

// const initialFormState: EntryFormState = {
// 	selectedType: '',
// 	selectedSubTypes: [],
// 	numQuestions: '',
// 	chartData: [],
// 	editingIndex: null,
// 	error: null
// }

// export const useEntryFormStore = create<EntryFormStore>(set => ({
// 	formData: { ...initialFormData },
// 	formStates: { ...initialFormState },

// 	setFormData: data =>
// 		set(state => ({
// 			formData: { ...state.formData, ...data }
// 		})),

// 	updateEntryFormState: updates =>
// 		set(state => ({
// 			formStates: {
// 				...state.formStates,
// 				...updates,
// 				selectedSubTypes: Array.isArray(updates.selectedSubTypes)
// 					? updates.selectedSubTypes
// 					: typeof updates.selectedSubTypes === 'string'
// 					? [updates.selectedSubTypes] // Convert string back to an array
// 					: state.formStates.selectedSubTypes
// 			}
// 		})),

// 	resetEntryFormStore: () =>
// 		set({
// 			formData: { ...initialFormData },
// 			formStates: { ...initialFormState }
// 		})
// }))

// export const resetEntryFormStore = () => {
// 	useEntryFormStore.setState({
// 		formData: { ...initialFormData },
// 		formStates: { ...initialFormState }
// 	})
// }

import create from 'zustand'

interface EntryFormData {
	questions: string
	timer: string
	picking: string
}

interface EntryFormState {
	selectedType: string | null
	selectedSubTypes: string // Backend expects a string, not an array
	numQuestions: number | ''
	chartData: {
		sectionId: string
		subSectionId: string
		type: string
		subTypes: string // Should be stored as a string
		questions: number
		count: number
	}[]
	editingIndex: number | null
	error: string | null
}

interface EntryFormStore {
	formData: EntryFormData
	formStates: EntryFormState
	setFormData: (data: Partial<EntryFormData>) => void
	updateEntryFormState: (updates: Partial<EntryFormState>) => void
	resetEntryFormStore: () => void
	[key: string]: unknown
}

const initialFormData: EntryFormData = {
	questions: '',
	timer: '',
	picking: 'true'
}

const initialFormState: EntryFormState = {
	selectedType: '',
	selectedSubTypes: '', // Store as string
	numQuestions: '',
	chartData: [],
	editingIndex: null,
	error: null
}

export const useEntryFormStore = create<EntryFormStore>(set => ({
	formData: { ...initialFormData },
	formStates: { ...initialFormState },

	setFormData: data =>
		set(state => ({
			formData: { ...state.formData, ...data }
		})),

	updateEntryFormState: updates =>
		set(state => ({
			formStates: {
				...state.formStates,
				...updates,
				// Convert array to string if an array is passed
				selectedSubTypes: Array.isArray(updates.selectedSubTypes)
					? updates.selectedSubTypes.join(', ') // Convert to a comma-separated string
					: updates.selectedSubTypes || state.formStates.selectedSubTypes
			}
		})),

	resetEntryFormStore: () =>
		set({
			formData: { ...initialFormData },
			formStates: { ...initialFormState }
		})
}))

export const resetEntryFormStore = () => {
	useEntryFormStore.setState({
		formData: { ...initialFormData },
		formStates: { ...initialFormState }
	})
}
