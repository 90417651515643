import { applyDefaultOptions } from './object'
import { linkRegex } from './regexs'
import { CompareOptions } from './types'

export function reverseString(str: string) {
	return str.split('').reverse().join('')
}

export function isString(str: unknown): str is string {
	return typeof str === 'string'
}

export function getInitials(fn: string, ln?: string): string {
	if (fn && ln) {
		return `${fn.charAt(0).toUpperCase()}${ln.charAt(0).toUpperCase()}`
	}

	return fn.charAt(0)
}

export function compareString(
	a: string | { [key: string]: any },
	b: string | { [key: string]: any },
	options?: CompareOptions
) {
	const { mode, key } = applyDefaultOptions(options as CompareOptions, {
		mode: 'asc'
	})
	const x = ((key ? a[key] : a) as string).toLowerCase()
	const y = ((key ? b[key] : b) as string).toLowerCase()
	if (x < y) {
		return mode === 'desc' ? 1 : -1
	}
	if (x > y) {
		return mode === 'desc' ? -1 : 1
	}
	return 0
}

export function formatLink(str: string): string {
	return str.replace(linkRegex, '')
}
