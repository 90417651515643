import React from 'react'
import TeamsRouteHandler from 'containers/TeamCreationRouteHandler'
import { PartialRouteObject } from 'react-router'
import { Teams } from 'screens/Teams'
import TeamsProvider from 'screens/Teams/Teams.config'
import { FilterProvider } from 'hooks/useFilter'

export const teamsRoutes: PartialRouteObject[] = [
	{
		path: '/*',
		element: TeamsRouteHandler
	},
	{
		path: '/:id',
		element: (
			<FilterProvider>
				<TeamsProvider>
					<Teams />
				</TeamsProvider>
			</FilterProvider>
		)
	},
	{
		path: '/demo',
		element: (
			<FilterProvider>
				<TeamsProvider demo>
					<Teams />
				</TeamsProvider>
			</FilterProvider>
		)
	}
]
