import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import type { ButtonProps } from '@material-ui/core/Button'

import { StyledButton } from './social-button.style'

export interface SocialButtonProps extends ButtonProps {
	/**
	 * element placed before the children.
	 */
	icon?: React.ReactNode
	/**
	 * element placed before the children.
	 */
	label?: string
	/**
	 * react children prop
	 */
	children?: React.ReactNode
}

export const SocialButton = ({
	href,
	icon,
	label,
	children,
	variant = 'outlined',
	fullWidth = true,
	color = 'inherit',
	...props
}: SocialButtonProps) => {
	return (
		<StyledButton
			variant={variant}
			href={href}
			fullWidth={fullWidth}
			color={color}
			{...props}
		>
			<Grid container justifyContent="center">
				<Grid item container alignItems="center" spacing={4} sx={{ width: '300px' }}>
					{icon && (
						<Grid item container justifyContent="flex-end" xs={4} sx={{ mt: 0.3 }}>
							{icon}
						</Grid>
					)}
					<Grid
						item
						container
						justifyContent={!icon ? 'center' : 'flex-start'}
						xs={!icon ? 12 : 8}
					>
						{children ?? <Typography variant="paragraph1">{label}</Typography>}
					</Grid>
				</Grid>
			</Grid>
		</StyledButton>
	)
}

export default SocialButton
