import { Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

export const TextToolTip = withStyles(() => {
	return {
		tooltip: {
			backgroundColor: '#262358',
			color: 'white',
			fontSize: 12,
			padding: 12,
			borderRadius: 8,
			maxWidth: 180
		},
		arrow: {
			color: '#262358'
		}
	}
})(Tooltip)

export default TextToolTip
