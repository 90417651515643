import React from 'react'
import { Card } from './ColoredCard.styles'

export interface ColoredCardProps {
	color: string
}

export const ColoredCard: React.FC<ColoredCardProps> = ({ color, children }) => {
	return <Card color={color}>{children}</Card>
}

export default ColoredCard
