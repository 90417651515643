import { SERVER_URL } from 'constants/config'
import { ipRegex } from './regexs'

export const isLocal = () => {
	const { hostname } = window.location
	return hostname.includes('localhost') || ipRegex.test(hostname)
}

export const getLocalUrl = (port: string | number, protocol?: string) => {
	const { hostname, protocol: baseProt } = window.location
	const prot = protocol || baseProt
	return `${prot.replace(':', '')}://${hostname}:${port}`
}

export const createOAuthUrl = (auth: 'facebook' | 'google') =>
	`${SERVER_URL}/api/v1/auth/${auth}?clientUrl=${window.location.origin}`
