import { isLocal, getLocalUrl } from 'utils/url'

export const env = process.env.REACT_APP_MODE || 'dev'

export const IS_DEV = env === 'dev'

export const SERVER_URL = process.env.REACT_APP_API_URL

export const API_URL = `${SERVER_URL}/api/v2`

export const ASSESSMENT_URL = process.env.REACT_APP_ASSESSMENT_URL

export const ASSESSMENT_LOCAL_URL = process.env.REACT_APP_ASSESSMENT_LOCAL_URL

export const TALENT_URL = process.env.REACT_APP_SANDBOX_URL

export const TALENT_LOCAL_URL = process.env.REACT_APP_SANDBOX_LOCAL_URL

export const WEBSOCKET_URL = process.env.REACT_APP_API_WEBSOCKET_URL

interface GetAssessmentLinkArgs {
	demo: boolean
	assessId: string
	userId: string
	pipelineId: string
}

export const getAssessmentQueryLink = ({
	assessId,
	demo,
	userId,
	pipelineId
}: GetAssessmentLinkArgs) => {
	const params = new URLSearchParams({
		assessId,
		pipelineId
	})

	const talentDomain = IS_DEV ? TALENT_LOCAL_URL : TALENT_URL

	return `${talentDomain}/${demo ? `demo/${userId}` : `login-talent?${params.toString()}`}`
}

export const getAssessmentLink = ({
	assessId,
	demo,
	userId,
	pipelineId
}: GetAssessmentLinkArgs) => {
	return `${TALENT_URL}/${
		demo ? `demo/${userId}` : `assessment/${assessId}`
	}/${pipelineId}/default`
}

interface GetPreviewLinkArgs {
	categoryId: string
	challengeId: string
	chapterId: string
}

export const getPreviewLink = ({
	categoryId,
	challengeId,
	chapterId
}: GetPreviewLinkArgs) => {
	return `${ASSESSMENT_URL}/preview/${categoryId}/${challengeId}/${chapterId}`
}

export const assessmentsSocketUrl = ({ userId }: { userId: string | null }) => {
	return `${WEBSOCKET_URL}/applicant?userId=${userId}`
}
