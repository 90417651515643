import React from 'react'
import { Colors } from 'packages/nuggetai.ui-kit/themes/utils'
import { CheckboxCheckedIcon } from './checkbox.style'
import CheckIcon from 'assets/icons/CheckIcon'

export interface CheckboxProps {
	/**
	 * defines the font size of the icon component
	 */
	fontSize?: 'small' | 'medium'
	/**
	 * defines the color of the icon component
	 */
	color?: Colors
	/**
	 * defines the shape of the icon component
	 */
	shape?: 'squared' | 'circle'
	/**
	 * defines the check status of the icon component
	 */
	checked?: boolean
}

export const CheckboxChecked = ({
	fontSize,
	shape = 'squared',
	color = 'primary',
	...props
}: CheckboxProps) => (
	<CheckboxCheckedIcon color={color} fontSize={fontSize} shape={shape} {...props}>
		<CheckIcon style={{ fontSize: fontSize === 'small' ? 12 : 14 }} fill="inherit" />
	</CheckboxCheckedIcon>
)

export const CheckboxIconDisplay = ({
	fontSize,
	shape = 'squared',
	color = 'primary',
	checked = true,
	...props
}: CheckboxProps) => (
	<CheckboxCheckedIcon
		checked={checked}
		color={color}
		fontSize={fontSize}
		shape={shape}
		{...props}
	>
		{checked && (
			<CheckIcon style={{ fontSize: fontSize === 'small' ? 16 : 22 }} fill="inherit" />
		)}
	</CheckboxCheckedIcon>
)
