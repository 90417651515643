import React, { useState } from 'react'
import { Box, Tabs, Tab, Typography } from '@material-ui/core'
import AvailablePlanContent from './Domesticdata'

const AvailablePlanCards = () => {
	const [activeTab, setActiveTab] = useState(0)
	const [value, setValue] = React.useState(0)

	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center'
			}}
		>
			<Box
				sx={{
					width: '800px',
					height: '80vh',
					overflowY: 'auto',
					scrollbarWidth: 'none',
					'&::-webkit-scrollbar': {
						display: 'none'
					}
				}}
			>
				<Box
					sx={{
						p: 3,
						bgcolor: 'background.paper'
					}}
				>
					<Typography variant="h6" gutterBottom>
						Available Plans
					</Typography>
					<Typography variant="body2" gutterBottom>
						You can add an additional plan on top of the current plan you have.
					</Typography>

					<Tabs value={activeTab} onChange={(event, newValue) => setActiveTab(newValue)}>
						<Tab label="Domestic" />
						<Tab label="International" />
					</Tabs>

					<AvailablePlanContent activeTab={activeTab} />
				</Box>
			</Box>
		</Box>
	)
}

export default AvailablePlanCards
