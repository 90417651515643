import React from 'react'
import DashboardLayout from 'layouts/dashboard'
import { Navigate, useLocation } from 'react-router'
import { useStoreon } from 'storeon/react'
import usePrevious from 'hooks/usePrevious'

export const RouteHandler = () => {
	const { token } = useStoreon('token')
	const { pathname } = useLocation()
	const prevToken = usePrevious(token)

	if (token) {
		return <DashboardLayout />
	}
	return (
		<Navigate
			to={
				prevToken
					? '/auth/login'
					: `/auth/login?redirectTo=${window.encodeURIComponent(pathname)}`
			}
		/>
	)
}

export default RouteHandler
