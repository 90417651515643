import styled from 'styled-components'
import { Grid, GridProps } from '@material-ui/core'

export const Container = styled(Grid)`
	padding: 30px 43px 70px 43px;
	max-width: 1500px;

	@media (max-width: 600px) {
		padding-left: 15px;
		padding-right: 15px;
	}
`

interface ContentProps extends GridProps {
	levelWithStepper?: boolean
	topGutter?: boolean
}

export const Content = styled<React.ComponentType<ContentProps>>(Grid)`
	padding-top: ${({ levelWithStepper, topGutter = true }) =>
		topGutter && !levelWithStepper ? 30 : undefined}px;

	margin-top: -21px;
`
