/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React from 'react'
import { format } from 'date-fns'
import { useTheme } from 'styled-components'
import { useStoreon } from 'storeon/react'
import { Grid, Typography, Divider } from '@material-ui/core'

import emails from 'data/emails.json'
import NyomiModal from 'components/NyomiModal'
import NyomiSadIcon from 'assets/icons/NyomiIcons/NyomiSadIcon'
import useIntl from 'hooks/useIntl'
import SubscriptionCard from 'components/SubscriptionCard'
import ManagePlansCard from 'components/ManagePlansCard'
import CalculateCostModal from 'components/CalculateCostModal'
import ButtonLink from 'components/ButtonLink'
import {
	AnalyticsUserSubscription,
	useUpdateSubscriptionCancellationStatusMutation
} from 'hooks/useApollo'

import { Paper } from '../../AccountSettings.style'
import { Header } from '../components'
import useBilling from './useBilling'

export interface BillingProps {}

const benchmarking = 'Benchmarking_Monthly'
const hiring = 'Hiring_Monthly'

export const Billing = () => {
	const intl = useIntl()
	const theme = useTheme()
	const { dispatch, user } = useStoreon('user')

	const [
		{ open, plans, loading, availablePlans, currentSubscriptions, employeeCount },
		{ handleClose, handleOpen, getBillingPortalUrl, handleActivatePlan }
	] = useBilling()

	const [updateStatus] = useUpdateSubscriptionCancellationStatusMutation()

	const handleCloseCancellationModal = (id: string) => {
		if (!user) return

		const index = user.subscriptions.findIndex(sub => sub.id === id)

		if (index === -1 || user.subscriptions[index].isCancellationMessageAcknowledged) return

		const newCopy = [...user.subscriptions]
		newCopy[index] = { ...newCopy[index], isCancellationMessageAcknowledged: true }

		dispatch('user/setUser', { ...user, subscriptions: newCopy })
		updateStatus({
			variables: {
				id,
				input: {
					isCancellationMessageAcknowledged: true,
					isCancellationAlertAcknowledged: newCopy[index].isCancellationAlertAcknowledged
				}
			}
		})
	}

	const handleCloseBothCancellationModals = (ids: string[]) => {
		if (!user) return

		const newSubsValue = ids.reduce<AnalyticsUserSubscription[]>((copy, id) => {
			const index = user.subscriptions.findIndex(sub => sub.id === id)

			const newCopy = [...user.subscriptions]
			newCopy[index] = { ...newCopy[index], isCancellationMessageAcknowledged: true }

			updateStatus({
				variables: {
					id,
					input: {
						isCancellationMessageAcknowledged: true,
						isCancellationAlertAcknowledged: newCopy[index].isCancellationAlertAcknowledged
					}
				}
			})

			return copy.concat(newCopy[index])
		}, [])

		dispatch('user/setUser', { ...user, subscriptions: newSubsValue })
	}

	const planKeys = Object.keys(availablePlans)
	const subKeys = Object.keys(currentSubscriptions)
	const subIds = subKeys.map(key => currentSubscriptions[key].id)

	const isPlanCancelledAtTheSameTime =
		currentSubscriptions?.[benchmarking]?.isCanceled &&
		!currentSubscriptions?.[benchmarking]?.isCancellationMessageAcknowledged &&
		currentSubscriptions?.[hiring]?.isCanceled &&
		!currentSubscriptions?.[hiring]?.isCancellationMessageAcknowledged

	return (
		<>
			<Paper elevation={7}>
				<Header title={intl.formatMessage({ id: 'accountSettings.billing' })} />
				{/* my plans */}
				{subKeys.length > 0 && (
					<Grid container spacing={5}>
						<Grid item xs={12}>
							<ManagePlansCard
								employeeCount={employeeCount}
								subscriptions={currentSubscriptions}
								billingPortal={getBillingPortalUrl()}
								onShowCalculateModal={handleOpen}
							/>
						</Grid>
					</Grid>
				)}
				{planKeys.length > 0 && subKeys.length > 0 && (
					<Grid item xs={12} sx={{ my: 6 }}>
						<Divider />
					</Grid>
				)}
				{/* available plans */}
				{planKeys.length > 0 && (
					<Grid container spacing={5}>
						<Grid item xs={12}>
							<Typography variant="h5" fontWeight={500}>
								{intl.formatMessage({ id: 'accountSettings.billing.title' })}
							</Typography>
							<Typography
								variant="paragraph1"
								sx={{ mt: 2, color: theme.text.description }}
							>
								{intl.formatMessage({ id: 'accountSettings.billing.desc' })}
							</Typography>
						</Grid>
						<Grid item container spacing={3}>
							{planKeys.map(key => (
								<Grid item key={key} xs={12} md={6}>
									<SubscriptionCard
										{...plans[key]}
										loading={loading}
										onActivate={() => handleActivatePlan({ planName: key })}
									/>
								</Grid>
							))}
						</Grid>
						<Grid item container alignItems="center" justifyContent="space-between">
							<Grid item>
								<a href={`mailto:${emails.sales}`}>
									<ButtonLink>
										{intl.formatMessage({ id: 'payment.plan.contact' })}
									</ButtonLink>
								</a>
							</Grid>
						</Grid>
					</Grid>
				)}
			</Paper>
			<CalculateCostModal
				title={`${plans.Benchmarking_Monthly.title} plan`}
				open={open}
				onClose={handleClose}
			/>
			{!subKeys.length ? null : isPlanCancelledAtTheSameTime ? (
				<NyomiModal
					open={isPlanCancelledAtTheSameTime}
					onClose={() => handleCloseBothCancellationModals(subIds)}
					title={intl.formatMessage(
						{
							id: `accountSettings.billing.bothPlansCancelled.title`
						},
						{
							plan1: currentSubscriptions?.[benchmarking]?.title,
							plan2: currentSubscriptions?.[hiring]?.title
						}
					)}
					desc={intl.formatMessage(
						{
							id: 'accountSettings.billing.bothPlansCancelled.desc'
						},
						{
							plan1: currentSubscriptions?.[benchmarking]?.title,
							plan2: currentSubscriptions?.[hiring]?.title,
							date: format(
								new Date(currentSubscriptions?.[benchmarking]?.endsAt),
								'MMM dd, yyyy'
							)
						}
					)}
					subDesc={intl.formatMessage({
						id: 'accountSettings.billing.bothPlansCancelled.subdesc'
					})}
					href={getBillingPortalUrl()}
					nyomiIcon={NyomiSadIcon}
					btnLabel={intl.formatMessage({ id: 'reactivatePlan' })}
					subBtnLabel={intl.formatMessage({ id: 'viewDashboard' })}
				/>
			) : (
				subKeys.map(key => (
					<NyomiModal
						key={key}
						open={
							currentSubscriptions[key].isCanceled &&
							!currentSubscriptions[key].isCancellationMessageAcknowledged
						}
						onClose={() => handleCloseCancellationModal(currentSubscriptions[key].id)}
						title={intl.formatMessage({
							id: `accountSettings.billing.${currentSubscriptions[key].type}.title`
						})}
						desc={intl.formatMessage(
							{
								id: `accountSettings.billing.${currentSubscriptions[key].type}.message`
							},
							{
								date: format(new Date(currentSubscriptions[key].endsAt), 'MMM dd, yyyy')
							}
						)}
						href={getBillingPortalUrl()}
						nyomiIcon={NyomiSadIcon}
						btnLabel={intl.formatMessage({ id: 'reactivatePlan' })}
						subBtnLabel={intl.formatMessage({ id: 'viewDashboard' })}
					/>
				))
			)}
		</>
	)
}

export default Billing
