import React from 'react'
import { Grid, Skeleton, Theme, useMediaQuery } from '@material-ui/core'

import { ProfileCardContainer } from './ProfileCard.styles'

export const ProfileCardSkeleton = () => {
	const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
	return (
		<ProfileCardContainer isSelected={false}>
			<Grid item container spacing={4}>
				<Grid item container spacing={2} wrap="nowrap" alignItems="center">
					<Grid
						item
						container
						spacing={2}
						alignItems={smUp ? 'center' : 'flex-start'}
						direction={smUp ? 'row' : 'column'}
					>
						<Grid item>
							<Skeleton variant="circular" animation="wave" height={48} width={48} />
						</Grid>
						<Grid container item spacing={1} xs>
							<Grid item container spacing={1} alignItems="center">
								<Grid item>
									<Skeleton width={50} animation="wave" />
								</Grid>
								<Grid item>
									<Skeleton variant="circular" animation="wave" width={24} height={24} />
								</Grid>
								<Grid item>
									<Skeleton variant="circular" animation="wave" width={24} height={24} />
								</Grid>
							</Grid>
							<Grid container item alignItems="center">
								<Grid item>
									<Skeleton animation="wave" width={120} height={14} />
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={2} container justifyContent="flex-end" wrap="nowrap" spacing={1}>
						<div>
							<Skeleton
								variant="rectangular"
								animation="wave"
								width={72}
								height={36}
								style={{ borderTopLeftRadius: 110, borderTopRightRadius: 110 }}
							/>
							<Skeleton
								variant="text"
								animation="wave"
								width={65}
								height={13}
								sx={{ ml: 0.4 }}
							/>
						</div>
					</Grid>
				</Grid>
				<Grid container item justifyContent="space-between" alignItems="center">
					<Grid item>
						<Skeleton
							variant="text"
							animation="wave"
							width={100}
							height={30}
							style={{ borderRadius: 10 }}
						/>
					</Grid>
					<Grid item>
						<Skeleton variant="text" animation="wave" width={120} height={16} />
					</Grid>
				</Grid>
			</Grid>
		</ProfileCardContainer>
	)
}

export default ProfileCardSkeleton
