import React from 'react'
import { useTheme } from 'styled-components'
import { Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { FilterItemContainer } from './FilterItem.style'

export interface FilterItemProps {
	label: string
	count: number
	onClick(e?: React.MouseEvent<HTMLButtonElement>): void
}

export const FilterItem = ({ label, count, onClick }: FilterItemProps) => {
	const theme = useTheme()
	return (
		<FilterItemContainer onClick={onClick}>
			<Typography variant="paragraph1" color={count ? 'primary' : theme.text.description}>
				{label} {count > 0 && `(${count})`}
			</Typography>
			<ExpandMoreIcon
				sx={{ ml: 0.4, mt: 0.2, fontSize: 16, fill: theme.text.description }}
			/>
		</FilterItemContainer>
	)
}

export default FilterItem
