import React from 'react'
import { IntlProvider } from 'react-intl'

import { en } from './locales'

export interface LocaleProviderProps {
	/**
	 * defines the locale settings of the provider
	 */
	locale?: string
	/**
	 * defines the default locale settings of the provider
	 */
	defaultLocale?: string
	/**
	 * react children prop
	 */
	children?: React.ReactNode
}

export const LocaleProvider = ({
	children,
	locale = 'en',
	defaultLocale = 'en'
}: LocaleProviderProps) => {
	return (
		<IntlProvider locale={locale} defaultLocale={defaultLocale} messages={en}>
			{children}
		</IntlProvider>
	)
}
