import React, { useEffect, useRef } from 'react'
import * as echarts from 'echarts'

const CustomGauge = ({ percentage }) => {
	const chartRef = useRef(null)
	const myChartRef = useRef<echarts.ECharts | null>(null)

	useEffect(() => {
		if (chartRef.current) {
			if (!myChartRef.current) {
				myChartRef.current = echarts.init(chartRef.current)
			}

			const ROOT_PATH = 'https://echarts.apache.org/examples'
			const _panelImageURL = `${ROOT_PATH}/data/asset/img/custom-gauge-panel.png`
			const _valOnRadianMax = 200
			const _outerRadius = 140
			const _innerRadius = 120
			const _insidePanelRadius = 100

			const renderItem = (params, api) => {
				const valOnRadian = api.value(1)
				const coords = api.coord([api.value(0), valOnRadian])
				const polarEndRadian = coords[3]
				const coordSys = params.coordSys

				return {
					type: 'group',
					children: [
						{
							type: 'image',
							style: {
								image: _panelImageURL,
								x: coordSys.cx - _outerRadius,
								y: coordSys.cy - _outerRadius,
								width: _outerRadius * 2,
								height: _outerRadius * 2
							},
							clipPath: {
								type: 'sector',
								shape: {
									cx: coordSys.cx,
									cy: coordSys.cy,
									r: _outerRadius,
									r0: _innerRadius,
									startAngle: 0,
									endAngle: -polarEndRadian
								}
							}
						},
						{
							type: 'circle',
							shape: {
								cx: coordSys.cx,
								cy: coordSys.cy,
								r: _insidePanelRadius
							},
							style: {
								fill: '#fff',
								shadowBlur: 25,
								shadowColor: 'rgba(76,107,167,0.4)'
							}
						},
						{
							type: 'text',
							style: {
								text: `${percentage}%`,
								fontSize: 30,
								fontWeight: 500,
								x: coordSys.cx,
								y: coordSys.cy,
								fill: 'rgb(0,50,190)',
								align: 'center',
								verticalAlign: 'middle'
							}
						}
					]
				}
			}

			const option = {
				dataset: {
					source: [[1, (percentage / 100) * _valOnRadianMax]]
				},
				angleAxis: {
					type: 'value',
					startAngle: 0,
					show: false,
					min: 0,
					max: _valOnRadianMax
				},
				radiusAxis: {
					type: 'value',
					show: false
				},
				polar: {},
				series: [
					{
						type: 'custom',
						coordinateSystem: 'polar',
						renderItem
					}
				]
			}

			myChartRef.current.setOption(option)

			return () => {
				myChartRef.current?.dispose()
				myChartRef.current = null
			}
		}
	}, [percentage])

	return (
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
			<div ref={chartRef} style={{ width: '300px', height: '300px' }} />
		</div>
	)
}

export default CustomGauge
