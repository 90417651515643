import React from 'react'

import { NoRecordLabel } from './NoRecord.styles'

export interface NoRecordProps {
	label: string
}

export const NoRecord = ({ label }: NoRecordProps) => {
	return <NoRecordLabel align="center">{label}</NoRecordLabel>
}

export default NoRecord
